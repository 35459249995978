import React from 'react';

import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { SkeletonBasic } from '../../../componentUI/skeleton';

import {
  AppRouterSkeletonWrapperStyled,
  FooterMainContainerBlockFourStyled,
  FooterMainContainerBlockOneStyled,
  FooterMainContainerBlockThreeStyled,
  FooterMainContainerBlockTwoStyled,
  FooterMainContainerStyled,
  HeaderMainContainerStyled,
  MainContainerStyled,
  MainMainContainerBlockFiveStyled,
  MainMainContainerBlockFourStyled,
  MainMainContainerBlockOneStyled,
  MainMainContainerBlockSevenStyled,
  MainMainContainerBlockSixStyled,
  MainMainContainerBlockThreeStyled,
  MainMainContainerBlockTwoStyled,
  MainMainContainerStyled,
} from './styled';

export const BalanceMainSkeleton = () => {
  return (
    <AppRouterSkeletonWrapperStyled>
      <MainContainerStyled>
        <HeaderMainContainerStyled>
          <SkeletonBasic width="140px" height="20px" bgColor={COLOR_MAP.controls.skeleton.content2} />
        </HeaderMainContainerStyled>

        <MainMainContainerStyled>
          <MainMainContainerBlockOneStyled>
            <MainMainContainerBlockThreeStyled>
              <SkeletonBasic width="60px" height="60px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="192px" height="20px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            </MainMainContainerBlockThreeStyled>

            <MainMainContainerBlockFourStyled>
              <SkeletonBasic width="298px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="378px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="272px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            </MainMainContainerBlockFourStyled>

            <MainMainContainerBlockFiveStyled>
              <SkeletonBasic width="256px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            </MainMainContainerBlockFiveStyled>
          </MainMainContainerBlockOneStyled>

          <MainMainContainerBlockTwoStyled>
            <MainMainContainerBlockSixStyled>
              <SkeletonBasic width="60px" height="60px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="138px" height="20px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            </MainMainContainerBlockSixStyled>

            <MainMainContainerBlockSevenStyled>
              <SkeletonBasic width="100%" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="100%" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="100%" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            </MainMainContainerBlockSevenStyled>
          </MainMainContainerBlockTwoStyled>
        </MainMainContainerStyled>

        <FooterMainContainerStyled>
          <FooterMainContainerBlockOneStyled>
            <SkeletonBasic width="339px" height="20px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            <SkeletonBasic width="80px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
          </FooterMainContainerBlockOneStyled>

          <FooterMainContainerBlockTwoStyled>
            <SkeletonBasic width="248px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            <FooterMainContainerBlockFourStyled>
              <SkeletonBasic width="88px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="48px" height="10px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            </FooterMainContainerBlockFourStyled>
          </FooterMainContainerBlockTwoStyled>

          <FooterMainContainerBlockThreeStyled>
            <SkeletonBasic width="1800px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content1} />
            <SkeletonBasic width="1800px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content1} />
            <SkeletonBasic width="1800px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content1} />
            <SkeletonBasic width="1800px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content1} />
            <SkeletonBasic width="1800px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content1} />
            <SkeletonBasic width="1800px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content1} />
            <SkeletonBasic width="1800px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content1} />
          </FooterMainContainerBlockThreeStyled>
        </FooterMainContainerStyled>
      </MainContainerStyled>
    </AppRouterSkeletonWrapperStyled>
  );
};
