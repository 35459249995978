import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { getCheckStatuses } from '../product-api';

export const useCheckStatusesQuery = () => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [QUERY_KEY.getCheckStatuses],
    queryFn: async () => {
      const { data } = await getCheckStatuses();

      return data;
    },

    onError: () => {
      dispatch(
        addToast({
          text: 'Something went wrong. Please try again.',
        }),
      );
    },
  });
};
