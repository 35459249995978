import styled from 'styled-components';
import { Button } from '../../componentUI/simple/Button';
import { adaptive } from '../../componentUI/screen';
import { Picture } from '../../componentUI/complex/Picture';

export const ButtonSubmit = styled(Button)`
  ${adaptive.maxWidth.mobile} {
    margin: 24px 0 0;
    width: 100%;
  }
`;

export const Promo = styled(Picture)`
  margin: 0 auto;

  ${adaptive.maxWidth.mobile} {
    display: none;
  }
`;

export const Container = styled.div`
  width: 100%;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  gap: 20px;

  padding-bottom: 45px;
`;

export const Image = styled(Picture)`
  cursor: pointer;
  display: block;
  position: relative;
  left: 325px;
  bottom: 30px;

  ${adaptive.maxWidth.mobile} {
    left: 285px;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
`;

export const Label = styled.label`
  padding-left: 11px;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 140%;
  cursor: pointer;
`;

export const RadioButton = styled.input`
  position: relative;
  width: 18px;
  margin: 0 10px 0 0;
  cursor: pointer;

  :before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid gray;
  }

  :after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    visibility: visible;
  }

  :checked:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #cbad73;
  }

  :checked:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #cbad73;
    transform: translate(-50%, -50%);
    visibility: visible;
  }
`;

export const WrapperCheckbox = styled.div`
  display: flex;
  justify-self: start;
  margin-top: 20px;
`;

export const InputCheckbox = styled.input`
  width: 20px;
  height: 20px;
  accent-color: #c8ac79;
  border: 1px solid #c7ccd0;
  outline: none;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }
`;

export const Link = styled.a`
  color: #c8ac79;
  text-decoration: none;
`;
