export const createRandomUUID = () => {
  try {
    // Attempt to use the native crypto.randomUUID if available
    return crypto.randomUUID();
  } catch (e) {
    // Fallback to a custom UUID generator if crypto.randomUUID is not available
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.floor(Math.random() * 16);
      const v = c === 'x' ? r : (r % 4) + 8;
      return v.toString(16);
    });
  }
};
