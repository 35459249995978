import styled, { CSSProperties } from 'styled-components';

const CompletionRegistrationHeaderBlockStyled = styled.div`
  width: 100%;
  padding: 12px 40px;

  display: flex;
  justify-content: space-between;
`;

const stylesForButtons: CSSProperties = {
  gap: '6px',
  backgroundColor: 'transparent',
};

export { CompletionRegistrationHeaderBlockStyled, stylesForButtons };
