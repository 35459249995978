import { useMutation } from '@tanstack/react-query';

import { QUERY_KEY } from '../../../common';

import { userUTM } from '../userUTM';

import { userUTMRequest } from '../types';

export const useUserUTMMutate = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.userUTMMutate],
    mutationFn: async (params: userUTMRequest) => {
      const { data } = await userUTM(params);

      return data;
    },
  });
};
