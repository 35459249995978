import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';
import { getNotificationSetting } from '../index';

import { addToast } from '../../../redux/reducers/toast-reducer';

export const useNotificationsSettingQuery = () => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [QUERY_KEY.getNotificationsSetting],
    queryFn: async () => {
      const { data } = await getNotificationSetting();

      return data;
    },
    onError: () => {
      dispatch(
        addToast({
          text: 'Something went wrong. Please try again.',
        }),
      );
    },
  });
};
