import React, { useState } from 'react';
import { EyeIcon, EyeOffIcon } from '../../../common/icons/SvgInline/SvgIcons_3';

enum typeInput {
  password = 'password',
  text = 'text',
}

export const usePasswordIconManage = (password: string) => {
  const [passTypeInput, setPassTypeInput] = useState(typeInput.password);

  const eyeClickHandler = () => {
    setPassTypeInput((prev) => (prev === typeInput.password ? typeInput.text : typeInput.password));
  };

  const rightIconPassword =
    Boolean(password) && passTypeInput === typeInput.password ? (
      <EyeIcon clickHandler={eyeClickHandler} />
    ) : (
      <EyeOffIcon clickHandler={eyeClickHandler} />
    );

  return { rightIconPassword, passTypeInput };
};
