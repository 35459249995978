import { SelectedSetFiltersType } from '../../../../../containers/AuthenticationsTablet/types';
import { useOptionsMasterDropdownList } from '../constants';

/** Custom hook to get filtered options */
export const useOptionsMasterSelect = (selectedSetFilters: SelectedSetFiltersType) => {
  const optionsMasterDropdownList = useOptionsMasterDropdownList();
  const keys = Object.keys(selectedSetFilters);

  const nameObject = keys.map((key) => {
    return selectedSetFilters[key].nameObject;
  });

  return optionsMasterDropdownList.filter(({ value }) => {
    return !nameObject.includes(value);
  });
};
