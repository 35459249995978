import React from 'react';
import styled from 'styled-components';

/* eslint-disable max-len */

const TimeIcon = ({ size = '14' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 12.8334C9.89949 12.8334 12.25 10.4829 12.25 7.58337C12.25 4.68388 9.89949 2.33337 7 2.33337C4.1005 2.33337 1.75 4.68388 1.75 7.58337C1.75 10.4829 4.1005 12.8334 7 12.8334Z"
      stroke="#666666"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 4.66663V7.72913L9.33333 8.74996"
      stroke="#666666"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7 1.16663L7 2.33329" stroke="#666666" strokeWidth="1.2" />
    <path d="M8.16683 0.718262H5.8335" stroke="#666666" strokeWidth="1.2" strokeLinecap="round" />
    <path d="M2.50635 2.96008L3.18621 3.63995" stroke="#666666" strokeWidth="1.2" strokeLinecap="round" />
    <path d="M11.4789 2.96008L10.8198 3.61917" stroke="#666666" strokeWidth="1.2" strokeLinecap="round" />
  </svg>
);

const UploadPhotoIcon = ({ size = '24', color = '#ffffff' }: { size?: string; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
      stroke={color}
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M17 8L12 3L7 8" stroke={color} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 3V15" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const FakeIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10230_17454)">
      <path
        d="M10 18.3332C14.6024 18.3332 18.3334 14.6022 18.3334 9.99984C18.3334 5.39746 14.6024 1.6665 10 1.6665C5.39765 1.6665 1.66669 5.39746 1.66669 9.99984C1.66669 14.6022 5.39765 18.3332 10 18.3332Z"
        stroke="#DE4141"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.5 7.5L7.5 12.5" stroke="#DE4141" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 7.5L12.5 12.5" stroke="#DE4141" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_10230_17454">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const LegitIcon = ({ color = '#ffffff', size = '20' }: { color?: string; size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10230_17722)">
      <path
        d="M18.3334 9.23355V10.0002C18.3323 11.7972 17.7504 13.5458 16.6745 14.9851C15.5985 16.4244 14.0861 17.4773 12.3628 17.9868C10.6395 18.4963 8.79774 18.4351 7.11208 17.8124C5.42642 17.1896 3.98723 16.0386 3.00915 14.5311C2.03108 13.0236 1.56651 11.2403 1.68475 9.44714C1.80299 7.65402 2.49769 5.94715 3.66525 4.58111C4.83281 3.21506 6.41068 2.26303 8.16351 1.867C9.91635 1.47097 11.7502 1.65216 13.3917 2.38355"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 3.3335L10 11.6752L7.5 9.17516"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10230_17722">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const CanceledIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10230_30202)">
      <path
        d="M3.9884 4.22879C2.55048 5.72626 1.66669 7.75984 1.66669 9.99984C1.66669 14.6022 5.39765 18.3332 10 18.3332C12.3912 18.3332 14.5472 17.326 16.0668 15.7128M6.83631 2.28803C7.81182 1.88741 8.88012 1.6665 10 1.6665C14.6024 1.6665 18.3334 5.39746 18.3334 9.99984C18.3334 11.2282 18.0676 12.3945 17.5904 13.4443"
        stroke="#AAAAAA"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line x1="2.79806" y1="2.5" x2="17.5" y2="17.202" stroke="#AAAAAA" strokeWidth="1.6" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_10230_30202">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SkipOutcomeIcon = ({ size = '16', color = '#DDDDDD' }: { size?: string; color?: string }) => (
  <svg width={size} height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line y1="1.3" x2="16" y2="1.3" stroke={color} strokeWidth="1.4" />
  </svg>
);

const LoaderStyle = styled.div<{ size?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
  ${({ size }) => size && `width: ${size}px; height: ${size}px;`}

  display: inline-block;

  animation: rotation 2s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoaderCertificate = ({ size = '20', color = '#AAAAAA' }: { size?: string; color?: string }) => (
  <LoaderStyle size={size}>
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10230_17396)">
        <path d="M10 1.6665V4.99984" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 15V18.3333" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M4.10834 4.1084L6.46667 6.46673"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5333 13.5332L15.8917 15.8915"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M1.66669 10H5.00002" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 10H18.3333" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M4.10834 15.8915L6.46667 13.5332"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5333 6.46673L15.8917 4.1084"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10230_17396">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </LoaderStyle>
);

const ViewCertificateIcon = ({ size = '20', color = '#CBAD73' }: { size?: string; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 8.3335H10" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5 11.6665H7.5" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15.8333 13.3332C17.6743 13.3332 19.1667 11.8408 19.1667 9.99984C19.1667 8.15889 17.6743 6.6665 15.8333 6.6665C13.9924 6.6665 12.5 8.15889 12.5 9.99984C12.5 11.8408 13.9924 13.3332 15.8333 13.3332Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9383 13.0289L13.3333 17.4998L15.8333 15.8551L18.3333 17.4998L17.7283 13.0234"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1666 5.55386V4.16667C19.1666 3.24619 18.4205 2.5 17.5 2.5H2.49998C1.5795 2.5 0.833313 3.24619 0.833313 4.16667V15.8333C0.833313 16.7538 1.5795 17.5 2.49998 17.5H11.0013"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const AddCertificateIcon = ({ size = '20', color = '#CBAD73' }: { size?: string; color?: string }) => (
  <div style={{ display: 'flex', alignItems: 'center', minWidth: '20px' }}>
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10230_17460)">
        <path
          d="M10 18.3332C14.6024 18.3332 18.3334 14.6022 18.3334 9.99984C18.3334 5.39746 14.6024 1.6665 10 1.6665C5.39765 1.6665 1.66669 5.39746 1.66669 9.99984C1.66669 14.6022 5.39765 18.3332 10 18.3332Z"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M10 6.6665V13.3332" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.66669 10H13.3334" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_10230_17460">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

const CopyPasteIcon = ({ size = '20', color = '#CBAD73' }: { size?: string; color?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3333 3.33334H14.9999C15.4419 3.33334 15.8659 3.50894 16.1784 3.8215C16.491 4.13406 16.6666 4.55798 16.6666 5.00001V16.6667C16.6666 17.1087 16.491 17.5326 16.1784 17.8452C15.8659 18.1577 15.4419 18.3333 14.9999 18.3333H4.99992C4.55789 18.3333 4.13397 18.1577 3.82141 17.8452C3.50885 17.5326 3.33325 17.1087 3.33325 16.6667V5.00001C3.33325 4.55798 3.50885 4.13406 3.82141 3.8215C4.13397 3.50894 4.55789 3.33334 4.99992 3.33334H6.66659"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5001 1.66666H7.50008C7.03984 1.66666 6.66675 2.03975 6.66675 2.49999V4.16666C6.66675 4.62689 7.03984 4.99999 7.50008 4.99999H12.5001C12.9603 4.99999 13.3334 4.62689 13.3334 4.16666V2.49999C13.3334 2.03975 12.9603 1.66666 12.5001 1.66666Z"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export {
  AddCertificateIcon,
  CanceledIcon,
  CopyPasteIcon,
  FakeIcon,
  LegitIcon,
  LoaderCertificate,
  SkipOutcomeIcon,
  TimeIcon,
  UploadPhotoIcon,
  ViewCertificateIcon,
};
