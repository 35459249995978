import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { Text, TEXT_SIZE, TEXT_WEIGHT } from '../../../../componentUI/simple/Text';
import { PasswordStrength, ValidPassText } from './components';
import { ThemeEnum } from '../../CheckPassword';

import {
  DescriptionConditionsStyled,
  VisualizationPassSecurityStyled,
  WrapperDescriptionConditionsStyled,
  WrapperPasswordStrengthStyled,
} from './styled';

type Props = {
  infoValidPass: {
    isLength: boolean;
    isNumber: boolean;
    isSpecialSymbol: boolean;
    isLowerCase: boolean;
    isUpperCase: boolean;
  };
  password: string;
  designTheme: ThemeEnum;
};

const themeSettings = {
  black: COLOR_MAP.text.black,
  white: COLOR_MAP.text.white,
};

export const VisualizationPassSecurity = ({ infoValidPass, password, designTheme }: Props) => {
  const { t } = useTranslation();
  const { isLength, isNumber, isSpecialSymbol, isLowerCase, isUpperCase } = infoValidPass;

  return (
    <VisualizationPassSecurityStyled>
      <WrapperDescriptionConditionsStyled>
        <Text size={TEXT_SIZE.sub} weight={TEXT_WEIGHT.bold} colorText={themeSettings[designTheme]}>
          {t('auth.signUpPage.passwordSecurity.title')}
        </Text>

        <DescriptionConditionsStyled>
          <div>
            <ValidPassText
              designTheme={designTheme}
              verificationStatus={isLength}
              messageText={t('auth.signUpPage.passwordSecurity.conditions.minLength')}
            />
            <ValidPassText
              designTheme={designTheme}
              verificationStatus={isNumber}
              messageText={t('auth.signUpPage.passwordSecurity.conditions.oneNumber')}
            />
            <ValidPassText
              designTheme={designTheme}
              verificationStatus={isSpecialSymbol}
              messageText={t('auth.signUpPage.passwordSecurity.conditions.specialChar')}
            />
          </div>

          <div>
            <ValidPassText
              designTheme={designTheme}
              verificationStatus={isLowerCase}
              messageText={t('auth.signUpPage.passwordSecurity.conditions.lowerCase')}
            />
            <ValidPassText
              designTheme={designTheme}
              verificationStatus={isUpperCase}
              messageText={t('auth.signUpPage.passwordSecurity.conditions.upperCase')}
            />
          </div>
        </DescriptionConditionsStyled>
      </WrapperDescriptionConditionsStyled>

      <WrapperPasswordStrengthStyled>
        <Text size={TEXT_SIZE.sub} weight={TEXT_WEIGHT.bold} colorText={themeSettings[designTheme]}>
          {t('auth.signUpPage.passwordSecurity.strengthTitle')}
        </Text>

        <PasswordStrength password={password} />
      </WrapperPasswordStrengthStyled>
    </VisualizationPassSecurityStyled>
  );
};
