import styled from 'styled-components';

const SelectWithTitleWrapper = styled.label`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`;

export { SelectWithTitleWrapper };
