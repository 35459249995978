import styled from 'styled-components';

const ErrorInputReactSelectBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const WrapperTextErrorStyled = styled.div`
  display: flex;
`;
export { ErrorInputReactSelectBlockStyled, WrapperTextErrorStyled };
