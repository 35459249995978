import styled from 'styled-components';
import { Text } from '../Text';

export const Link = styled(Text)`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
