import styled from 'styled-components';

import { COLOR_MAP } from '../../componentUI/colorsMap';
import { Title, TITLE_WEIGHT } from '../../componentUI/simple/Title';
import { truthfulAdaptive } from '../../componentUI/screen';

const TypeTitle = styled(Title).attrs({
  colorText: COLOR_MAP.text.darkGrey,
  weight: TITLE_WEIGHT.normal,
})<{
  isChosen: boolean;
}>`
  padding: 8px 16px;

  border-bottom: 2px solid ${COLOR_MAP.text.lightGrey};

  ${({ isChosen }) =>
    isChosen &&
    `color: ${COLOR_MAP.controls.clickableNormal}; border-bottom: 2px solid ${COLOR_MAP.controls.clickableNormal};`}

  ${({ isChosen }) => !isChosen && '&:hover { cursor: pointer; }'}
  ${truthfulAdaptive.maxWidth.desktopS} {
    font-size: 18px;
    line-height: 22px;
  }

  &:hover {
    color: ${COLOR_MAP.controls.clickableHover};
    border-bottom: 2px solid ${COLOR_MAP.controls.clickableHover};
  }
`;

export { TypeTitle };
