import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAccountInfoQuery } from '../../../../api/auth/hooks';
import { useMatchMedia } from '../../../../hooks';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { BUTTON_SIZE, TertiaryButton } from '../../../../componentUI/simple/Button';
import { Text, TEXT_WEIGHT } from '../../../../componentUI/simple/Text';
import { LogOutIcon } from '../../../../common/icons/SvgInline/SvgIcons_3';
import { SettingIcon } from '../../../../common/icons/SvgInline/SvgIcons_6';
import { HorizonLineStyled, UserAreaStyled, WrapperOtherStyled, WrapperUserSettingStyled } from './styled';

// функционал блока "Верифицированный партнер", на текущий момент не используется

// import { CheckmarkIcon } from '../../../common/icons/SvgInline/SvgIcons_8';
// import { useUserBusinessInfoQuery } from '../../../api/verified-partner/hooks/useUserBusinessInfoQuery';

// const { data: VerifiedPartner } = useUserBusinessInfoQuery();

// {
//   VerifiedPartner?.isVerified && (
//     <BieStyled>
//       <CheckmarkIcon />
//       <Text size={TEXT_SIZE.annotation} colorText={COLOR_MAP.text.light}>
//         Verified Partner
//       </Text>
//     </BieStyled>
//   );
// }

export const UserArea = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useQueryClient();
  const { isDesktopS } = useMatchMedia();

  const { data: accountData } = useAccountInfoQuery(null, true);

  const LogOutAccount = () => {
    client.removeQueries();
    Cookies.remove('jwt');
    Cookies.remove('jwt-refresh');
    navigate('/signin');
  };

  return (
    <UserAreaStyled>
      <HorizonLineStyled />

      {!isDesktopS && (
        <WrapperUserSettingStyled to="/account-settings/general-info">
          <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.white}>
            {accountData?.firstName}
          </Text>
          <SettingIcon size="28" />
        </WrapperUserSettingStyled>
      )}

      <WrapperOtherStyled>
        <TertiaryButton
          size={BUTTON_SIZE.extraSmall}
          style={{ padding: '0', gap: '8px', fontFamily: 'Open Sans' }}
          colorsMap={{
            clickable: `${COLOR_MAP.controls.clickableNormal}`,
            clickableHover: `${COLOR_MAP.controls.clickableHover}`,
            clickablePressed: `${COLOR_MAP.controls.clickablePressed}`,
            clickableDisabled: `${COLOR_MAP.controls.clickableDisabled}`,
          }}
          backgroundColor="transparent"
          onClick={LogOutAccount}
        >
          <LogOutIcon />
          {t('baseLayout.userArea.logOut')}
        </TertiaryButton>
      </WrapperOtherStyled>
      <HorizonLineStyled />
    </UserAreaStyled>
  );
};
