import { SortProduct } from '../../../../../api/product/type';

/** проверяем какая кнопка-стрелка в заголовке
 * нажата и исходя из этого скрываем её из заголовка
 */

export const processingClickFilter = (selectedFilter: SortProduct | null, buttonFilter: SortProduct) => {
  if (selectedFilter === buttonFilter) {
    return false;
  }
  return true;
};
