import styled, { CSSProperties } from 'styled-components';
import { truthfulAdaptive } from '../../../../componentUI/screen';

const OTPCodeStyled = styled.div`
  align-self: center;

  width: 100%;
  max-width: 484px;

  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 24px;

  ${truthfulAdaptive.maxWidth.mobile} {
    align-self: start;
  }
`;

const WrapperMainContextStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const OTPCodeFooterStyled = styled.footer`
  margin-top: 16px;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const stylesForButtons: CSSProperties = {
  padding: '0',
  border: 'none',
  fontFamily: 'Open Sans, sans-serif',
};

export { OTPCodeFooterStyled, OTPCodeStyled, stylesForButtons, WrapperMainContextStyled };
