import styled from 'styled-components';

const WrapperIndicatorStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 5px;
  gap: 0 4px;
`;

const IndicatorStyled = styled.div<{ indicator: boolean }>`
  background: ${({ indicator }) =>
    indicator ? 'linear-gradient(225deg, #6BCBA9 0%, #429F5C 100%);' : 'rgba(203, 173, 115, 0.2);'};
  border-radius: 2px;
`;

export { IndicatorStyled, WrapperIndicatorStyled };
