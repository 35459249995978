import React, { useCallback } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TrashIcon } from '../../../common/icons/SvgInline/SvgIcons_2';
import { useStyledModal } from '../../../hooks';
import { COLOR_MAP } from '../../colorsMap';
import { Z_INDEX_LAYERS } from '../../constants';
import { truthfulAdaptive } from '../../screen';
import { noop, VisuallyHiddenInput } from '../../utils';
import { BrandedButton, Button } from '../Button';
import { Text, TEXT_SIZE } from '../Text';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../Title';
import { Props } from './Radio';

const RadioContainer = styled.div`
  position: relative;

  ${truthfulAdaptive.maxWidth.mobile} {
    width: 100%;
  }
`;

const RadioWrapper = styled.label<{
  isChecked: boolean;
  disabled?: boolean;
  isBgColor?: boolean;
}>`
  width: 184px;
  padding: 20px 0;
  display: grid;
  justify-content: center;
  gap: 12px;
  background-color: ${COLOR_MAP.background.white};
  box-shadow: 0 8px 16px rgba(150, 112, 38, 0.05), 0 16px 28px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;

  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')};
  ${({ isChecked }) =>
    isChecked ? `border: 2px solid ${COLOR_MAP.text.brandColor};` : `border: 2px solid ${COLOR_MAP.text.white}`};
  ${({ isChecked, isBgColor }) =>
    isChecked && isBgColor
      ? `background-color: ${COLOR_MAP.background.brandColorSnow};`
      : `background-color: ${COLOR_MAP.background.white}`};

  user-select: none;
  cursor: pointer;

  ${truthfulAdaptive.maxWidth.mobile} {
    width: 100%;
  }
`;

const stylesForButtons: CSSProperties = {
  position: 'absolute',
  right: '-4px',
  top: '-4px',
  padding: '4px',
  backgroundColor: COLOR_MAP.background.white,
  boxShadow: '0px 4px 9px rgba(150, 112, 38, 0.05), 0px 5px 14px rgba(0, 0, 0, 0.08)',
};

const PictureWrapperStyle = styled.div`
  width: 164px;
  height: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WrapperBtnStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const stylesForModal: CSSProperties = {
  maxWidth: '480px',
  alignItems: 'center',
};

const SelectedDefaultTemplateStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 4px 8px;
  background-color: ${COLOR_MAP.background.brandColor};
  border-radius: 8px 0;
  z-index: ${Z_INDEX_LAYERS.other};
  cursor: pointer;
  max-width: 70%;
`;

const WrapperImgStyled = styled.div`
  position: relative;
`;

type RadioChipsWithPhotoProps = Props & {
  url: string;
  nameTemplate: string;
  thumbnail: string | null;
  templateOrientation: 'landscape' | 'portrait';
  isBgColor?: boolean;
  onDelete?: (value: string) => void;
  isDefault?: boolean | null;
};

const templateOrientationList = {
  landscape: '25px',
  portrait: '15px',
};

export const RadioChipsPictureInPicture = ({
  onChange = noop,
  onDelete = noop,
  checkedValue,
  disabled,
  name,
  nameTemplate,
  value,
  url,
  thumbnail,
  templateOrientation,
  isBgColor,
  isDefault,
}: RadioChipsWithPhotoProps) => {
  const { t } = useTranslation(); // Initialize useTranslation
  const [Modal, toggleModal] = useStyledModal();

  const isChecked = checkedValue === value;

  const changeHandler = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  const deleteHandler = useCallback(() => {
    onDelete(value);
  }, [onDelete, value]);

  const onClose = () => {
    toggleModal();
  };

  return (
    <>
      <RadioContainer>
        {onDelete !== noop ? (
          <BrandedButton style={stylesForButtons} onClick={() => onClose()}>
            <TrashIcon />
          </BrandedButton>
        ) : null}

        {isDefault && (
          <SelectedDefaultTemplateStyled>
            <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.white}>
              {t('radioChipsPictureInPicture.defaultTemplate')}
            </Text>
          </SelectedDefaultTemplateStyled>
        )}

        <RadioWrapper isBgColor={isBgColor} disabled={disabled} isChecked={isChecked}>
          <PictureWrapperStyle>
            <WrapperImgStyled>
              <img src={url} alt="template" width="auto" height="auto" />

              {thumbnail && (
                <img
                  src={thumbnail}
                  alt="thumbnail"
                  width="16px"
                  height="16px"
                  style={{
                    position: 'absolute',
                    top: templateOrientationList[templateOrientation],
                    right: '20px',
                    borderRadius: '2px',
                  }}
                />
              )}
            </WrapperImgStyled>
          </PictureWrapperStyle>

          <VisuallyHiddenInput type="radio" onChange={changeHandler} checked={isChecked} name={name} value={value} />
          <Text style={{ alignSelf: 'end', textAlign: 'center' }}>{nameTemplate}</Text>
        </RadioWrapper>
      </RadioContainer>

      <Modal stylesForModal={stylesForModal} onClose={toggleModal}>
        <Title size={TITLE_SIZE.h4} weight={TITLE_WEIGHT.normal}>
          {t('radioChipsPictureInPicture.confirmDeleteTitle')}
        </Title>

        <Text>{t('radioChipsPictureInPicture.confirmDeleteDescription')}</Text>

        <WrapperBtnStyle>
          <Button
            onClick={() => {
              deleteHandler();
              onClose();
            }}
            isStretch
          >
            {t('radioChipsPictureInPicture.confirmDeleteYes')}
          </Button>
          <Button onClick={() => onClose()} isStretch>
            {t('radioChipsPictureInPicture.confirmDeleteNo')}
          </Button>
        </WrapperBtnStyle>
      </Modal>
    </>
  );
};
