import React, { MouseEventHandler } from 'react';

type Props = {
  onClick: MouseEventHandler<HTMLElement>;
  disabled: boolean;
};

const LeftNav = React.memo(({ disabled, onClick }: Props) => {
  return <button disabled={disabled} onClick={onClick} aria-label="Previous Slide" style={{ display: 'none' }} />;
});

LeftNav.displayName = 'LeftNav';

export { LeftNav };
