import { useTranslation } from 'react-i18next';
import { NAME_OBJECT_GET_PRODUCT, SelectDropDownListType } from '../types';

const { publicId, date, brand, modelName, sku, checkStatus } = NAME_OBJECT_GET_PRODUCT;

type formingDropDownListType = {
  masterFilter: NAME_OBJECT_GET_PRODUCT | null | undefined;
  filterID: string;
};

export const useFormingDropDownList = ({
  masterFilter,
  filterID,
}: formingDropDownListType): SelectDropDownListType | null => {
  const { t } = useTranslation();

  const INCLUDE_VALUER_DROPDOWNLIST_ONE = [
    { key: 'is', value: true, label: t('authenticationsTablet.selectDropDown.is') },
    { key: 'is not', value: false, label: t('authenticationsTablet.selectDropDown.isNot') },
  ];

  const INCLUDE_VALUER_DROPDOWNLIST_TWO = [
    { key: 'contains', value: true, label: t('authenticationsTablet.selectDropDown.contains') },
    { key: 'doesn’t contain', value: false, label: t('authenticationsTablet.selectDropDown.doesntContain') },
  ];

  const VALUER_DROPDOWNLIST_DATE = [
    { key: 'Today', value: 'today', label: t('authenticationsTablet.selectDropDown.today') },
    { key: 'Last 7 days', value: 'last7', label: t('authenticationsTablet.selectDropDown.last7Days') },
    { key: 'Last 30 days', value: 'last30', label: t('authenticationsTablet.selectDropDown.last30Days') },
    { key: 'Last 90 days', value: 'last90', label: t('authenticationsTablet.selectDropDown.last90Days') },
    { key: 'Custom', value: 'custom', label: t('authenticationsTablet.selectDropDown.custom') },
  ];

  // Determine the correct dropdown list based on the selected filter
  switch (masterFilter) {
    case publicId:
      return {
        id: filterID,
        nameObject: publicId,
        dropDownList: INCLUDE_VALUER_DROPDOWNLIST_TWO,
      };
    case date:
      return {
        id: filterID,
        nameObject: date,
        dropDownList: VALUER_DROPDOWNLIST_DATE,
      };
    case brand:
      return {
        id: filterID,
        nameObject: brand,
        dropDownList: INCLUDE_VALUER_DROPDOWNLIST_ONE,
      };
    case modelName:
      return {
        id: filterID,
        nameObject: modelName,
        dropDownList: INCLUDE_VALUER_DROPDOWNLIST_TWO,
      };
    case sku:
      return {
        id: filterID,
        nameObject: sku,
        dropDownList: INCLUDE_VALUER_DROPDOWNLIST_TWO,
      };
    case checkStatus:
      return {
        id: filterID,
        nameObject: checkStatus,
        dropDownList: INCLUDE_VALUER_DROPDOWNLIST_ONE,
      };
    default:
      return null;
  }
};
