import styled from 'styled-components';

const VisualizationPassSecurityStyled = styled.div`
  padding: 12px 16px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;

  text-align: start;

  border: 1px solid rgba(203, 173, 115, 0.2);
  border-radius: 4px;
`;

const WrapperDescriptionConditionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const DescriptionConditionsStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 12px;
`;

const WrapperPasswordStrengthStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 4px;
`;

export {
  DescriptionConditionsStyled,
  VisualizationPassSecurityStyled,
  WrapperDescriptionConditionsStyled,
  WrapperPasswordStrengthStyled,
};
