import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { getBrandTypeId } from '../brands-api';
import { GetBrandTypeIdRequestType } from '../types';

type ErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

export const useGetBrandIdQuery = ({ id, localeName }: GetBrandTypeIdRequestType, enabled = false) => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [QUERY_KEY.useGetBrandIdQuery, id, localeName],
    queryFn: async () => {
      const { data } = await getBrandTypeId({ id, localeName });

      return data;
    },
    enabled,
    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};
