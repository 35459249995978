import { useMutation } from '@tanstack/react-query';

import { QUERY_KEY } from '../../../common';
import { referralCodesActivate } from '../ReferralCodes';
import { ErrorType } from '../types';

export const useReferralCodesActivateMutate = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.usePromoPackagesActivateMutate],
    mutationFn: async (code: string) => {
      const { data } = await referralCodesActivate(code);

      return data;
    },
    onError: (error: ErrorType) => {
      return error;
    },
  });
};
