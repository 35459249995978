import styled from 'styled-components';

const NotificationsSkeletonStyled = styled.div`
  margin-top: 10px;

  display: flex;
  flex-direction: column;

  gap: 10px;
`;

export { NotificationsSkeletonStyled };
