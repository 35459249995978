import { css, FlattenSimpleInterpolation } from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';

export const TYPE_RADIO = {
  type1: 'type1',
  type2: 'type2',
} as const;

export type radioTypes = keyof typeof TYPE_RADIO;

export const RADIO_BODY_STYLE: Record<radioTypes, FlattenSimpleInterpolation> = {
  type1: css``,
  type2: css`
    padding: 8px 10px;
    border: 1px solid ${COLOR_MAP.controls.clickableNormal};
    border-radius: 4px;

    &:hover {
      border: 1px solid ${COLOR_MAP.controls.clickableHover};
    }
  `,
};

export const RADIO_CHECKED_BODY_STYLE: Record<radioTypes, FlattenSimpleInterpolation> = {
  type1: css``,
  type2: css`
    background-color: rgba(203, 173, 115, 0.2); ;
  `,
};

export const RADIO_INPUT_STYLE: Record<radioTypes, FlattenSimpleInterpolation> = {
  type1: css`
    border: 1px solid ${COLOR_MAP.controls.clickableNormal};

    &:hover {
      border: 1px solid ${COLOR_MAP.controls.clickableHover};
    }
  `,
  type2: css`
    border: 1px solid ${COLOR_MAP.controls.clickableNormal};

    &:hover {
      border: 1px solid ${COLOR_MAP.controls.clickableHover};
    }
  `,
};

export const RADIO_CHECKED_STYLE: Record<radioTypes, FlattenSimpleInterpolation> = {
  type1: css`
    background-color: ${COLOR_MAP.controls.clickableNormal};

    &:hover {
      background-color: ${COLOR_MAP.controls.clickableHover};
    }
  `,
  type2: css`
    background-color: ${COLOR_MAP.controls.clickableNormal};

    &:hover {
      background-color: ${COLOR_MAP.controls.clickableHover};
    }
  `,
};

export const RADIO_SIDE = {
  right: 'right',
  left: 'left',
} as const;

export type radioSideTypes = keyof typeof RADIO_SIDE;

export const ICON_SIDE = {
  right: 'right',
  left: 'left',
} as const;

export type iconSideTypes = keyof typeof ICON_SIDE;
