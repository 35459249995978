import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { getUserTransactionsCreditsInfo } from '../UserCredits';

type Props = {
  limit: number;
  page: number;
  sort?: Array<Array<string>>;
};

export const useUserTransactionsCreditsQuery = ({ limit, page, sort }: Props) => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [QUERY_KEY.getUserTransactionsCreditsInfo, page, limit],
    queryFn: async () => {
      const { data } = await getUserTransactionsCreditsInfo({ limit, page, sort });

      return data;
    },
    onError: () => {
      dispatch(
        addToast({
          text: 'Something went wrong. Please try again.',
        }),
      );
    },
  });
};
