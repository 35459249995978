/* eslint-disable react/no-array-index-key */
import React from 'react';

import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { Hr } from '../../../componentUI/simple/Hr';
import { SkeletonBasic } from '../../../componentUI/skeleton';

import {
  MainContainerCertificateInfoBlockOne,
  MainContainerCertificateInfoBlockTwo,
  MainContainerCertificateInfoStyled,
} from './styled';

export const CertificateInfoSkeleton = () => {
  return (
    <MainContainerCertificateInfoStyled>
      <MainContainerCertificateInfoBlockOne>
        <SkeletonBasic width="100%" height="40px" bgColor={COLOR_MAP.controls.skeleton.content2} />
        <SkeletonBasic width="100%" height="40px" bgColor={COLOR_MAP.controls.skeleton.content2} />
      </MainContainerCertificateInfoBlockOne>

      <Hr style={{ width: '100%' }} />

      <MainContainerCertificateInfoBlockTwo>
        <SkeletonBasic width="100%" height="44px" bgColor={COLOR_MAP.controls.skeleton.content2} />
        <SkeletonBasic width="100%" height="44px" bgColor={COLOR_MAP.controls.skeleton.content1} />
      </MainContainerCertificateInfoBlockTwo>
    </MainContainerCertificateInfoStyled>
  );
};
