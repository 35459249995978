import React from 'react';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TEXT_SIZE, TEXT_WEIGHT, Text } from '../../../../componentUI/simple/Text';
import { TITLE_SIZE, Title } from '../../../../componentUI/simple/Title';
import { SkeletonBasic } from '../../../../componentUI/skeleton';

import {
  MainSeparatorStyled,
  SecondarySeparatorStyled,
  SummaryInformationStyled,
  WrapperCashbackStyled,
  WrapperCreditAndDiscountStyled,
  WrapperTotalLGStyled,
} from './styled';

export const SummaryInformationSkeleton = () => {
  return (
    <SummaryInformationStyled>
      <Title size={TITLE_SIZE.h4}>Summary</Title>

      <WrapperCreditAndDiscountStyled>
        <SkeletonBasic width="100%" height="22px" bgColor={COLOR_MAP.controls.skeleton.content2} />
        <SkeletonBasic width="100%" height="32px" bgColor={COLOR_MAP.controls.skeleton.content1} />
        <SecondarySeparatorStyled />
      </WrapperCreditAndDiscountStyled>

      <WrapperCashbackStyled>
        <Text weight={TEXT_WEIGHT.bold} size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey}>
          Bonus
        </Text>

        <SkeletonBasic width="100%" height="22px" bgColor={COLOR_MAP.controls.skeleton.content2} />
        <SkeletonBasic width="100%" height="22px" bgColor={COLOR_MAP.controls.skeleton.content2} />
        <SecondarySeparatorStyled />
        <SkeletonBasic width="100%" height="22px" bgColor={COLOR_MAP.controls.skeleton.content2} />
      </WrapperCashbackStyled>

      <WrapperTotalLGStyled>
        <SecondarySeparatorStyled />
        <SkeletonBasic width="100%" height="22px" bgColor={COLOR_MAP.controls.skeleton.content2} />
      </WrapperTotalLGStyled>

      <MainSeparatorStyled />

      <SkeletonBasic width="100%" height="22px" bgColor={COLOR_MAP.controls.skeleton.content1} />
    </SummaryInformationStyled>
  );
};
