/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCheckAuthQuery } from '../../api/auth/hooks/useCheckAuthQuery';

import { useGoogleAnalytics } from '../../analytics';

export const HASH_KEY = 'hash_package';

export const Promo = () => {
  useGoogleAnalytics();
  const location = useLocation();
  const navigate = useNavigate();

  const { data: isValidToken } = useCheckAuthQuery(null);

  useEffect(() => {
    const hash: string | undefined = location.pathname.split('/')?.[2];

    if (hash) {
      window.localStorage.setItem(HASH_KEY, hash);
    }

    navigate(isValidToken ? '/' : '/signup');
  }, []);

  return null;
};
