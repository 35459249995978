import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { getBalanceInfo } from '../UserCredits';

type ErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

export const useBalanceUserInfoQuery = () => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [QUERY_KEY.getBalanceUserInfo],
    queryFn: async () => {
      const { data } = await getBalanceInfo();

      return data;
    },
    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something went wrong. Please try again.',
        }),
      );
    },
  });
};
