/* eslint-disable max-len */
import React from 'react';

const PlusIcon = ({ size = '20', color = '#CBAD73' }: { size?: string; color?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 4.1665V15.8332" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.16669 10H15.8334" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const DashboardIcon = ({ size = '28', color = '#ffffff' }: { size?: string; color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 28 28" fill="none">
      <path
        d="M22.1667 3.5H5.83333C4.54467 3.5 3.5 4.54467 3.5 5.83333V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V5.83333C24.5 4.54467 23.4553 3.5 22.1667 3.5Z"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.5 10.5H24.5" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.5 24.5V10.5" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const AuthenticationsIcon = ({ size = '28', color = '#ffffff' }: { size?: string; color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 28 28" fill="none">
      <path
        d="M23.3334 3.5H4.66671C4.04787 3.5 3.45438 3.74583 3.01679 4.18342C2.57921 4.621 2.33337 5.21449 2.33337 5.83333V12.8333C2.33337 15.9275 3.56254 18.895 5.75046 21.0829C6.83381 22.1663 8.11994 23.0256 9.5354 23.6119C10.9509 24.1982 12.468 24.5 14 24.5C17.0942 24.5 20.0617 23.2708 22.2496 21.0829C24.4375 18.895 25.6667 15.9275 25.6667 12.8333V5.83333C25.6667 5.21449 25.4209 4.621 24.9833 4.18342C24.5457 3.74583 23.9522 3.5 23.3334 3.5Z"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33337 11.6667L14 16.3334L18.6667 11.6667"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const BalanceIcon = ({ size = '28', color = '#ffffff' }: { size?: string; color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 28 28" fill="none">
      <path
        d="M24.5 4.66675H3.49996C2.21129 4.66675 1.16663 5.71142 1.16663 7.00008V21.0001C1.16663 22.2887 2.21129 23.3334 3.49996 23.3334H24.5C25.7886 23.3334 26.8333 22.2887 26.8333 21.0001V7.00008C26.8333 5.71142 25.7886 4.66675 24.5 4.66675Z"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.16663 11.6667H26.8333"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const HelpCenterIcon = ({ size = '28', color = '#ffffff' }: { size?: string; color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 28 28" fill="none">
      <path
        d="M14 25.6666C20.4434 25.6666 25.6667 20.4432 25.6667 13.9999C25.6667 7.5566 20.4434 2.33325 14 2.33325C7.55672 2.33325 2.33337 7.5566 2.33337 13.9999C2.33337 20.4432 7.55672 25.6666 14 25.6666Z"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 18.6666C16.5774 18.6666 18.6667 16.5772 18.6667 13.9999C18.6667 11.4226 16.5774 9.33325 14 9.33325C11.4227 9.33325 9.33337 11.4226 9.33337 13.9999C9.33337 16.5772 11.4227 18.6666 14 18.6666Z"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.75171 5.75171L10.6984 10.6984"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3016 17.3018L22.2483 22.2484"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3016 10.6984L22.2483 5.75171"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3016 10.6984L21.42 6.58008"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.75171 22.2484L10.6984 17.3018"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const AcademyIcon = ({ size = '28', color = '#ffffff' }: { size?: string; color?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.30008 13.1473V19.3427C6.30008 20.9022 9.74749 22.1665 14.0001 22.1665C18.2527 22.1665 21.7001 20.9022 21.7001 19.3427V13.1473M24.5001 11.8256L26.8334 10.7242L14.0001 4.6665L1.16675 10.7242L14.0001 16.7819L24.5001 11.8256ZM24.5001 11.8256V22.1665"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CloseCrossIcon = ({ size = '40' }: { size?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 40 40" fill="none">
    <path d="M8 32L32 8" stroke="white" strokeWidth="2.4" strokeLinecap="round" />
    <path d="M8 8L32 32" stroke="white" strokeWidth="2.4" strokeLinecap="round" />
  </svg>
);

const HamburgerIcon = ({ size = '40' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 8H34" stroke="white" strokeWidth="2.4" strokeLinecap="round" />
    <path d="M6 20H34" stroke="white" strokeWidth="2.4" strokeLinecap="round" />
    <path d="M6 32H34" stroke="white" strokeWidth="2.4" strokeLinecap="round" />
  </svg>
);

const AuthenticateIcon = ({ size = '24', color = '#ffffff' }: { size?: string; color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_23117_21359)">
      <path
        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
        stroke="white"
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M22 4L12 14.01L9 11.01" stroke={color} strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_23117_21359">
        <rect width={size} height={size} fill={color} />
      </clipPath>
    </defs>
  </svg>
);

const CameraOffIcon = ({ size = '16', color = '#7C7059' }: { size?: string; color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_19525_56814)">
      <path d="M0.666656 0.666656L15.3333 15.3333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14 14H1.99999C1.64637 14 1.30723 13.8595 1.05718 13.6095C0.807132 13.3594 0.666656 13.0203 0.666656 12.6667V5.33333C0.666656 4.97971 0.807132 4.64057 1.05718 4.39052C1.30723 4.14048 1.64637 4 1.99999 4H3.99999M5.99999 2H9.99999L11.3333 4H14C14.3536 4 14.6927 4.14048 14.9428 4.39052C15.1928 4.64057 15.3333 4.97971 15.3333 5.33333V11.56M10.1867 10.1867C9.96539 10.51 9.67558 10.7807 9.33785 10.9793C9.00012 11.178 8.62276 11.2997 8.23262 11.336C7.84248 11.3722 7.44914 11.3221 7.08059 11.1891C6.71204 11.056 6.37733 10.8434 6.10027 10.5664C5.82321 10.2893 5.61061 9.95462 5.47759 9.58606C5.34457 9.21751 5.29441 8.82418 5.33066 8.43404C5.36691 8.0439 5.4887 7.66654 5.68734 7.32881C5.88599 6.99107 6.15662 6.70127 6.47999 6.48"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_19525_56814">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export {
  AcademyIcon,
  AuthenticateIcon,
  AuthenticationsIcon,
  BalanceIcon,
  CameraOffIcon,
  CloseCrossIcon,
  DashboardIcon,
  HamburgerIcon,
  HelpCenterIcon,
  PlusIcon,
};
