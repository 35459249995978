import React from 'react';
/* eslint-disable max-len */

export const Dotted = () => (
  <svg width="21" height="6" viewBox="0 0 21 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.84757 5.5C2.15257 5.5 1.55978 5.26908 1.06919 4.80723C0.578606 4.3253 0.333313 3.72289 0.333313 3C0.333313 2.23695 0.578606 1.63454 1.06919 1.19277C1.55978 0.730924 2.15257 0.5 2.84757 0.5C3.54256 0.5 4.13535 0.730924 4.62594 1.19277C5.11653 1.63454 5.36182 2.23695 5.36182 3C5.36182 3.72289 5.11653 4.3253 4.62594 4.80723C4.13535 5.26908 3.54256 5.5 2.84757 5.5Z"
      fill="#CBAD73"
      fillOpacity="0.4"
    />
    <path
      d="M10.3333 5.5C9.63832 5.5 9.04552 5.26908 8.55494 4.80723C8.06435 4.3253 7.81906 3.72289 7.81906 3C7.81906 2.23695 8.06435 1.63454 8.55494 1.19277C9.04552 0.730924 9.63832 0.5 10.3333 0.5C11.0283 0.5 11.6211 0.730924 12.1117 1.19277C12.6023 1.63454 12.8476 2.23695 12.8476 3C12.8476 3.72289 12.6023 4.3253 12.1117 4.80723C11.6211 5.26908 11.0283 5.5 10.3333 5.5Z"
      fill="#CBAD73"
      fillOpacity="0.4"
    />
    <path
      d="M17.8191 5.5C17.1241 5.5 16.5313 5.26908 16.0407 4.80723C15.5501 4.3253 15.3048 3.72289 15.3048 3C15.3048 2.23695 15.5501 1.63454 16.0407 1.19277C16.5313 0.730924 17.1241 0.5 17.8191 0.5C18.5141 0.5 19.1068 0.730924 19.5974 1.19277C20.088 1.63454 20.3333 2.23695 20.3333 3C20.3333 3.72289 20.088 4.3253 19.5974 4.80723C19.1068 5.26908 18.5141 5.5 17.8191 5.5Z"
      fill="#CBAD73"
      fillOpacity="0.4"
    />
  </svg>
);
