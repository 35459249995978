import styled from 'styled-components';
import { truthfulAdaptive } from '../../../../componentUI/screen';

const WrapperFooterButtonStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  ${truthfulAdaptive.maxWidth.mobile} {
    flex-direction: column-reverse;
    justify-content: flex-start;
    gap: 16px;
  }
`;

const WrapperCancelButtonStyle = styled.div`
  max-width: 286px;
  width: 100%;

  ${truthfulAdaptive.maxWidth.mobile} {
    max-width: 100%;
  }
`;

const WrapperGenerateButtonStyle = styled.div`
  max-width: 286px;
  width: 100%;

  ${truthfulAdaptive.maxWidth.mobile} {
    max-width: 100%;
  }
`;

export { WrapperCancelButtonStyle, WrapperFooterButtonStyled, WrapperGenerateButtonStyle };
