import Cookies from 'js-cookie';
import { instance } from '../axios-instance';
import {
  CartTotalParamsRequestType,
  CartTotalResponseTypes,
  InvoicesOrderParamsRequestType,
  InvoicesParamsRequestType,
} from './types';

export const postInvoices = (data: InvoicesParamsRequestType) => {
  return instance.post<string>('invoices', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const postInvoicesOrder = (data: InvoicesOrderParamsRequestType) => {
  return instance.post<string>('invoices/order-payment', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const cartTotal = (data: CartTotalParamsRequestType) => {
  return instance.post<CartTotalResponseTypes>('invoices/get-cart-total', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

// @ts-ignore
export const getPrice = (data) => {
  if (data == null) {
    return instance.get('tariff-packages/get-price', {
      headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
    });
  }
  return instance.get(
    `tariff-packages/get-price?productTypeId=${data.id}&volume=${data.volume}&answerTimeId=${data.answerTimeId}`,
    { headers: { Authorization: `Bearer ${Cookies.get('jwt')}` } },
  );
};
