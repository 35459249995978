import { Dispatch, SetStateAction } from 'react';
import { AllChecksDraftsElementsType } from '../containers/AuthenticationsTablet/types';

/** функция-хелпер, сделана что бы не перегружать модуль AuthenticationsTablet
 * "лишним кодом" обрабатывает изменения стояния чекбокса у заказа
 * и запись его актуального состояния в стейт setAllChecksDraftsElements */

export const changeStatusCheckbox = (
  setAllChecksDraftsElements: Dispatch<SetStateAction<AllChecksDraftsElementsType>>,
  id: string,
) => {
  if (setAllChecksDraftsElements) {
    setAllChecksDraftsElements((prev) => {
      if (prev instanceof Object) {
        const currentStatusCheckbox = prev[id];
        return { ...prev, [id]: !currentStatusCheckbox };
      }

      return prev;
    });
  }
};
