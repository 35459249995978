import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetStatistics } from '../../../../api/product/hooks/useGetStatistics';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { StatusIndicator } from '../../../../componentUI/simple/StatusIndicator';
import { Text, TEXT_SIZE } from '../../../../componentUI/simple/Text';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../../../../componentUI/simple/Title';
import { RightArrow } from '../../../../common/icons/SvgInline/SvgIcons_7';
import { OrderStatisticsSkeleton } from './skeleton';
import { ItemWrapper, NumberBlock, OrderStatisticsStyled, PointSeparator, WrapperRightArrowStyled } from './styled';
import { DataItemsType, DesignType, DoNotShowType, Props } from './types';
import { OrderStatisticsExternalSkeleton } from './skeleton/OrderStatisticsExternalSkeleton';

const DATA_ITEMS: Array<DataItemsType> = [
  {
    sortId: 3,
    technicalNameItem: 'photosNeed',
    publicNameItem: 'dashboard.summary.orderStatistics.photosNeeded',
    linkItem: '/authentications/photos-needed',
    statusIndicatorItem: 'orange',
  },
  {
    sortId: 2,
    technicalNameItem: 'underReview',
    publicNameItem: 'dashboard.summary.orderStatistics.underReview',
    linkItem: '/authentications/under-review',
    statusIndicatorItem: 'blue',
  },
  {
    sortId: 1,
    technicalNameItem: 'completed',
    publicNameItem: 'dashboard.summary.orderStatistics.completed',
    linkItem: '/authentications/completed',
    statusIndicatorItem: 'green',
  },
  {
    sortId: 4,
    technicalNameItem: 'drafts',
    publicNameItem: 'dashboard.summary.orderStatistics.drafts',
    linkItem: '/authentications/drafts',
    statusIndicatorItem: 'grey20',
  },
];

const getDoNotShowFilterDataStatisticsOrder = (
  dataItems: Array<DataItemsType>,
  doNotShow: DoNotShowType | undefined,
) => {
  if (doNotShow) {
    return dataItems.filter((dataItemsElement) => {
      return !doNotShow.includes(dataItemsElement.technicalNameItem);
    });
  }

  return dataItems;
};

const changingOrderElements = (dataItems: Array<DataItemsType>, dataDesign: DesignType) => {
  if (dataDesign === 'dashboard') {
    return dataItems.sort((a, b) => a.sortId - b.sortId);
  }
  return dataItems;
};

export const OrderStatistics = ({
  doNotShow,
  idExternalData = false,
  externalData,
  externalIsLoading,
  design,
}: Props) => {
  const { t } = useTranslation();

  const { data: dataStatistics, isLoading: isLoadingStatistics } = useGetStatistics(idExternalData);

  if (isLoadingStatistics) {
    return <OrderStatisticsSkeleton />;
  }

  if (externalIsLoading) {
    return <OrderStatisticsExternalSkeleton />;
  }

  const statisticsOrder = idExternalData ? externalData : dataStatistics;

  const doNotShowFilterDataItems = getDoNotShowFilterDataStatisticsOrder(DATA_ITEMS, doNotShow);

  return (
    <OrderStatisticsStyled design={design}>
      {statisticsOrder &&
        changingOrderElements(doNotShowFilterDataItems, design).map(
          ({ technicalNameItem, publicNameItem, linkItem, statusIndicatorItem }) => {
            return (
              <ItemWrapper key={technicalNameItem} to={linkItem}>
                <StatusIndicator bgc={COLOR_MAP.accent[statusIndicatorItem]} />

                {design === 'dashboard' && (
                  <>
                    <Text style={{ whiteSpace: 'nowrap' }} colorText={COLOR_MAP.text.darkGrey}>
                      {t(publicNameItem)}
                    </Text>

                    <PointSeparator />

                    <Title colorText={COLOR_MAP.text.darkGrey} size={TITLE_SIZE.h5} weight={TITLE_WEIGHT.normal}>
                      {statisticsOrder[technicalNameItem]}
                    </Title>
                    <WrapperRightArrowStyled>
                      <RightArrow color={COLOR_MAP.accent.gold} size="16px" />
                    </WrapperRightArrowStyled>
                  </>
                )}

                {design === 'column' && (
                  <>
                    <Text colorText={COLOR_MAP.text.grey}>{t(publicNameItem)}</Text>

                    <NumberBlock>
                      <Text size={TEXT_SIZE.annotation} colorText={COLOR_MAP.text.grey}>
                        {statisticsOrder[technicalNameItem]}
                      </Text>
                    </NumberBlock>
                  </>
                )}
              </ItemWrapper>
            );
          },
        )}
    </OrderStatisticsStyled>
  );
};
