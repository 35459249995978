import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from '../../../../hooks';
import { BUTTON_SIZE, TertiaryButton } from '../../../../componentUI/simple/Button';
import { LogOutIcon } from '../../../../common/icons/SvgInline/SvgIcons_3';
import { LogoMain } from '../../../../common/images';
import { CompletionRegistrationHeaderBlockStyled, stylesForButtons } from './styled';

export const CompletionRegistrationHeaderBlock = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useQueryClient();
  const { isMobile } = useMatchMedia();

  return (
    <CompletionRegistrationHeaderBlockStyled>
      <img alt="logo b2b" src={LogoMain} width={isMobile ? 40 : 48} height={isMobile ? 40 : 48} />

      <TertiaryButton
        size={BUTTON_SIZE.extraSmall}
        style={stylesForButtons}
        onClick={() => {
          client.removeQueries();
          Cookies.remove('jwt');
          Cookies.remove('jwt-refresh');
          navigate('/signin');
        }}
      >
        <LogOutIcon />
        {t('auth.completionRegistration.completionRegistrationHeaderBlock.logOut')}
      </TertiaryButton>
    </CompletionRegistrationHeaderBlockStyled>
  );
};
