import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLOR_MAP } from '../../colorsMap';
import { supportHover } from '../../screen';
import { BUTTON_SIZE, SIZE_STYLE } from './constants';
import { PropsLinkRouterButton } from './types';
import { buttonTextStyle, linkRouterCommonStyles } from './utils';

const LinkRouterComponent = styled(Link)<PropsLinkRouterButton>`
  ${linkRouterCommonStyles};
  ${buttonTextStyle};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px;

  border: 2px solid ${({ colorsMap }) => colorsMap?.clickable};
  border-radius: 2px;
  user-select: none;
  background-color: ${({ colorsMap }) => colorsMap?.clickable};

  color: ${({ colorText }) => colorText};
  ${({ size }) => SIZE_STYLE[size || BUTTON_SIZE.small]};
  text-decoration: none;

  & path {
    stroke: ${({ colorIcon }) => colorIcon};
  }

  ${supportHover} {
    &:hover {
      background-color: ${({ colorsMap }) => colorsMap?.clickableHover};
      border-color: ${({ colorsMap }) => colorsMap?.clickableHover};
    }
  }

  &:active {
    background-color: ${({ colorsMap }) => colorsMap?.clickablePressed};
    border-color: ${({ colorsMap }) => colorsMap?.clickablePressed};
  }

  ${({ disabled, colorsMap }) =>
    disabled &&
    `
    background-color: ${colorsMap?.clickableDisabled};
    border-color: ${colorsMap?.clickableDisabled};
    cursor: not-allowed;
    pointer-events: none;
    text-decoration: none;
  `}
`;

const DEFAULT_COLOR_MAP = {
  clickable: COLOR_MAP.controls.clickableNormal,
  clickableHover: COLOR_MAP.controls.clickableHover,
  clickablePressed: COLOR_MAP.controls.clickablePressed,
  clickableDisabled: COLOR_MAP.controls.clickableDisabled,
};

export const LinkRouterButton = ({
  size = BUTTON_SIZE.small,
  colorIcon = COLOR_MAP.controls.textAndIcon,
  colorText = COLOR_MAP.controls.textAndIcon,
  isStretch = false,
  disabled = false,
  colorsMap = DEFAULT_COLOR_MAP,
  to = '#',
  children,
  ...props
}: Partial<PropsLinkRouterButton>) => {
  return (
    <LinkRouterComponent
      as={disabled ? 'span' : undefined}
      to={to}
      size={size}
      colorIcon={colorIcon}
      colorText={colorText}
      isStretch={isStretch}
      disabled={disabled}
      colorsMap={colorsMap}
      aria-disabled={disabled}
      {...props}
    >
      {children}
    </LinkRouterComponent>
  );
};
