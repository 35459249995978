import React from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from '../../../../common/icons/SvgInline/SvgIcons_5';
import { BUTTON_SIZE, SecondButton } from '../../../../componentUI/simple/Button';
import { FilterV2 } from './components';
import { FiltersStyled, WrapperFilterStyled, WrapperSecondButtonStyled } from './styled';
import { Props } from './types';
import { createRandomUUID } from '../../../../helpers/createRandomUUID';

/** Содержит список фильтров выбранных пользователем
 *  для сортировки таблицы */

export const ListFilters = ({ idFiltersPage, setIdFiltersPage, setSelectedSetFilters, selectedSetFilters }: Props) => {
  const { t } = useTranslation();

  const removeFilter = (id: string) => {
    if (selectedSetFilters) {
      delete selectedSetFilters[id];
    }

    const filteredID = idFiltersPage.filter((idFilter) => idFilter !== id);
    setIdFiltersPage(filteredID);
  };

  return (
    <FiltersStyled>
      <WrapperFilterStyled>
        {idFiltersPage.map((id) => (
          <FilterV2
            key={id}
            filterID={id}
            removeFilter={removeFilter}
            setSelectedSetFilters={setSelectedSetFilters}
            selectedSetFilters={selectedSetFilters}
          />
        ))}
      </WrapperFilterStyled>

      <WrapperSecondButtonStyled>
        <SecondButton
          size={BUTTON_SIZE.extraSmall}
          isStretch
          onClick={() => {
            setIdFiltersPage((prev) => [...prev, createRandomUUID()]);
          }}
        >
          <Plus />
          {t('authenticationsTablet.listFilters.addFilter')}
        </SecondButton>
      </WrapperSecondButtonStyled>
    </FiltersStyled>
  );
};
