import styled from 'styled-components';

import { truthfulAdaptive } from '../../../../componentUI/screen';

const NoRecordsFoundStyled = styled.section`
  height: 100%;

  display: flex;
  justify-content: center;
`;

const WrapperNoRecordsFoundStyled = styled.section`
  margin-top: 15%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  ${truthfulAdaptive.maxWidth.mobile} {
    margin-top: 30px;
    gap: 32px;
  }
`;

export { NoRecordsFoundStyled, WrapperNoRecordsFoundStyled };
