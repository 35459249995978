/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { SecondaryLinkButton } from '../../../../componentUI/simple/Button';
import { Text, TEXT_WEIGHT } from '../../../../componentUI/simple/Text';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../../../../componentUI/simple/Title';
import { useMatchMedia, useSelectedLanguage } from '../../../../hooks';
import { CreditIconV2 } from '../../../../common/icons/SvgInline/SvgIcons_10';
import {
  CircleMarkerOldBalanceStyled,
  ConvertedBalanceModalStyled,
  InformationalTextStyled,
  SeparatorBalanceStyled,
  SeparatorFormStyled,
  stylesForButtons,
  WrapperBonusStyled,
  WrapperConvertBalanceBonusElementStyled,
  WrapperConvertBalanceElementStyled,
  WrapperConvertCreditStyled,
  WrapperConvertTotalBalanceElementStyled,
  WrapperNewBalanceStyled,
  WrapperOldBalanceStyled,
  WrapperTitleStyled,
} from './styled';
import { Props } from './types';

export const ConvertedBalanceModalNewUser = ({ PromoPackagesActivateData }: Props) => {
  const { isMobile } = useMatchMedia();
  const { t } = useTranslation();
  const { packages, totalCredits } = PromoPackagesActivateData;
  const selectedLanguage = useSelectedLanguage();

  return (
    <ConvertedBalanceModalStyled>
      <WrapperTitleStyled>
        <CreditIconV2 />
        <Title weight={TITLE_WEIGHT.normal} size={TITLE_SIZE.h5}>
          {t('baseLayout.convertedBalanceModalNewUser.welcomeTitle')}
        </Title>
      </WrapperTitleStyled>

      <SeparatorFormStyled />

      <WrapperConvertCreditStyled>
        {packages.map((element, index) => {
          const { brandType, answerTime, volume, price, total } = element;

          return brandType.toLowerCase() !== 'bonus' ? (
            <WrapperConvertBalanceElementStyled key={index}>
              <WrapperOldBalanceStyled>
                <CircleMarkerOldBalanceStyled />
                <Text>
                  {brandType}, {answerTime}
                </Text>
                <Text weight={TEXT_WEIGHT.bold}>{volume}</Text>
              </WrapperOldBalanceStyled>

              {!isMobile && <SeparatorBalanceStyled />}

              <WrapperNewBalanceStyled>
                <Text>
                  &nbsp;&nbsp;=&nbsp;&nbsp;{price}&nbsp;&nbsp;x&nbsp;&nbsp;{volume}&nbsp;&nbsp;=&nbsp;&nbsp;
                  <strong>{total}</strong>&nbsp;&nbsp;{t('baseLayout.convertedBalanceModalNewUser.lgCredits')}
                </Text>
              </WrapperNewBalanceStyled>
            </WrapperConvertBalanceElementStyled>
          ) : (
            <>
              <WrapperBonusStyled>
                <SeparatorFormStyled />
                <WrapperConvertBalanceBonusElementStyled>
                  <Text colorText={COLOR_MAP.accent.green}>
                    + {t('baseLayout.convertedBalanceModalNewUser.conversionBonus')}
                  </Text>

                  <SeparatorBalanceStyled />

                  <Text colorText={COLOR_MAP.accent.green}>
                    <strong>{total}</strong> {t('baseLayout.convertedBalanceModalNewUser.lgCredits')}
                  </Text>
                </WrapperConvertBalanceBonusElementStyled>
              </WrapperBonusStyled>
            </>
          );
        })}
      </WrapperConvertCreditStyled>

      <WrapperConvertTotalBalanceElementStyled>
        <Text weight={TEXT_WEIGHT.bold}>{t('baseLayout.convertedBalanceModalNewUser.totalYouWillGet')}</Text>

        <SeparatorBalanceStyled />

        <Text>
          <strong>{totalCredits}</strong> {t('baseLayout.convertedBalanceModalNewUser.lgCredits')}
        </Text>
      </WrapperConvertTotalBalanceElementStyled>

      <InformationalTextStyled>
        <Text colorText={COLOR_MAP.text.lightBlue}>{t('baseLayout.convertedBalanceModalNewUser.infoText')}</Text>
      </InformationalTextStyled>

      <SecondaryLinkButton
        style={stylesForButtons}
        isStretch
        href={`https://legitgrails.frontkb.com/${selectedLanguage.toLowerCase()}/categories/2498946`}
        target="_blank"
        rel="noreferrer"
      >
        {t('baseLayout.convertedBalanceModalNewUser.learnMore')}
      </SecondaryLinkButton>
    </ConvertedBalanceModalStyled>
  );
};
