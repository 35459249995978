/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, DragEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addToast } from '../../../../redux/reducers/toast-reducer';
import { useMatchMedia, useStyledModal } from '../../../../hooks';
import { checkingFileSize, inputPhotoClear, isFileTypeAllowed } from '../../../../helpers';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { ViewingExamplePhotos } from '../../../../componentUI/complex/ViewingExamplePhotos';
import { TAG_NAME } from '../../../../componentUI/constants';
import { SecondButton, TertiaryButton } from '../../../../componentUI/simple/Button';
import { Checkbox } from '../../../../componentUI/simple/Checkbox';
import { Text, TEXT_SIZE } from '../../../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../../../componentUI/simple/Title';
import { InfoTooltip } from '../../../../componentUI/simple/Tooltip';
import { AlertTriangleIconV2 } from '../../../../common/icons/SvgInline/SvgIcons_11';
import { ImgPlusIcon } from '../../../../common/icons/SvgInline/SvgIcons_12';
import { InfoIcon } from '../../../../common/icons/SvgInline/SvgIcons_5';
import { UploadingNewRequestedPhoto } from './components';
import { COLOR_MAP_BUTTON_ICON, MAXIMUM_NUMBER_PHOTOS, MAXIMUM_PHOTO_SIZE } from './locale';
import {
  CommentDescriptionsStyled,
  CommentStyled,
  ContainerUploadPhotosStyled,
  DragAndDropIndicatorStyled,
  FooterStyled,
  HeaderStyled,
  MainStyled,
  PhotoCardV2Styled,
  ReasonsDescriptionsStyled,
  ReasonsPhotosStyled,
  ReasonsStyled,
  RequiredChangesStyled,
  UploadPhotosStyled,
  WrapperLickStyled,
  WrapperSecondButton,
  WrapperTextReasons,
  WrapperTitleDragAndDropStyled,
  WrapperTitlePhotosStyled,
  WrapperTitleRequiredStyled,
  WrapperUploadPhotosInfoStyled,
} from './styled';
import { Props } from './types';

/** Компонент отрисовывает весь блок индексов, которые используются
 *  для дозагрузки в них фотографий заказа */

export const IndexRequiredPhoto = ({
  productId,
  problematicIndex,
  setAllUploadedPhotos,
  setAllUploadedPhotosIndex,
}: Props) => {
  const { t } = useTranslation();
  const { publicName, id, iconUrl } = problematicIndex;
  const { comment, photoUrl, problems, imageSamples } = problematicIndex.reason;

  const isImageSamples = imageSamples && imageSamples.length > 0;
  const isProblems = problems && problems.length > 0;
  const isComment = Boolean(comment);

  const dispatch = useDispatch();
  const { isMobile, isDesktopSNew } = useMatchMedia();
  const [Modal, toggleModal] = useStyledModal();

  const [ModalPhotoUrl, toggleModalPhotoUrl] = useStyledModal();

  // индикатор Drag-and-drop
  const [drag, setDrag] = useState(false);

  // отслеживаем состояние isLoading запроса useUpdatePhotoProductMutate из модуля ниже
  const [isLoadingUploadedPhotos, setIsLoadingUploadedPhotos] = useState(false);

  // состояние чекбокса "I don't have a photo of this detail" текущего индекса
  const [isNotPhoto, setIsNotPhoto] = useState(false);

  // id фотографий загруженных в индекс, параметр необходим для
  // корректного подсчета загруженных фотографий в индексе
  // и показа функционала Checkbox "I don't have a photo of this detail"
  const [indexUploadedPhotos, setIndexUploadedPhotos] = useState<Array<string>>([]);

  // количество фотографий выбранных пользователем в данный момент, в индекс ещё не загружены
  const [files, setFiles] = useState<File[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  // условие при котором показывается функционал Checkbox "I don't have a photo of this detail"
  const isShowCheckbox = indexUploadedPhotos.length <= 0 && files.length <= 0;

  // условие при котором необходимо скрыть кнопку загрузки фотографий
  const isHideButton = files.length >= 5 || indexUploadedPhotos.length >= 5;

  // если количество загруженных фото превышает константу делаем
  // кнопку Upload new photo недоступной и другие условия
  const isDisabledBtnUploadPhoto =
    indexUploadedPhotos.length >= MAXIMUM_NUMBER_PHOTOS || isNotPhoto || isLoadingUploadedPhotos;

  // проверяем пришла ли миниатюра или фото с "кружочком"
  const isDrawThumbnail = Boolean(iconUrl) || Boolean(photoUrl);

  // индикаторы ошибок проверки выбранных фото
  const [isErrorCheckingFileSize, setIsErrorCheckingFileSize] = useState(false);
  const [isErrorLimitUploadedImages, setIsErrorLimitUploadedImages] = useState(false);
  const [isErrorPromiseUploadingPhoto, setIsErrorPromiseUploadingPhoto] = useState(false);
  const [isErrorWrongFile, setIsErrorWrongFile] = useState(false);

  // setAllUploadedPhotos - если чекбокс установлен то поле photos не добавляется, а поле
  // isUnableProvide меняет значение на true
  // setAllUploadedPhotosIndex - если чекбокс установлен то это аналогично загруженным фото
  // setAllIsNotPhoto - изменяем состояние чекбокса isNotPhoto для этого индекса в общем списке AllIsNotPhoto
  const handleCheckboxClick = (isCheck: boolean) => {
    if (!isCheck) {
      setAllUploadedPhotos((prev) => prev.filter((element) => element.angleId !== id));

      setAllUploadedPhotosIndex((prev) => {
        return { ...prev, [id]: isCheck };
      });

      return;
    }

    if (isCheck) {
      setAllUploadedPhotos((prev) => [...prev, { angleId: id, isUnableProvide: isCheck }]);

      setAllUploadedPhotosIndex((prev) => {
        return { ...prev, [id]: isCheck };
      });
    }
  };

  const dragStartHandler = (event: SyntheticEvent) => {
    event.preventDefault();
    setDrag(true);
  };

  const dragLeaveHandler = (event: SyntheticEvent) => {
    event.preventDefault();
    setDrag(false);
  };

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  // проверяем выбранные файлы по условиям и добавляем их к общему списку
  // ранее выбранный файлов, если есть, для кнопки
  const onChangeButton = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event.target.files) {
      // получаем количество выбранных фотографий пользователей сейчас и складываем их
      // с загруженными фотографиями им ранее в этом индексе
      let currentNumberUploadedFiles = files.length + indexUploadedPhotos.length;

      const filteredFiles = Array.from(event.target.files).filter((file) => {
        if (!checkingFileSize(file, MAXIMUM_PHOTO_SIZE)) {
          setIsErrorCheckingFileSize(true);
          return false;
        }

        if (currentNumberUploadedFiles >= MAXIMUM_NUMBER_PHOTOS) {
          setIsErrorLimitUploadedImages(true);
          return false;
        }

        if (!isFileTypeAllowed(file)) {
          setIsErrorWrongFile(true);
          return false;
        }

        currentNumberUploadedFiles++;
        return true;
      });

      setFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
    }
  };

  const onDropHandler = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (event?.dataTransfer?.files) {
      let currentNumberUploadedFiles = files.length + indexUploadedPhotos.length;

      const filteredFiles = Array.from(event.dataTransfer.files).filter((file) => {
        if (!checkingFileSize(file, MAXIMUM_PHOTO_SIZE)) {
          setIsErrorCheckingFileSize(true);
          return false;
        }

        if (currentNumberUploadedFiles >= MAXIMUM_NUMBER_PHOTOS) {
          setIsErrorLimitUploadedImages(true);
          return false;
        }

        if (!isFileTypeAllowed(file)) {
          setIsErrorWrongFile(true);
          return false;
        }

        currentNumberUploadedFiles++;
        return true;
      });

      setFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
    }

    setDrag(false);
  };

  // блок отвечающий за показ ошибок загрузки фотографии в индекс пользователю
  // если выполнено одно из условий
  useEffect(() => {
    if (isErrorCheckingFileSize) {
      dispatch(
        addToast({
          text: t('uploadRequiredPhotos.indexRequiredPhoto.errorFileSize'),
        }),
      );
      inputPhotoClear(inputRef);
      setIsErrorCheckingFileSize(false);
    }

    if (isErrorLimitUploadedImages) {
      dispatch(
        addToast({
          text: t('uploadRequiredPhotos.indexRequiredPhoto.errorLimitImages'),
        }),
      );
    }

    if (isErrorPromiseUploadingPhoto) {
      dispatch(
        addToast({
          text: t('uploadRequiredPhotos.indexRequiredPhoto.errorUpload'),
        }),
      );
      setIsErrorPromiseUploadingPhoto(false);
    }

    if (isErrorWrongFile) {
      dispatch(
        addToast({
          text: t('uploadRequiredPhotos.indexRequiredPhoto.errorFileType'),
        }),
      );
      setIsErrorWrongFile(false);
    }
  }, [isErrorCheckingFileSize, isErrorLimitUploadedImages, isErrorPromiseUploadingPhoto, isErrorWrongFile]);

  // отслеживаем присутствует ли в данном индексе хотя бы одно загруженное фото
  // при инициализации компонента т.к. indexUploadedPhotos === 0 присваивается значение false
  useEffect(() => {
    if (indexUploadedPhotos.length > 0) {
      setAllUploadedPhotosIndex((prev) => {
        return { ...prev, [id]: true };
      });
      return;
    }

    if (indexUploadedPhotos.length <= 0) {
      setAllUploadedPhotosIndex((prev) => {
        return { ...prev, [id]: false };
      });
    }
  }, [indexUploadedPhotos]);

  return (
    <PhotoCardV2Styled
      drag={drag}
      onDragStart={dragStartHandler}
      onDragOver={dragStartHandler}
      onDragLeave={dragLeaveHandler}
      onDrop={onDropHandler}
    >
      {drag && (
        <DragAndDropIndicatorStyled>
          <WrapperTitleDragAndDropStyled>
            <ImgPlusIcon size="24" color={COLOR_MAP.accent.black} />
            <Title style={{ pointerEvents: 'none' }} tag={TAG_NAME.h6} size={TITLE_SIZE.h6}>
              {t('uploadRequiredPhotos.indexRequiredPhoto.dragTitle')}
            </Title>
          </WrapperTitleDragAndDropStyled>
        </DragAndDropIndicatorStyled>
      )}

      {isDrawThumbnail && (
        <ModalPhotoUrl onClose={toggleModalPhotoUrl} isPhoto>
          <ViewingExamplePhotos FilesImages={[photoUrl || iconUrl || '']} />
        </ModalPhotoUrl>
      )}

      <HeaderStyled>
        <Title tag={isMobile ? TAG_NAME.h4 : TAG_NAME.h3} size={isMobile ? TITLE_SIZE.h4 : TITLE_SIZE.h3}>
          {publicName}
        </Title>
      </HeaderStyled>

      <MainStyled>
        <RequiredChangesStyled>
          <WrapperTitleRequiredStyled>
            <Title tag={TAG_NAME.h6} size={TITLE_SIZE.h6}>
              {t('uploadRequiredPhotos.indexRequiredPhoto.requiredChanges')}
            </Title>
            <InfoTooltip
              widthTooltip="235px"
              text={<Text size={TEXT_SIZE.sub}>{t('uploadRequiredPhotos.indexRequiredPhoto.tooltip')}</Text>}
            />
          </WrapperTitleRequiredStyled>

          <ReasonsStyled>
            {isProblems && (
              <ReasonsDescriptionsStyled>
                {problems.map((problem) => {
                  return (
                    <WrapperTextReasons key={problem}>
                      <AlertTriangleIconV2 />
                      <Text style={{ whiteSpace: 'nowrap' }} colorText={COLOR_MAP.text.orange}>
                        {problem}
                      </Text>
                    </WrapperTextReasons>
                  );
                })}
              </ReasonsDescriptionsStyled>
            )}

            {isDrawThumbnail && (
              <ReasonsPhotosStyled onClick={toggleModalPhotoUrl}>
                <img width="100%" height="100%" src={photoUrl || iconUrl || ''} alt="reasons" />
              </ReasonsPhotosStyled>
            )}
          </ReasonsStyled>
        </RequiredChangesStyled>

        {isComment && (
          <CommentStyled>
            <Title tag={TAG_NAME.h6} size={TITLE_SIZE.h6}>
              {t('uploadRequiredPhotos.indexRequiredPhoto.comment')}
            </Title>
            <CommentDescriptionsStyled>{comment}</CommentDescriptionsStyled>
          </CommentStyled>
        )}
      </MainStyled>

      <UploadPhotosStyled>
        {/* требования по макету */}
        {!isDesktopSNew ? (
          <WrapperUploadPhotosInfoStyled>
            <WrapperTitlePhotosStyled>
              <Title tag={TAG_NAME.h6} size={TITLE_SIZE.h6}>
                {t('uploadRequiredPhotos.indexRequiredPhoto.uploadPhotos')}
              </Title>
              <Text colorText={COLOR_MAP.text.darkGrey}>
                &nbsp;{t('uploadRequiredPhotos.indexRequiredPhoto.maxPhotos')}
              </Text>
            </WrapperTitlePhotosStyled>

            {isImageSamples && (
              <WrapperLickStyled>
                <Modal onClose={toggleModal} isPhoto>
                  <ViewingExamplePhotos FilesImages={imageSamples} />
                </Modal>

                <TertiaryButton isStretch onClick={toggleModal}>
                  <InfoIcon color={COLOR_MAP.accent.gold} />
                  {t('uploadRequiredPhotos.indexRequiredPhoto.seeImageSample')}
                </TertiaryButton>
              </WrapperLickStyled>
            )}
          </WrapperUploadPhotosInfoStyled>
        ) : (
          <>
            <WrapperTitlePhotosStyled>
              <Title tag={TAG_NAME.h6} size={TITLE_SIZE.h6}>
                {t('uploadRequiredPhotos.indexRequiredPhoto.uploadPhotos')}
              </Title>
              <Text colorText={COLOR_MAP.text.darkGrey}>
                &nbsp;{t('uploadRequiredPhotos.indexRequiredPhoto.maxPhotos')}
              </Text>
            </WrapperTitlePhotosStyled>

            {isImageSamples && (
              <WrapperLickStyled>
                <Modal onClose={toggleModal} isPhoto>
                  <ViewingExamplePhotos FilesImages={imageSamples} />
                </Modal>

                <TertiaryButton isStretch onClick={toggleModal}>
                  <InfoIcon color={COLOR_MAP.accent.gold} />
                  {t('uploadRequiredPhotos.indexRequiredPhoto.seeImageSample')}
                </TertiaryButton>
              </WrapperLickStyled>
            )}
          </>
        )}

        <ContainerUploadPhotosStyled>
          <UploadingNewRequestedPhoto
            inputRef={inputRef}
            angleId={id}
            productId={productId}
            files={files}
            setFiles={setFiles}
            setAllUploadedPhotos={setAllUploadedPhotos}
            setIndexUploadedPhotos={setIndexUploadedPhotos}
            setIsErrorLimitUploadedImages={setIsErrorLimitUploadedImages}
            setIsLoadingUploadedPhotos={setIsLoadingUploadedPhotos}
          />

          {!isHideButton && (
            <WrapperSecondButton isDisabledBtnUploadPhoto={isDisabledBtnUploadPhoto}>
              <input
                type="file"
                multiple
                accept=".heic,.png,.heif,.jpg,.jpeg,.webp,.avif"
                ref={inputRef}
                onChange={onChangeButton}
                style={{ display: 'none' }}
              />

              <SecondButton
                style={{ border: 'none', width: '100%', height: '100%' }}
                isStretch
                onClick={handleButtonClick}
                disabled={isDisabledBtnUploadPhoto}
                colorsMap={COLOR_MAP_BUTTON_ICON}
              >
                <ImgPlusIcon />
              </SecondButton>
            </WrapperSecondButton>
          )}
        </ContainerUploadPhotosStyled>
      </UploadPhotosStyled>

      {isShowCheckbox && (
        <FooterStyled>
          <Checkbox
            label={t('uploadRequiredPhotos.indexRequiredPhoto.noPhotoCheckbox')}
            checked={isNotPhoto}
            onChange={() => {
              setIsNotPhoto((prev) => {
                handleCheckboxClick(!prev);
                return !prev;
              });
            }}
          />
        </FooterStyled>
      )}
    </PhotoCardV2Styled>
  );
};
