import React from 'react';

import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { SkeletonBasic } from '../../../../../componentUI/skeleton';
import { OrderStatisticsExternalSkeletonStyled } from './styled';

export const OrderStatisticsExternalSkeleton = () => {
  return (
    <OrderStatisticsExternalSkeletonStyled>
      <SkeletonBasic width="100%" height="24px" bgColor={COLOR_MAP.controls.skeleton.content2} />
      <SkeletonBasic width="100%" height="24px" bgColor={COLOR_MAP.controls.skeleton.content2} />
      <SkeletonBasic width="100%" height="24px" bgColor={COLOR_MAP.controls.skeleton.content2} />
    </OrderStatisticsExternalSkeletonStyled>
  );
};
