import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { routeHandlers } from './routeHandlers';
import { WrapperArrow, WrapperBreadCrumbs } from './styled';
import { LinkRouter } from '../../componentUI/simple/Link';
import { TAG_NAME } from '../../componentUI/constants';
import { Text, TEXT_SIZE, TEXT_WEIGHT } from '../../componentUI/simple/Text';
import { COLOR_MAP } from '../../componentUI/colorsMap';

const RightArrow = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 6H9.5" stroke="#AAAAAA" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 2.5L9.5 6L6 9.5" stroke="#AAAAAA" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const BreadCrumbs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname, state } = location;
  const previousPath = state?.from;
  const pathnames = pathname.split('/').filter((x) => x);

  const generateBreadcrumbs = (pathname: string, pathnames: string[], previousPath?: string) => {
    const matchingRoute = routeHandlers.find((route) => {
      return typeof route.pattern === 'function' ? route.pattern(pathname) : route.pattern.test(pathname);
    });

    if (matchingRoute) {
      return matchingRoute.handler(pathname, pathnames, previousPath).map((item) => ({
        name: t(item.name),
        path: item.path,
      }));
    }

    // Default handler for unspecified paths
    const breadcrumbItems = [{ name: t('breadCrumbs.dashboard'), path: '/dashboard' }];

    let accumulatedPath = '';
    pathnames.forEach((segment, index) => {
      accumulatedPath += `/${segment}`;
      const isLast = index === pathnames.length - 1;
      const translationKey = `breadCrumbs.${segment}`;
      const name = t(translationKey) !== translationKey ? t(translationKey) : segment;
      const path = isLast ? '' : accumulatedPath;
      breadcrumbItems.push({ name, path });
    });

    return breadcrumbItems;
  };

  const breadcrumbItems = generateBreadcrumbs(pathname, pathnames, previousPath);

  return (
    <WrapperBreadCrumbs>
      {breadcrumbItems.map((item, index) => {
        const isLast = index === breadcrumbItems.length - 1;
        return (
          <React.Fragment key={`${item.name}`}>
            {index > 0 && (
              <WrapperArrow>
                <RightArrow />
              </WrapperArrow>
            )}
            {!isLast && item.path ? (
              <LinkRouter
                to={item.path}
                size={TEXT_SIZE.sub}
                colorText={COLOR_MAP.text.black}
                weight={TEXT_WEIGHT.normal}
              >
                {item.name}
              </LinkRouter>
            ) : (
              <Text
                tag={TAG_NAME.span}
                size={TEXT_SIZE.sub}
                weight={TEXT_WEIGHT.normal}
                colorText={COLOR_MAP.text.darkGrey}
              >
                {item.name}
              </Text>
            )}
          </React.Fragment>
        );
      })}
    </WrapperBreadCrumbs>
  );
};
