/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePrevious } from '../../../../hooks';
import { SearchIcon } from '../../../../common/icons/SvgInline/SvgIcons_2';
import { Input } from '../../../../componentUI/simple/Input';
import { TableSearchStyled } from './styled';
import { Props } from './types';

const DELAY_TIME_SENDING = 2000; // ms

/** Содержит поле поиска по табличной части списка заказов */

export const TableSearch = ({ setInputSearch, pressedFilterButton }: Props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  // предыдущее значение из поля ввода
  const prevInputSearch = usePrevious(search);

  // задержка по времени, перед отправкой данных в запрос products/get-products
  useEffect(() => {
    if (search === prevInputSearch) {
      return;
    }

    const delaySending = setTimeout(() => setInputSearch(search), DELAY_TIME_SENDING);

    return () => clearTimeout(delaySending);
  }, [search]);

  return (
    <TableSearchStyled pressedFilterButton={pressedFilterButton}>
      <Input
        placeholder={t('authenticationsTablet.search.placeholder')}
        onChange={(event) => setSearch(event)}
        value={search}
        leftIcon={<SearchIcon />}
        isStretch
        style={{ padding: '10px 10px 10px 38px' }}
      />
    </TableSearchStyled>
  );
};
