const stylesForModalOther = {
  top: '32px',
  bottom: '32px',
  padding: '20px 32px',
  width: '1200px',
  maxWidth: '1200px',
};

const stylesForModalDesktop = {
  top: '32px',
  bottom: '32px',
  padding: '20px 32px',
  width: 'calc(100% - 128px)',
  maxWidth: 'calc(100% - 128px)',
};

const stylesForModalTablet = {
  top: '32px',
  bottom: '32px',
  padding: '20px 32px',
  width: 'calc(100% - 64px)',
  maxWidth: 'calc(100% - 64px)',
};

const stylesForModalMobile = {
  top: '16px',
  bottom: '16px',
  left: '0',
  padding: '20px',
  width: 'calc(100% - 16px)',
  maxWidth: 'calc(100% - 16px)',
};

type ChoosingDesignStyleType = Record<
  'isMobile' | 'isTablet' | 'isDesktopS' | 'isDesktop' | 'isDesktop1440' | 'isDesktop1600' | 'isMega',
  boolean
>;

/** выбор стиля оформления модального окна в зависимости от
 * разрешения экрана пользователя по брекпоинтам
 */

export const choosingDesignStyle = (currentResolution: ChoosingDesignStyleType) => {
  const { isMobile, isTablet, isDesktopS, isDesktop, isDesktop1440 } = currentResolution;

  if (isMobile) {
    return stylesForModalMobile;
  }

  if (!isMobile && isTablet) {
    return stylesForModalTablet;
  }

  if (!isTablet && isDesktopS) {
    return stylesForModalTablet;
  }

  if (!isDesktopS && isDesktop) {
    return stylesForModalDesktop;
  }

  if (!isDesktop && isDesktop1440) {
    return stylesForModalOther;
  }

  if (!isDesktop && !isDesktop1440) {
    return stylesForModalOther;
  }

  return stylesForModalOther;
};
