import { AuthPageType } from '../../../common/constants';

// значения доступности столбцов по умолчанию
const DEFAULT_COLUMN_VALUES = {
  draft: true,
  order: true,
  status: true,
  date: true,
  brand: true,
  model: true,
  sku: true,
  answer: true,
  outcome: true,
  action: true,
};

// обозначение страниц на которых встречается элемент AuthenticationsTablet
const { all, photosNeeded, underReview, completed, drafts, dashboard } = AuthPageType;

/** Содержит список столбцов для таблицы
 *  AuthenticationsTablet доступных к показу
 * в зависимости от расположения таблицы на различных страницах сайта */

export const creatingListColumns = (currentPage: string | undefined) => {
  switch (currentPage) {
    case all:
      return { ...DEFAULT_COLUMN_VALUES, ...{ draft: false } };
    case photosNeeded:
      return { ...DEFAULT_COLUMN_VALUES, ...{ draft: false, outcome: false } };
    case underReview:
      return { ...DEFAULT_COLUMN_VALUES, ...{ draft: false, outcome: false, action: false } };
    case completed:
      return { ...DEFAULT_COLUMN_VALUES, ...{ draft: false, status: false } };
    case drafts:
      return { ...DEFAULT_COLUMN_VALUES, ...{ status: false, outcome: false } };
    case dashboard:
      return { ...DEFAULT_COLUMN_VALUES, ...{ draft: false } };
    default:
      return { ...DEFAULT_COLUMN_VALUES, ...{ draft: false } };
  }
};
