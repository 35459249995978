import { SelectedSetFiltersType } from '../../../../../containers/AuthenticationsTablet/types';
import { NAME_OBJECT_GET_PRODUCT } from '../types';

type ObjectFormationType = {
  nameObject: NAME_OBJECT_GET_PRODUCT;
  idFilter: string;
  parameterOne: string;
  parameterTwo: string | boolean;
};

const { publicId, date, brand, modelName, sku, checkStatus } = NAME_OBJECT_GET_PRODUCT;

/** формирует объект который используется фильтрации значений из AuthenticationsTablet
 * в эндпоинте /products/get-products объект формируется исходя из выбранного значения мастер
 * выпадающего списка */

export const objectFormation = ({
  nameObject,
  idFilter,
  parameterOne,
  parameterTwo,
}: ObjectFormationType): SelectedSetFiltersType => {
  switch (nameObject) {
    case publicId:
    case modelName:
    case sku:
    case checkStatus:
      return {
        [idFilter]: {
          nameObject,
          value: parameterOne,
          include: parameterTwo,
        },
      };
    case date:
      return {
        [idFilter]: {
          nameObject,
          fromDate: parameterOne,
          untilDate: parameterTwo,
        },
      };
    case brand:
      return {
        [idFilter]: {
          nameObject,
          id: parameterOne,
          include: parameterTwo,
        },
      };
    default:
      return {
        [idFilter]: {
          nameObject,
          value: parameterOne,
          include: parameterTwo,
        },
      };
  }
};
