import Cookies from 'js-cookie';

import { instance } from '../axios-instance';
import {
  createCertificatesTemplatesTypes,
  responseCertificatesTemplates,
  responseUserCertificateTemplates,
} from './types';

export const getCertificatesTemplates = () => {
  return instance.get<Array<responseCertificatesTemplates>>('/certificate-templates', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getUserCertificateTemplates = () => {
  return instance.get<Array<responseUserCertificateTemplates>>('/user-certificate-templates', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const createCertificatesTemplates = (template: createCertificatesTemplatesTypes) => {
  return instance.post<{}>('/user-certificate-templates', template, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const deleteCertificatesTemplates = (id: string) => {
  return instance.delete<{}>(`/user-certificate-templates/${id}`, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const defaultTemplates = (id: string) => {
  return instance.patch<{}>(
    `/user-certificate-templates/set-default/${id}`,
    {},
    {
      headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
    },
  );
};
