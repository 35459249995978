/* eslint-disable max-len */
import { COLOR_MAP } from '../../../../componentUI/colorsMap';

// максимальное количество загружаемых фотографий
const MAXIMUM_NUMBER_PHOTOS = 5;
// максимальный размер загруженной фотографии в мегабайтах
const MAXIMUM_PHOTO_SIZE = 10;
// набор стилей для иконки кнопки
const COLOR_MAP_BUTTON_ICON = {
  clickable: COLOR_MAP.controls.fillNormalV2,
  clickableHover: COLOR_MAP.controls.clickableHoverV2,
  clickablePressed: COLOR_MAP.controls.clickablePressedV2,
  clickableDisabled: COLOR_MAP.controls.clickableDisabledV2,
};

export { MAXIMUM_NUMBER_PHOTOS, MAXIMUM_PHOTO_SIZE, COLOR_MAP_BUTTON_ICON };
