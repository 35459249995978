/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useIsMobile } from '../../../hooks';

import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { SkeletonBasic } from '../../../componentUI/skeleton';

export const CertificateInfoSkeletonTemplates = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <>
        {new Array(3).fill('1').map((_, index) => {
          return (
            <SkeletonBasic width="184px" height="238px" bgColor={COLOR_MAP.controls.skeleton.content2} key={index} />
          );
        })}
      </>
    );
  }

  return (
    <>
      {new Array(6).fill('1').map((_, index) => {
        return (
          <SkeletonBasic width="184px" height="238px" bgColor={COLOR_MAP.controls.skeleton.content2} key={index} />
        );
      })}
    </>
  );
};
