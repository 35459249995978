import React from 'react';
import styled from 'styled-components';
import * as SvgIcons1 from './SvgIcons_1';
import * as SvgIcons10 from './SvgIcons_10';
import * as SvgIcons11 from './SvgIcons_11';
import * as SvgIcons2 from './SvgIcons_2';
import * as SvgIcons3 from './SvgIcons_3';
import * as SvgIcons4 from './SvgIcons_4';
import * as SvgIcons5 from './SvgIcons_5';
import * as SvgIcons6 from './SvgIcons_6';
import * as SvgIcons7 from './SvgIcons_7';
import * as SvgIcons8 from './SvgIcons_8';
import * as SvgIcons9 from './SvgIcons_9';
import * as SvgIcons12 from './SvgIcons_12';

import { Text } from '../../../componentUI/simple/Text';

const WrapperIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const IconArray = [
  Object.entries(SvgIcons1),
  Object.entries(SvgIcons2),
  Object.entries(SvgIcons3),
  Object.entries(SvgIcons4),
  Object.entries(SvgIcons5),
  Object.entries(SvgIcons6),
  Object.entries(SvgIcons7),
  Object.entries(SvgIcons8),
  Object.entries(SvgIcons9),
  Object.entries(SvgIcons10),
  Object.entries(SvgIcons11),
  Object.entries(SvgIcons12),
].flat();

export const SvgFromDevelopers = () => {
  return (
    <div style={{ display: 'flex' }}>
      <div>
        {IconArray.map(([name, Icon]) => {
          return (
            <WrapperIcon key={name}>
              <Text>{name}</Text>
              <Icon />
            </WrapperIcon>
          );
        })}
      </div>
    </div>
  );
};
