import Cookies from 'js-cookie';
import { instance } from '../axios-instance';
import { PromoPackagesActivateResponseTypes } from './types';

export const promoPackagesActivate = (data: string) => {
  return instance.post<PromoPackagesActivateResponseTypes>(
    'promo-packages/activate',
    { hash: data },
    {
      headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
    },
  );
};
