import styled from 'styled-components';
import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../../componentUI/screen';

const ElementTablePartIsMobileStyled = styled.section<{ isFinGuardActive: boolean; numberOrdersUser: number }>`
  // ширина одного элемента, необходима для корректного построения Masonry-раскладки
  // 50% - gap родителя (20px)
  // 4 минимальное количество заказов при котором они расширяются на всю страницу
  // в мобильном представлении независимо от разрешения экрана
  position: relative;

  width: ${({ numberOrdersUser }) => (numberOrdersUser && numberOrdersUser > 4 ? 'calc(50% - 20px)' : '100%')};

  padding: ${({ isFinGuardActive }) => (isFinGuardActive ? '0 16px 16px 16px' : '16px')};

  display: flex;
  flex-direction: column;
  gap: 12px;

  border-radius: 6px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06), 0 3px 5px 0 rgba(150, 112, 38, 0.04);
  cursor: pointer;

  ${truthfulAdaptive.maxWidth.mobile} {
    width: 100%;
  }
`;

const TitleStyled = styled.div`
  position: relative;
  width: calc(100% + 32px); // убираем поля и растягиваем на всю ширину блока
  right: 16px; // убираем поля и растягиваем на всю ширину блока

  padding: 0 16px 12px 16px;

  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid ${COLOR_MAP.accent.lightGrey};
`;

const StatusTitleStyled = styled.div`
  display: flex;
  align-items: center;
`;

const OutcomeTitleStyled = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxTitleStyled = styled.div`
  display: flex;
  align-items: center;
`;

const MainStyled = styled.div`
  height: auto;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ProductInformationUnitOneStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImgTitleStyled = styled.div``;

const OrderInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const OrderNumberTitleStyled = styled.div`
  display: flex;
  align-items: center;

  white-space: nowrap;
`;

const AnswerTitleStyled = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperOrderAnswerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const DateTitleStyled = styled.div`
  display: flex;
  align-items: center;
`;

const ProductInformationUnitTwoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BrandTitleStyled = styled.div`
  display: flex;
  align-items: center;
`;

const ModelTitleStyled = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperCategoryStyled = styled.div``;

// const SKUTitleStyled = styled.div`
//   padding: 4px 10px;

//   display: inline-block;

//   border-radius: 4px;

//   border: 1px solid ${COLOR_MAP.accent.grey};
// `;

const FooterStyled = styled.div`
  padding: 12px 0 0 0;
  border-top: 1px solid ${COLOR_MAP.accent.lightGrey};
`;

const ActionTitleStyled = styled.div`
  display: flex;
  align-items: center;
`;

const FinGuardLabel = styled.div`
  display: flex;
  padding: 3px 6px;
  margin-bottom: -7px;
  align-items: center;
  gap: 4px;
  width: fit-content;

  border-radius: 6px;
  background: linear-gradient(89deg, ${COLOR_MAP.gradient.cardGradient1} 0%, ${COLOR_MAP.gradient.cardGradient2} 100%);
`;

export {
  ActionTitleStyled,
  AnswerTitleStyled,
  BrandTitleStyled,
  CheckboxTitleStyled,
  DateTitleStyled,
  ElementTablePartIsMobileStyled,
  FinGuardLabel,
  FooterStyled,
  ImgTitleStyled,
  MainStyled,
  ModelTitleStyled,
  OrderInfoStyled,
  OrderNumberTitleStyled,
  OutcomeTitleStyled,
  ProductInformationUnitOneStyled,
  ProductInformationUnitTwoStyled,
  // SKUTitleStyled,
  StatusTitleStyled,
  TitleStyled,
  WrapperCategoryStyled,
  WrapperOrderAnswerStyled,
};
