import styled from 'styled-components';

import { truthfulAdaptive } from '../../../componentUI/screen';

import { COLOR_MAP } from '../../../componentUI/colorsMap';

const AccountSettingsSkeletonStyled = styled.div`
  max-width: 1600px;
  width: 100%;

  display: grid;
  grid-template-columns: 20% 1fr;

  ${truthfulAdaptive.maxWidth.desktopS} {
    grid-template-columns: 1fr;
  }
`;

const ColumnMenuStyled = styled.div`
  padding: 24px 28px;

  min-height: 100vh;
  display: flex;
  flex-direction: column;

  background-color: ${COLOR_MAP.background.darkGrey};
`;

const ColumnMenuTopStyled = styled.div`
  padding: 16px;

  display: flex;
  justify-content: space-between;

  background-color: ${COLOR_MAP.background.darkGrey};
`;

const ColumnMenuTopBlockOneStyled = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const HeaderColumnMenuStyled = styled.div`
  display: flex;
`;

const MainColumnMenuStyled = styled.div`
  flex: 1 1 auto;

  margin-top: 38px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FooterColumnMenuStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainColumnMenuBlockOneStyled = styled.div`
  margin-left: 28px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const HeaderColumnMenuBlockOneStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const HeaderColumnMenuBlockTwoStyled = styled.div`
  margin-top: 38px;

  display: flex;
  gap: 34px;
`;

const MainContainerStyled = styled.div`
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
`;

const HeaderMainContainerStyled = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 55px;
`;

const HeaderMainContainerBlockOneStyled = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const MainMainContainerStyled = styled.div`
  margin-top: 36px;

  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: 36px;
`;

const MainMainContainerBlockOneStyled = styled.div`
  display: flex;

  gap: 10px;
`;

const MainMainContainerBlockTwoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MainMainContainerBlockThreeStyled = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

const MainMainContainerBlockFourStyled = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MainFooterContainerStyled = styled.div`
  padding: 20px 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;

  border-top: 1px solid ${COLOR_MAP.controls.skeleton.content1};
`;

export {
  AccountSettingsSkeletonStyled,
  ColumnMenuStyled,
  ColumnMenuTopBlockOneStyled,
  ColumnMenuTopStyled,
  FooterColumnMenuStyled,
  HeaderColumnMenuBlockOneStyled,
  HeaderColumnMenuBlockTwoStyled,
  HeaderColumnMenuStyled,
  HeaderMainContainerBlockOneStyled,
  HeaderMainContainerStyled,
  MainColumnMenuBlockOneStyled,
  MainColumnMenuStyled,
  MainContainerStyled,
  MainFooterContainerStyled,
  MainMainContainerBlockFourStyled,
  MainMainContainerBlockOneStyled,
  MainMainContainerBlockThreeStyled,
  MainMainContainerBlockTwoStyled,
  MainMainContainerStyled,
};
