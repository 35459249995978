import styled from 'styled-components';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { LinkClassic, LinkRouter } from '../../../../componentUI/simple/Link';

const sharedStyles = `
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

const TitleWrapperStyled = styled.div.attrs({ className: 'title-wrapper' })`
  ${sharedStyles}
`;

const TitleWrapperLinkStyled = styled(LinkRouter).attrs({ className: 'title-wrapper' })`
  ${sharedStyles}
`;

const TitleWrapperAnchorStyled = styled(LinkClassic).attrs({ className: 'title-wrapper' })`
  ${sharedStyles}
`;

const WrapperIconStyled = styled.div`
  padding: 0 10px 0 0;
  display: flex;
  align-items: center;
`;

const SelectionIndicatorStyled = styled.div<{ isActiveLink: boolean }>`
  position: relative;
  right: 28px;
  height: 28px;
  width: 6px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: ${({ isActiveLink }) => (isActiveLink ? COLOR_MAP.controls.clickableNormal : 'transparent')};

  .title-wrapper:hover & {
    background-color: ${COLOR_MAP.controls.clickableNormal};
  }
`;

const CreditsStyled = styled.div<{ isActiveLink: boolean }>`
  padding: 5px 10px 5px 5px;
  display: flex;
  gap: 5px;
  border-radius: 16px;
  border: 1px solid
    ${({ isActiveLink }) => (isActiveLink ? `${COLOR_MAP.accent.darkGrey}` : `${COLOR_MAP.accent.lightGrey}`)};

  .title-wrapper:hover & {
    border-color: ${COLOR_MAP.accent.darkGrey};
  }
`;

export {
  CreditsStyled,
  SelectionIndicatorStyled,
  TitleWrapperStyled,
  TitleWrapperLinkStyled,
  TitleWrapperAnchorStyled,
  WrapperIconStyled,
};
