/* eslint-disable react/no-array-index-key */
import React from 'react';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TAG_NAME } from '../../../../componentUI/constants';
import { SkeletonBasic } from '../../../../componentUI/skeleton';

export const CalculationSkeleton = () => {
  return (
    <>
      {new Array(5).fill('1').map((_, index) => {
        return (
          <React.Fragment key={index}>
            <SkeletonBasic width="100%" height="62px" radius="10px" bgColor={COLOR_MAP.controls.skeleton.content1} />
            <SkeletonBasic
              tag={TAG_NAME.section}
              width="100%"
              height="18px"
              bgColor={COLOR_MAP.controls.skeleton.content2}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
