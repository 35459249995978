import { GetProductsRequestType } from '../../../../../api/product/type';
import { CVS_TYPE } from '../types';

const { CurrentTable, CurrentPage, SearchAndFilter } = CVS_TYPE;

/** задает параметры запроса данных
 * для эндпоинта 'products/get-csv' в зависимости
 * от того какой тип csv-экспорта выбран  */

export const exportCSVSettings = (paramsGetProductQuery: GetProductsRequestType, checkedImport: string) => {
  switch (checkedImport) {
    case CurrentTable:
      return {
        ...paramsGetProductQuery,
        limit: 9999999999999,
        page: 1,
        search: null,
      };

    case CurrentPage:
      return paramsGetProductQuery;

    case SearchAndFilter:
      return {
        ...paramsGetProductQuery,
        limit: 9999999999999,
        page: 1,
      };

    default:
      return paramsGetProductQuery;
  }
};
