import React, { ReactNode, useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { InfoIcon } from '../../../common/icons/SvgInline/SvgIcons_5';
import { COLOR_MAP } from '../../colorsMap';
import { Z_INDEX_LAYERS } from '../../constants';
import { adaptive, truthfulAdaptive } from '../../screen';
import { SecondButton } from '../Button';
import { Text } from '../Text';
import { ChevronTooltipIcon } from './ChevronTooltipIcon';

const DEFAULT_COLOR_MAP = {
  clickable: COLOR_MAP.controls.buttonBlueNormal,
  clickableHover: COLOR_MAP.controls.buttonBlueHover,
  clickablePressed: COLOR_MAP.controls.buttonBlueActive,
  clickableDisabled: COLOR_MAP.controls.disabled,
};

const stylesForButtons: CSSProperties = {
  padding: '0',
  margin: '0',
  border: 'none',
  width: '20px',
  height: '20px',
};

const WrapperIconStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: none;
`;

const InfoTooltipStyled = styled.div`
  position: relative;
`;

const StyledTooltip = styled(Text)<{
  widthTooltip: string;
  positionMobile?: 'default' | 'left' | 'right';
  positionFromLeft?: string;
}>`
  position: absolute;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  line-height: 14px;

  ${({ widthTooltip }) => `width: ${widthTooltip};`}

  bottom: 30px;
  ${({ positionFromLeft }) => (positionFromLeft ? `left: ${positionFromLeft};` : 'left: -5px;')}

  padding: 10px 15px 10px 10px;

  background-color: ${COLOR_MAP.text.white};
  filter: drop-shadow(0px 5px 14px rgba(0, 0, 0, 0.08)) drop-shadow(0px 4px 9px rgba(150, 112, 38, 0.05));

  border-radius: 7px;
  z-index: ${Z_INDEX_LAYERS.other};

  ${adaptive.maxWidth.mobile} {
    bottom: 28px;

    ${({ positionFromLeft }) => (positionFromLeft ? `left: ${positionFromLeft};` : 'left: -110px;')}

    ${({ positionMobile }) => positionMobile === 'left' && 'left: -240px;'}
    ${({ positionMobile }) => positionMobile === 'right' && 'left: -42px;'}

    font-size: 9px;
    line-height: 11px;
  }
`;

const TriangleIconWrapper = styled.div`
  position: absolute;

  bottom: 18px;
  right: 5px;
  filter: drop-shadow(0px 5px 14px rgba(0, 0, 0, 0.08)) drop-shadow(0px 4px 9px rgba(150, 112, 38, 0.05));
  pointer-events: none;

  ${truthfulAdaptive.maxWidth.mobile} {
    bottom: 16px;
  }
`;

type Props = {
  text: string | ReactNode;
  widthTooltip?: string;
  positionMobile?: 'default' | 'left' | 'right';
  positionFromLeft?: string;
};

export const InfoTooltip = ({ text, widthTooltip = '156px', positionMobile, positionFromLeft }: Props) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <InfoTooltipStyled>
      <SecondButton
        colorsMap={DEFAULT_COLOR_MAP}
        style={stylesForButtons}
        onMouseOver={() => {
          setIsShow(true);
        }}
        onMouseOut={() => {
          setIsShow(false);
        }}
      >
        <WrapperIconStyled>
          <InfoIcon size="20" />
        </WrapperIconStyled>
      </SecondButton>

      {isShow && (
        <>
          <TriangleIconWrapper>
            <ChevronTooltipIcon width="10" height="8" />
          </TriangleIconWrapper>

          <StyledTooltip
            widthTooltip={widthTooltip}
            positionMobile={positionMobile}
            positionFromLeft={positionFromLeft}
          >
            {text}
          </StyledTooltip>
        </>
      )}
    </InfoTooltipStyled>
  );
};
