import styled, { CSSProperties } from 'styled-components';

import { COLOR_MAP } from '../../../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../../../componentUI/screen';

const FilterV2Styled = styled.div<{ isShow: boolean }>`
  display: grid;
  grid-template-columns: 168px 1fr auto;
  align-items: center;
  gap: 12px;

  grid-template-areas: 'Master Dependent TrashButton';

  ${truthfulAdaptive.maxWidth.mobile} {
    padding: 12px 0;

    grid-template-columns: 1fr auto;

    grid-template-areas:
      'Master  TrashButton'
      'Dependent Dependent';

    border-bottom: 1px solid ${COLOR_MAP.accent.lightGrey};

    // при перестроении элементов появляется нижний отступ хотя не должен
    // решил вот так
    gap: ${({ isShow }) => (isShow ? ' 12px;' : '0;')};
  }
`;

const MasterFilterStyled = styled.div`
  grid-area: Master;

  width: 100%;
`;

const DependentFiltersStyled = styled.div`
  grid-area: Dependent;
`;

const TrashButton = styled.button`
  grid-area: TrashButton;

  padding: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: none;

  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 0.8;
    }
  }

  @media (hover: none) and (pointer: coarse) {
    &:active {
      opacity: 0.8;
    }
  }
`;

const stylesForControl: CSSProperties = {
  minHeight: '44px',
};

export { DependentFiltersStyled, FilterV2Styled, MasterFilterStyled, stylesForControl, TrashButton };
