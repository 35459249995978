import React, { CSSProperties, InputHTMLAttributes, isValidElement, ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import { PALLETE } from '../../pallete';
import { noop, VisuallyHiddenInput } from '../../utils';
import { Text } from '../Text';
import {
  RADIO_BODY_STYLE,
  RADIO_CHECKED_BODY_STYLE,
  RADIO_CHECKED_STYLE,
  RADIO_INPUT_STYLE,
  radioTypes,
  TYPE_RADIO,
} from './constants';

type BasePropsRadio = {
  hasError?: boolean;
};

export type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> &
  BasePropsRadio & {
    checkedValue: string;
    disabled?: boolean;
    label?: string | ReactNode;
    onChange: (value: string) => void;
    name: string;
    value: string;
    typeRadio?: radioTypes;
    circleIsVisible?: boolean;
    style?: CSSProperties;
  };

const RadioWrapper = styled.label<{ disabled?: boolean; typeRadio: radioTypes; checked: boolean }>`
  flex-grow: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 15px;
  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')};
  ${({ typeRadio }) => RADIO_BODY_STYLE[typeRadio]};
  ${({ typeRadio, checked }) => checked && RADIO_CHECKED_BODY_STYLE[typeRadio]};

  user-select: none;
  cursor: pointer;
`;

const RadioRender = styled.span<{
  typeRadio: radioTypes;
  hasError?: boolean;
}>`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1.2px solid;
  border-color: ${({ hasError }) => hasError && PALLETE.status.error};
  ${({ typeRadio }) => RADIO_INPUT_STYLE[typeRadio]};

  border-radius: 50%;
`;

const CheckboxChosen = styled.span<{ typeRadio: radioTypes }>`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  ${({ typeRadio }) => RADIO_CHECKED_STYLE[typeRadio]};
`;

// TODO написать обертку-группировщик что бы не передавать колбэк и значение в каждый Radio
export const Radio = ({
  checkedValue,
  disabled,
  onChange = noop,
  name,
  label = '',
  hasError,
  value,
  typeRadio = TYPE_RADIO.type1,
  circleIsVisible = true,
  style,
}: Props) => {
  const changeHandler = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  const labelRender = isValidElement(label) ? label : <Text>{label}</Text>;
  const checked = checkedValue === value;

  return (
    <RadioWrapper style={style} disabled={disabled} typeRadio={typeRadio} checked={checked}>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <VisuallyHiddenInput
          type="radio"
          onChange={changeHandler}
          checked={checked}
          id={name}
          name={name}
          value={value}
        />
        {circleIsVisible && (
          <RadioRender hasError={hasError} typeRadio={typeRadio}>
            {checked && <CheckboxChosen typeRadio={typeRadio} />}
          </RadioRender>
        )}
      </div>
      {labelRender}
    </RadioWrapper>
  );
};
