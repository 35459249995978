import styled from 'styled-components';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';

import { truthfulAdaptive } from '../../../../componentUI/screen';

const CompletionRegistrationStyled = styled.div<{ isLoading: boolean }>`
  display: ${({ isLoading }) => (isLoading ? 'none' : 'grid')};

  min-height: 100vh;
  width: 100%;

  padding: 0 0 20px 0;

  grid-template-rows: auto 1fr auto;
  justify-items: center;

  background-color: ${COLOR_MAP.background.white};

  ${truthfulAdaptive.maxWidth.mobile} {
    gap: 24px;
  }
`;

const WrapperAccountInfoStyled = styled.div`
  padding: 0 24px;

  max-width: 480px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RadioWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${truthfulAdaptive.maxWidth.mobile} {
    gap: 20px;
  }
`;

const WrappingInputFieldsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${truthfulAdaptive.maxWidth.mobile} {
    gap: 20px;
  }
`;

const WrapperLoaderStyled = styled.div`
  min-height: 100vh;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  CompletionRegistrationStyled,
  RadioWrapperStyled,
  WrapperAccountInfoStyled,
  WrapperLoaderStyled,
  WrappingInputFieldsStyled,
};
