import styled from 'styled-components';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../componentUI/screen';

const TransactionsStyled = styled.div<{ recordLimitTransactions: boolean }>`
  display: grid;
  gap: 20px 0;

  ${({ recordLimitTransactions }) =>
    recordLimitTransactions ? null : 'min-height: 84vh; grid-template-rows: auto 1fr auto;'}
`;

const WrapperTabledTransactionsStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${truthfulAdaptive.maxWidth.mobile} {
    gap: 16px 0;

    div:last-child {
      margin: 0 0 16px 0;
    }
  }
`;

const WrapperNoTransactionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  ${truthfulAdaptive.maxWidth.mobile} {
    gap: 32px;
  }
`;

const TabletElementStyled = styled.div`
  padding: 18px 0;

  display: grid;
  grid-template-columns: 1fr 16% 16%;
  grid-template-areas: 'Action BalanceAfter DateAndTime';
  align-items: center;
  gap: 0 10px;

  border-bottom: 1px solid ${COLOR_MAP.accent.grey};

  ${truthfulAdaptive.maxWidth.mobile} {
    padding: 12px;

    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      'Action Action'
      'BalanceAfter DateAndTime';
    gap: 12px 0;

    border-radius: 6px;
    border-bottom: none;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06), 0px 3px 5px 0px rgba(150, 112, 38, 0.04);
  }
`;

const TransactionInformationStyled = styled.div`
  display: flex;
  gap: 0 8px;

  ${truthfulAdaptive.maxWidth.mobile} {
    justify-content: space-between;
  }
`;

const WrapperDescriptionsElementStyled = styled.div`
  grid-area: Action;
  display: flex;
  gap: 0 8px;
`;

const ArrivalAndExpenseStyled = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: 0 8px;
`;

const WrapperLinkElementStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`;

const WrapperBalanceAfterStyled = styled.div`
  grid-area: BalanceAfter;

  display: flex;
  flex-direction: column;

  white-space: nowrap;
`;

const WrapperDateAndTimeStyled = styled.div`
  grid-area: DateAndTime;

  display: flex;
  flex-direction: column;
`;

const WrapperPaginationStyled = styled.div`
  padding: 20px 0;
`;

export {
  ArrivalAndExpenseStyled,
  TabletElementStyled,
  TransactionInformationStyled,
  TransactionsStyled,
  WrapperBalanceAfterStyled,
  WrapperDateAndTimeStyled,
  WrapperDescriptionsElementStyled,
  WrapperLinkElementStyled,
  WrapperNoTransactionsStyled,
  WrapperPaginationStyled,
  WrapperTabledTransactionsStyled,
};
