import { EmptyAuthentications, EmptyDraft } from '../../../../common/images/NoRecordsFound';
import { PresetValuesTypes } from './types';

const PRESET_VALUES: PresetValuesTypes = {
  drafts: {
    picture: EmptyDraft,
  },
  all: {
    picture: EmptyAuthentications,
  },
  'photos-needed': {
    picture: EmptyAuthentications,
  },
  'under-review': {
    picture: EmptyAuthentications,
  },
  completed: {
    picture: EmptyAuthentications,
  },
};

export { PRESET_VALUES };
