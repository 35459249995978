import { NameStatus } from '../api/products/type';
import { COLOR_MAP } from '../componentUI/colorsMap';
import { ResultStatusName } from '../redux/types';

const {
  COMPLETED,
  AWAIT_CERTIFICATE,
  OPEN,
  UPDATED,
  UPDATE_NEEDED,
  ERROR,
  READY,
  DRAFT,
  AWAIT_QR_LABEL,
  DOUBLE_CHECK,
} = ResultStatusName;

export enum Status {
  'Photos needed' = 'Photos needed',
  'Under review' = 'Under review',
  'Completed' = 'Completed',
  'Drafts' = 'Drafts',
}

const STATUS_DICTIONARY: Array<[Array<ResultStatusName>, Status]> = [
  [[UPDATE_NEEDED], Status['Photos needed']],
  [[OPEN, UPDATED, ERROR, DOUBLE_CHECK], Status['Under review']],
  [[READY, COMPLETED, AWAIT_CERTIFICATE, AWAIT_QR_LABEL], Status.Completed],
  [[DRAFT], Status.Drafts],
];

/**
 * цвета в соответствии с категорией продукта
 */
export const STATUS_MAP_COLOR: Record<Status | 'Unknown', string> = {
  'Photos needed': COLOR_MAP.accent.orange,
  'Under review': COLOR_MAP.accent.blue,
  Completed: COLOR_MAP.accent.green,
  Drafts: COLOR_MAP.accent.grey20,
  Unknown: '',
};

/**
 * выполняет проверку по словарю и возвращает категорию продукта.
 */
export const getStatusProduct = (status: ResultStatusName): Status | 'Unknown' => {
  return STATUS_DICTIONARY.find((statusItem) => statusItem[0].includes(status))?.[1] || 'Unknown';
};

/**
 * Get the status key for translation with camelCase handling.
 * @param status The current status of the product.
 * @returns The key to use in translation.json.
 */
export const getStatusTranslationKey = (status: Status | 'Unknown' | NameStatus): string => {
  if (status === 'Unknown') {
    return 'unknown';
  }

  return status
    .toLowerCase() // Convert to lowercase
    .replace(/\s+([a-z])/g, (_, letter) => letter.toUpperCase()) // Convert spaces followed by a letter to camelCase
    .replace(/\s+/g, ''); // Remove any remaining spaces
};

/**
 * Converts a kebab-case string to camelCase.
 * Example: "photos-needed" -> "photosNeeded"
 * @param str The kebab-case string to convert.
 * @returns The camelCase version of the string.
 */
export const kebabToCamelCase = (str: string): string => {
  return str.toLowerCase().replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
};

type PublicNameToResultStatusMap = {
  [key: string]: ResultStatusName | ((systemStatus: string) => ResultStatusName);
};

const publicNameToResultStatus: PublicNameToResultStatusMap = {
  'Waiting for certificate': ResultStatusName.AWAIT_CERTIFICATE,
  'Under review': (systemStatus: string) => (systemStatus === 'OPEN' ? ResultStatusName.OPEN : ResultStatusName.READY),
  Completed: ResultStatusName.COMPLETED,
  Draft: ResultStatusName.DRAFT,
  'Photos needed': ResultStatusName.UPDATE_NEEDED,
  'Waiting for item labels': ResultStatusName.AWAIT_QR_LABEL,
  Error: ResultStatusName.ERROR,
};

export const convertPublicNameToResultStatus = (publicName: string, systemStatus: string): ResultStatusName => {
  const result = publicNameToResultStatus[publicName];
  if (typeof result === 'function') {
    return result(systemStatus);
  }
  return result || ResultStatusName.OPEN;
};
