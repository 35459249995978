import React from 'react';
import { BellNotification, Logo, LogoPlus } from '../../../../components';
import { useMatchMedia } from '../../../../hooks';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { Text, TEXT_WEIGHT } from '../../../../componentUI/simple/Text';
import { SkeletonBasic } from '../../../../componentUI/skeleton';
import { SettingIcon } from '../../../../common/icons/SvgInline/SvgIcons_6';
import { CloseCrossIcon } from '../../../../common/icons/SvgInline/SvgIcons_9';
import { CapsUserAvatar } from '../../../../common/images/caps';
import {
  AvatarUserStyled,
  InfoUserStyled,
  stylesForTitle,
  TitleIsSidebarStyled,
  TitleStyled,
  WrapperCloseCrossIconStyled,
  WrapperSettingStyled,
} from './styled';
import { Props } from './types';

export const Title = ({
  closeHandler,
  isSubscriptions,
  isSidebar,
  hasNotification,
  userAvatarImg,
  userAccountName,
  isLoadingAccountInfo,
}: Props) => {
  const { isDesktopS } = useMatchMedia();

  return (
    <TitleStyled>
      {!isDesktopS && (
        <>
          {isSubscriptions && <LogoPlus />}

          {!isSubscriptions && <Logo />}
        </>
      )}

      {isDesktopS && (
        <WrapperSettingStyled to="/account-settings/general-info">
          {!isLoadingAccountInfo && (
            <InfoUserStyled>
              <AvatarUserStyled src={userAvatarImg || CapsUserAvatar} alt="Avatar" />

              <Text style={stylesForTitle} weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.white}>
                {userAccountName}
              </Text>
            </InfoUserStyled>
          )}

          {isLoadingAccountInfo && (
            <InfoUserStyled>
              <SkeletonBasic width="40px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="150px" height="22px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            </InfoUserStyled>
          )}

          <SettingIcon size="28" />
        </WrapperSettingStyled>
      )}

      {isSidebar && isDesktopS && (
        <TitleIsSidebarStyled>
          <BellNotification fill={COLOR_MAP.controls.textAndIcon} hasNotification={hasNotification} />

          <WrapperCloseCrossIconStyled onClick={closeHandler}>
            <CloseCrossIcon />
          </WrapperCloseCrossIconStyled>
        </TitleIsSidebarStyled>
      )}
    </TitleStyled>
  );
};
