import React from 'react';

import { useTranslation } from 'react-i18next';
import { Text, TEXT_WEIGHT } from '../../../../componentUI/simple/Text';

import { Props } from './types';

export const CertificateFreeInfo = ({ templateFree, userSubscriptions }: Props) => {
  const { t } = useTranslation();
  const isShowInformation = Boolean(templateFree) && Boolean(!userSubscriptions?.result);

  if (!isShowInformation) {
    return null;
  }

  return (
    isShowInformation && (
      <Text weight={TEXT_WEIGHT.bold}>
        {t('certificateInfoWs.certificateFreeInfo', {
          used: templateFree?.used,
          limit: templateFree?.limit,
        })}
      </Text>
    )
  );
};
