import styled from 'styled-components';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { LinkRouter } from '../../../../componentUI/simple/Link';
import { DesignType } from './types';

const OrderStatisticsStyled = styled.div<{ design: DesignType }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${({ design }) => design === 'column' && 'padding-left: 28px;'}
`;

const NumberBlock = styled.div`
  padding: 3px 8px;

  border: 1px solid ${COLOR_MAP.text.grey};
  border-radius: 12px;
`;

const ItemWrapper = styled(LinkRouter)`
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: inherit;

  &:hover {
    opacity: 0.8;
  }
`;

const PointSeparator = styled.div`
  position: relative;
  top: 8px;

  width: 100%;

  border-bottom: 1px dashed ${COLOR_MAP.text.darkGrey};
`;

// This wrapper prevents the arrow icon from shifting
const WrapperRightArrowStyled = styled.div`
  width: 8%;
`;

export { ItemWrapper, NumberBlock, OrderStatisticsStyled, PointSeparator, WrapperRightArrowStyled };
