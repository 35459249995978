/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';

import { OverlayingPopup } from '../../components';

import { ContainerStyled } from './styled';
import { Props } from './types';

export const useOnboardingModal = (): [(props: Props) => JSX.Element, () => void] => {
  const [isOpened, setIsOpened] = useState(false);

  const toggleOverlayingModal = useCallback(() => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  const OverlayingModal = ({ children }: Props) => {
    return (
      <OverlayingPopup isOpened={isOpened} onClose={setIsOpened}>
        <ContainerStyled>{children}</ContainerStyled>
      </OverlayingPopup>
    );
  };

  return [OverlayingModal, toggleOverlayingModal];
};
