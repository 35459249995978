/* eslint-disable react-hooks/rules-of-hooks */
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Cookies from 'js-cookie';
import React from 'react';
import { createRoot } from 'react-dom/client';
// import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { AppStyled } from './AppStyled';
import { refreshAuthToken } from './helpers';
import './index.css';
import { store } from './redux';

type ErrorType = {
  response: {
    status: number;
    statusText: string;
  };
};

const container = document.getElementById('root')!;
const root = createRoot(container);

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      const err = error as ErrorType;

      if (err?.response?.status === 401) {
        const refreshToken = Cookies.get('jwt-refresh');

        refreshAuthToken(refreshToken);
      }
    },
  }),

  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <Provider store={store}>
        <BrowserRouter>
          <AppStyled />
          <App />
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
);
