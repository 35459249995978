/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useConfirmEmailMutate, useResendDigitCodeMutate } from '../../../../api/auth/hooks';
import { QUERY_KEY } from '../../../../common';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { InputWithError } from '../../../../componentUI/complex/InputWithError';
import { TAG_NAME } from '../../../../componentUI/constants';
import { Button, SecondButton } from '../../../../componentUI/simple/Button';
import { Text } from '../../../../componentUI/simple/Text';
import { NotificationType } from '../../../../componentUI/types';
import { useFormField } from '../../../../hooks';
import { addToast } from '../../../../redux/reducers/toast-reducer';
import { TitleBlock } from '../../components';
import { OTPCodeFooterStyled, OTPCodeStyled, stylesForButtons, WrapperMainContextStyled } from './styled';
import { Props } from './types';

/** Страница содержит функционал ввода
 *  кода-подтверждения */

export const OTPCode = ({ email }: Props) => {
  const client = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    mutate: confirmEmail,
    data: dataConfirmEmail,
    isError: isErrorConfirmEmail,
    isLoading: isLoadingConfirmEmail,
    isSuccess: isSuccessConfirmEmail,
  } = useConfirmEmailMutate();

  const {
    mutate: resendDigitCode,
    isLoading: isLoadingResendDigitCode,
    isSuccess: isSuccessResendDigitCode,
  } = useResendDigitCodeMutate();

  const [digitCode, setDigitCode, digitCodeError, setDigitCodeError] = useFormField({
    initValue: '',
    withError: true,
  });

  const minNumCharacters = digitCode.length >= 6;
  const isDisableVerifyBtn = Boolean(
    digitCodeError || digitCode.length < 6 || isLoadingConfirmEmail || isLoadingResendDigitCode,
  );

  useEffect(() => {
    if (isErrorConfirmEmail) {
      setDigitCodeError(t('auth.otpCode.errorOne'));
    }
  }, [isErrorConfirmEmail]);

  useEffect(() => {
    if (isSuccessConfirmEmail) {
      // сохраняем валидный токен
      Cookies.set('jwt', dataConfirmEmail.token);
      Cookies.set('jwt-refresh', dataConfirmEmail.refreshToken);
      // перезапрашиваем список доступных url
      client.invalidateQueries({ queryKey: [QUERY_KEY.registerUser] });
      client.invalidateQueries({ queryKey: [QUERY_KEY.getAccountInfo] });

      navigate('/completion-registration');
      setDigitCodeError('');
      dispatch(
        addToast({
          text: t('auth.otpCode.isSuccessConfirmEmail'),
          type: NotificationType.Success,
        }),
      );
    }
  }, [isSuccessConfirmEmail]);

  useEffect(() => {
    if (isSuccessResendDigitCode) {
      dispatch(
        addToast({
          text: t('auth.otpCode.isSuccessResendDigitCode'),
          type: NotificationType.Success,
        }),
      );
    }
  }, [isSuccessResendDigitCode]);

  const changeDigitCode = (value: string) => {
    setDigitCodeError(value || minNumCharacters ? '' : t('auth.otpCode.errorOne'));
    setDigitCode(value);
  };

  return (
    <OTPCodeStyled>
      <TitleBlock titleText={t('auth.otpCode.title')} subtitleText={t('auth.otpCode.subtitle')} emailText={email} />

      <WrapperMainContextStyled>
        <InputWithError
          inputMode="numeric"
          value={digitCode}
          onChange={(event) => {
            changeDigitCode(event);
          }}
          onBlur={(event) => {
            changeDigitCode(event.currentTarget.value);
          }}
          placeholder={t('auth.otpCode.inputPlaceholder')}
          errorMessage={digitCodeError}
        />

        <Button
          style={{ textTransform: 'uppercase' }}
          isStretch
          disabled={isDisableVerifyBtn}
          onClick={() => confirmEmail({ email, code: digitCode })}
        >
          {t('auth.otpCode.buttonVer')}
        </Button>
      </WrapperMainContextStyled>

      <OTPCodeFooterStyled>
        <Text tag={TAG_NAME.span} colorText={COLOR_MAP.text.white}>
          {t('auth.otpCode.footerTitle')}
        </Text>

        <SecondButton style={stylesForButtons} onClick={() => resendDigitCode({ email })}>
          {t('auth.otpCode.footerLink')}
        </SecondButton>
      </OTPCodeFooterStyled>
    </OTPCodeStyled>
  );
};
