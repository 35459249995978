/* eslint-disable max-len */
import React from 'react';

export const EyeIcon = ({ clickHandler, size = '20' }: { clickHandler?: () => void; size?: string }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
      style={{ cursor: 'pointer' }}
    >
      <path
        d="M10.0002 3.3335C4.16683 3.3335 0.833496 10.0002 0.833496 10.0002C0.833496 10.0002 4.16683 16.6668 10.0002 16.6668C15.8335 16.6668 19.1668 10.0002 19.1668 10.0002C19.1668 10.0002 15.8335 3.3335 10.0002 3.3335Z"
        stroke="#020202"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
        stroke="#020202"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EyeOffIcon = ({ clickHandler, size = '20' }: { clickHandler?: () => void; size?: string }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
      style={{ cursor: 'pointer' }}
    >
      <g clipPath="url(#clip0_9602_9499)">
        <path
          d="M14.9502 14.95C13.5257 16.0358 11.7911 16.6374 10.0002 16.6667C4.16683 16.6667 0.833496 10 0.833496 10C1.87007 8.06825 3.30778 6.38051 5.05016 5.05M8.25016 3.53333C8.82377 3.39907 9.41105 3.33195 10.0002 3.33333C15.8335 3.33333 19.1668 10 19.1668 10C18.661 10.9463 18.0577 11.8373 17.3668 12.6583M11.7668 11.7667C11.538 12.0123 11.262 12.2093 10.9553 12.3459C10.6486 12.4826 10.3176 12.556 9.9819 12.562C9.64623 12.5679 9.3128 12.5061 9.0015 12.3804C8.69021 12.2547 8.40743 12.0675 8.17003 11.8301C7.93264 11.5927 7.74549 11.31 7.61975 10.9987C7.49402 10.6874 7.43227 10.3539 7.43819 10.0183C7.44411 9.68258 7.51759 9.35154 7.65423 9.04487C7.79087 8.73821 7.98787 8.46221 8.2335 8.23333"
          stroke="#020202"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.833496 0.833313L19.1668 19.1666"
          stroke="#020202"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9602_9499">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const MatchIcon = ({ size = '20', color = '#6BCBA9' }: { size?: string; color?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9693_7733)">
        <path
          d="M18.3332 9.23336V10C18.3321 11.797 17.7503 13.5456 16.6743 14.9849C15.5983 16.4242 14.0859 17.4771 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8122C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0234 1.56633 11.2401 1.68457 9.44696C1.80281 7.65383 2.49751 5.94697 3.66507 4.58092C4.83263 3.21488 6.41049 2.26285 8.16333 1.86682C9.91617 1.47079 11.7501 1.65198 13.3915 2.38336"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3333 3.33331L10 11.675L7.5 9.17498"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9693_7733">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const MismatchIcon = ({ size = '20', color = '#DE4141' }: { size?: string; color?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9693_10362)">
        <path
          d="M13.4503 1.66669H6.55033L1.66699 6.55002V13.45L6.55033 18.3334H13.4503L18.3337 13.45V6.55002L13.4503 1.66669Z"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12.5 7.5L7.5 12.5" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5 7.5L12.5 12.5" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_9693_10362">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LogOutIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H7.5"
        stroke="#CBAD73"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.333 14.1666L17.4997 9.99992L13.333 5.83325"
        stroke="#CBAD73"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17.5 10H7.5" stroke="#CBAD73" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const RefreshIcon = ({ size = '20' }: { size?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.833344 3.33334V8.33334H5.83334"
        stroke="#CBAD73"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1667 16.6667V11.6667H14.1667"
        stroke="#CBAD73"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.075 7.50001C16.6524 6.30567 15.9341 5.23785 14.9871 4.39619C14.0402 3.55454 12.8955 2.96648 11.6598 2.68689C10.4241 2.4073 9.13771 2.4453 7.92068 2.79732C6.70365 3.14935 5.59563 3.80394 4.70001 4.70001L0.833344 8.33335M19.1667 11.6667L15.3 15.3C14.4044 16.1961 13.2964 16.8507 12.0793 17.2027C10.8623 17.5547 9.57593 17.5927 8.34025 17.3131C7.10456 17.0335 5.95984 16.4455 5.0129 15.6038C4.06595 14.7622 3.34765 13.6944 2.92501 12.5"
        stroke="#CBAD73"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DeleteIcon = ({ size = '20' }: { size?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9880_41062)">
        <path
          d="M9.99999 18.3333C14.6024 18.3333 18.3333 14.6024 18.3333 9.99999C18.3333 5.39762 14.6024 1.66666 9.99999 1.66666C5.39762 1.66666 1.66666 5.39762 1.66666 9.99999C1.66666 14.6024 5.39762 18.3333 9.99999 18.3333Z"
          stroke="#CBAD73"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12.5 7.5L7.5 12.5" stroke="#CBAD73" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5 7.5L12.5 12.5" stroke="#CBAD73" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_9880_41062">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const InfoIcon = ({ size = '20', color = '#ffffff' }: { size?: string; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_9336_3943)">
      <path
        d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 9.99999C18.3334 5.39762 14.6025 1.66666 10.0001 1.66666C5.39771 1.66666 1.66675 5.39762 1.66675 9.99999C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 13.3333V10" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 6.66666H10.0083" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_9336_3943">
        <rect width="20" height="20" fill={color} />
      </clipPath>
    </defs>
  </svg>
);

export const NoNotifications = () => {
  return (
    <svg width="180" height="160" viewBox="0 0 180 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.583 12.8C115.931 12.8 116.212 13.0662 116.212 13.3946C116.212 16.3497 118.749 18.7453 121.878 18.7453C122.226 18.7453 122.507 19.0115 122.507 19.3398C122.507 19.6682 122.226 19.9344 121.878 19.9344C118.749 19.9344 116.212 22.33 116.212 25.2851C116.212 25.6134 115.931 25.8796 115.583 25.8796C115.235 25.8796 114.953 25.6134 114.953 25.2851C114.953 22.33 112.417 19.9344 109.288 19.9344C108.94 19.9344 108.658 19.6682 108.658 19.3398C108.658 19.0115 108.94 18.7453 109.288 18.7453C112.417 18.7453 114.953 16.3497 114.953 13.3946C114.953 13.0662 115.235 12.8 115.583 12.8ZM115.583 16.1228C114.892 17.542 113.679 18.6875 112.177 19.3398C113.679 19.9922 114.892 21.1377 115.583 22.5568C116.274 21.1377 117.487 19.9922 118.989 19.3398C117.487 18.6875 116.274 17.542 115.583 16.1228Z"
        fill="#BCA371"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.3061 31.158C32.7461 31.158 33.1027 31.4976 33.1027 31.9165C33.1027 35.6872 36.3122 38.7439 40.2713 38.7439C40.7113 38.7439 41.0679 39.0835 41.0679 39.5024C41.0679 39.9214 40.7113 40.261 40.2713 40.261C36.3122 40.261 33.1027 43.3177 33.1027 47.0884C33.1027 47.5073 32.7461 47.8469 32.3061 47.8469C31.8662 47.8469 31.5096 47.5073 31.5096 47.0884C31.5096 43.3177 28.3001 40.261 24.341 40.261C23.901 40.261 23.5444 39.9214 23.5444 39.5024C23.5444 39.0835 23.901 38.7439 24.341 38.7439C28.3001 38.7439 31.5096 35.6872 31.5096 31.9165C31.5096 31.4976 31.8662 31.158 32.3061 31.158ZM32.3061 35.3977C31.4322 37.2085 29.8975 38.6701 27.9962 39.5024C29.8975 40.3348 31.4322 41.7964 32.3061 43.6072C33.1801 41.7964 34.7148 40.3348 36.6161 39.5024C34.7148 38.6701 33.1801 37.2085 32.3061 35.3977Z"
        fill="#BCA371"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.6126 137.967C78.8637 137.967 79.0672 138.155 79.0672 138.387C79.0672 140.473 80.8992 142.164 83.159 142.164C83.41 142.164 83.6136 142.352 83.6136 142.584C83.6136 142.815 83.41 143.003 83.159 143.003C80.8992 143.003 79.0672 144.694 79.0672 146.78C79.0672 147.012 78.8637 147.2 78.6126 147.2C78.3615 147.2 78.1579 147.012 78.1579 146.78C78.1579 144.694 76.326 143.003 74.0662 143.003C73.8151 143.003 73.6116 142.815 73.6116 142.584C73.6116 142.352 73.8151 142.164 74.0662 142.164C76.326 142.164 78.1579 140.473 78.1579 138.387C78.1579 138.155 78.3615 137.967 78.6126 137.967ZM78.6126 140.313C78.1137 141.315 77.2378 142.123 76.1525 142.584C77.2378 143.044 78.1137 143.853 78.6126 144.854C79.1114 143.853 79.9874 143.044 81.0726 142.584C79.9874 142.123 79.1114 141.315 78.6126 140.313Z"
        fill="#BCA371"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M138.438 62.8669C138.593 62.8669 138.718 62.9765 138.718 63.1117C138.718 64.3286 139.846 65.315 141.236 65.315C141.391 65.315 141.516 65.4246 141.516 65.5598C141.516 65.695 141.391 65.8046 141.236 65.8046C139.846 65.8046 138.718 66.791 138.718 68.0078C138.718 68.143 138.593 68.2526 138.438 68.2526C138.284 68.2526 138.159 68.143 138.159 68.0078C138.159 66.791 137.031 65.8046 135.641 65.8046C135.486 65.8046 135.361 65.695 135.361 65.5598C135.361 65.4246 135.486 65.315 135.641 65.315C137.031 65.315 138.159 64.3286 138.159 63.1117C138.159 62.9765 138.284 62.8669 138.438 62.8669ZM138.438 64.2351C138.131 64.8195 137.592 65.2912 136.925 65.5598C137.592 65.8284 138.131 66.3001 138.438 66.8844C138.745 66.3001 139.284 65.8284 139.952 65.5598C139.284 65.2912 138.745 64.8195 138.438 64.2351Z"
        fill="#BCA371"
      />
      <path
        d="M78.5615 27.7358C74.5007 28.572 71.8812 32.55 72.7173 36.6108C73.5535 40.6717 77.5315 43.2912 81.5924 42.455C85.6532 41.6189 88.2728 37.6409 87.4366 33.58C86.6004 29.5191 82.6224 26.8996 78.5615 27.7358ZM81.0092 39.6227C78.5067 40.138 76.0513 38.5211 75.536 36.0186C75.0207 33.5161 76.6376 31.0607 79.1401 30.5454C81.6426 30.0301 84.0979 31.647 84.6132 34.1495C85.1285 36.652 83.5117 39.1074 81.0092 39.6227Z"
        fill="url(#paint0_linear_12152_130723)"
      />
      <path
        d="M103.348 108.669C108.533 112.083 109.972 119.067 106.557 124.252C103.143 129.437 96.1585 130.876 90.9734 127.461C85.7883 124.047 84.3502 117.063 87.7646 111.877C91.179 106.692 98.1632 105.254 103.348 108.669Z"
        fill="url(#paint1_linear_12152_130723)"
      />
      <path
        d="M134.686 100.544L55.7664 116.794C62.4516 102.387 64.5723 86.3695 61.3634 70.7858C58.4872 56.8173 67.4524 43.6357 81.2174 40.6353C95.1859 37.7591 108.494 46.5322 111.368 60.4893C114.703 75.8811 122.853 89.9495 134.686 100.544Z"
        fill="#CCAF77"
      />
      <g filter="url(#filter0_d_12152_130723)">
        <path
          d="M136.588 109.78L57.6684 126.031C55.0749 126.565 52.5235 124.885 51.9895 122.291C51.4554 119.698 53.1355 117.146 55.729 116.612L134.649 100.362C137.242 99.828 139.794 101.508 140.328 104.102C140.738 106.898 139.182 109.246 136.588 109.78Z"
          fill="#D7BA83"
        />
      </g>
      <ellipse
        cx="135.203"
        cy="106.935"
        rx="26.7024"
        ry="26.7024"
        transform="rotate(9.43086 135.203 106.935)"
        fill="#C1A56C"
      />
      <g filter="url(#filter1_d_12152_130723)">
        <ellipse
          cx="135.203"
          cy="106.935"
          rx="21.3619"
          ry="21.3619"
          transform="rotate(9.43086 135.203 106.935)"
          fill="#D7BA83"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.493 100.388C147.11 101.068 147.045 102.096 146.348 102.685L132.207 114.631C131.86 114.925 131.402 115.065 130.941 115.02C130.479 114.975 130.055 114.749 129.768 114.395L124.724 108.167C124.147 107.454 124.271 106.432 125.001 105.882C125.732 105.333 126.791 105.465 127.368 106.177L131.307 111.041L144.114 100.221C144.811 99.6329 145.876 99.7073 146.493 100.388Z"
        fill="#705930"
      />
      <g filter="url(#filter2_b_12152_130723)">
        <path
          d="M84.0851 70.976C84.0851 81.7405 75.3587 90.4669 64.5942 90.4669C53.8296 90.4669 45.1033 81.7405 45.1033 70.976C45.1033 60.2115 53.8296 51.4851 64.5942 51.4851C75.3587 51.4851 84.0851 60.2115 84.0851 70.976Z"
          fill="#F6DFB4"
          fillOpacity="0.5"
        />
      </g>
      <g filter="url(#filter3_d_12152_130723)">
        <path
          d="M46.161 82.3156C46.9872 81.6505 48.1962 81.7812 48.8613 82.6075C49.5264 83.4338 49.3957 84.6427 48.5694 85.3078L40.6915 91.6489C39.8652 92.314 38.6562 92.1833 37.9912 91.357C37.3261 90.5308 37.4568 89.3218 38.283 88.6567L46.161 82.3156Z"
          fill="#D7BA83"
        />
      </g>
      <g filter="url(#filter4_d_12152_130723)">
        <path
          d="M87.2035 70.7162C87.2035 83.3466 76.9645 93.5856 64.3342 93.5856C51.7038 93.5856 41.4648 83.3466 41.4648 70.7162C41.4648 58.0859 51.7038 47.8469 64.3342 47.8469C76.9645 47.8469 87.2035 58.0859 87.2035 70.7162ZM46.6034 70.7162C46.6034 80.5086 54.5417 88.447 64.3342 88.447C74.1266 88.447 82.0649 80.5086 82.0649 70.7162C82.0649 60.9238 74.1266 52.9855 64.3342 52.9855C54.5417 52.9855 46.6034 60.9238 46.6034 70.7162Z"
          fill="url(#paint2_linear_12152_130723)"
        />
      </g>
      <g filter="url(#filter5_d_12152_130723)">
        <path
          d="M36.5915 86.6834C38.1568 85.4234 40.4471 85.671 41.7071 87.2363L42.6848 88.4509C43.9447 90.0162 43.6971 92.3065 42.1318 93.5665L22.0974 109.693C20.5321 110.952 18.2418 110.705 16.9819 109.14L16.0042 107.925C14.7442 106.36 14.9918 104.069 16.5571 102.809L36.5915 86.6834Z"
          fill="#BB9D63"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_12152_130723"
          x="40.3496"
          y="96.416"
          width="111.584"
          height="48.9485"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.69387" />
          <feGaussianBlur stdDeviation="5.7704" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12152_130723" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12152_130723" result="shape" />
        </filter>
        <filter
          id="filter1_d_12152_130723"
          x="104.605"
          y="82.4923"
          width="61.1955"
          height="61.1958"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.15509" />
          <feGaussianBlur stdDeviation="4.61632" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12152_130723" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12152_130723" result="shape" />
        </filter>
        <filter
          id="filter2_b_12152_130723"
          x="41.2058"
          y="47.5876"
          width="46.7767"
          height="46.7767"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.94874" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_12152_130723" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_12152_130723" result="shape" />
        </filter>
        <filter
          id="filter3_d_12152_130723"
          x="28.2127"
          y="78.7731"
          width="30.4272"
          height="28.8903"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.23598" />
          <feGaussianBlur stdDeviation="4.67698" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12152_130723" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12152_130723" result="shape" />
        </filter>
        <filter
          id="filter4_d_12152_130723"
          x="32.1109"
          y="44.7289"
          width="64.4465"
          height="64.4465"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.23598" />
          <feGaussianBlur stdDeviation="4.67698" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12152_130723" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12152_130723" result="shape" />
        </filter>
        <filter
          id="filter5_d_12152_130723"
          x="5.84598"
          y="82.7612"
          width="46.997"
          height="43.3256"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.23598" />
          <feGaussianBlur stdDeviation="4.67698" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12152_130723" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12152_130723" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_12152_130723"
          x1="80.442"
          y1="43.0779"
          x2="77.3349"
          y2="27.9884"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3955C" />
          <stop offset="0.555113" stopColor="#8E7545" />
          <stop offset="1" stopColor="#7E6A43" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12152_130723"
          x1="97.7073"
          y1="130.014"
          x2="93.0563"
          y2="107.426"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3955C" />
          <stop offset="0.555113" stopColor="#8E7545" />
          <stop offset="1" stopColor="#7E6A43" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12152_130723"
          x1="51.8784"
          y1="93.3771"
          x2="74.9641"
          y2="47.8469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BFA168" />
          <stop offset="0.555113" stopColor="#836731" />
          <stop offset="1" stopColor="#927235" />
        </linearGradient>
      </defs>
    </svg>
  );
};
