import styled from 'styled-components';

import { truthfulAdaptive } from '../../../componentUI/screen';

import { COLOR_MAP } from '../../../componentUI/colorsMap';

const AppRouterSkeletonWrapperStyled = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;

  ${truthfulAdaptive.maxWidth.desktopS} {
  }
`;

const MainContainerStyled = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${COLOR_MAP.background.white};
`;

const HeaderMainContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 55px;
`;

const MainMainContainerStyled = styled.div`
  margin-top: 22px;

  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-template-areas: 'mainMainContainerBlockOne mainMainContainerBlockTwo';

  gap: 20px;

  ${truthfulAdaptive.maxWidth.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    grid-template-areas:
      'mainMainContainerBlockTwo'
      'mainMainContainerBlockOne';
  }
`;

const MainMainContainerBlockOneStyled = styled.div`
  grid-area: mainMainContainerBlockOne;

  padding: 28px 40px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  gap: 32px;

  background-color: ${COLOR_MAP.controls.skeleton.content1};
`;

const MainMainContainerBlockThreeStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const MainMainContainerBlockFourStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const MainMainContainerBlockFiveStyled = styled.div`
  display: flex;
  align-self: flex-end;
`;

const MainMainContainerBlockTwoStyled = styled.div`
  grid-area: mainMainContainerBlockTwo;

  padding: 28px 40px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  gap: 32px;

  background-color: ${COLOR_MAP.controls.skeleton.content1};
`;

const MainMainContainerBlockSixStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const MainMainContainerBlockSevenStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;
const FooterMainContainerStyled = styled.div`
  width: 100%;
  margin-top: 39px;

  display: flex;
  flex-direction: column;
  background-color: ${COLOR_MAP.background.white};
`;

const FooterMainContainerBlockOneStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 28px;
`;

const FooterMainContainerBlockTwoStyled = styled.div`
  margin-top: 33px;

  display: flex;
  justify-content: space-between;
  gap: 30px;
`;

const FooterMainContainerBlockThreeStyled = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FooterMainContainerBlockFourStyled = styled.div`
  max-width: 350px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 28px;
`;

const HeaderMainContainerMobileStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 29px;
`;

export {
  AppRouterSkeletonWrapperStyled,
  FooterMainContainerBlockFourStyled,
  FooterMainContainerBlockOneStyled,
  FooterMainContainerBlockThreeStyled,
  FooterMainContainerBlockTwoStyled,
  FooterMainContainerStyled,
  HeaderMainContainerMobileStyled,
  HeaderMainContainerStyled,
  MainContainerStyled,
  MainMainContainerBlockFiveStyled,
  MainMainContainerBlockFourStyled,
  MainMainContainerBlockOneStyled,
  MainMainContainerBlockSevenStyled,
  MainMainContainerBlockSixStyled,
  MainMainContainerBlockThreeStyled,
  MainMainContainerBlockTwoStyled,
  MainMainContainerStyled,
};
