/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLOR_MAP } from '../../colorsMap';
import { supportHover } from '../../screen';
import { BUTTON_SIZE, SIZE_STYLE } from './constants';
import { PropsLinkRouterButton } from './types';
import { buttonTextStyle, linkRouterCommonStyles } from './utils';

const LinkRouterComponent = styled(Link)<PropsLinkRouterButton>`
  ${linkRouterCommonStyles};
  ${buttonTextStyle};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px;

  border: 2px solid ${({ colorsMap }) => colorsMap?.clickable};
  border-radius: 2px;
  user-select: none;
  background-color: transparent;
  text-decoration: none;

  color: ${({ colorText }) => colorText};
  ${({ size }) => SIZE_STYLE[size]};

  & path {
    stroke: ${({ colorIcon }) => colorIcon};
  }

  ${({ size }) => SIZE_STYLE[size]};

  & path {
    stroke: ${({ colorsMap }) => colorsMap?.clickable};
  }

  ${supportHover} {
    &:hover {
      color: ${({ colorsMap }) => colorsMap?.clickableHover};
      border: 2px solid ${({ colorsMap }) => colorsMap?.clickableHover};

      & path {
        stroke: ${({ colorsMap }) => colorsMap?.clickableHover};
      }
    }
  }

  &:active {
    color: ${({ colorsMap }) => colorsMap?.clickablePressed};
    border: 2px solid ${({ colorsMap }) => colorsMap?.clickablePressed};

    & path {
      stroke: ${({ colorsMap }) => colorsMap?.clickablePressed};
    }
  }

  &:disabled,
  &:disabled:active,
  &:disabled:hover {
    color: ${({ colorsMap }) => colorsMap?.clickableDisabled};
    border: 2px solid ${({ colorsMap }) => colorsMap?.clickableDisabled};
    cursor: not-allowed;

    & path {
      stroke: ${({ colorsMap }) => colorsMap?.clickableDisabled};
    }
  }
`;

const DEFAULT_COLOR_MAP = {
  clickable: COLOR_MAP.controls.clickableNormal,
  clickableHover: COLOR_MAP.controls.clickableHover,
  clickablePressed: COLOR_MAP.controls.clickablePressed,
  clickableDisabled: COLOR_MAP.controls.clickableDisabled,
};

export const SecondLinkRouterButton = ({
  size = BUTTON_SIZE.small,
  colorIcon = COLOR_MAP.controls.clickableNormal,
  colorText = COLOR_MAP.controls.clickableNormal,
  isStretch = false,
  disabled = false,
  colorsMap = DEFAULT_COLOR_MAP,
  to = '#',
  children,
  ...props
}: Partial<PropsLinkRouterButton>) => {
  return (
    <LinkRouterComponent
      as={disabled ? 'span' : undefined}
      to={to}
      size={size}
      colorIcon={colorIcon}
      colorText={colorText}
      isStretch={isStretch}
      disabled={disabled}
      colorsMap={colorsMap}
      aria-disabled={disabled}
      {...props}
    >
      {children}
    </LinkRouterComponent>
  );
};
