/* eslint-disable react-hooks/exhaustive-deps */
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAccountInfoQuery } from '../../api/auth/hooks';
import { useCheckAuthQuery } from '../../api/auth/hooks/useCheckAuthQuery';
import { SocketProvider } from '../../context/SocketContext';
import { useCheckUTM } from '../../hooks/useCheckUTM';
import { AccountSettingsMainSkeleton } from '../../modules/AccountSettings/skeleton';
import { BalanceWrapperMainSkeleton } from '../../modules/Balance/skeleton/WrapperSkeleton';
import { ToastContainer } from '../../modules/ToastContainer/ToastContainer';
import { TopUpMainSkeleton } from '../../modules/TopUpV3/skeleton';
import '../../scss/_vars.scss';
import { StartPrivateSession, StartPublicSession } from '../AuthSession';
import { AppRouterSkeleton } from './skeleton';

export const AppRouter = () => {
  useCheckUTM();

  const { pathname } = useLocation();
  const [params] = useSearchParams();

  const paymentMethod = params.get('paymentMethod');
  const tokenFromGoogle = paymentMethod ? '' : params.get('token');
  const tokenRefreshGoogle = paymentMethod ? '' : params.get('refresh-token');

  const isJwtKey = Boolean(tokenFromGoogle) || Boolean(Cookies.get('jwt'));
  const jwtToken = tokenFromGoogle;

  const {
    isFetching,
    data: isValidToken,
    isSuccess: isSuccessValidToken,
    isError: isErrorValidToken,
  } = useCheckAuthQuery(jwtToken);

  const { data: AccountData } = useAccountInfoQuery(jwtToken, isSuccessValidToken);

  useEffect(() => {
    if (tokenFromGoogle && tokenRefreshGoogle) {
      Cookies.set('jwt', tokenFromGoogle);
      Cookies.set('jwt-refresh', tokenRefreshGoogle);
    }
  }, []);

  if (isFetching && pathname.includes('top-up')) return <TopUpMainSkeleton />;
  if (isFetching && pathname.includes('account-settings')) return <AccountSettingsMainSkeleton />;
  if (isFetching && pathname.includes('balance')) return <BalanceWrapperMainSkeleton />;
  if (isFetching) return <AppRouterSkeleton />;

  return (
    <>
      <ToastContainer />
      {AccountData && isSuccessValidToken && isValidToken && (
        <SocketProvider>
          <StartPrivateSession isProfileFull={AccountData.isProfileFull} />
        </SocketProvider>
      )}

      {(isErrorValidToken || !isJwtKey) && <StartPublicSession />}
    </>
  );
};
