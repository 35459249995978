import React from 'react';
import styled from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';
import { TEXT_WEIGHT } from '../Text';

type Props = {
  color: string;
  weight: string;
};

const Wrapper = styled.span<Props>`
  color: ${({ color }) => color};
  ${({ weight }) => `font-weight: ${weight}`};
`;

export const Asterisk = ({ color = COLOR_MAP.accent.red, weight = TEXT_WEIGHT.bold }) => {
  return (
    <Wrapper color={color} weight={weight}>
      *
    </Wrapper>
  );
};
