import { TFunction } from 'i18next';
import { AccountTypeEnum, StepNumberEnum } from '../types';

const { business } = AccountTypeEnum;

const { step1, step2 } = StepNumberEnum;

/** текст для кнопки завершения регистрации в зависимости от выбранного типа
 * аккаунта и текущего шага регистрации
 */

export const getButtonText = (t: TFunction, typeAcc: AccountTypeEnum | string, step: StepNumberEnum) => {
  if (typeAcc !== business) {
    return t('auth.completionRegistration.finishButton');
  }

  if (typeAcc === business && step === step1) {
    return t('auth.completionRegistration.nextButton');
  }

  if (typeAcc === business && step === step2) {
    return t('auth.completionRegistration.finishButton');
  }
};
