import styled from 'styled-components';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../componentUI/screen';

const FiltersStyled = styled.div`
  padding: 16px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 12px;

  background-color: ${COLOR_MAP.background.white};

  box-shadow: 0 4px 9px rgba(150, 112, 38, 0.05), 0 5px 14px rgba(0, 0, 0, 0.08);
  border-radius: 6px;

  ${truthfulAdaptive.maxWidth.mobile} {
    padding: 12px;
  }
`;

const WrapperFilterStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${truthfulAdaptive.maxWidth.mobile} {
    gap: 0;
  }
`;

const WrapperSecondButtonStyled = styled.div`
  width: 220px;
`;

export { FiltersStyled, WrapperFilterStyled, WrapperSecondButtonStyled };
