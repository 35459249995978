export const SIZE = {
  xs: 'xs',
  sm: 'sm',
  lg: 'lg',
} as const;

export const FONT_SIZE: Record<keyof typeof SIZE, string> = {
  xs: '6px',
  sm: '8px',
  lg: '12xp',
};
