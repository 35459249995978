import React, { ChangeEventHandler } from 'react';

import styled from 'styled-components';
import { FONT_SIZE } from './constants';
import { PropsLabel } from './types';

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Label = styled.label<PropsLabel>`
  position: relative;
  display: inline-block;
  font-size: ${({ size }) => FONT_SIZE[size]};
  width: 6em;
  height: 3.4em;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${Input} {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 3.4em;

  &::before {
    position: absolute;
    content: '';
    height: 2.6em;
    width: 2.6em;
    left: 0.4em;
    bottom: 0.4em;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  ${Input}:checked + & {
    background-color: #cbad73;
  }

  ${Input}:checked + &::before {
    -webkit-transform: translateX(2.6em);
    -ms-transform: translateX(2.6em);
    transform: translateX(2.6em);
  }

  ${Input}:focus + & {
    box-shadow: 0 0 0.1em #2196f3;
  }

  ${Input}:disabled + & {
    pointer-events: none;
    background: #e6e6e6;
  }
`;

export type Props = {
  checked: boolean;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
  name?: string;
  value: any;
  size: string;
  title?: string;
  id?: string;
};

export const ToggleSwitch = ({ value, checked, onChange, name, id, disabled, title, size }: Props) => {
  return (
    // @ts-ignore
    <Label htmlFor={id} disabled={disabled} title={title} size={size}>
      <Input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <Slider />
    </Label>
  );
};
