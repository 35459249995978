import styled from 'styled-components';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { Title, TITLE_WEIGHT } from '../../../../componentUI/simple/Title';

const ContainerTitle = styled.div`
  padding: 4px 0;

  display: flex;
  gap: 16px;
`;

const TypeTitle = styled(Title).attrs({ colorText: COLOR_MAP.text.darkGrey, weight: TITLE_WEIGHT.normal })<{
  isChosen: boolean;
}>`
  ${({ isChosen }) => isChosen && `color: ${COLOR_MAP.text.black}; border-bottom: 2px solid ${COLOR_MAP.text.black};`}

  ${({ isChosen }) => !isChosen && '&:hover { cursor: pointer; }'}
`;

export { ContainerTitle, TypeTitle };
