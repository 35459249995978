import { Dispatch, SetStateAction } from 'react';
import { AllChecksDraftsElementsType } from '../containers/AuthenticationsTablet/types';

type Props = {
  allChecksDrafts: AllChecksDraftsElementsType;
  setAllChecksDrafts: Dispatch<SetStateAction<AllChecksDraftsElementsType>>;
  isCheck: boolean;
};

/** функция-хелпер устанавливающая или снимающая чекбокс draft со всех
 * элементов таблицы AuthenticationsTablet */

export const changeStatusAllCheckboxes = ({ allChecksDrafts, setAllChecksDrafts, isCheck }: Props) => {
  if (allChecksDrafts instanceof Object && setAllChecksDrafts) {
    const keys = Object.keys(allChecksDrafts);

    const checkAllDraftElement = keys.reduce<{ [id: string]: boolean }>((acc, element) => {
      return { ...acc, [element]: isCheck };
    }, {});

    setAllChecksDrafts(checkAllDraftElement);
  }
};
