import React from 'react';
import styled from 'styled-components';

import { COLOR_MAP } from '../../colorsMap';
import { supportHover } from '../../screen';
import { BUTTON_SIZE, SIZE_STYLE } from './constants';
import { PropsButton } from './types';
import { commonStyles } from './utils';

const ButtonComponent = styled.button<PropsButton>`
  ${commonStyles};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 0;

  border: none;
  background-color: transparent;

  ${({ size }) => SIZE_STYLE[size]};
  font-family: Montserrat, sans-serif;
  color: ${({ colorsMap }) => (colorsMap ? colorsMap.clickable : `${COLOR_MAP.controls.dark.clickable}`)};

  user-select: none;

  & path {
    stroke: ${({ colorsMap }) => (colorsMap ? colorsMap.clickable : `${COLOR_MAP.controls.dark.clickable}`)};
  }

  ${supportHover} {
    &:hover {
      color: ${({ colorsMap }) => (colorsMap ? colorsMap.clickableHover : `${COLOR_MAP.controls.dark.clickableHover}`)};
      & path {
        stroke: ${({ colorsMap }) =>
          colorsMap ? colorsMap.clickableHover : `${COLOR_MAP.controls.dark.clickableHover}`};
      }
    }
  }

  &:active {
    color: ${({ colorsMap }) =>
      colorsMap ? colorsMap.clickablePressed : `${COLOR_MAP.controls.dark.clickablePressed}`};
    & path {
      stroke: ${({ colorsMap }) =>
        colorsMap ? colorsMap.clickablePressed : `${COLOR_MAP.controls.dark.clickablePressed}`};
    }
  }

  &:disabled,
  &:disabled:active,
  &:disabled:hover {
    color: ${({ colorsMap }) =>
      colorsMap ? colorsMap.clickableDisabled : `${COLOR_MAP.controls.dark.clickableDisabled}`};

    cursor: not-allowed;
    & path {
      stroke: ${({ colorsMap }) =>
        colorsMap ? colorsMap.clickableDisabled : `${COLOR_MAP.controls.dark.clickableDisabled}`};
    }
  }
`;

export const DarkButton = ({
  colorsMap = {
    clickable: `${COLOR_MAP.controls.dark.clickable}`,
    clickableHover: `${COLOR_MAP.controls.dark.clickableHover}`,
    clickablePressed: `${COLOR_MAP.controls.dark.clickablePressed}`,
    clickableDisabled: `${COLOR_MAP.controls.dark.clickableDisabled}`,
  },
  size = BUTTON_SIZE.small,
  isStretch = false,
  disabled = false,
  children,
  ...props
}: Partial<PropsButton>) => {
  return (
    <ButtonComponent size={size} isStretch={isStretch} disabled={disabled} colorsMap={colorsMap} {...props}>
      {typeof children === 'string' ? children : children}
    </ButtonComponent>
  );
};
