/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { COLOR_MAP } from '../../componentUI/colorsMap';
import { DarkButton, LightButton } from '../../componentUI/simple/Button';
import { useIsTablet } from '../../hooks';
import { Notification } from '../index';

type BellNotificationType = {
  fill?: string;
  hasNotification?: boolean;
  showColumnMobileMenu?: boolean;
};

export const BellNotification = ({
  hasNotification = false,
  fill = COLOR_MAP.controls.normal,
  showColumnMobileMenu,
}: BellNotificationType) => {
  const [showNotification, setShowNotification] = useState(false);
  const isTablet = useIsTablet();

  const changeShowNotification = useCallback(() => setShowNotification((prevState) => !prevState), []);

  const BellButton = isTablet ? LightButton : DarkButton;

  useEffect(() => {
    if (showColumnMobileMenu) {
      setShowNotification(false);
    }
  }, [showColumnMobileMenu]);

  return (
    <>
      <BellButton
        style={{ cursor: 'pointer', position: 'relative' }}
        onClick={() => setShowNotification(!showNotification)}
      >
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.9497 4.38359C20.2625 5.69634 21 7.47682 21 9.33333C21 17.5 24.5 19.8333 24.5 19.8333H3.5C3.5 19.8333 7 17.5 7 9.33333C7 7.47682 7.7375 5.69634 9.05025 4.38359C10.363 3.07083 12.1435 2.33333 14 2.33333C15.8565 2.33333 17.637 3.07083 18.9497 4.38359Z"
            stroke={fill}
            strokeWidth="2.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.0181 24.5C15.813 24.8536 15.5186 25.1471 15.1644 25.3511C14.8102 25.5551 14.4086 25.6625 13.9998 25.6625C13.591 25.6625 13.1894 25.5551 12.8352 25.3511C12.481 25.1471 12.1866 24.8536 11.9814 24.5"
            stroke={fill}
            strokeWidth="2.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          {hasNotification && <circle cx="21" cy="7" r="4" fill="#DE4141" />}
        </svg>
      </BellButton>
      {showNotification && <Notification changeShowNotification={changeShowNotification} />}
    </>
  );
};
