/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { Props } from './types';

export const Portal = ({ children }: Props) => {
  const [container] = useState(() => document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return createPortal(children, container);
};
