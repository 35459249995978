import { useTranslation } from 'react-i18next';

/** Возвращает текущий язык системы в формате "EN" */

const LANGUAGES_TRANSLATION = ['EN', 'JA'];

export const useSelectedLanguage = () => {
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language.slice(0, 2).toUpperCase();

  // если ткущий язык системы отличается от представленных на сайте то возвращается перевод по умолчанию
  const selectedLanguage = LANGUAGES_TRANSLATION.find((element) => element === currentLanguage);

  if (!selectedLanguage) {
    return 'EN';
  }

  return selectedLanguage;
};
