import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGoogleAnalytics } from '../../../../../analytics';
import { useMatchMedia } from '../../../../../hooks';
import { TermsBlock } from '../../../../../components';
import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { Picture } from '../../../../../componentUI/complex/Picture';
import { Button } from '../../../../../componentUI/simple/Button';
import { Text } from '../../../../../componentUI/simple/Text';
import { errorLogo } from '../../../assets';
import { LogoBlock, TitleBlock } from '../..';
import { ContainerStyled, SeparatorStyled, WrapperButtonStyled, WrapperMainStyled, WrapperTextStyled } from './styled';

export const ScreenSignUpError = () => {
  useGoogleAnalytics();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useMatchMedia();

  return (
    <ContainerStyled>
      <LogoBlock />

      <WrapperMainStyled>
        <Picture url={errorLogo} width={isMobile ? 180 : 225} height={isMobile ? 160 : 200} alt="error logo" />

        <WrapperTextStyled>
          <TitleBlock titleText={t('auth.signUpError.title')} subtitleText={t('auth.signUpError.subtitle')} />

          <SeparatorStyled />

          <Text colorText={COLOR_MAP.text.white}>{t('auth.signUpError.bodyText')}</Text>
        </WrapperTextStyled>

        <WrapperButtonStyled>
          <Button
            isStretch
            onClick={() => {
              navigate('/signup');
            }}
          >
            {t('auth.signUpError.backToSignUp')}
          </Button>
        </WrapperButtonStyled>
      </WrapperMainStyled>

      <TermsBlock />
    </ContainerStyled>
  );
};
