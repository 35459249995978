import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortProduct } from '../../../../../api/product/type';
import { AuthPageType } from '../../../../../common/constants';
import { ArrowDown, ArrowUp } from '../../../../../common/icons/SvgInline/SvgIcons_5';

const {
  resultStatusUp,
  resultStatusDown,
  createdAtUp,
  createdAtDown,
  answerTimeUp,
  answerTimeDown,
  checkStatusUp,
  checkStatusDown,
} = SortProduct;

// обозначение страниц на которых встречается элемент AuthenticationsTablet
const { all, photosNeeded, underReview, completed, drafts, dashboard } = AuthPageType;

export const useGetSortingParameters = (currentPage: string | undefined) => {
  const { t } = useTranslation();

  const ALL = [
    { value: checkStatusUp, label: t('authenticationsTablet.sorting.sortByStatus'), icon: <ArrowUp /> },
    { value: checkStatusDown, label: t('authenticationsTablet.sorting.sortByStatus'), icon: <ArrowDown /> },
    { value: createdAtUp, label: t('authenticationsTablet.sorting.sortByDateTime'), icon: <ArrowUp /> },
    { value: createdAtDown, label: t('authenticationsTablet.sorting.sortByDateTime'), icon: <ArrowDown /> },
  ];

  const PHOTOS_NEEDED = [
    { value: createdAtUp, label: t('authenticationsTablet.sorting.sortByDateTime'), icon: <ArrowUp /> },
    { value: createdAtDown, label: t('authenticationsTablet.sorting.sortByDateTime'), icon: <ArrowDown /> },
    { value: answerTimeUp, label: t('authenticationsTablet.sorting.sortByAnswerTime'), icon: <ArrowUp /> },
    { value: answerTimeDown, label: t('authenticationsTablet.sorting.sortByAnswerTime'), icon: <ArrowDown /> },
  ];

  const UNDER_REVIEW = [
    { value: createdAtUp, label: t('authenticationsTablet.sorting.sortByDateTime'), icon: <ArrowUp /> },
    { value: createdAtDown, label: t('authenticationsTablet.sorting.sortByDateTime'), icon: <ArrowDown /> },
    { value: answerTimeUp, label: t('authenticationsTablet.sorting.sortByAnswerTime'), icon: <ArrowUp /> },
    { value: answerTimeDown, label: t('authenticationsTablet.sorting.sortByAnswerTime'), icon: <ArrowDown /> },
  ];

  const COMPLETED = [
    { value: createdAtUp, label: t('authenticationsTablet.sorting.sortByDateTime'), icon: <ArrowUp /> },
    { value: createdAtDown, label: t('authenticationsTablet.sorting.sortByDateTime'), icon: <ArrowDown /> },
    { value: resultStatusUp, label: t('authenticationsTablet.sorting.sortByOutcome'), icon: <ArrowUp /> },
    { value: resultStatusDown, label: t('authenticationsTablet.sorting.sortByOutcome'), icon: <ArrowDown /> },
  ];

  const DRAFTS = [
    { value: createdAtUp, label: t('authenticationsTablet.sorting.sortByDateTime'), icon: <ArrowUp /> },
    { value: createdAtDown, label: t('authenticationsTablet.sorting.sortByDateTime'), icon: <ArrowDown /> },
    { value: answerTimeUp, label: t('authenticationsTablet.sorting.sortByAnswerTime'), icon: <ArrowUp /> },
    { value: answerTimeDown, label: t('authenticationsTablet.sorting.sortByAnswerTime'), icon: <ArrowDown /> },
  ];

  const OTHER = [
    { value: createdAtUp, label: t('authenticationsTablet.sorting.sortByDateTime'), icon: <ArrowUp /> },
    { value: createdAtDown, label: t('authenticationsTablet.sorting.sortByDateTime'), icon: <ArrowDown /> },
  ];

  switch (currentPage) {
    case all:
      return ALL;
    case photosNeeded:
      return PHOTOS_NEEDED;
    case underReview:
      return UNDER_REVIEW;
    case completed:
      return COMPLETED;
    case drafts:
      return DRAFTS;
    case dashboard:
      return OTHER;
    default:
      return OTHER;
  }
};
