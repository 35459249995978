import { CSSProperties, ReactNode } from 'react';
import { LegitIcon } from '../common/icons/SvgInline/SvgIcons_1';
import { AlertTriangleIcon } from '../common/icons/SvgInline/SvgIcons_2';
import { InfoIcon } from '../common/icons/SvgInline/SvgIcons_3';
import { COLOR_MAP } from './colorsMap';

export enum NotificationType {
  Default = 'default',
  Info = 'info',
  Success = 'success',
}

export enum SimpleNotificationType {
  Default = 'default',
  Info = 'info',
  Success = 'success',
  Bad = 'bad photo',
}

export const notificationColors = {
  [NotificationType.Default]: COLOR_MAP.accent.red,
  [NotificationType.Info]: COLOR_MAP.accent.blue,
  [NotificationType.Success]: COLOR_MAP.accent.green,
  [SimpleNotificationType.Bad]: COLOR_MAP.accent.orange,
};

type IconType = typeof InfoIcon;

export const notificationIcons: Record<NotificationType, IconType> = {
  [NotificationType.Default]: AlertTriangleIcon,
  [NotificationType.Info]: InfoIcon,
  [NotificationType.Success]: LegitIcon,
};

export const simpleNotificationIcons: Record<SimpleNotificationType, IconType> = {
  [NotificationType.Default]: AlertTriangleIcon,
  [NotificationType.Info]: InfoIcon,
  [NotificationType.Success]: LegitIcon,
  [SimpleNotificationType.Bad]: AlertTriangleIcon,
};

export interface SimpleNotificationProps {
  type: SimpleNotificationType;
  text: string;
  style?: CSSProperties;
}

export interface SimpleNotificationCustomIconProps {
  type: SimpleNotificationType;
  text: string;
  children: ReactNode | undefined;
}
