import styled from 'styled-components';
import { COLOR_MAP } from '../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../componentUI/screen';

export const CertificateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 12px;

  background-color: ${COLOR_MAP.background.brandColor}10;
  border-radius: 10px;
`;

export const WrapperSecondButtonStyle = styled.div`
  max-width: 233px;
  width: 100%;

  ${truthfulAdaptive.maxWidth.mobile} {
    max-width: 100%;
  }
`;

export const WrapperIconStyled = styled.div`
  position: relative;

  top: 5px;

  display: inline-flex;
  flex-wrap: nowrap;
`;

export const SeparatorStyled = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLOR_MAP.background.separatorColorOne};
`;
