import { inputPhotoClear } from '../../../helpers';
import { HandleDeleteImageType } from '../types';

export const handleDeleteImage = (data: HandleDeleteImageType) => {
  const {
    setIndexUploadedPhotos,
    setAllUploadedPhotos,
    setUploadPhotos,
    setIsErrorLimitUploadedImages,
    inputRef,
    fileId,
  } = data;

  setUploadPhotos((prev) => {
    return prev.filter(({ fileId: id }) => id !== fileId);
  });

  setAllUploadedPhotos((prev) => {
    return prev.filter(({ fileId: id }) => id !== fileId);
  });

  setIndexUploadedPhotos((prev) => {
    return prev.filter((id) => id !== fileId);
  });

  setIsErrorLimitUploadedImages(false);

  inputPhotoClear(inputRef);
};
