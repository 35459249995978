import { useEffect, useState } from 'react';

/** получение и сохранение значения в LocalStorage
 * по передаваемому ключу */

// key - ключ по которому будет получено/записано значение
// defaultValue - значение по умолчанию

export const useLocalStorage = <T>(key: string, defaultValue: T) => {
  const [value, setValue] = useState(() => {
    const item = localStorage.getItem(key);

    return item ? (JSON.parse(item) as T) : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as const;
};
