import styled, { css } from 'styled-components';
import { Z_INDEX_LAYERS } from '../../../componentUI';
import { COLOR_MAP } from '../../../componentUI/colorsMap';

export const MobileMenuWrapperStyles = css`
  right: 0;
  top: 0;

  position: absolute;
  z-index: ${Z_INDEX_LAYERS.menusLayer.modalLayer};

  width: 100%;
  min-width: 320px;
  max-width: 384px;
`;

export const MobileContainerColumnMenuStyles = css`
  width: 100%;
  min-height: 100vh;
`;

export const MenuWrapper = styled.div<{ isSidebar?: boolean }>`
  position: ${({ isSidebar }) => (isSidebar ? 'fixed' : 'relative')};

  min-height: 100vh;
  width: calc(100vw / 5);
  max-width: 384px;

  ${({ isSidebar }) => isSidebar && MobileMenuWrapperStyles}

  background: ${COLOR_MAP.background.darkGrey};
`;

export const ContainerColumnMenu = styled.div<{ isSidebar?: boolean; fixedPosition: boolean }>`
  position: ${({ fixedPosition }) => (fixedPosition ? 'fixed' : 'static')};
  z-index: ${Z_INDEX_LAYERS.menusLayer.modalLayer};

  display: grid;
  grid-template-rows: auto auto;

  width: calc(100vw / 5);
  max-width: 384px;

  min-height: 900px; //для правильного определения высоты, пока всё грузится

  ${({ isSidebar }) => isSidebar && MobileContainerColumnMenuStyles}

  box-sizing: border-box;
  padding: 20px 28px 16px 28px;

  background: ${COLOR_MAP.background.darkGrey};
`;

export const HorizonLine = styled.div`
  width: 100%;
  height: 1px;

  background-color: rgba(102, 102, 102, 0.5);
`;

export const WrapperNavigateBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ColumnBlockOneStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px 0;
`;

export const ColumnBlockTwoStyled = styled.div`
  align-self: end;

  display: flex;
  flex-direction: column;
  gap: 20px 0;
`;
