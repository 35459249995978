import styled, { CSSProperties } from 'styled-components';

import { truthfulAdaptive } from '../../../componentUI/screen';

import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { TEXT_WEIGHT } from '../../../componentUI/simple/Text';

const WrapperSubscribesBannerV2Styled = styled.div`
  position: relative;
  overflow: hidden;

  padding: 9px 0px;
  height: 100%;

  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 42px;
  gap: 12px;

  border-radius: 6px;
  background: linear-gradient(90deg, #eeb41e 4.3%, #f46c20 99.92%);

  ${truthfulAdaptive.maxWidth.mobile} {
    grid-template-rows: 62px;
  }
`;

const WrapperPictureStyled = styled.div`
  position: relative;
  bottom: 24px;

  ${truthfulAdaptive.maxWidth.mobile} {
    bottom: 10px;
  }
`;

const WrapperMainStyled = styled.div`
  display: flex;
  justify-content: space-between;

  ${truthfulAdaptive.maxWidth.mobile} {
    flex-direction: column;
    gap: 6px;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const WrapperTextStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const CLoseWrapper = styled.div`
  position: relative;
  top: -9px;

  height: calc(100% + 18px);
  padding: 10px 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`;

const stylesForTertiaryButton: CSSProperties = {
  padding: 0,
  whiteSpace: 'nowrap',
  textTransform: 'none',
  fontWeight: TEXT_WEIGHT.bold,
  color: COLOR_MAP.text.white,
};

export {
  CLoseWrapper,
  WrapperMainStyled,
  WrapperPictureStyled,
  WrapperSubscribesBannerV2Styled,
  WrapperTextStyled,
  stylesForTertiaryButton,
};
