import styled from 'styled-components';
import { truthfulAdaptive } from '../../../../componentUI/screen';

const TableSearchStyled = styled.div<{ pressedFilterButton: boolean }>`
  width: 100%;

  min-width: ${({ pressedFilterButton }) => (pressedFilterButton ? '426px' : '232px')};

  ${truthfulAdaptive.maxWidth.tablet} {
    min-width: 232px;
  }

  ${truthfulAdaptive.maxWidth.mobile} {
    max-width: 100%;
  }
`;

export { TableSearchStyled };
