import { createGlobalStyle } from 'styled-components/macro';
import { adaptive } from './componentUI/screen';

export const AppStyled = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ${adaptive.maxWidth.mobile} {
    ::-webkit-scrollbar {
      width: 3px;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: #F6F6F7;
  }

  ::-webkit-scrollbar-thumb {
    background: #F0DDB9;
    opacity: 0.8;
  }

  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }
`;
