import React from 'react';
import { RadioChipsV2 } from '../../../../componentUI/simple/Radio';
import { saveTypeOfCertificateToLocalStorage } from '../../../../helpers';
import { RadioChipsCertificateStyled } from './styled';
import { Props } from './types';
import { useTranslatedCertificateTypes } from '../../../CertificateInfoWs/helpers/useTranslatedCertificateTypes ';

export const RadioChipsCertificate = ({
  isDisabledRadioChips,
  selectedCertificateType,
  setSelectedCertificateType,
  isFinancialGuaranteeUsed = false,
}: Props) => {
  const { TYPES_CERTIFICATES, TYPES_FINGUARD_CERTIFICATES, CUSTOM_CERTIFICATE } = useTranslatedCertificateTypes();
  const certificates = isFinancialGuaranteeUsed ? TYPES_FINGUARD_CERTIFICATES : TYPES_CERTIFICATES;

  return (
    <RadioChipsCertificateStyled>
      {certificates.map((element) => {
        const isDisabled = isFinancialGuaranteeUsed && element === CUSTOM_CERTIFICATE;

        return (
          <RadioChipsV2
            key={element}
            name="certificateType"
            label={element}
            value={element}
            checkedValue={selectedCertificateType}
            onChange={(value) => {
              setSelectedCertificateType(value);
              saveTypeOfCertificateToLocalStorage(value);
            }}
            disabled={isDisabled || isDisabledRadioChips}
          />
        );
      })}
    </RadioChipsCertificateStyled>
  );
};
