/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, TEXT_SIZE } from '../../../../componentUI/simple/Text';
import { Checkbox } from '../../../../componentUI/simple/Checkbox';
import { InfoTooltip } from '../../../../componentUI/simple/Tooltip';
import { IncludeQrLabelStyled } from './styled';
import { Props } from './types';

export const IncludeQrLabel = ({ isCertificateCustom, itemLabelNeeded, setItemLabelNeeded }: Props) => {
  const { t } = useTranslation();

  // динамическая установка и сброс значения includeQrLabel при смене типа сертификата
  useEffect(() => {
    if (isCertificateCustom) {
      setItemLabelNeeded(true);
      return;
    }
    setItemLabelNeeded(false);
  }, [isCertificateCustom]);

  return (
    <IncludeQrLabelStyled>
      <Checkbox
        label={t('certificateInfoWs.includeQrLabel.label')}
        checked={itemLabelNeeded}
        onChange={setItemLabelNeeded}
      />
      <InfoTooltip
        widthTooltip="320px"
        positionMobile="left"
        text={<Text size={TEXT_SIZE.sub}>{t('certificateInfoWs.includeQrLabel.tooltip')}</Text>}
      />
    </IncludeQrLabelStyled>
  );
};
