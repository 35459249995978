import React from 'react';

import { COLOR_MAP } from '../colorsMap';

import { TAG_NAME } from '../constants';
import { SkeletonStyled } from './styled';
import { PropsSkeleton } from './types';

export const SkeletonBasic = ({
  bgColor = COLOR_MAP.controls.skeleton.content1,
  width = '180px',
  height = '180px',
  radius = '6px',
  tag = TAG_NAME.div,
  style,
  children,
  ...otherProps
}: Partial<PropsSkeleton>) => {
  return (
    <SkeletonStyled
      as={tag}
      style={style}
      bgColor={bgColor}
      width={width}
      height={height}
      radius={radius}
      {...otherProps}
    >
      {typeof children === 'string' ? children : children}
    </SkeletonStyled>
  );
};
