import styled, { CSSProperties } from 'styled-components';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';

export const CreditsStyled = styled.div<{ isActiveLink: boolean }>`
  padding: 5px 10px 5px 5px;

  display: flex;
  gap: 5px;

  border-radius: 16px;

  border: 1px solid
    ${({ isActiveLink }) => (isActiveLink ? `${COLOR_MAP.accent.darkGrey}` : `${COLOR_MAP.accent.lightGrey}`)};
`;
export const WrapperSectionStyled = styled.div`
  display: flex;
  justify-content: space-between;

  cursor: pointer;
`;

export const stylesForModal: CSSProperties = {
  maxWidth: '600px',
};
