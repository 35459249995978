import styled from 'styled-components';

const SkeletonStyled = styled.div`
  padding: 18px 0;
  border-bottom: 1px solid #aaaaaa;
`;

const SkeletonMobileStyled = styled.div`
  padding: 12px;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const WrapperBlockOne = styled.div`
  display: flex;

  justify-content: space-between;
  gap: 20px;
`;
export { SkeletonMobileStyled, SkeletonStyled, WrapperBlockOne };
