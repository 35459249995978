import styled, { CSSProperties } from 'styled-components';

import { COLOR_MAP } from '../../componentUI/colorsMap';
import { Z_INDEX_LAYERS } from '../../componentUI/constants';
import { adaptive } from '../../componentUI/screen';
import { TEXT_WEIGHT } from '../../componentUI/simple/Text';

const NotificationWrapper = styled.div`
  position: absolute;
  top: 64px;
  right: 20px;

  max-width: 480px;
  width: 100%;

  padding: 12px 9px 0 16px;

  display: flex;
  flex-direction: column;

  background-color: ${COLOR_MAP.background.white};

  box-shadow: 0px 4px 9px rgba(150, 112, 38, 0.05), 0px 5px 14px rgba(0, 0, 0, 0.08);

  border-radius: 4px;

  z-index: ${Z_INDEX_LAYERS.notificationsLayer.modalLayer};

  ${adaptive.maxWidth.mobile} {
    right: 0;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NotificationMessagesWrapper = styled.div`
  width: 100%;
  max-height: 354px;

  margin-top: 12px;

  display: flex;
  flex-direction: column;

  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 2px;
    background-color: ${COLOR_MAP.scroll.grey};
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLOR_MAP.scroll.greyBrown};
    border-radius: 3px;
  }
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
`;

const TopBarWrapper = styled.div`
  margin-top: 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// растягиваем кнопку на всю ширину родительского контейнера
// marginLeft: '-16px',
// width: 'calc(100% + 25px)',
// 25px - сумма паддингов родителя слева и справа
// -16px смещение на ширину левого падинга родительского контейнера
const stylesForButton: CSSProperties = {
  marginLeft: '-16px',
  width: 'calc(100% + 25px)',
  padding: '13px',
  gap: '6px',

  backgroundColor: COLOR_MAP.controls.notification.clickable,

  border: 'none',
  textTransform: 'none',
};

const stylesForTertiaryButton: CSSProperties = {
  backgroundColor: 'transparent',
  whiteSpace: 'nowrap',
  textTransform: 'none',
  fontWeight: TEXT_WEIGHT.normal,
};

const NoNotificationsWrapper = styled.div`
  padding: 28px 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export {
  CloseIconWrapper,
  NoNotificationsWrapper,
  NotificationMessagesWrapper,
  NotificationWrapper,
  TitleWrapper,
  TopBarWrapper,
  stylesForButton,
  stylesForTertiaryButton,
};
