import React from 'react';
import { useTranslation } from 'react-i18next';
import { sendUserEvent } from '../../../../analytics';
import { BUTTON_SIZE, LinkRouterButton, SecondLinkRouterButton } from '../../../../componentUI/simple/Button';
import { AuthButtonIcon } from '../../../../common/icons/SvgInline/SvgIcons_6';
import { PlusIcon } from '../../../../common/icons/SvgInline/SvgIcons_9';
import { ButtonBlockStyled } from './styled';

export const ButtonBlock = () => {
  const { t } = useTranslation();

  const addBalanceHandler = () => {
    sendUserEvent('Top up now button clicked');
  };

  const newAuthenticationHandler = () => {
    sendUserEvent('New authentication button clicked');
  };

  return (
    <ButtonBlockStyled>
      <LinkRouterButton isStretch size={BUTTON_SIZE.small} to="/authenticate" onClick={newAuthenticationHandler}>
        <AuthButtonIcon /> {t('baseLayout.buttonBlock.authenticate')}
      </LinkRouterButton>

      <SecondLinkRouterButton isStretch size={BUTTON_SIZE.small} to="/top-up" onClick={addBalanceHandler}>
        <PlusIcon /> {t('baseLayout.buttonBlock.addBalance')}
      </SecondLinkRouterButton>
    </ButtonBlockStyled>
  );
};
