import styled from 'styled-components';

import { PropsSkeleton } from './types';

const SkeletonStyled = styled.div<PropsSkeleton>`
  max-width: ${({ width }) => width};
  height: ${({ height }) => height};

  width: 100%;

  display: flex;

  border-radius: ${({ radius }) => radius};

  background-color: ${({ bgColor }) => bgColor};
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    ${({ bgColor }) => bgColor};
  background-size: 200% 100%;
  background-position-x: 180%;

  animation: 1s loading ease-in-out infinite;

  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
`;

export { SkeletonStyled };
