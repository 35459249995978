import styled from 'styled-components';
import { Z_INDEX_LAYERS } from '../../../../componentUI';

export const ImgTitleStyled = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImgTitleHovered = styled(ImgTitleStyled)`
  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`;

export const ShieldIconWrapper = styled.div`
  position: absolute;
  top: -2px;
  right: -9px;
`;

export const Tooltip = styled.div`
  position: absolute;
  bottom: calc(100% + 7px);
  left: -23px;
  z-index: ${Z_INDEX_LAYERS.modalsLayer.modalLayer};

  visibility: hidden;
  opacity: 0;

  display: flex;
  align-items: center;
  max-width: 224px;
  min-width: 96px;
  padding: 6px 10px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  color: white;
  box-shadow: 0 3px 5px rgba(150, 112, 38, 0.04), 0 4px 12px rgba(0, 0, 0, 0.06);
  white-space: nowrap;

  &::before {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 14px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, 0.8);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;
  }
`;
