/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { emptyStringCheck, textErrorCheck } from '../../../../../../helpers';
import { useFormField } from '../../../../../../hooks';
import { InputWithError } from '../../../../../../componentUI/complex/InputWithError';
import { Props } from './types';

/** Отрисовывает поля firstName и lastName */
export const UserFullName = ({ setFormData, setErrorUserFullName }: Props) => {
  const { t } = useTranslation();

  const [firstName, setFirstName, firstNameError, setFirstNameError] = useFormField({
    initValue: '',
    withError: true,
  });

  const [lastName, setLastName, lastNameError, setLastNameError] = useFormField({
    initValue: '',
    withError: true,
  });

  const changeFirstName = (value: string) => {
    setFirstNameError(value ? '' : t('auth.completionRegistration.userFullName.firstNameError'));
    setFirstName(value);
  };

  const changeLastName = (value: string) => {
    setLastNameError(value ? '' : t('auth.completionRegistration.userFullName.lastNameError'));
    setLastName(value);
  };

  // передаем актуальные данные которые ввел пользователь
  // в поля input в родительский компонент в виде одного объекта
  useEffect(() => {
    setFormData((prev) => {
      return { ...prev, firstName, lastName };
    });
  }, [firstName, lastName]);

  // проверяем корректность ввода данных и если они не корректны
  // передаем буллевый флаг ошибки в родительский компонент
  useEffect(() => {
    const isEmptyString = emptyStringCheck([firstName, lastName]);
    const isError = textErrorCheck([firstNameError, lastNameError]);

    if (isEmptyString || isError) {
      setErrorUserFullName(true);
      return;
    }

    setErrorUserFullName(false);
  }, [firstNameError, lastNameError, firstName, lastName]);

  return (
    <>
      <InputWithError
        name="firstName"
        value={firstName}
        errorMessage={firstNameError}
        onChange={(event) => changeFirstName(event)}
        onBlur={(event) => changeFirstName(event.currentTarget.value)}
        placeholder={t('auth.completionRegistration.userFullName.firstNamePlaceholder')}
      />

      <InputWithError
        name="lastName"
        value={lastName}
        errorMessage={lastNameError}
        onChange={(event) => changeLastName(event)}
        onBlur={(event) => changeLastName(event.currentTarget.value)}
        placeholder={t('auth.completionRegistration.userFullName.lastNamePlaceholder')}
      />
    </>
  );
};
