import styled from 'styled-components';
import { truthfulAdaptive } from '../../../componentUI/screen';

const WrapperSlideStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${truthfulAdaptive.maxWidth.tablet} {
    gap: 20px;
  }
`;

const WrapperTextContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export { WrapperSlideStyled, WrapperTextContentStyled };
