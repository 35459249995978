import styled, { CSSProperties } from 'styled-components';
import { truthfulAdaptive } from '../../../../componentUI/screen';

const FailureTopUpStyled = styled.div`
  min-height: 80vh;
  display: grid;
  align-items: center;
`;

const WrapperFailureTopUpStyled = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  justify-items: center;
  gap: 40px;

  ${truthfulAdaptive.maxWidth.mobile} {
    gap: 16px;
  }
`;

const WrapperTextStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  text-align: center;

  ${truthfulAdaptive.maxWidth.mobile} {
    gap: 8px;
  }
`;

const WrapperBtnStyled = styled.div`
  width: 100%;
  max-width: 280px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  ${truthfulAdaptive.maxWidth.mobile} {
    max-width: 100%;
  }
`;

const stylesForBtn: CSSProperties = {
  display: 'flex',
  gap: '4px',
};

export { FailureTopUpStyled, WrapperBtnStyled, WrapperTextStyled, WrapperFailureTopUpStyled, stylesForBtn };
