import React from 'react';
import styled from 'styled-components';

import { COLOR_MAP } from '../componentUI/colorsMap';

import { CameraOffIcon } from '../common/icons/SvgInline/SvgIcons_9';

import { PlaceholderImageIcon } from '../common/images';
import { isHeicOrHeif } from './isHeicOrHeif';

export const ImageOrderStyled = styled.div<{ isBigSize: boolean }>`
  width: ${({ isBigSize }) => (isBigSize ? '60px' : '32px')};
  height: ${({ isBigSize }) => (isBigSize ? '60px' : '32px')};

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: ${COLOR_MAP.background.brandColorSoftV3};
  object-fit: contain;
`;

/**
 * получение миниатюры изображения заказа пользователя
 *
 * isBigSize - флаг, выставляет размер миниатюры
 */
export const getImageOrder = (photoUrl: string | null, isBigSize = false) => {
  // устанавливаем первую фотографий из полученного массива в качестве миниатюры, если они найдены
  if (photoUrl) {
    // Проверяем есть ли у файла расширение Heif, если есть,
    // то показываем заглушку вместо миниатюры
    let mainUrl = photoUrl;

    if (isHeicOrHeif(photoUrl)) {
      mainUrl = PlaceholderImageIcon;
    }

    return (
      <ImageOrderStyled isBigSize={isBigSize}>
        <img style={{ borderRadius: '50%' }} width="100%" height="100%" src={mainUrl} alt="order" />
      </ImageOrderStyled>
    );
  }

  return (
    <ImageOrderStyled isBigSize={isBigSize}>
      <CameraOffIcon size={isBigSize ? '30px' : '16px'} />
    </ImageOrderStyled>
  );
};
