import { css } from 'styled-components';
import { PropsButton, PropsLinkButton, PropsLinkRouterButton } from './types';

const width = ({ isStretch }: PropsButton) => `width: ${isStretch ? '100%' : 'auto'}`;
const linkWidth = ({ isStretch }: PropsLinkButton) => `width: ${isStretch ? '100%' : 'auto'}`;
const linkRouterWidth = ({ isStretch }: PropsLinkRouterButton) => `width: ${isStretch ? '100%' : 'auto'}`;

export const commonStyles = css`
  font-style: normal;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;

  ${width}
`;

export const linkCommonStyles = css`
  font-style: normal;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;

  ${linkWidth}
`;

export const linkRouterCommonStyles = css`
  font-style: normal;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;

  ${linkRouterWidth}
`;

export const buttonTextStyle = css`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 700;
`;

export const buttonTextStyleRegular = css`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
`;
