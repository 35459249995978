import styled from 'styled-components';
import { truthfulAdaptive } from '../../componentUI/screen';

const ControlsBlockContainer = styled.div<{ isSummaryBlock: boolean | undefined }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ isSummaryBlock }) => (isSummaryBlock ? '20px 0 0 0' : '20px 40px 0 40px')};

  ${truthfulAdaptive.maxWidth.tablet} {
    padding: ${({ isSummaryBlock }) => (isSummaryBlock ? '0' : '4px 0 0 0')};
  }
`;

const RowControlBlock = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const WrapperSecondButton = styled.div`
  width: 160px;
`;

export { ButtonContainer, ControlsBlockContainer, RowControlBlock, WrapperSecondButton };
