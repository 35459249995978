import styled from 'styled-components';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../componentUI/screen';

const ButtonFilterStyled = styled.div`
  max-width: 172px;

  padding: 9px 12px;

  display: flex;
  gap: 12px;

  user-select: none;

  box-shadow: 0 3px 5px rgba(150, 112, 38, 0.04), 0 4px 12px rgba(0, 0, 0, 0.06);
  border-radius: 6px;

  cursor: pointer;

  ${truthfulAdaptive.maxWidth.mobile} {
    max-width: 100%;
    justify-content: space-between;
  }
`;

const WrapperFilterChevronIcon = styled.div<{ pressedFilterButton: boolean }>`
  display: flex;
  align-items: center;

  transition: transform 0.5s;

  ${({ pressedFilterButton }) => pressedFilterButton && 'transform: rotate(180deg);'}
`;

const FirstBlockStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CountStyled = styled.div`
  width: 23px;
  height: 23px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${COLOR_MAP.text.brandColor};
  border-radius: 50%;
`;

export { ButtonFilterStyled, CountStyled, FirstBlockStyled, WrapperFilterChevronIcon };
