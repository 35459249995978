import { AccountTypeEnum, StepNumberEnum } from '../types';

type GetButtonLockConditionsType = {
  typeAcc: AccountTypeEnum | string;
  selectStep: StepNumberEnum;
  error: {
    errorUserFullName: boolean;
    errorBusinessForm: boolean;
    accountTypeError: boolean;
    countryError: boolean;
    businessError: boolean;
    stateError: boolean;
  };
};

const { business } = AccountTypeEnum;

const { step1, step2 } = StepNumberEnum;

/** получаем набор условий для разблокировки кнопки завершения регистрации
 * в зависимости от условий
 */

export const getButtonLockConditions = ({ typeAcc, selectStep, error }: GetButtonLockConditionsType) => {
  const { errorUserFullName, errorBusinessForm, accountTypeError, countryError, businessError, stateError } = error;

  if (typeAcc !== business) {
    return errorUserFullName || countryError || accountTypeError || stateError;
  }

  if (typeAcc === business && selectStep === step1) {
    return errorUserFullName || countryError || accountTypeError || businessError || stateError;
  }

  if (typeAcc === business && selectStep === step2) {
    return errorBusinessForm;
  }
};
