import styled from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';

export const InputComponents = styled.input`
  box-sizing: border-box;
  vertical-align: center;
  width: 100%;
  padding: 10px;

  background-color: ${COLOR_MAP.controls.textAndIcon};
  border: 1px solid ${COLOR_MAP.controls.strokeNormal};
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;

  font-size: 16px;
  line-height: normal;

  &::placeholder {
    color: ${COLOR_MAP.controls.placeholder};
  }

  &:focus {
    outline: none;
  }

  &::-ms-reveal {
    display: none;
  }

  &::-ms-clear {
    display: none;
  }
`;
