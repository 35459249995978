import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getToasts } from '../../redux/selectors/toast-selectors';
import { Toast } from '../../componentUI/complex/Toast';
import { NotificationType } from '../../componentUI/types';
import { adaptive } from '../../componentUI/screen';
import { Z_INDEX_LAYERS } from '../../componentUI';

const Wrapper = styled.div`
  position: fixed;
  top: 80px;
  right: 20px;
  left: auto;
  z-index: ${Z_INDEX_LAYERS.errorsLayer};

  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: calc(100% - 40px);

  ${adaptive.maxWidth.desktop} {
    right: 16px;

    max-width: calc(100% - 32px);
  }
`;

export const ToastContainer = () => {
  const toasts = useSelector(getToasts) as Array<{ id: string; text: string; type?: NotificationType }>;

  return (
    <Wrapper>
      {toasts &&
        toasts.map(({ id, text, type = NotificationType.Default }) => (
          <Toast key={id} id={id} text={text} type={type} />
        ))}
    </Wrapper>
  );
};
