import styled from 'styled-components';

import { Text } from '../../../../../componentUI/simple/Text';

const ScreenResetPassRequestSuccessStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const WrapperSignInStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const WrapperTextStyled = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const LinkTextStyled = styled(Text)`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export { LinkTextStyled, ScreenResetPassRequestSuccessStyled, WrapperSignInStyled, WrapperTextStyled };
