import { useTranslation } from 'react-i18next';

export const STANDARD_CERTIFICATE_KEY = 'certificateInfoWs.standardCertificate';
export const CUSTOM_CERTIFICATE_KEY = 'certificateInfoWs.customCertificate';
export const FINANCIAL_GUARANTEE_CERTIFICATE_KEY = 'certificateInfoWs.financialGuaranteeCertificate';

export const useTranslatedCertificateTypes = () => {
  const { t } = useTranslation();

  const STANDARD_CERTIFICATE = t(STANDARD_CERTIFICATE_KEY);
  const CUSTOM_CERTIFICATE = t(CUSTOM_CERTIFICATE_KEY);
  const FINANCIAL_GUARANTEE_CERTIFICATE = t(FINANCIAL_GUARANTEE_CERTIFICATE_KEY);

  const TYPES_CERTIFICATES = [STANDARD_CERTIFICATE, CUSTOM_CERTIFICATE];
  const TYPES_FINGUARD_CERTIFICATES = [FINANCIAL_GUARANTEE_CERTIFICATE, CUSTOM_CERTIFICATE];

  return {
    STANDARD_CERTIFICATE,
    CUSTOM_CERTIFICATE,
    FINANCIAL_GUARANTEE_CERTIFICATE,
    TYPES_CERTIFICATES,
    TYPES_FINGUARD_CERTIFICATES,
  };
};
