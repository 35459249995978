import React, { ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';
import { Text, TEXT_SIZE } from '../Text';
import { noop, VisuallyHiddenInput } from '../../utils';
import { ICON_SIDE, iconSideTypes, RADIO_SIDE, radioSideTypes } from './constants';
import { Props } from './Radio';
import { IconType } from '../../../common/icons/SvgInline/IconType';

const RadioWrapper = styled.label<{
  radioSide: radioSideTypes;
  checked: boolean;
  disabled?: boolean;
  withBorder?: boolean;
}>`
  display: flex;
  width: 100%;
  min-width: 100px;

  min-height: ${({ withBorder }) => (withBorder ? '38px' : 'auto')};

  gap: 8px;

  padding: ${({ withBorder }) => (withBorder ? '8px 10px' : '0px')};

  align-items: center;
  justify-content: ${({ radioSide }) => (radioSide === RADIO_SIDE.right ? 'space-between' : 'flex-start')};

  ${({ checked }) => (checked ? `background-color: ${COLOR_MAP.text.brandColor}20;` : 'background-color: transparent')};
  ${({ disabled }) => (disabled ? `background-color: ${COLOR_MAP.background.disabled}; pointer-events: none;` : '')};

  ${({ checked, withBorder, disabled }) => {
    let backgroundColor;
    let border;

    if (withBorder) {
      if (checked) {
        backgroundColor = `${COLOR_MAP.text.brandColor}20`;
        border = `1px solid ${COLOR_MAP.controls.clickableNormal}`;
      } else {
        backgroundColor = 'transparent';
        border = '1px solid transparent';
      }

      // Check if border should be set based on the disabled prop
      if (disabled) {
        border = `1px solid ${COLOR_MAP.controls.disabled}`;
        backgroundColor = `${COLOR_MAP.background.disabled}`;
      } else {
        border = `1px solid ${COLOR_MAP.controls.clickableNormal}`;
      }
    } else {
      backgroundColor = 'transparent';
      border = 'none';
    }

    return `
      background-color: ${backgroundColor};
      border: ${border};
    `;
  }};

  border-radius: 4px;
  box-sizing: border-box;

  user-select: none;
  cursor: pointer;
`;

const IconTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
`;

const Circle = styled.div<{ disabled?: boolean }>`
  position: relative;

  width: 20px;
  height: 20px;

  border-radius: 50%;
  border: ${({ disabled }) =>
    disabled ? `1.2px solid ${COLOR_MAP.controls.disabled};` : `1.2px solid ${COLOR_MAP.controls.clickableNormal};`};

  background-color: ${({ disabled }) =>
    disabled ? `${COLOR_MAP.background.disabled};` : `${COLOR_MAP.background.white};`};
`;

const CheckedCircle = styled(Circle)`
  &::before {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;

    width: 10px;
    height: 10px;

    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ disabled }) =>
      disabled ? `${COLOR_MAP.controls.disabled};` : `${COLOR_MAP.controls.clickableNormal};`};
  }
`;

export const RadioChipsV2WithIcon = ({
  checkedValue,
  disabled,
  onChange = noop,
  name,
  label = '',
  subtext,
  value,
  withBorder = true,
  icon: Icon,
  iconProps,
  iconSide = ICON_SIDE.left,
  radioSide = RADIO_SIDE.right,
}: Props & {
  icon: React.ComponentType<IconType>;
  iconProps: IconType;
  withBorder?: boolean;
  subtext?: ReactNode | string;
  iconSide?: iconSideTypes;
  radioSide?: radioSideTypes;
}) => {
  const changeHandler = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  const checked = checkedValue === value;

  return (
    <RadioWrapper disabled={disabled} checked={checked} withBorder={withBorder} radioSide={radioSide}>
      {iconSide === ICON_SIDE.left && <Icon {...iconProps} />}
      <ContentContainer>
        <IconTextContainer>
          <Text>{label}</Text>
          {subtext && (
            <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey}>
              {subtext}
            </Text>
          )}
        </IconTextContainer>
      </ContentContainer>
      {radioSide === RADIO_SIDE.right &&
        (checked ? <CheckedCircle disabled={disabled} /> : <Circle disabled={disabled} />)}
      <VisuallyHiddenInput type="radio" onChange={changeHandler} checked={checked} name={name} value={value} />
      {radioSide === RADIO_SIDE.left &&
        (checked ? <CheckedCircle disabled={disabled} /> : <Circle disabled={disabled} />)}
      {iconSide === ICON_SIDE.right && <Icon {...iconProps} />}
    </RadioWrapper>
  );
};
