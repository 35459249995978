import styled, { CSSProperties } from 'styled-components';
import { truthfulAdaptive } from '../../../../../../componentUI/screen';

const ReferralCodeStyled = styled.div`
  display: flex;
  gap: 16px;

  ${truthfulAdaptive.maxWidth.mobile} {
    flex-direction: column;
    gap: 8px;
  }
`;

const WrapperReferralCodeStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const stylesForButtons: CSSProperties = {
  textTransform: 'uppercase',
  maxHeight: '44px',
};

export { ReferralCodeStyled, stylesForButtons, WrapperReferralCodeStyled };
