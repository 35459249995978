import styled, { CSSProperties } from 'styled-components';
import { truthfulAdaptive } from '../../../../../../../../componentUI/screen';

const DateFilterStyled = styled.div`
  display: grid;
  grid-template-columns: 168px 1fr;
  gap: 12px;

  ${truthfulAdaptive.maxWidth.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

const WrapperMultiSelectStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 12px;

  ${truthfulAdaptive.maxWidth.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

const DatePickerWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  min-width: 26%;

  ${truthfulAdaptive.maxWidth.mobile} {
    min-width: 100%;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const stylesForControl: CSSProperties = {
  minHeight: '44px',
};

export { ArrowWrapper, DateFilterStyled, DatePickerWrapper, stylesForControl, WrapperMultiSelectStyled };
