import React, { useCallback } from 'react';
import styled from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';
import { Text } from '../Text';
import { noop, VisuallyHiddenInput } from '../../utils';
import { Props } from './Radio';

const RadioWrapper = styled.label<{ checked: boolean; disabled?: boolean; withBorder?: boolean }>`
  display: flex;
  width: 100%;
  min-width: 100px;

  min-height: ${({ withBorder }) => (withBorder ? '38px' : 'auto')};

  gap: 8px;

  padding: ${({ withBorder }) => (withBorder ? '8px 10px' : '0px')};

  align-items: center;
  justify-content: flex-start;

  ${({ checked }) => (checked ? `background-color: ${COLOR_MAP.text.brandColor}20;` : 'background-color: transparent')};
  ${({ disabled }) => (disabled ? `background-color: ${COLOR_MAP.background.disabled}; pointer-events: none;` : '')};

  ${({ checked, withBorder, disabled }) => {
    let backgroundColor;
    let border;

    if (withBorder) {
      if (checked) {
        backgroundColor = `${COLOR_MAP.text.brandColor}20`;
        border = `1px solid ${COLOR_MAP.controls.clickableNormal}`;
      } else {
        backgroundColor = 'transparent';
        border = '1px solid transparent';
      }

      // Check if border should be set based on the disabled prop
      if (disabled) {
        border = `1px solid ${COLOR_MAP.controls.disabled}`;
        backgroundColor = `${COLOR_MAP.background.disabled}`;
      } else {
        border = `1px solid ${COLOR_MAP.controls.clickableNormal}`;
      }
    } else {
      backgroundColor = 'transparent';
      border = 'none';
    }

    return `
      background-color: ${backgroundColor};
      border: ${border};
    `;
  }};

  border-radius: 4px;
  box-sizing: border-box;

  user-select: none;
  cursor: pointer;
`;

const Circle = styled.div<{ disabled?: boolean }>`
  position: relative;

  width: 20px;
  height: 20px;

  border-radius: 50%;
  border: ${({ disabled }) =>
    disabled ? `1.2px solid ${COLOR_MAP.controls.disabled};` : `1.2px solid ${COLOR_MAP.controls.clickableNormal};`};

  background-color: ${({ disabled }) =>
    disabled ? `${COLOR_MAP.background.disabled};` : `${COLOR_MAP.background.white};`};
`;

const CheckedCircle = styled(Circle)`
  &::before {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;

    width: 10px;
    height: 10px;

    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ disabled }) =>
      disabled ? `${COLOR_MAP.controls.disabled};` : `${COLOR_MAP.controls.clickableNormal};`};
  }
`;

export const RadioChipsV2 = ({
  checkedValue,
  disabled,
  onChange = noop,
  name,
  label = '',
  value,
  withBorder = true,
}: Props & { withBorder?: boolean }) => {
  const changeHandler = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  const checked = checkedValue === value;

  return (
    <RadioWrapper disabled={disabled} checked={checked} withBorder={withBorder}>
      {checked ? <CheckedCircle disabled={disabled} /> : <Circle disabled={disabled} />}
      <Text>{label}</Text>

      <VisuallyHiddenInput type="radio" onChange={changeHandler} checked={checked} name={name} value={value} />
    </RadioWrapper>
  );
};
