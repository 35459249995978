import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGoogleAnalytics } from '../../../../../analytics';
import { useMatchMedia } from '../../../../../hooks';
import { TermsBlock } from '../../../../../components';
import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { Picture } from '../../../../../componentUI/complex/Picture';
import { Text } from '../../../../../componentUI/simple/Text';
import { email24logo } from '../../../assets';
import { LogoBlock, TitleBlock } from '../..';
import { ContainerStyled, SeparatorStyled, WrapperMainStyled, WrapperTextStyled } from './styled';

export const ScreenSignUpEmailSuccess = () => {
  useGoogleAnalytics();
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();

  return (
    <ContainerStyled>
      <LogoBlock />

      <WrapperMainStyled>
        <Picture url={email24logo} width={isMobile ? 180 : 225} height={isMobile ? 160 : 200} alt="email logo" />

        <WrapperTextStyled>
          <TitleBlock
            titleText={t('auth.signUpEmailSuccess.title')}
            subtitleText={t('auth.signUpEmailSuccess.subtitle')}
          />

          <SeparatorStyled />

          <Text colorText={COLOR_MAP.text.white}>{t('auth.signUpEmailSuccess.bodyText')}</Text>
        </WrapperTextStyled>
      </WrapperMainStyled>

      <TermsBlock />
    </ContainerStyled>
  );
};
