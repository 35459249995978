import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetUserSubscriptions } from '../../../api/subscriptions/hooks/useGetUserSubscriptions';
import { CloseIcon } from '../../../common/icons/SvgInline/SvgIcons_2';

import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { BUTTON_SIZE, SecondButton } from '../../../componentUI/simple/Button';
import { TITLE_SIZE, Title } from '../../../componentUI/simple/Title';
import { useIsDesktop } from '../../../hooks';

import { useProductsQuery } from '../../../api/product/hooks/useProductsQuery';
import { useBalanceUserInfoQuery } from '../../../api/userCredits/hooks';

import { Background, CLoseWrapper, Container } from './styled';

const COLOR_MAP_BTN = {
  clickable: COLOR_MAP.controls.light.clickable,
  clickableHover: COLOR_MAP.controls.light.clickableHover,
  clickablePressed: COLOR_MAP.controls.light.clickablePressed,
  clickableDisabled: COLOR_MAP.controls.light.clickableDisabled,
};

const PARAMS_PRODUCT_QUERY = {
  page: 1,
  limit: 10,
};

const STORADGE_KEY = 'asdndsadaswmeldsox';
const MINIMUM_BALANCE = 1;

export const SubscribesBanner = ({ isNotification }: { isNotification?: boolean }) => {
  const { data: credits } = useBalanceUserInfoQuery();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  const hiddenBanner = (credits && credits.balanceInfo.creditsVolume < MINIMUM_BALANCE) || false;

  const { data: products } = useProductsQuery(PARAMS_PRODUCT_QUERY);

  const { data, isLoading } = useGetUserSubscriptions();

  const [isHidden, setIsHidden] = useState(Boolean(localStorage.getItem(STORADGE_KEY)));

  const closeCallback = () => {
    setIsHidden(true);
    localStorage.setItem(STORADGE_KEY, 'true');
  };

  if (pathname !== '/dashboard' || isLoading || data?.result || isHidden || hiddenBanner || !products?.entities.length)
    return null;

  return (
    <Background style={{ margin: isNotification ? 0 : undefined }}>
      <Container>
        <div>
          <Title
            size={TITLE_SIZE.h3}
            colorText={COLOR_MAP.text.white}
            margins={[0, 0, 4, 0]}
            style={{ textAlign: 'center' }}
          >
            Introducing LegitGrails+
          </Title>
          <Title size={TITLE_SIZE.h6} colorText={COLOR_MAP.text.white} style={{ textAlign: 'center' }}>
            Try now with 14 days trial
          </Title>
        </div>
        <SecondButton
          onClick={() => navigate('/account-settings/subscriptions')}
          colorsMap={COLOR_MAP_BTN}
          size={BUTTON_SIZE.extraSmall}
          style={{ paddingLeft: isDesktop ? '40px' : '82px', paddingRight: isDesktop ? '40px' : '82px' }}
        >
          SEE PLANS
        </SecondButton>
      </Container>
      <CLoseWrapper>
        <CloseIcon
          size="32"
          color={COLOR_MAP.text.white}
          props={{ onClick: closeCallback, style: { cursor: 'pointer' } }}
        />
      </CLoseWrapper>
    </Background>
  );
};
