import React from 'react';
import styled from 'styled-components';
import { TriangleError } from '../../../common/icons/SvgInline/SvgIcons_7';
import { COLOR_MAP } from '../../colorsMap';
import { Input } from '../../simple/Input';
import { PropsInput } from '../../simple/Input/types';
import { Text, TEXT_SIZE, TEXT_WEIGHT } from '../../simple/Text';

type Props = { errorMessage?: string } & PropsInput;

const WrapperError = styled.div<{ isError: boolean }>`
  width: 100%;

  ${({ isError }) => isError && `input { border: 2px solid ${COLOR_MAP.accent.red}; }`}
`;

export const InputWithError = ({ errorMessage, ...props }: Props) => {
  return (
    <WrapperError isError={Boolean(errorMessage)}>
      <Input {...props} />
      {errorMessage && (
        <div style={{ display: 'flex', marginTop: '6px' }}>
          <TriangleError />
          &nbsp;
          <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.accent.red} size={TEXT_SIZE.annotation}>
            {errorMessage}
          </Text>
        </div>
      )}
    </WrapperError>
  );
};
