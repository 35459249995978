import { Dispatch, MouseEvent, SetStateAction } from 'react';
import { SortProduct } from '../../../../../api/product/type';

/** Получаем sort параметр для передачи в эндпонит
 * products/get-products
 */

export const handleClickChevronButton = (
  event: MouseEvent<HTMLElement>,
  selectedFilter: SortProduct | null,
  setSelectedFilter: Dispatch<SetStateAction<SortProduct | null>>,
) => {
  const dataAttribute = event.currentTarget.getAttribute('data-filter-id');

  if (dataAttribute === selectedFilter) {
    return;
  }

  setSelectedFilter(dataAttribute as SortProduct);
};
