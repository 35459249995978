import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-ZR3WDGSKF9';

export function init() {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  ReactGA.initialize(TRACKING_ID, { debug: isDev });
}

export function sendEvent(category, action, otherOptions) {
  ReactGA.event({ category, action, ...otherOptions });
}

export function sendUserEvent(action, otherOptions) {
  ReactGA.event({ category: 'USER', action, ...otherOptions });
}
