import React from 'react';

/* eslint-disable max-len */

const RightArrow = ({ color = '#ffffff', size = '20' }: { color?: string; size?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 18L15 12L9 6" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const DiscoverPricingIcon = ({ width = '86', height = '82' }: { width?: string; height?: string }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 86 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_9713_24419)">
        <path
          d="M15.6875 14.4C15.6875 13.0745 16.7508 12 18.0625 12H48.9375C50.2492 12 51.3125 13.0745 51.3125 14.4V56.4C51.3125 57.7255 50.2492 58.8 48.9375 58.8H18.0625C16.7508 58.8 15.6875 57.7255 15.6875 56.4V14.4Z"
          fill="#C5C5C5"
        />
      </g>
      <path
        d="M33.0741 18.8721C33.2484 18.5153 33.7518 18.5153 33.926 18.8721L35.1346 21.3466C35.2038 21.4882 35.3375 21.5864 35.4922 21.6091L38.1945 22.0059C38.5841 22.0632 38.7397 22.547 38.4578 22.8247L36.5024 24.7508C36.3904 24.8611 36.3393 25.02 36.3658 25.1757L36.8274 27.8954C36.8939 28.2875 36.4866 28.5866 36.1382 28.4014L33.7211 27.1173C33.5828 27.0438 33.4174 27.0438 33.2791 27.1173L30.862 28.4014C30.5135 28.5866 30.1063 28.2875 30.1728 27.8954L30.6344 25.1757C30.6609 25.02 30.6098 24.8611 30.4978 24.7508L28.5424 22.8247C28.2605 22.547 28.416 22.0632 28.8057 22.0059L31.508 21.6091C31.6627 21.5864 31.7964 21.4882 31.8656 21.3466L33.0741 18.8721Z"
        fill="#A0A0A0"
      />
      <path
        d="M21.625 35.9998C21.625 35.3371 22.1567 34.7998 22.8125 34.7998H44.1875C44.8433 34.7998 45.375 35.3371 45.375 35.9998V37.1998C45.375 37.8625 44.8433 38.3998 44.1875 38.3998H22.8125C22.1567 38.3998 21.625 37.8625 21.625 37.1998V35.9998Z"
        fill="#A0A0A0"
      />
      <path
        d="M24 44.3998C24 43.7371 24.5317 43.1998 25.1875 43.1998H41.8125C42.4683 43.1998 43 43.7371 43 44.3998C43 45.0625 42.4683 45.5998 41.8125 45.5998H25.1875C24.5317 45.5998 24 45.0625 24 44.3998Z"
        fill="#A0A0A0"
      />
      <path
        d="M24 50.3998C24 49.7371 24.5317 49.1998 25.1875 49.1998H41.8125C42.4683 49.1998 43 49.7371 43 50.3998C43 51.0625 42.4683 51.5998 41.8125 51.5998H25.1875C24.5317 51.5998 24 51.0625 24 50.3998Z"
        fill="#A0A0A0"
      />
      <g filter="url(#filter1_d_9713_24419)">
        <path
          d="M34.6875 8.4C34.6875 7.07452 35.7508 6 37.0625 6H72.6875C73.9992 6 75.0625 7.07452 75.0625 8.4V58.8C75.0625 60.1255 73.9992 61.2 72.6875 61.2H37.0625C35.7508 61.2 34.6875 60.1255 34.6875 58.8V8.4Z"
          fill="#FFFEFD"
        />
      </g>
      <path
        d="M54.4491 11.6724C54.6234 11.3156 55.1268 11.3156 55.301 11.6724L56.8586 14.8614C56.9277 15.0031 57.0615 15.1013 57.2162 15.124L60.6989 15.6354C61.0885 15.6926 61.2441 16.1765 60.9622 16.4542L58.442 18.9365C58.3301 19.0468 58.279 19.2057 58.3054 19.3614L58.9004 22.8665C58.9669 23.2586 58.5596 23.5577 58.2112 23.3725L55.0961 21.7176C54.9577 21.6441 54.7924 21.6441 54.654 21.7176L51.539 23.3725C51.1905 23.5577 50.7833 23.2586 50.8498 22.8665L51.4447 19.3614C51.4712 19.2057 51.4201 19.0468 51.3081 18.9365L48.788 16.4542C48.5061 16.1765 48.6617 15.6926 49.0513 15.6354L52.534 15.124C52.6887 15.1013 52.8224 15.0031 52.8916 14.8614L54.4491 11.6724Z"
        fill="#C8C8C8"
      />
      <path
        d="M40.625 31.2C40.625 30.5373 41.1567 30 41.8125 30H67.9375C68.5933 30 69.125 30.5373 69.125 31.2V33.6C69.125 34.2627 68.5933 34.8 67.9375 34.8H41.8125C41.1567 34.8 40.625 34.2627 40.625 33.6V31.2Z"
        fill="#D9D9D9"
      />
      <path
        d="M43 40.8001C43 40.1374 43.5317 39.6001 44.1875 39.6001H65.5625C66.2183 39.6001 66.75 40.1374 66.75 40.8001C66.75 41.4628 66.2183 42.0001 65.5625 42.0001H44.1875C43.5317 42.0001 43 41.4628 43 40.8001Z"
        fill="#E6E6E6"
      />
      <path
        d="M43 46.8001C43 46.1374 43.5317 45.6001 44.1875 45.6001H65.5625C66.2183 45.6001 66.75 46.1374 66.75 46.8001C66.75 47.4628 66.2183 48.0001 65.5625 48.0001H44.1875C43.5317 48.0001 43 47.4628 43 46.8001Z"
        fill="#E6E6E6"
      />
      <path
        d="M43 52.8001C43 52.1374 43.5317 51.6001 44.1875 51.6001H65.5625C66.2183 51.6001 66.75 52.1374 66.75 52.8001C66.75 53.4628 66.2183 54.0001 65.5625 54.0001H44.1875C43.5317 54.0001 43 53.4628 43 52.8001Z"
        fill="#E6E6E6"
      />
      <g filter="url(#filter2_d_9713_24419)">
        <path
          d="M44.1875 61.5428C44.1875 64.0045 37.2759 66 28.75 66C20.2241 66 13.3125 64.0045 13.3125 61.5428L13.3125 57.8002L28.75 57.6001L44.1875 57.8002V61.5428Z"
          fill="url(#paint0_linear_9713_24419)"
        />
      </g>
      <ellipse cx="28.75" cy="57.3712" rx="15.4375" ry="4.45714" fill="#E4E4E4" />
      <g filter="url(#filter3_d_9713_24419)">
        <path
          d="M41.8125 55.4286C41.8125 57.8902 34.9009 59.8857 26.375 59.8857C17.8491 59.8857 10.9375 57.8902 10.9375 55.4286L10.9375 51.686L26.375 51.4858L41.8125 51.686V55.4286Z"
          fill="url(#paint1_linear_9713_24419)"
        />
      </g>
      <ellipse cx="26.375" cy="51.257" rx="15.4375" ry="4.45714" fill="#E4E4E4" />
      <defs>
        <filter
          id="filter0_d_9713_24419"
          x="5.6875"
          y="8"
          width="55.625"
          height="66.7998"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9713_24419" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9713_24419" result="shape" />
        </filter>
        <filter
          id="filter1_d_9713_24419"
          x="24.6875"
          y="2"
          width="60.375"
          height="75.2002"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9713_24419" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9713_24419" result="shape" />
        </filter>
        <filter
          id="filter2_d_9713_24419"
          x="3.3125"
          y="53.6001"
          width="50.875"
          height="28.3999"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9713_24419" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9713_24419" result="shape" />
        </filter>
        <filter
          id="filter3_d_9713_24419"
          x="0.9375"
          y="47.4858"
          width="50.875"
          height="28.3999"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9713_24419" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9713_24419" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_9713_24419"
          x1="13.3884"
          y1="61.1843"
          x2="43.8072"
          y2="61.1843"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0192656" stopColor="#AFAFAF" />
          <stop offset="0.682616" stopColor="#D8D8D8" />
          <stop offset="0.966507" stopColor="#A6A6A6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9713_24419"
          x1="11.0134"
          y1="55.07"
          x2="41.4322"
          y2="55.07"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0192656" stopColor="#AFAFAF" />
          <stop offset="0.682616" stopColor="#D8D8D8" />
          <stop offset="0.966507" stopColor="#A6A6A6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const HowItWorksIcon = ({ width = '88', height = '85' }: { width?: string; height?: string }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 88 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_9714_26398)">
        <path
          d="M10.75 14.4C10.75 13.0745 11.8133 12 13.125 12H74.875C76.1867 12 77.25 13.0745 77.25 14.4V52.8C77.25 54.1255 76.1867 55.2 74.875 55.2H13.125C11.8133 55.2 10.75 54.1255 10.75 52.8V14.4Z"
          fill="#F3F3F3"
        />
      </g>
      <path
        d="M10.75 14.4C10.75 13.0745 11.8133 12 13.125 12H29.75V55.2H13.125C11.8133 55.2 10.75 54.1255 10.75 52.8V14.4Z"
        fill="#BABABA"
      />
      <path
        d="M14.3125 17.9998C14.3125 17.3371 14.8442 16.7998 15.5 16.7998H25C25.6558 16.7998 26.1875 17.3371 26.1875 17.9998V21.5998C26.1875 22.2625 25.6558 22.7998 25 22.7998H15.5C14.8442 22.7998 14.3125 22.2625 14.3125 21.5998V17.9998Z"
        fill="white"
      />
      <path
        d="M14.3125 26.3998C14.3125 25.7371 14.8442 25.1998 15.5 25.1998H25C25.6558 25.1998 26.1875 25.7371 26.1875 26.3998V29.9998C26.1875 30.6625 25.6558 31.1998 25 31.1998H15.5C14.8442 31.1998 14.3125 30.6625 14.3125 29.9998V26.3998Z"
        fill="white"
      />
      <path
        d="M35.6875 17.9998C35.6875 17.3371 36.2192 16.7998 36.875 16.7998H70.125C70.7808 16.7998 71.3125 17.3371 71.3125 17.9998V25.1998C71.3125 25.8625 70.7808 26.3998 70.125 26.3998H36.875C36.2192 26.3998 35.6875 25.8625 35.6875 25.1998V17.9998Z"
        fill="#D8D8D8"
      />
      <path
        d="M35.6875 32.3998C35.6875 31.7371 36.2192 31.1998 36.875 31.1998H70.125C70.7808 31.1998 71.3125 31.7371 71.3125 32.3998V47.9998C71.3125 48.6625 70.7808 49.1998 70.125 49.1998H36.875C36.2192 49.1998 35.6875 48.6625 35.6875 47.9998V32.3998Z"
        fill="#D8D8D8"
      />
      <g filter="url(#filter1_d_9714_26398)">
        <path
          d="M29.7699 63.6496L33.9298 40.1857C34.0537 39.4868 33.3161 38.9581 32.7044 39.3073L12.165 51.0305L19.4606 53.2035L13.0557 62.328L18.924 66.5344L25.3289 57.4099L29.7699 63.6496Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_9714_26398"
          x="0.75"
          y="8"
          width="86.5"
          height="63.2002"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9714_26398" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9714_26398" result="shape" />
        </filter>
        <filter
          id="filter1_d_9714_26398"
          x="0.165039"
          y="33.1968"
          width="45.7783"
          height="51.3374"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9714_26398" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9714_26398" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const BrandCoverageIcon = ({ width = '78', height = '82' }: { width?: string; height?: string }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 78 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.625 27.0248V29.5383C42.625 30.1814 42.5691 30.8113 42.462 31.4235L45.3983 32.0519C45.5472 31.2368 45.625 30.3967 45.625 29.5383V27.0248C45.625 19.3897 39.4689 13.2002 31.875 13.2002C24.2811 13.2002 18.125 19.3897 18.125 27.0248V29.5383C18.125 30.3967 18.2028 31.2368 18.3517 32.0519L21.288 31.4235C21.1809 30.8113 21.125 30.1814 21.125 29.5383V27.0248C21.125 21.0555 25.9379 16.2165 31.875 16.2165C37.8121 16.2165 42.625 21.0555 42.625 27.0248Z"
        fill="#DDDDDD"
      />
      <g filter="url(#filter0_d_9714_26423)">
        <path
          d="M12.8207 31.6798C12.9953 30.451 14.0371 29.5386 15.2657 29.5386H48.4843C49.7128 29.5386 50.7546 30.451 50.9292 31.6798L52.9921 46.1986C54.3814 55.9761 46.875 64.7284 37.1001 64.7284H26.6498C16.875 64.7284 9.36857 55.9761 10.7578 46.1986L12.8207 31.6798Z"
          fill="#FFFEFD"
        />
      </g>
      <g filter="url(#filter1_d_9714_26423)">
        <path
          d="M17.9742 32.9004H45.7758C46.5437 32.9004 47.2136 33.4835 47.3268 34.2949L49.0533 46.6724C50.1764 54.7237 44.1089 61.9004 36.2481 61.9004H27.5019C19.6411 61.9004 13.5736 54.7237 14.6967 46.6724L16.4232 34.2949C16.5364 33.4835 17.2063 32.9004 17.9742 32.9004Z"
          stroke="#E4E4E4"
          shapeRendering="crispEdges"
        />
      </g>
      <rect x="29.375" y="29.5386" width="5" height="10.0542" fill="#C8C8C8" />
      <ellipse cx="31.875" cy="40.8494" rx="3.75" ry="3.77034" fill="#ADADAD" />
      <path
        d="M40.1875 11.6897C40.1875 10.4365 41.0567 9.34413 42.2957 9.04019L54.3562 6.08154C54.7994 5.97282 55.2631 5.97282 55.7063 6.08154L67.7668 9.04019C69.0058 9.34413 69.875 10.4365 69.875 11.6897V30.7794C69.875 33.613 68.5303 36.285 66.2375 38.0076L57.0052 44.9437C55.8403 45.8188 54.2222 45.8188 53.0573 44.9437L43.825 38.0076C41.5322 36.285 40.1875 33.613 40.1875 30.7794V11.6897Z"
        fill="#D9D9D9"
      />
      <g filter="url(#filter2_d_9714_26423)">
        <path
          d="M42.5947 12.0974C42.5947 11.4675 43.0243 10.918 43.6376 10.7635L54.3535 8.06404C54.7984 7.95196 55.2644 7.95196 55.7093 8.06404L66.4251 10.7635C67.0384 10.918 67.468 11.4675 67.468 12.0974V31.1432C67.468 32.845 66.6773 34.4511 65.3262 35.4934L56.442 42.3475C55.6117 42.9881 54.4511 42.9881 53.6207 42.3475L44.7366 35.4934C43.3855 34.4511 42.5947 32.845 42.5947 31.1432V12.0974Z"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.0246 18.6383C62.5249 19.0447 62.5977 19.7758 62.1872 20.2711L53.8539 30.3257C53.6495 30.5723 53.3515 30.7248 53.0301 30.7471C52.7087 30.7695 52.3922 30.6599 52.1549 30.444L47.9883 26.6529C47.5117 26.2194 47.4805 25.4854 47.9184 25.0137C48.3564 24.5419 49.0977 24.511 49.5742 24.9446L52.8282 27.9053L60.3753 18.7993C60.7858 18.3039 61.5242 18.2319 62.0246 18.6383Z"
        fill="#C8C8C8"
      />
      <defs>
        <filter
          id="filter0_d_9714_26423"
          x="0.191797"
          y="25.3786"
          width="63.3664"
          height="55.9899"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.24" />
          <feGaussianBlur stdDeviation="5.2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9714_26423" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9714_26423" result="shape" />
        </filter>
        <filter
          id="filter1_d_9714_26423"
          x="3.6625"
          y="28.2404"
          width="56.425"
          height="50.8"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.24" />
          <feGaussianBlur stdDeviation="5.2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9714_26423" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9714_26423" result="shape" />
        </filter>
        <filter
          id="filter2_d_9714_26423"
          x="32.1947"
          y="3.81998"
          width="45.6735"
          height="55.6481"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.24" />
          <feGaussianBlur stdDeviation="5.2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9714_26423" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9714_26423" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const NextFeatureIcon = ({ width = '85', height = '85' }: { width?: string; height?: string }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_10833_88005)">
        <path
          d="M10.75 20.4C10.75 19.0745 11.8133 18 13.125 18H72.5C73.8117 18 74.875 19.0745 74.875 20.4V66C74.875 67.3255 73.8117 68.4 72.5 68.4H13.125C11.8133 68.4 10.75 67.3255 10.75 66V20.4Z"
          fill="#FFFEFD"
        />
      </g>
      <g filter="url(#filter1_d_10833_88005)">
        <path
          d="M15.5 39.6002C15.5 38.2747 16.5633 37.2002 17.875 37.2002H25C26.3117 37.2002 27.375 38.2747 27.375 39.6002V46.8002C27.375 48.1257 26.3117 49.2002 25 49.2002H17.875C16.5633 49.2002 15.5 48.1257 15.5 46.8002V39.6002Z"
          fill="#E6E6E6"
        />
      </g>
      <g filter="url(#filter2_d_10833_88005)">
        <path
          d="M15.5 24.0001C15.5 22.6746 16.5633 21.6001 17.875 21.6001H25C26.3117 21.6001 27.375 22.6746 27.375 24.0001V31.2001C27.375 32.5256 26.3117 33.6001 25 33.6001H17.875C16.5633 33.6001 15.5 32.5256 15.5 31.2001V24.0001Z"
          fill="#E6E6E6"
        />
      </g>
      <g filter="url(#filter3_d_10833_88005)">
        <path
          d="M15.5 55.1998C15.5 53.8743 16.5633 52.7998 17.875 52.7998H25C26.3117 52.7998 27.375 53.8743 27.375 55.1998V62.3998C27.375 63.7253 26.3117 64.7998 25 64.7998H17.875C16.5633 64.7998 15.5 63.7253 15.5 62.3998V55.1998Z"
          fill="#E6E6E6"
        />
      </g>
      <g filter="url(#filter4_d_10833_88005)">
        <path
          d="M32.125 43.1998C32.125 41.8743 33.1883 40.7998 34.5 40.7998H66.5625C67.8742 40.7998 68.9375 41.8743 68.9375 43.1998C68.9375 44.5253 67.8742 45.5998 66.5625 45.5998H34.5C33.1883 45.5998 32.125 44.5253 32.125 43.1998Z"
          fill="#E6E6E6"
        />
      </g>
      <g filter="url(#filter5_d_10833_88005)">
        <path
          d="M30.9375 27.6002C30.9375 26.2747 32.0008 25.2002 33.3125 25.2002H65.375C66.6867 25.2002 67.75 26.2747 67.75 27.6002C67.75 28.9257 66.6867 30.0002 65.375 30.0002H33.3125C32.0008 30.0002 30.9375 28.9257 30.9375 27.6002Z"
          fill="#E6E6E6"
        />
      </g>
      <g filter="url(#filter6_d_10833_88005)">
        <path
          d="M30.9375 58.7999C30.9375 57.4744 32.0008 56.3999 33.3125 56.3999H65.375C66.6867 56.3999 67.75 57.4744 67.75 58.7999C67.75 60.1254 66.6867 61.1999 65.375 61.1999H33.3125C32.0008 61.1999 30.9375 60.1254 30.9375 58.7999Z"
          fill="#E6E6E6"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7943 39.7533C25.0344 39.9898 25.0694 40.4151 24.8723 40.7033L20.8723 46.5533C20.7742 46.6967 20.6312 46.7854 20.477 46.7984C20.3227 46.8115 20.1707 46.7477 20.0569 46.6221L18.0569 44.4164C17.8281 44.1641 17.8131 43.7371 18.0233 43.4626C18.2336 43.1881 18.5894 43.1701 18.8181 43.4224L20.3801 45.145L24.0026 39.847C24.1997 39.5588 24.5541 39.5168 24.7943 39.7533Z"
        fill="#A0A0A0"
      />
      <path
        d="M37.3467 12.6319C37.3467 7.6791 41.3199 3.66406 46.2211 3.66406H70.0347C74.936 3.66406 78.9092 7.6791 78.9092 12.6319C78.9092 17.5847 74.936 21.5998 70.0347 21.5998H46.2211C41.3199 21.5998 37.3467 17.5847 37.3467 12.6319Z"
        fill="#D8D8D8"
      />
      <g filter="url(#filter7_d_10833_88005)">
        <path
          d="M57.6737 5.92759C57.8596 5.56125 58.3965 5.56125 58.5823 5.92759L60.2434 9.20235C60.3172 9.34782 60.4599 9.44865 60.6249 9.47198L64.3393 9.99711C64.7548 10.0559 64.9207 10.5527 64.6201 10.8378L61.9323 13.3869C61.8129 13.5001 61.7584 13.6633 61.7866 13.8232L62.4211 17.4225C62.4921 17.8251 62.0577 18.1322 61.686 17.9421L58.3638 16.2427C58.2162 16.1672 58.0399 16.1672 57.8923 16.2427L54.57 17.9421C54.1983 18.1322 53.764 17.8251 53.8349 17.4225L54.4694 13.8232C54.4976 13.6633 54.4431 13.5001 54.3237 13.3869L51.636 10.8378C51.3353 10.5527 51.5012 10.0559 51.9167 9.99711L55.6311 9.47198C55.7962 9.44865 55.9388 9.34782 56.0126 9.20235L57.6737 5.92759Z"
          fill="#FFFEFD"
        />
      </g>
      <g filter="url(#filter8_d_10833_88005)">
        <path
          d="M69.189 9.60376C69.3093 9.35734 69.657 9.35734 69.7774 9.60376L70.8532 11.8066C70.901 11.9044 70.9934 11.9723 71.1003 11.9879L73.5059 12.3412C73.775 12.3807 73.8825 12.7149 73.6877 12.9067L71.947 14.6214C71.8697 14.6975 71.8344 14.8073 71.8526 14.9148L72.2636 17.3359C72.3095 17.6068 72.0282 17.8133 71.7875 17.6855L69.6359 16.5424C69.5403 16.4916 69.4261 16.4916 69.3305 16.5424L67.1788 17.6855C66.9381 17.8133 66.6568 17.6068 66.7028 17.3359L67.1137 14.9148C67.132 14.8073 67.0967 14.6975 67.0194 14.6214L65.2786 12.9067C65.0839 12.7149 65.1914 12.3807 65.4605 12.3412L67.8661 11.9879C67.973 11.9723 68.0653 11.9044 68.1131 11.8066L69.189 9.60376Z"
          fill="#F1F1F1"
        />
      </g>
      <g filter="url(#filter9_d_10833_88005)">
        <path
          d="M46.4785 9.60376C46.5989 9.35734 46.9466 9.35734 47.0669 9.60376L48.1428 11.8066C48.1906 11.9044 48.2829 11.9723 48.3898 11.9879L50.7954 12.3412C51.0645 12.3807 51.172 12.7149 50.9773 12.9067L49.2365 14.6214C49.1592 14.6975 49.1239 14.8073 49.1422 14.9148L49.5531 17.3359C49.5991 17.6068 49.3178 17.8133 49.0771 17.6855L46.9254 16.5424C46.8298 16.4916 46.7156 16.4916 46.6201 16.5424L44.4684 17.6855C44.2277 17.8133 43.9464 17.6068 43.9923 17.3359L44.4033 14.9148C44.4215 14.8073 44.3862 14.6975 44.3089 14.6214L42.5682 12.9067C42.3735 12.7149 42.4809 12.3807 42.75 12.3412L45.1556 11.9879C45.2625 11.9723 45.3549 11.9044 45.4027 11.8066L46.4785 9.60376Z"
          fill="#F1F1F1"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_10833_88005"
          x="0.75"
          y="14"
          width="84.125"
          height="70.3999"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10833_88005" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10833_88005" result="shape" />
        </filter>
        <filter
          id="filter1_d_10833_88005"
          x="5.5"
          y="33.2002"
          width="31.875"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10833_88005" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10833_88005" result="shape" />
        </filter>
        <filter
          id="filter2_d_10833_88005"
          x="5.5"
          y="17.6001"
          width="31.875"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10833_88005" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10833_88005" result="shape" />
        </filter>
        <filter
          id="filter3_d_10833_88005"
          x="5.5"
          y="48.7998"
          width="31.875"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10833_88005" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10833_88005" result="shape" />
        </filter>
        <filter
          id="filter4_d_10833_88005"
          x="22.125"
          y="36.7998"
          width="56.8125"
          height="24.7998"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10833_88005" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10833_88005" result="shape" />
        </filter>
        <filter
          id="filter5_d_10833_88005"
          x="20.9375"
          y="21.2002"
          width="56.8125"
          height="24.7998"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10833_88005" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10833_88005" result="shape" />
        </filter>
        <filter
          id="filter6_d_10833_88005"
          x="20.9375"
          y="52.3999"
          width="56.8125"
          height="24.7998"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10833_88005" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10833_88005" result="shape" />
        </filter>
        <filter
          id="filter7_d_10833_88005"
          x="41.4824"
          y="1.65283"
          width="33.291"
          height="32.3472"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10833_88005" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10833_88005" result="shape" />
        </filter>
        <filter
          id="filter8_d_10833_88005"
          x="55.1792"
          y="5.41895"
          width="28.6079"
          height="28.3052"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10833_88005" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10833_88005" result="shape" />
        </filter>
        <filter
          id="filter9_d_10833_88005"
          x="32.4688"
          y="5.41895"
          width="28.6079"
          height="28.3052"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10833_88005" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10833_88005" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const PoleIcon = ({ color = '#ffffff', size = '20' }: { color?: string; size?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 4.16669V15.8334" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.16669 10H15.8334" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const DataBaseIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 6.6665C14.1421 6.6665 17.5 5.54722 17.5 4.1665C17.5 2.78579 14.1421 1.6665 10 1.6665C5.85786 1.6665 2.5 2.78579 2.5 4.1665C2.5 5.54722 5.85786 6.6665 10 6.6665Z"
        stroke="#CBAD73"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 10C17.5 11.3833 14.1667 12.5 10 12.5C5.83333 12.5 2.5 11.3833 2.5 10"
        stroke="#CBAD73"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 4.1665V15.8332C2.5 17.2165 5.83333 18.3332 10 18.3332C14.1667 18.3332 17.5 17.2165 17.5 15.8332V4.1665"
        stroke="#CBAD73"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CheckIcon = ({ color = '#6BCBA9', size = '20' }: { color?: string; size?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6667 5L7.50001 14.1667L3.33334 10"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Star = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.23841 1.04695C8.47813 0.317682 9.52187 0.317683 9.76159 1.04695L11.3372 5.8404C11.4444 6.16654 11.7519 6.38736 12.0988 6.38736H17.1977C17.9734 6.38736 18.296 7.36858 17.6684 7.81929L13.5433 10.7818C13.2626 10.9834 13.1452 11.3407 13.2524 11.6668L14.828 16.4603C15.0678 17.1895 14.2233 17.7959 13.5958 17.3452L9.47069 14.3827C9.19002 14.1812 8.80998 14.1812 8.52931 14.3827L4.40424 17.3452C3.77666 17.7959 2.93225 17.1895 3.17197 16.4602L4.7476 11.6668C4.85481 11.3407 4.73737 10.9834 4.4567 10.7818L0.331628 7.81929C-0.295955 7.36858 0.0265812 6.38736 0.802317 6.38736H5.90119C6.24811 6.38736 6.55557 6.16654 6.66277 5.8404L8.23841 1.04695Z"
      fill="white"
    />
  </svg>
);

const PocketIcon = ({ size = '20' }: { size?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6667 2.5H3.33332C2.8913 2.5 2.46737 2.67559 2.15481 2.98816C1.84225 3.30072 1.66666 3.72464 1.66666 4.16667V9.16667C1.66666 11.3768 2.54463 13.4964 4.10743 15.0592C4.88125 15.833 5.79991 16.4469 6.81096 16.8657C7.82201 17.2845 8.90564 17.5 9.99999 17.5C12.2101 17.5 14.3297 16.622 15.8925 15.0592C17.4553 13.4964 18.3333 11.3768 18.3333 9.16667V4.16667C18.3333 3.72464 18.1577 3.30072 17.8452 2.98816C17.5326 2.67559 17.1087 2.5 16.6667 2.5Z"
        stroke="#DCD0B9"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66666 8.33301L9.99999 11.6663L13.3333 8.33301"
        stroke="#CBAD73"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const LGIcon = ({ width = '78', height = '73' }: { width?: string; height?: string }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 78 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_14104_468)">
        <path
          d="M10 15C10 13.8954 10.8954 13 12 13H64C65.1046 13 66 13.8954 66 15V47C66 48.1046 65.1046 49 64 49H12C10.8954 49 10 48.1046 10 47V15Z"
          fill="#E7E7E7"
        />
      </g>
      <path d="M10 15C10 13.8954 10.8954 13 12 13H26V49H12C10.8954 49 10 48.1046 10 47V15Z" fill="#CECECE" />
      <path
        d="M31 17C31 16.4477 31.4477 16 32 16H60C60.5523 16 61 16.4477 61 17V19C61 19.5523 60.5523 20 60 20H32C31.4477 20 31 19.5523 31 19V17Z"
        fill="white"
      />
      <path
        d="M31 25C31 24.4477 31.4477 24 32 24H60C60.5523 24 61 24.4477 61 25V29C61 29.5523 60.5523 30 60 30H32C31.4477 30 31 29.5523 31 29V25Z"
        fill="white"
      />
      <path
        d="M31 33C31 32.4477 31.4477 32 32 32H60C60.5523 32 61 32.4477 61 33V34C61 34.5523 60.5523 35 60 35H32C31.4477 35 31 34.5523 31 34V33Z"
        fill="white"
      />
      <path
        d="M31 38C31 37.4477 31.4477 37 32 37H60C60.5523 37 61 37.4477 61 38V39C61 39.5523 60.5523 40 60 40H32C31.4477 40 31 39.5523 31 39V38Z"
        fill="white"
      />
      <path
        d="M31 43C31 42.4477 31.4477 42 32 42H60C60.5523 42 61 42.4477 61 43V44C61 44.5523 60.5523 45 60 45H32C31.4477 45 31 44.5523 31 44V43Z"
        fill="white"
      />
      <g filter="url(#filter1_d_14104_468)">
        <path
          d="M51 38.363C51 38.0015 51.2763 37.6862 51.6709 37.5975L58.5639 36.0482C58.8501 35.9839 59.1499 35.9839 59.4361 36.0482L66.3291 37.5975C66.7237 37.6862 67 38.0015 67 38.363V49.2939C67 50.2706 66.4913 51.1923 65.6222 51.7905L59.9074 55.7242C59.3733 56.0919 58.6267 56.0919 58.0926 55.7242L52.3778 51.7905C51.5087 51.1923 51 50.2706 51 49.2939V38.363Z"
          fill="#F0F0F0"
        />
      </g>
      <g filter="url(#filter2_d_14104_468)">
        <path
          d="M19 25C18.5582 25 18.2 25.3582 18.2 25.8V28.2H15.8C15.3582 28.2 15 28.5582 15 29C15 29.4418 15.3582 29.8 15.8 29.8H18.2V32.2C18.2 32.6418 18.5582 33 19 33C19.4418 33 19.8 32.6418 19.8 32.2V29.8H22.2C22.6418 29.8 23 29.4418 23 29C23 28.5582 22.6418 28.2 22.2 28.2H19.8V25.8C19.8 25.3582 19.4418 25 19 25Z"
          fill="#F4F4F4"
        />
      </g>
      <g filter="url(#filter3_d_14104_468)">
        <path
          d="M34.5 4C34.2239 4 34 4.22385 34 4.5V6H32.5C32.2239 6 32 6.22385 32 6.5C32 6.77615 32.2239 7 32.5 7H34V8.5C34 8.77615 34.2239 9 34.5 9C34.7761 9 35 8.77615 35 8.5V7H36.5C36.7761 7 37 6.77615 37 6.5C37 6.22385 36.7761 6 36.5 6H35V4.5C35 4.22385 34.7761 4 34.5 4Z"
          fill="#BFBFBF"
        />
      </g>
      <path d="M21 5L17.7961 10.1294H24.2039L21 5Z" fill="#F1F1F1" />
      <path d="M17.7961 10.1294L21 20L24.2039 10.1294H17.7961Z" fill="white" />
      <path d="M24.2039 10.1294L21 20L30 9.58916L24.2039 10.1294Z" fill="#F1F1F1" />
      <path d="M30 9.58916L26.7531 5L24.2039 10.1294L30 9.58916Z" fill="#CBCBCB" />
      <path d="M21 5L24.2039 10.1294L26.7531 5H21Z" fill="#DEDEDE" />
      <path d="M17.7961 10.1294L21 5H15.2469L17.7961 10.1294Z" fill="#DEDEDE" />
      <path d="M15.2469 5L12 9.58916L17.7961 10.1294L15.2469 5Z" fill="#CBCBCB" />
      <path d="M21 20L17.7961 10.1294L12 9.58916L21 20Z" fill="#F1F1F1" />
      <g filter="url(#filter4_d_14104_468)">
        <path
          d="M13 16C12.4477 16 12 16.4477 12 17V20H9C8.44772 20 8 20.4477 8 21C8 21.5523 8.44772 22 9 22H12V25C12 25.5523 12.4477 26 13 26C13.5523 26 14 25.5523 14 25V22H17C17.5523 22 18 21.5523 18 21C18 20.4477 17.5523 20 17 20H14V17C14 16.4477 13.5523 16 13 16Z"
          fill="#F4F4F4"
        />
      </g>
      <g filter="url(#filter5_d_14104_468)">
        <path
          d="M58.9999 41L60.066 42.7189L61.9388 41.9549L61.791 43.9721L63.7552 44.4549L62.4499 46L63.7552 47.5451L61.791 48.0279L61.9388 50.0451L60.066 49.2811L58.9999 51L57.9338 49.2811L56.061 50.0451L56.2088 48.0279L54.2446 47.5451L55.5499 46L54.2446 44.4549L56.2088 43.9721L56.061 41.9549L57.9338 42.7189L58.9999 41Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_14104_468"
          x="0"
          y="9"
          width="76"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14104_468" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14104_468" result="shape" />
        </filter>
        <filter
          id="filter1_d_14104_468"
          x="40.6"
          y="31.84"
          width="36.8"
          height="40.8"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.24" />
          <feGaussianBlur stdDeviation="5.2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14104_468" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14104_468" result="shape" />
        </filter>
        <filter
          id="filter2_d_14104_468"
          x="11"
          y="23"
          width="16"
          height="16"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14104_468" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14104_468" result="shape" />
        </filter>
        <filter
          id="filter3_d_14104_468"
          x="28"
          y="2"
          width="13"
          height="13"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14104_468" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14104_468" result="shape" />
        </filter>
        <filter
          id="filter4_d_14104_468"
          x="4"
          y="14"
          width="18"
          height="18"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14104_468" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14104_468" result="shape" />
        </filter>
        <filter
          id="filter5_d_14104_468"
          x="46.2446"
          y="36"
          width="25.5105"
          height="26"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14104_468" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14104_468" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const SheetIcon = ({ size = '24' }: { size?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon/file">
        <path
          id="Vector"
          d="M6 2H13L20 9V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2Z"
          stroke="#020202"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M13 2V9H20"
          stroke="#020202"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

const TriangleError = ({ size = '16' }: { size?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.21335 12L6.86001 2.57335C6.97886 2.37742 7.1462 2.21543 7.34588 2.103C7.54557 1.99058 7.77086 1.93152 8.00001 1.93152C8.22917 1.93152 8.45446 1.99058 8.65414 2.103C8.85383 2.21543 9.02117 2.37742 9.14001 2.57335L14.7867 12C14.9031 12.2016 14.9647 12.4302 14.9654 12.663C14.966 12.8958 14.9057 13.1248 14.7904 13.327C14.6751 13.5293 14.5089 13.6978 14.3082 13.8159C14.1076 13.934 13.8795 13.9975 13.6467 14H2.35335C2.12055 13.9975 1.89247 13.934 1.69182 13.8159C1.49116 13.6978 1.32492 13.5293 1.20963 13.327C1.09434 13.1248 1.03402 12.8958 1.03467 12.663C1.03533 12.4302 1.09693 12.2016 1.21335 12Z"
        stroke="#DE4141"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 6V8.66667" stroke="#DE4141" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 11.3333H8.00667" stroke="#DE4141" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export {
  BrandCoverageIcon,
  CheckIcon,
  DataBaseIcon,
  DiscoverPricingIcon,
  HowItWorksIcon,
  LGIcon,
  NextFeatureIcon,
  PocketIcon,
  PoleIcon,
  RightArrow,
  SheetIcon,
  Star,
  TriangleError,
};
