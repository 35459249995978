import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';

import { NotificationPageType } from '../../../common/constants';

import { getNotification } from '../../index';

const { notViewed } = NotificationPageType;

export const useNotificationMutate = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationKey: [QUERY_KEY.getNotification],
    mutationFn: async (currentTab: NotificationPageType) => {
      const { data } = await getNotification({ limit: 20, 'is-read': currentTab !== notViewed });

      return data;
    },
    onError: () => {
      dispatch(
        addToast({
          text: 'Something went wrong. Please try again.',
        }),
      );
    },
  });
};
