/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import TagManager from 'react-gtm-module';

export function useGoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-KKTZZ99',
    });
    const currentPath = location.pathname + location.search;
    ReactGA.set({ page: currentPath });
    ReactGA.send({ hitType: 'pageview', page: currentPath });
  }, []);
}
