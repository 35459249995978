import React from 'react';

import { useIsDesktop } from '../../../hooks';

import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { SkeletonBasic } from '../../../componentUI/skeleton';
import {
  AccountSettingsSkeletonStyled,
  ColumnMenuStyled,
  ColumnMenuTopBlockOneStyled,
  ColumnMenuTopStyled,
  FooterColumnMenuStyled,
  HeaderColumnMenuBlockOneStyled,
  HeaderColumnMenuBlockTwoStyled,
  HeaderColumnMenuStyled,
  HeaderMainContainerBlockOneStyled,
  HeaderMainContainerStyled,
  MainColumnMenuBlockOneStyled,
  MainColumnMenuStyled,
  MainContainerStyled,
  MainFooterContainerStyled,
  MainMainContainerBlockFourStyled,
  MainMainContainerBlockOneStyled,
  MainMainContainerBlockThreeStyled,
  MainMainContainerBlockTwoStyled,
  MainMainContainerStyled,
} from './styled';

export const AccountSettingsMainSkeleton = () => {
  const isDesktop = useIsDesktop();

  return (
    <AccountSettingsSkeletonStyled>
      {isDesktop && (
        <ColumnMenuStyled>
          <HeaderColumnMenuStyled>
            <SkeletonBasic width="40px" height="40px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
          </HeaderColumnMenuStyled>

          <MainColumnMenuStyled>
            <SkeletonBasic width="195px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            <SkeletonBasic width="221px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            <SkeletonBasic width="166px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />

            <MainColumnMenuBlockOneStyled>
              <SkeletonBasic width="212px" height="14px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
              <SkeletonBasic width="212px" height="14px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
              <SkeletonBasic width="212px" height="14px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            </MainColumnMenuBlockOneStyled>

            <SkeletonBasic width="195px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
          </MainColumnMenuStyled>

          <FooterColumnMenuStyled>
            <HeaderColumnMenuBlockOneStyled>
              <SkeletonBasic width="264px" height="43px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
              <SkeletonBasic width="264px" height="43px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
            </HeaderColumnMenuBlockOneStyled>

            <HeaderColumnMenuBlockTwoStyled>
              <SkeletonBasic width="206px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
              <SkeletonBasic width="24px" height="24px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            </HeaderColumnMenuBlockTwoStyled>

            <SkeletonBasic
              width="160px"
              height="16px"
              bgColor={COLOR_MAP.controls.skeleton.sidebar2}
              style={{ marginTop: '26px' }}
            />

            <SkeletonBasic
              width="250px"
              height="10px"
              bgColor={COLOR_MAP.controls.skeleton.sidebar2}
              style={{ marginTop: '40px' }}
            />
          </FooterColumnMenuStyled>
        </ColumnMenuStyled>
      )}

      {!isDesktop && (
        <ColumnMenuTopStyled>
          <SkeletonBasic width="40px" height="40px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />

          <ColumnMenuTopBlockOneStyled>
            <SkeletonBasic width="40px" height="40px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
            <SkeletonBasic width="28px" height="28px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
            <SkeletonBasic width="28px" height="28px" bgColor={COLOR_MAP.controls.skeleton.sidebar1} />
          </ColumnMenuTopBlockOneStyled>
        </ColumnMenuTopStyled>
      )}

      <MainContainerStyled>
        <HeaderMainContainerStyled>
          <HeaderMainContainerBlockOneStyled>
            <SkeletonBasic width="400px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content2} />

            <SkeletonBasic width="200px" height="36px" bgColor={COLOR_MAP.controls.skeleton.content2} />

            <SkeletonBasic width="900px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content2} />
          </HeaderMainContainerBlockOneStyled>
        </HeaderMainContainerStyled>

        <MainMainContainerStyled>
          <SkeletonBasic width="180px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />

          <MainMainContainerBlockOneStyled>
            <SkeletonBasic width="100px" height="100px" bgColor={COLOR_MAP.controls.skeleton.content1} />

            <MainMainContainerBlockThreeStyled>
              <SkeletonBasic width="200px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="200px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            </MainMainContainerBlockThreeStyled>
          </MainMainContainerBlockOneStyled>

          <MainMainContainerBlockTwoStyled>
            <SkeletonBasic width="420px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            <SkeletonBasic width="420px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            <SkeletonBasic width="420px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
          </MainMainContainerBlockTwoStyled>

          <MainMainContainerBlockFourStyled>
            <SkeletonBasic width="200px" height="36px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            <SkeletonBasic width="420px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
          </MainMainContainerBlockFourStyled>
        </MainMainContainerStyled>

        <MainFooterContainerStyled>
          <SkeletonBasic width="300px" height="36px" bgColor={COLOR_MAP.controls.skeleton.content1} />
        </MainFooterContainerStyled>
      </MainContainerStyled>
    </AccountSettingsSkeletonStyled>
  );
};
