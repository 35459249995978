import { useEffect, useRef } from 'react';

/** Сохраняет значение параметра до его обновления (хранит предыдущее состояние)  */

export const usePrevious = (value: string) => {
  const prevValueRef = useRef('');

  useEffect(() => {
    prevValueRef.current = value;
  }, [value]);

  return prevValueRef.current;
};
