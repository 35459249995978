import styled from 'styled-components';

import { truthfulAdaptive } from '../../../componentUI/screen';

import { COLOR_MAP } from '../../../componentUI/colorsMap';

const AppRouterSkeletonWrapperStyled = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 20% 1fr;

  ${truthfulAdaptive.maxWidth.desktopS} {
    grid-template-columns: 1fr;
  }
`;

const ColumnMenuStyled = styled.div`
  padding: 24px 28px;

  min-height: 100vh;
  display: flex;
  flex-direction: column;

  background-color: ${COLOR_MAP.background.darkGrey};
`;

const ColumnMenuTopStyled = styled.div`
  padding: 16px;

  display: flex;
  justify-content: space-between;

  background-color: ${COLOR_MAP.background.darkGrey};
`;

const ColumnMenuTopBlockOneStyled = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const HeaderColumnMenuStyled = styled.div`
  display: flex;
`;

const MainColumnMenuStyled = styled.div`
  flex: 1 1 auto;

  margin-top: 38px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FooterColumnMenuStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainColumnMenuBlockOneStyled = styled.div`
  margin-left: 28px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const HeaderColumnMenuBlockOneStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const HeaderColumnMenuBlockTwoStyled = styled.div`
  margin-top: 38px;

  display: flex;
  gap: 34px;
`;

const MainContainerStyled = styled.div`
  padding: 24px 40px;

  display: flex;
  flex-direction: column;

  background-color: ${COLOR_MAP.background.white};

  ${truthfulAdaptive.maxWidth.mobile} {
    padding: 16px;
  }
`;

const HeaderMainContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 55px;
`;

const HeaderMainContainerBlockOneStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const HeaderMainContainerBlockTwoStyled = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

const MainMainContainerStyled = styled.div`
  margin-top: 22px;

  display: grid;
  grid-template-columns: 7fr 3fr;

  grid-template-areas: 'mainMainContainerBlockOne mainMainContainerBlockTwo';

  gap: 20px;
  background-color: ${COLOR_MAP.background.white};

  ${truthfulAdaptive.maxWidth.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    grid-template-areas:
      'mainMainContainerBlockTwo'
      'mainMainContainerBlockOne';
  }
`;

const MainMainContainerBlockOneStyled = styled.div`
  grid-area: mainMainContainerBlockOne;

  padding: 32px;

  display: grid;
  grid-template-columns: 3fr 2fr;
  justify-content: space-between;
  align-items: center;
  gap: 50px;

  background-color: ${COLOR_MAP.controls.skeleton.content1};
`;

const MainMainContainerBlockTwoStyled = styled.div`
  grid-area: mainMainContainerBlockTwo;

  display: flex;
  flex-direction: column;
  gap: 16px;

  ${truthfulAdaptive.maxWidth.tablet} {
    flex-direction: row;
  }
`;

const MainMainContainerBlockThreeStyled = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
`;

const MainMainContainerBlockFourStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainMainContainerBlockFiveStyled = styled.div`
  display: flex;
  gap: 38px;
`;

const MainMainContainerBlockSixStyled = styled.div`
  margin-top: 42px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FooterMainContainerStyled = styled.div`
  width: 100%;
  margin-top: 39px;

  display: flex;
  flex-direction: column;
  background-color: ${COLOR_MAP.background.white};
`;

const FooterMainContainerBlockOneStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FooterMainContainerBlockTwoStyled = styled.div`
  margin-top: 33px;

  display: flex;
  justify-content: space-between;
  gap: 33px;
`;

const FooterMainContainerBlockThreeStyled = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const HeaderMainContainerMobileStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 29px;
`;

const MainMainContainerMobileStyled = styled.div`
  margin-top: 24px;

  display: flex;
  flex-direction: column;
`;

const MainMainContainerMobileBlockOneStyled = styled.div`
  display: flex;
  gap: 16px;
`;

const MainMainContainerMobileBlockTwoStyled = styled.div`
  margin-top: 18px;
  padding: 24px 32px;

  display: flex;
  flex-direction: column;
  gap: 60px;

  background-color: ${COLOR_MAP.controls.skeleton.content1};
`;

const MainMainContainerMobileBlockThreeStyled = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const MainMainContainerMobileBlockFourStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainMainContainerMobileBlockFiveStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const MainMainContainerMobileBlockSixStyled = styled.div`
  margin-top: 36px;

  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

const FooterMainContainerMobileStyled = styled.div`
  margin-top: 23px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FooterMainContainerMobileBlockOneStyled = styled.div`
  position: relative;

  padding: 18px;

  display: flex;
  flex-direction: column;
  gap: 35px;

  background-color: ${COLOR_MAP.controls.skeleton.content1};
`;

const FooterMainContainerMobileBlockTwoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export {
  AppRouterSkeletonWrapperStyled,
  ColumnMenuStyled,
  ColumnMenuTopBlockOneStyled,
  ColumnMenuTopStyled,
  FooterColumnMenuStyled,
  FooterMainContainerBlockOneStyled,
  FooterMainContainerBlockThreeStyled,
  FooterMainContainerBlockTwoStyled,
  FooterMainContainerMobileBlockOneStyled,
  FooterMainContainerMobileBlockTwoStyled,
  FooterMainContainerMobileStyled,
  FooterMainContainerStyled,
  HeaderColumnMenuBlockOneStyled,
  HeaderColumnMenuBlockTwoStyled,
  HeaderColumnMenuStyled,
  HeaderMainContainerBlockOneStyled,
  HeaderMainContainerBlockTwoStyled,
  HeaderMainContainerMobileStyled,
  HeaderMainContainerStyled,
  MainColumnMenuBlockOneStyled,
  MainColumnMenuStyled,
  MainContainerStyled,
  MainMainContainerBlockFiveStyled,
  MainMainContainerBlockFourStyled,
  MainMainContainerBlockOneStyled,
  MainMainContainerBlockSixStyled,
  MainMainContainerBlockThreeStyled,
  MainMainContainerBlockTwoStyled,
  MainMainContainerMobileBlockFiveStyled,
  MainMainContainerMobileBlockFourStyled,
  MainMainContainerMobileBlockOneStyled,
  MainMainContainerMobileBlockSixStyled,
  MainMainContainerMobileBlockThreeStyled,
  MainMainContainerMobileBlockTwoStyled,
  MainMainContainerMobileStyled,
  MainMainContainerStyled,
};
