/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './App.css';
import * as analytics from './analytics/analytics';
import { useGetLocale } from './api/auth/hooks';
import { AppRouter } from './components';
import './locales/i18n';

export const App = () => {
  const { i18n } = useTranslation();
  const { data: localeData, isSuccess: localeIsSuccess } = useGetLocale();

  useEffect(() => {
    analytics.init();
  }, []);

  useEffect(() => {
    if (localeIsSuccess && localeData) {
      i18n.changeLanguage(localeData);
    }
  }, [localeIsSuccess]);

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
};
