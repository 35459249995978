import styled, { CSSProperties } from 'styled-components';
import { LinkRouter } from '../../../../componentUI/simple/Link';

const TitleStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const TitleIsSidebarStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const WrapperSettingStyled = styled(LinkRouter)`
  display: flex;
  align-items: center;
  gap: 16px;

  cursor: pointer;
`;

const InfoUserStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AvatarUserStyled = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const stylesForTitle: CSSProperties = {
  width: '136px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const WrapperCloseCrossIconStyled = styled.div`
  cursor: pointer;
`;

export {
  AvatarUserStyled,
  InfoUserStyled,
  stylesForTitle,
  TitleIsSidebarStyled,
  TitleStyled,
  WrapperCloseCrossIconStyled,
  WrapperSettingStyled,
};
