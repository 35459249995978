import styled, { CSSProperties } from 'styled-components';
import { truthfulAdaptive } from '../../componentUI/screen';
import { TEXT_WEIGHT } from '../../componentUI/simple/Text';

const FooterStyled = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${truthfulAdaptive.maxWidth.tablet} {
    margin-top: 24px;
  }
`;

const stylesForButtons: CSSProperties = {
  maxWidth: '55px',
  border: 'none',
  fontWeight: TEXT_WEIGHT.normal,
  alignSelf: 'flex-end',
};

export { FooterStyled, stylesForButtons };
