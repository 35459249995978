import React, { MouseEventHandler } from 'react';
// import { ButtonV2 } from '../../../componentUI/simple/Button';

type Props = {
  onClick: MouseEventHandler<HTMLElement>;
  rightNavRef: React.RefObject<HTMLButtonElement>;
  disabled: boolean;
};

const RightNav = React.memo(({ disabled, onClick, rightNavRef }: Props) => {
  return (
    <button
      ref={rightNavRef}
      disabled={disabled}
      onClick={onClick}
      aria-label="Previous Slide"
      style={{ display: 'none' }}
    />
  );
});

RightNav.displayName = 'RightNav';

export { RightNav };
