import React from 'react';
import styled from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';
import { supportHover } from '../../screen';
import { BUTTON_SIZE, SIZE_STYLE } from './constants';
import { PropsButton } from './types';
import { commonStyles } from './utils';

const ButtonComponent = styled.button<PropsButton>`
  ${commonStyles};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px;

  border: none;
  border-radius: 6px;
  background-color: ${({ backgroundColor }) => backgroundColor};

  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.09), 0 1px 4px 0 rgba(0, 0, 0, 0.08);

  ${({ size }) => SIZE_STYLE[size]};
  font-family: Montserrat, sans-serif;
  color: ${({ colorsMap }) => (colorsMap ? colorsMap.clickable : `${COLOR_MAP.controls.clickableNormal}`)};

  user-select: none;

  & path {
    stroke: ${({ colorsMap }) => (colorsMap ? colorsMap.clickable : `${COLOR_MAP.controls.clickableNormal}`)};
  }

  ${supportHover} {
    &:hover {
      color: ${({ colorsMap }) => (colorsMap ? colorsMap.clickableHover : `${COLOR_MAP.controls.clickableHover}`)};
      & path {
        stroke: ${({ colorsMap }) => (colorsMap ? colorsMap.clickableHover : `${COLOR_MAP.controls.clickableHover}`)};
      }
    }
  }

  &:active {
    color: ${({ colorsMap }) => (colorsMap ? colorsMap.clickablePressed : `${COLOR_MAP.controls.clickablePressed}`)};
    & path {
      stroke: ${({ colorsMap }) => (colorsMap ? colorsMap.clickablePressed : `${COLOR_MAP.controls.clickablePressed}`)};
    }
  }

  &:disabled,
  &:disabled:active,
  &:disabled:hover {
    color: ${({ colorsMap }) => (colorsMap ? colorsMap.clickableDisabled : `${COLOR_MAP.controls.clickableDisabled}`)};

    cursor: not-allowed;
    & path {
      stroke: ${({ colorsMap }) =>
        colorsMap ? colorsMap.clickableDisabled : `${COLOR_MAP.controls.clickableDisabled}`};
    }
  }
`;

export const TransparentButtonWithShadow = ({
  backgroundColor = 'transparent',
  colorsMap = {
    clickable: `${COLOR_MAP.controls.clickableNormal}`,
    clickableHover: `${COLOR_MAP.controls.clickableHover}`,
    clickablePressed: `${COLOR_MAP.controls.clickablePressed}`,
    clickableDisabled: `${COLOR_MAP.controls.clickableDisabled}`,
  },
  size = BUTTON_SIZE.small,
  isStretch = false,
  disabled = false,
  children,
  ...props
}: Partial<PropsButton>) => {
  return (
    <ButtonComponent
      size={size}
      isStretch={isStretch}
      disabled={disabled}
      backgroundColor={backgroundColor}
      colorsMap={colorsMap}
      {...props}
    >
      {typeof children === 'string' ? children : children}
    </ButtonComponent>
  );
};
