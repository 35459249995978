import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { Text } from '../../../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../../../componentUI/simple/Title';
import { checkLogo } from '../../assets';
import { DescriptionListStyled, WrapperElementDescriptionStyled, WrapperTextStyled } from './styled';

export const DescriptionList = () => {
  const { t } = useTranslation();
  const content = t('auth.signUpPage.descriptionList.items', { returnObjects: true }) as Array<{
    title: string;
    text: string;
    id: number;
  }>;

  return (
    <DescriptionListStyled>
      {content.map(({ title, text, id }) => (
        <WrapperElementDescriptionStyled key={id}>
          <img src={checkLogo} width={28} height={32} alt="check logo" />
          <WrapperTextStyled>
            <Title size={TITLE_SIZE.h4} colorText={COLOR_MAP.text.white}>
              {title}
            </Title>

            <Text colorText={COLOR_MAP.text.white} style={{ opacity: '0.8' }}>
              {text}
            </Text>
          </WrapperTextStyled>
        </WrapperElementDescriptionStyled>
      ))}
    </DescriptionListStyled>
  );
};
