import { RANGE } from '../types';

const VALUE_RANGE: { [key: string]: number } = {
  last7: 7,
  last30: 30,
  last90: 90,
};

const { today, last7, last30, last90, custom } = RANGE;

type GetDateRangeType = {
  selectRange: string | undefined;
  fromDatePicker?: Date;
  untilDatePicker?: Date;
};

// "сбор" даты после выбора в дата пикере, т.к. используются разные форматы
// и автоматическая конвертация может дать некорректный день
const dateConversion = (data: Date) => {
  // добавление нуля впереди месяца и корректировка его номера
  const padMonth = (month: number) => {
    return month < 10 ? `0${month + 1}` : month + 1;
  };

  // добавление нуля впереди дня
  const padDay = (day: number) => {
    return day < 10 ? `0${day}` : day;
  };

  const year = data.getFullYear();
  const month = data.getMonth();
  const day = data.getDate();

  return `${year}-${padMonth(month)}-${padDay(day)}T00:00:00.000Z`;
};

const generateRangeDate = () => {
  const start = new Date();
  start.setUTCHours(0, 0, 0, 0);
  const fromDate = start.toISOString();

  const end = new Date();
  end.setUTCHours(0, 0, 0, 0);
  const untilDate = end.toISOString();

  return {
    fromDate,
    untilDate,
  };
};

const generateFixedRangeDate = (range: string) => {
  const start = new Date();
  start.setUTCHours(0, 0, 0, 0);
  const untilDate = start.toISOString();

  const end = new Date();
  end.setUTCHours(0, 0, 0, 0);
  end.setDate(end.getDate() - Number(VALUE_RANGE[range]));
  const fromDate = end.toISOString();

  return {
    fromDate,
    untilDate,
  };
};

const generateCustomRangeDate = (fromPicker: Date | undefined, untilPicker: Date | undefined) => {
  if (fromPicker && !untilPicker) {
    const fromDate = dateConversion(fromPicker);

    return {
      fromDate,
      untilDate: '',
    };
  }

  if (untilPicker && !fromPicker) {
    const untilDate = dateConversion(untilPicker);

    return {
      fromDate: '',
      untilDate,
    };
  }

  if (fromPicker && untilPicker) {
    const fromDate = dateConversion(fromPicker);
    const untilDate = dateConversion(untilPicker);

    return {
      fromDate,
      untilDate,
    };
  }

  return {
    fromDate: '',
    untilDate: '',
  };
};

export const getDateRange = ({ selectRange, fromDatePicker, untilDatePicker }: GetDateRangeType) => {
  switch (selectRange) {
    case today:
      return generateRangeDate();

    case last7:
    case last30:
    case last90:
      return generateFixedRangeDate(selectRange);

    case custom:
      return generateCustomRangeDate(fromDatePicker, untilDatePicker);
    default:
      return {
        fromDate: '',
        untilDate: '',
      };
  }
};
