import Cookies from 'js-cookie';
import { instance } from '../axios-instance';

import { UpdateProductResponseType } from '../product/type';
import {
  ChangeEmailRequestType,
  ConfirmEmailRequestType,
  ConfirmEmailResponseType,
  ForgotEmailRequestType,
  ForgotPasswordRequestType,
  GetAuthType,
  getCheckAuthRequestType,
  getCheckAuthResponseType,
  GetLocaleResponseType,
  getResendDigitCodeRequestType,
  GoogleAuthRequestType,
  LoginRequestType,
  LoginResponseType,
  RefreshTokenRequestRequestType,
  RefreshTokenRequestResponseType,
  RegisterRequestType,
  RegisterResponseType,
  UpdateLocaleRequestType,
  UpdateLocaleResponseType,
  UpdateMeRequestType,
  UpdateMeResponseType,
} from './authType';

// @ts-ignore
export const confirmRegister = (data) => {
  return instance.post('auth/transfer-bundles/register', data);
};

export const register = (data: RegisterRequestType) => {
  return instance.post<RegisterResponseType>('auth/email/register', data);
};

export const login = (data: LoginRequestType) => {
  return instance.post<LoginResponseType>('auth/email/login', data);
};

export const getAuth = (data: string | undefined) => {
  return instance.get<GetAuthType>('auth/me', {
    headers: { Authorization: `Bearer ${data}` },
  });
};

export const updateMe = (params: Partial<UpdateMeRequestType>) => {
  return instance.patch<UpdateMeResponseType>('auth/me', params, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const removeMe = () => {
  return instance.delete('auth/me', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const forgotEmail = (data: ForgotEmailRequestType) => {
  return instance.post<{}>('auth/forgot/password', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const forgotPassword = (data: ForgotPasswordRequestType) => {
  return instance.post<{}>('auth/reset/password', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const confirmEmail = (data: ConfirmEmailRequestType) => {
  return instance.post<ConfirmEmailResponseType>('auth/email/confirm', data);
};

export const getAuthGooglePost = (data: GoogleAuthRequestType) => {
  return instance.post('auth/google/oauth-link-create', data);
};

export const getAuthGoogle = async (cancelUrl: string) => {
  return instance.get('https://test.test.com/api/auth/google/oauth-link-create', {
    params: { cancelUrl },
  });
};

export const refreshTokenRequest = (refreshToken: RefreshTokenRequestRequestType) => {
  return instance.post<RefreshTokenRequestResponseType>(
    'auth/refresh',
    {},
    { headers: { Authorization: `Bearer ${refreshToken}` } },
  );
};

export const getCheckAuth = (jwtToken: getCheckAuthRequestType) => {
  return instance.get<getCheckAuthResponseType>('auth/check', {
    headers: { Authorization: `Bearer ${jwtToken}` },
  });
};

export const changeEmail = (newEmail: ChangeEmailRequestType) => {
  return instance.patch<{}>('auth/me/change-email', newEmail, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getResendDigitCode = (email: getResendDigitCodeRequestType) => {
  return instance.post('/auth/email/resend', email);
};

// Получить язык сайта который пользователь выбрал ранее
export const getLocale = () => {
  return instance.get<GetLocaleResponseType>('auth/locale', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

// Изменить язык сайта, который пользователь выбрал на сайте
export const updateLocale = (data: UpdateLocaleRequestType) => {
  return instance.patch<UpdateLocaleResponseType>('auth/update', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

/**
 * Turn off user profile info value for show completion-registration again. !!!Works only for test@test.test user!!!
 *
 * @returns  {Promise<axios.AxiosResponse<T>} An object containing promise with response.
 *
 */
export const offFullProfileInfo = (id: string) => {
  return instance.patch<UpdateProductResponseType>(
    `/users/${id}`,
    { isProfileFull: false },
    {
      headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
    },
  );
};
