import styled from 'styled-components';

import { truthfulAdaptive } from '../../../../componentUI/screen';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';

const TopUpMainSkeletonStyled = styled.div`
  max-width: 1600px;
  width: 100%;

  display: grid;
  grid-template-columns: 20% 1fr;

  ${truthfulAdaptive.maxWidth.desktopS} {
    grid-template-columns: 1fr;
  }
`;

const ColumnMenuStyled = styled.div`
  padding: 24px 28px;

  min-height: 100vh;
  display: flex;
  flex-direction: column;

  background-color: ${COLOR_MAP.background.darkGrey};
`;

const ColumnMenuTopStyled = styled.div`
  padding: 16px;

  display: flex;
  justify-content: space-between;

  background-color: ${COLOR_MAP.background.darkGrey};
`;

const ColumnMenuTopBlockOneStyled = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const HeaderColumnMenuStyled = styled.div`
  display: flex;
`;

const MainColumnMenuStyled = styled.div`
  flex: 1 1 auto;

  margin-top: 38px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FooterColumnMenuStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainColumnMenuBlockOneStyled = styled.div`
  margin-left: 28px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const HeaderColumnMenuBlockOneStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const HeaderColumnMenuBlockTwoStyled = styled.div`
  margin-top: 38px;

  display: flex;
  gap: 34px;
`;

const MainContainerStyled = styled.div`
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
`;

const WrapperContainerColumn = styled.div`
  margin-top: 36px;
  min-height: 85vh;

  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  background-color: ${COLOR_MAP.background.white};

  ${truthfulAdaptive.maxWidth.tablet} {
    grid-template-columns: 1fr;
  }
`;

const MainContainerColumnOneStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainContainerColumnTwoStyled = styled.div`
  padding: 0 20px;

  display: grid;
  align-content: space-between;

  gap: 28px;

  border-left: 1px solid ${COLOR_MAP.controls.skeleton.content1};
`;

const MainContainerColumnThreeStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const MainContainerColumnFourStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const HeaderMainContainerStyled = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 55px;
`;

const HeaderMainContainerBlockOneStyled = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
`;

const HeaderMainContainerBlockTwoStyled = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

const HeaderMainContainerMobileStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 29px;
`;

const MainMainContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;

  ${truthfulAdaptive.maxWidth.tablet} {
    gap: 24px;
  }
`;

const MainMainContainerBlockOneStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const MainMainContainerBlockTwoStyled = styled.div`
  padding: 10px;

  height: 90px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  border-radius: 6px;
  background-color: ${COLOR_MAP.controls.skeleton.content1};
`;

const WrapperBackgroundV1Styled = styled.div`
  padding: 10px;

  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 4px;

  border-radius: 6px;
  background-color: ${COLOR_MAP.controls.skeleton.content1};
`;

const WrapperBackgroundV2Styled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const WrapperBackgroundV3Styled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const SummaryInformationSkeletonStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SummaryInformationSkeletonBackgroundV1Styled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const EqualsSkeletonSkeletonStyled = styled.div`
  padding: 10px;

  display: flex;
  justify-content: space-between;
  gap: 30px;

  border-radius: 6px;
  background-color: ${COLOR_MAP.controls.skeleton.content1};

  ${truthfulAdaptive.maxWidth.mobile} {
    flex-direction: column;
    justify-content: center;
  }
`;

const BonusSkeletonStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export {
  BonusSkeletonStyled,
  ColumnMenuStyled,
  ColumnMenuTopBlockOneStyled,
  ColumnMenuTopStyled,
  EqualsSkeletonSkeletonStyled,
  FooterColumnMenuStyled,
  HeaderColumnMenuBlockOneStyled,
  HeaderColumnMenuBlockTwoStyled,
  HeaderColumnMenuStyled,
  HeaderMainContainerBlockOneStyled,
  HeaderMainContainerBlockTwoStyled,
  HeaderMainContainerMobileStyled,
  HeaderMainContainerStyled,
  MainColumnMenuBlockOneStyled,
  MainColumnMenuStyled,
  MainContainerColumnFourStyled,
  MainContainerColumnOneStyled,
  MainContainerColumnThreeStyled,
  MainContainerColumnTwoStyled,
  MainContainerStyled,
  MainMainContainerBlockOneStyled,
  MainMainContainerBlockTwoStyled,
  MainMainContainerStyled,
  SummaryInformationSkeletonBackgroundV1Styled,
  SummaryInformationSkeletonStyled,
  TopUpMainSkeletonStyled,
  WrapperBackgroundV1Styled,
  WrapperBackgroundV2Styled,
  WrapperBackgroundV3Styled,
  WrapperContainerColumn,
};
