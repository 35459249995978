import styled, { CSSProperties } from 'styled-components';
import { truthfulAdaptive } from '../../../componentUI/screen';

import { COLOR_MAP } from '../../../componentUI/colorsMap';

const ConvertedBalanceBannerStyled = styled.div`
  overflow: hidden;
  position: relative;
  padding: 10px 20px;

  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  align-items: center;

  background: linear-gradient(45deg, #06be9d 0%, #0b8da9 55.73%, #6a0aa5 72.89%, #db0f71 87.84%, #ef7505 100%);
`;

const WrapperCreditIconStyled = styled.div`
  ${truthfulAdaptive.maxWidth.mobile} {
    align-self: start;
  }
`;

const WrapperTextBlockStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 16px;
`;

const stylesForLink: CSSProperties = {
  borderBottom: `1px dashed ${COLOR_MAP.text.white}`,
  whiteSpace: 'nowrap',
  display: 'inline-block',
  cursor: 'pointer',
};

const WrapperDotsIconStyled = styled.div`
  position: absolute;
  top: -100px;
  right: -100px;

  pointer-events: none;

  ${truthfulAdaptive.maxWidth.mobile} {
    top: -35px;
    right: -180px;
  }
`;

const stylesForModal: CSSProperties = {
  maxWidth: '600px',
};

const ContainerText = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export {
  ContainerText,
  ConvertedBalanceBannerStyled,
  WrapperCreditIconStyled,
  WrapperDotsIconStyled,
  WrapperTextBlockStyled,
  stylesForLink,
  stylesForModal,
};
