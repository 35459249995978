import React from 'react';
import styled from 'styled-components';

import { COLOR_MAP } from '../../colorsMap';
import { supportHover, truthfulAdaptive } from '../../screen';
import { darkenHex } from '../../utils';
import { HrVertical } from '../HrVertical';
import { Text } from '../Text';
import { PropsLinkButtonShowHide } from './types';

const darkLightBlue = darkenHex(COLOR_MAP.accent.lightBlue, 0.2);
const darkenLightBlue = darkenHex(darkLightBlue, 0.2);

const LinkComponent = styled.a`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  gap: 10px;

  border: 2px solid ${COLOR_MAP.accent.lightBlue};
  border-radius: 6px;

  user-select: none;
  background-color: transparent;

  text-align: center;
  text-decoration: none;

  cursor: pointer;

  ${truthfulAdaptive.maxWidth.mobile} {
    padding: 12px;

    flex-direction: column;
    align-items: flex-start;

    text-align: left;
  }

  ${supportHover} {
    &:hover {
      border: 2px solid ${darkLightBlue};
    }
  }

  &:active {
    border: 2px solid ${darkenLightBlue};
  }

  &:disabled,
  &:disabled:active,
  &:disabled:hover {
    border: 2px solid ${COLOR_MAP.controls.clickableDisabled};

    cursor: not-allowed;
  }
`;

const IconWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(24px, auto) minmax(96px, auto);
  align-items: center;
  gap: 5px;

  & path {
    stroke: ${COLOR_MAP.accent.lightBlue};
  }
`;

export const LinkSecondButtonShowHide = ({ iconText, text, children, ...props }: Partial<PropsLinkButtonShowHide>) => {
  return (
    <LinkComponent {...props}>
      <IconWrapper>
        {children}
        <Text colorText={COLOR_MAP.accent.lightBlue}>{iconText}</Text>
      </IconWrapper>

      <HrVertical />

      <Text style={{ textAlign: 'left' }}>{text}</Text>
    </LinkComponent>
  );
};
