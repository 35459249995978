import React from 'react';

import styled from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';
import { supportHover } from '../../screen';
import { BUTTON_SIZE, SIZE_STYLE } from './constants';
import { PropsButton } from './types';
import { buttonTextStyle, commonStyles } from './utils';

const ButtonComponent = styled.button<PropsButton>`
  ${commonStyles};
  ${buttonTextStyle};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px;

  border: 2px solid ${({ colorsMap }) => colorsMap?.clickable};
  border-radius: 2px;
  user-select: none;
  background-color: ${({ colorsMap }) => colorsMap?.clickable};

  color: ${({ colorText }) => colorText};
  ${({ size }) => SIZE_STYLE[size]};

  & path {
    stroke: ${({ colorIcon }) => colorIcon};
  }

  ${supportHover} {
    &:hover {
      background-color: ${({ colorsMap }) => colorsMap?.clickableHover};
      border: 2px solid ${({ colorsMap }) => colorsMap?.clickableHover};
    }
  }

  &:active {
    background-color: ${({ colorsMap }) => colorsMap?.clickablePressed};
    border: 2px solid ${({ colorsMap }) => colorsMap?.clickablePressed};
  }

  &:disabled,
  &:disabled:active,
  &:disabled:hover {
    background-color: ${({ colorsMap }) => colorsMap?.clickableDisabled};
    border: 2px solid ${({ colorsMap }) => colorsMap?.clickableDisabled};

    cursor: not-allowed;
  }
`;

const DEFAULT_COLOR_MAP = {
  clickable: COLOR_MAP.controls.clickableNormal,
  clickableHover: COLOR_MAP.controls.clickableHover,
  clickablePressed: COLOR_MAP.controls.clickablePressed,
  clickableDisabled: COLOR_MAP.controls.clickableDisabled,
};

export const Button = ({
  size = BUTTON_SIZE.small,
  colorIcon = COLOR_MAP.controls.textAndIcon,
  colorText = COLOR_MAP.controls.textAndIcon,
  isStretch = false,
  disabled = false,
  children,
  colorsMap = DEFAULT_COLOR_MAP,
  ...props
}: Partial<PropsButton>) => {
  return (
    <ButtonComponent
      size={size}
      colorIcon={colorIcon}
      colorText={colorText}
      isStretch={isStretch}
      disabled={disabled}
      colorsMap={colorsMap}
      {...props}
    >
      {typeof children === 'string' ? children : children}
    </ButtonComponent>
  );
};
