import React, { Dispatch, SetStateAction } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { CopyIcon } from '../common/icons/SvgInline/SvgIcons_11';
import { COLOR_MAP } from '../componentUI/colorsMap';
import { Text, TEXT_SIZE } from '../componentUI/simple/Text';

export const NumberOrderStyled = styled.div<{ isBigSize: boolean }>`
  padding: 0 6px 0 0;

  height: 100%;
  width: 100%;

  display: ${({ isBigSize }) => (isBigSize ? 'flex' : 'grid')};
  grid-template-columns: 1fr auto;
  gap: ${({ isBigSize }) => (isBigSize ? '12px' : '10px')};
  align-items: center;
`;

export const CopyPasteIconWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const stylesForText: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

/**
 * формирует блок с номером заказа и функцией копирования
 * данного номер в буфер обмена
 *
 * isBigSize - флаг, выставляет размер  шрифта и миниатюры копирования
 */
export const getNumberOrder = (
  numberOrder: string | null,
  isBigSize = false,
  getIsCopyTextBuffer: Dispatch<SetStateAction<boolean>>,
  isFinGuardActive = false,
) => {
  if (numberOrder) {
    return (
      <NumberOrderStyled isBigSize={isBigSize}>
        <Text
          style={stylesForText}
          colorText={isFinGuardActive ? COLOR_MAP.text.green : COLOR_MAP.text.brandColor}
          size={isBigSize ? TEXT_SIZE.regular : TEXT_SIZE.sub}
        >
          {numberOrder}
        </Text>

        <CopyPasteIconWrapperStyled
          onClick={(event) => {
            event.stopPropagation();
            navigator.clipboard.writeText(numberOrder);
            getIsCopyTextBuffer(true);
          }}
        >
          <CopyIcon
            size={isBigSize ? '24px' : '20px'}
            color={isFinGuardActive ? COLOR_MAP.text.green : COLOR_MAP.text.brandColor}
          />
        </CopyPasteIconWrapperStyled>
      </NumberOrderStyled>
    );
  }

  return null;
};
