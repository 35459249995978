import styled, { CSSProperties } from 'styled-components';

import { TertiaryButton } from '../../../../componentUI/simple/Button';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TEXT_WEIGHT } from '../../../../componentUI/simple/Text';

const NotificationMessageWrapper = styled.div`
  padding: 8px 0px;

  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'checkIcon message button';

  border-top: 1px solid ${COLOR_MAP.accent.lightGrey};

  background: transparent;

  // перестроение разметки согласно макета figma, BP - нет, добавлять не имеет смысла
  @media (max-width: 380px) {
    grid-template-columns: 1fr 30px;
    grid-template-rows: 1fr auto;

    grid-template-areas:
      'message   checkIcon'
      'button   checkIcon';

    column-gap: 10px;
    row-gap: 6px;
    justify-items: start;
  }
`;

const CheckIconWrapper = styled.div`
  grid-area: checkIcon;

  margin-right: 12px;
  padding: 0 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  background-color: ${COLOR_MAP.background.SnowWhite};

  border-radius: 2px;
`;

const MessageInformationWrapper = styled.div`
  grid-area: message;

  display: flex;
  flex-direction: column;
  gap: 2px;
`;

// убираю паддинг у кнопки что бы прижалась к левому краю, согласно макета
const TertiaryButtonStyled = styled(TertiaryButton)`
  @media (max-width: 380px) {
    padding-left: 0;
  }
`;

const stylesForButton: CSSProperties = {
  gridArea: 'button',

  backgroundColor: 'transparent',
  whiteSpace: 'nowrap',
  textTransform: 'uppercase',
  fontWeight: TEXT_WEIGHT.bold,
};

export {
  NotificationMessageWrapper,
  CheckIconWrapper,
  MessageInformationWrapper,
  TertiaryButtonStyled,
  stylesForButton,
};
