import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthPageType } from '../../../../common/constants';
import { TAG_NAME } from '../../../../componentUI/constants';
import { Title, TITLE_SIZE } from '../../../../componentUI/simple/Title';
import { useMatchMedia } from '../../../../hooks';
import { PRESET_VALUES } from './locale';
import { NoRecordsFoundStyled, WrapperNoRecordsFoundStyled } from './styled';
import { Props } from './types';
import { kebabToCamelCase } from '../../../../helpers';

// обозначение страниц на которых встречается элемент AuthenticationsTablet
const { dashboard } = AuthPageType;

export const NoRecordsFound = ({ currentPage }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();

  // получаем информацию находиться ли пользователь на странице dashboard
  const isDashboard = currentPage === dashboard;

  if (!currentPage) {
    return null;
  }

  // не показываем этот блок на данных страницах
  if (isDashboard) {
    return null;
  }

  return (
    <NoRecordsFoundStyled>
      <WrapperNoRecordsFoundStyled>
        <img
          src={PRESET_VALUES[currentPage].picture}
          alt={t('authenticationsTablet.notFound')}
          width={isMobile ? 180 : 225}
          height={isMobile ? 160 : 200}
        />
        <Title
          tag={isMobile ? TAG_NAME.h4 : TAG_NAME.h3}
          size={isMobile ? TITLE_SIZE.h4 : TITLE_SIZE.h3}
          style={{ textAlign: 'center' }}
        >
          {t(`authenticationsTablet.noRecordsFound.${kebabToCamelCase(currentPage)}`)}
        </Title>
      </WrapperNoRecordsFoundStyled>
    </NoRecordsFoundStyled>
  );
};
