import styled, { CSSProperties } from 'styled-components';
import { COLOR_MAP } from '../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../componentUI/screen';
import { TEXT_WEIGHT } from '../../componentUI/simple/Text';

export const WrapperInstructionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PhotosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ButtonContainer = styled.div`
  position: relative;
  width: calc(100% + 80px); // убираем поля и растягиваем на всю ширину блока
  right: 40px; // убираем поля и растягиваем на всю ширину блока

  padding: 40px 0 0 0;

  display: flex;
  justify-content: flex-end;

  border-top: 1px solid ${COLOR_MAP.accent.lightGrey};

  ${truthfulAdaptive.maxWidth.tabletNew} {
    position: static;
    width: 100%;
    right: 0;

    padding: 16px 0 0 0;
    border-top: none;
  }
`;

export const stylesForButtons: CSSProperties = {
  textTransform: 'uppercase',
  fontWeight: TEXT_WEIGHT.bold,
  right: '40px',
  position: 'relative',
};
