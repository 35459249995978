type CountryType = {
  value: string;
  label: string;
  postalRegex: string;
  isEU: boolean | null;
};

type StateType = {
  value: string;
  label: string;
};

type FormDataType = {
  firstName: string;
  lastName: string;
  country: CountryType | null;
  typeBusiness: string;
  companyName: string;
  addressOne: string;
  addressTwo: string;
  city: string;
  postalCode: string;
  stateOrProvince: string;
  vatNumber: string;
  countryStateId?: string;
};

enum AccountTypeEnum {
  personal = 'Personal',
  business = 'Business',
}

enum StepNumberEnum {
  step1 = 'step1',
  step2 = 'step2',
}

export { AccountTypeEnum, StepNumberEnum };
export type { CountryType, FormDataType, StateType };
