import React from 'react';
import { components, SingleValueProps } from 'react-select';
import styled from 'styled-components';

import { useMatchMedia } from '../../../hooks';

import { ModelImageIcon, NotSureModelImageIcon, OtherModelImageIcon } from '../../../common/icons/SvgInline/SvgIcons_4';
import { Text } from '../../../componentUI/simple/Text';
import { SelectedModelsType } from '../types';

export const StyledOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 8px;
`;

const isUrl = (jpegUrl: string | null) => {
  if (jpegUrl !== 'Other' && jpegUrl !== 'Not Sure' && jpegUrl !== null) {
    return true;
  }
  return false;
};

export const CustomSingleValue = ({ data, innerProps, ...props }: SingleValueProps<SelectedModelsType>) => {
  const { isMobile } = useMatchMedia();

  if (data.jpegUrl === 'offCursor') {
    return null;
  }

  return (
    <components.SingleValue {...props} innerProps={innerProps} data={data}>
      <StyledOptionWrapper>
        {data.jpegUrl === 'Other' && <OtherModelImageIcon size={isMobile ? '44' : '80'} />}
        {data.jpegUrl === 'Not Sure' && <NotSureModelImageIcon size={isMobile ? '44' : '80'} />}
        {data.jpegUrl === null && <ModelImageIcon size={isMobile ? '44' : '80'} />}
        {isUrl(data.jpegUrl) && (
          <img src={data.jpegUrl || ''} alt={data.name} width={isMobile ? 44 : 80} height={isMobile ? 44 : 80} />
        )}
        <Text>{data.name}</Text>
      </StyledOptionWrapper>
    </components.SingleValue>
  );
};
