import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';

import { addToast } from '../../../redux/reducers/toast-reducer';

import { getFreeCustomCertificates } from '../index';

type ErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

export const useFreeCustomTemplate = () => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [QUERY_KEY.getFreeCustomTemplate],
    queryFn: async () => {
      const { data } = await getFreeCustomCertificates();

      return data;
    },
    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};
