import styled from 'styled-components';
import { COLOR_MAP } from '../../componentUI/colorsMap';

export const Container = styled.div`
  min-width: 100%;
  min-height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${COLOR_MAP.background.darkGrey};
`;

export const DescriptionScreen = styled.div`
  min-height: 100vh;
  padding: 40px 20px 20px 20px;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 50px 0;
`;
