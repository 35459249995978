import styled from 'styled-components';

import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../../componentUI/screen';

const ContainerStyled = styled.div`
  padding: 40px 20px 16px 20px;

  min-height: 100vh;
  width: 100%;

  display: grid;
  grid-template-rows: auto 1fr auto;
  justify-items: center;
  gap: 28px;

  background-color: ${COLOR_MAP.background.darkGrey};

  ${truthfulAdaptive.maxWidth.mobile} {
    padding: 24px 20px 16px 20px;
  }
`;

const WrapperMainStyled = styled.div`
  align-self: center;

  width: 100%;
  max-width: 740px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  ${truthfulAdaptive.maxWidth.mobile} {
    align-self: start;
  }
`;

const WrapperTextStyled = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 20px;
`;

const SeparatorStyled = styled.div`
  max-width: 80px;
  width: 100%;
  height: 1px;

  background: ${COLOR_MAP.controls.placeholder};
`;

const WrapperButtonStyled = styled.div`
  max-width: 280px;
  width: 100%;
`;

export { ContainerStyled, SeparatorStyled, WrapperButtonStyled, WrapperMainStyled, WrapperTextStyled };
