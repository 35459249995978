import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';

import { addToast } from '../../../redux/reducers/toast-reducer';

import { getUserSubscriptions } from '../subscription-api';

type ErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

export const useGetUserSubscriptions = (isRefetchInterval = true) => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [QUERY_KEY.getUserSubscriptions],
    refetchInterval: isRefetchInterval ? 30000 : false,
    refetchIntervalInBackground: false,
    queryFn: async () => {
      const { data } = await getUserSubscriptions();

      return data;
    },
    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};
