/* eslint-disable   */
import React from 'react';

import { Picture } from '../../../../../../componentUI/complex/Picture';
import { useGoBack, useIsMobile } from '../../../../../../hooks';

import { COLOR_MAP } from '../../../../../../componentUI/colorsMap';
import { Text, TEXT_WEIGHT } from '../../../../../../componentUI/simple/Text';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../../../../../../componentUI/simple/Title';

import { ArrowLeftIcon } from '../../../../../../common/icons/SvgInline/SvgIcons_2';
import { LogoMain } from '../../../../../../common/images';

import { TertiaryButton } from '../../../../../../componentUI/simple/Button';
import { LinkClassic } from '../../../../../../componentUI/simple/Link';

import { useGoogleAnalytics } from '../../../../../../analytics';
import {
  ClauseWrapper,
  ConditionsWrapper,
  LastUpdateBlock,
  MobileNavigationBlockWrapper,
  NavigationBlock,
  NavigationBlockWrapper,
  ParagraphWrapper45,
  PassageWrapper10,
  PassageWrapper20,
  stylesForBlockLink,
  stylesForButton,
  TextAgreementBlock,
} from './styled';

export const ConditionsSubscription = () => {
  useGoogleAnalytics();
  const isMobile = useIsMobile();
  const goBack = useGoBack();

  return (
    <ConditionsWrapper>
      {isMobile && (
        <MobileNavigationBlockWrapper>
          <TertiaryButton style={stylesForButton} onClick={goBack}>
            <ArrowLeftIcon size="20" />
            Go back
          </TertiaryButton>
          <Picture url={LogoMain} width={48} height={48} />
        </MobileNavigationBlockWrapper>
      )}

      {!isMobile && (
        <NavigationBlockWrapper>
          <NavigationBlock>
            <Picture url={LogoMain} width={48} height={48} />

            <TertiaryButton style={stylesForButton} onClick={goBack}>
              <ArrowLeftIcon size="20" />
              Go back
            </TertiaryButton>

            <LinkClassic href="#1" style={stylesForBlockLink}>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                1.
              </Text>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                Subscription Options and Payment
              </Text>
            </LinkClassic>

            <LinkClassic href="#2" style={stylesForBlockLink}>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                2.
              </Text>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                Additional Clause: 14-day Trial Period
              </Text>
            </LinkClassic>

            <LinkClassic href="#3" style={stylesForBlockLink}>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                3.
              </Text>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                Auto-Renewal and Billing
              </Text>
            </LinkClassic>

            <LinkClassic href="#4" style={stylesForBlockLink}>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                4.
              </Text>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                Deadline for Payments
              </Text>
            </LinkClassic>

            <LinkClassic href="#5" style={stylesForBlockLink}>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                5.
              </Text>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                Refund/Cancellation Policy
              </Text>
            </LinkClassic>

            <LinkClassic href="#6" style={stylesForBlockLink}>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                6.
              </Text>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                Data Protection
              </Text>
            </LinkClassic>

            <LinkClassic href="#7" style={stylesForBlockLink}>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                7.
              </Text>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                Service Availability
              </Text>
            </LinkClassic>

            <LinkClassic href="#8" style={stylesForBlockLink}>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                8.
              </Text>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                Service Usage
              </Text>
            </LinkClassic>

            <LinkClassic href="#9" style={stylesForBlockLink}>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                9.
              </Text>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                Subscription Upgrade/Downgrade
              </Text>
            </LinkClassic>

            <LinkClassic href="#10" style={stylesForBlockLink}>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                10.
              </Text>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                Third-Party Involvement
              </Text>
            </LinkClassic>

            <LinkClassic href="#11" style={stylesForBlockLink}>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                11.
              </Text>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                Legal Jurisdiction
              </Text>
            </LinkClassic>

            <LinkClassic href="#12" style={stylesForBlockLink}>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                12.
              </Text>
              <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.grey}>
                Amendments and Updates
              </Text>
            </LinkClassic>
          </NavigationBlock>
        </NavigationBlockWrapper>
      )}

      <TextAgreementBlock>
        <Title size={isMobile ? TITLE_SIZE.h2 : TITLE_SIZE.h1} style={{ textAlign: 'center' }}>
          Terms & Conditions of LegitGrails+
        </Title>

        <LastUpdateBlock>
          <Title colorText={COLOR_MAP.accent.green} size={TITLE_SIZE.h6}>
            LAST UPDATED: July 12, 2023
          </Title>
        </LastUpdateBlock>

        <Title margins={[48, 0, 0, 0]} weight={TITLE_WEIGHT.normal}>
          Terms and Conditions for LG+ Subscription:
        </Title>

        <Text margins={[12, 0, 0, 0]}>
          Welcome to LegitGrails Plus (LG+)! Before you subscribe to our services, please take a moment to read and
          understand the following terms and conditions. By subscribing to LG+, you agree to be bound by these terms and
          conditions.
        </Text>

        <Title id="1" margins={[50, 0, 0, 0]}>
          1.&nbsp;&nbsp;&nbsp;SUBSCRIPTION OPTIONS AND PAYMENT
        </Title>

        <Text margins={[12, 0, 0, 0]}>
          LG+ offers three subscription plans: the Plus Plan ($99/month), the Advanced Plan ($399/month), and the
          Enterprise Plan (customized technical and business solutions). The subscription fee is payable on a monthly
          basis. We accept various payment methods, including credit cards and online payment platforms. Payments are
          processed by Stripe, a secure and reliable payment gateway.
        </Text>

        <Title id="2" margins={[50, 0, 0, 0]}>
          2.&nbsp;&nbsp;&nbsp;ADDITIONAL CLAUSE: 14-DAY TRIAL PERIOD
        </Title>

        <Text margins={[12, 0, 0, 0]}>
          During the 14-day trial period, please note that the discounts on purchases associated with the LG+
          subscription will not be active. The discounts will only become active once you have transitioned to a paid
          subscription after the trial period ends.
        </Text>

        <ClauseWrapper>
          <PassageWrapper20>
            <Text weight={TEXT_WEIGHT.bold}>2.1</Text>
            <Text weight={TEXT_WEIGHT.bold}>Discount Activation:</Text>
          </PassageWrapper20>
          <ParagraphWrapper45>
            <PassageWrapper10>
              <div>&bull;</div>
              <Text>
                Discounts on Hype, Luxury, and Flexible credit purchases, as well as any other eligible discounts, will
                be activated only after the start of your paid LG+ subscription.
              </Text>
            </PassageWrapper10>
            <PassageWrapper10>
              <div>&bull;</div>
              <Text>
                The discounts will be applicable according to the terms and conditions specified for each subscription
                plan.
              </Text>
            </PassageWrapper10>
            <PassageWrapper10>
              <div>&bull;</div>
              <Text>
                Please refer to the main terms and conditions for details on the specific discount percentages
                associated with each LG+ subscription plan.
              </Text>
            </PassageWrapper10>
          </ParagraphWrapper45>

          <PassageWrapper20>
            <Text weight={TEXT_WEIGHT.bold}>2.2</Text>
            <Text weight={TEXT_WEIGHT.bold}>Trial Period Benefits:</Text>
          </PassageWrapper20>
          <ParagraphWrapper45>
            <PassageWrapper10>
              <div>&bull;</div>
              <Text>
                While discounts on purchases are not active during the trial period, you will still have access to all
                other LG+ features and benefits.
              </Text>
            </PassageWrapper10>
            <PassageWrapper10>
              <div>&bull;</div>
              <Text>
                This includes the ability to create customizable certificates, become a LegitGrails Verified Partner,
                access educational courses, and utilize other relevant features offered within LG+.
              </Text>
            </PassageWrapper10>
          </ParagraphWrapper45>
        </ClauseWrapper>

        <Text margins={[12, 0, 0, 0]}>
          Please be aware that the trial period is primarily intended for exploring the features and benefits of LG+,
          and any discounts associated with the subscription will be activated upon the start of the paid subscription.
        </Text>

        <Text margins={[12, 0, 0, 0]}>
          If you have any further questions or concerns regarding the discount activation during the trial period,
          please reach out to our customer support team for assistance.
        </Text>

        <Title id="3" margins={[50, 0, 0, 0]}>
          3.&nbsp;&nbsp;&nbsp;AUTO-RENEWAL AND BILLING
        </Title>

        <Text margins={[12, 0, 0, 0]}>
          Your LG+ subscription will automatically renew at the end of each billing cycle, unless you cancel your
          subscription before the renewal date. The subscription fee will be charged to your chosen payment method. You
          will be notified in advance of the upcoming renewal and any changes to the subscription fee.
        </Text>

        <Title id="4" margins={[50, 0, 0, 0]}>
          4.&nbsp;&nbsp;&nbsp;DEADLINE FOR PAYMENTS
        </Title>

        <Text margins={[12, 0, 0, 0]}>
          Payment for the LG+ subscription is due by the specified deadline indicated in your account settings. Failure
          to make timely payments may result in the suspension or termination of your subscription.
        </Text>

        <Title id="5" margins={[50, 0, 0, 0]}>
          5.&nbsp;&nbsp;&nbsp;REFUND/CANCELLATION POLICY
        </Title>

        <Text margins={[12, 0, 0, 0]}>
          Once payment is made for your LG+ subscription, refunds are not available for changes of mind or other
          reasons. We understand that unforeseen circumstances may arise, and in such cases, we encourage you to contact
          our customer support team to discuss possible solutions.
        </Text>
        <Text margins={[12, 0, 0, 0]}>
          Upon cancelation of your LG+ subscription, your subscription benefits and access to LG+ features will be
          terminated immediately. This includes any discounts, cashback earnings, API integrations, educational courses,
          and other privileges associated with your subscription. We recommend utilizing your subscription benefits
          fully before canceling to make the most of your LG+ experience.
        </Text>

        <Title id="6" margins={[50, 0, 0, 0]}>
          6.&nbsp;&nbsp;&nbsp;DATA PROTECTION
        </Title>

        <Text margins={[12, 0, 0, 0]}>
          We take data protection seriously and implement appropriate security measures to safeguard your information.
          Your data will be stored in secure locations, and we comply with the applicable data protection laws,
          including the General Data Protection Regulation (GDPR). For more information on how we handle your data,
          please refer to our Privacy Policy.
        </Text>

        <Title id="7" margins={[50, 0, 0, 0]}>
          7.&nbsp;&nbsp;&nbsp;SERVICE AVAILABILITY
        </Title>

        <Text margins={[12, 0, 0, 0]}>
          We strive to provide a high level of service quality and uptime. However, occasional maintenance breaks or
          updates may be necessary to ensure optimal performance. We will make reasonable efforts to schedule such
          interruptions during off-peak hours and minimize any impact on your subscription. In the event of service
          disruptions beyond our control, such as internet outages or cyber attacks, we cannot be held responsible for
          the lack of service.
        </Text>

        <Title id="8" margins={[50, 0, 0, 0]}>
          8.&nbsp;&nbsp;&nbsp;SERVICE USAGE
        </Title>

        <Text margins={[12, 0, 0, 0]}>
          Your LG+ subscription grants you access to custom features to enhance the value of your items and improve
          authentication operations. It is important to use the service responsibly and within legal bounds. Any misuse,
          including but not limited to fraudulent activities, unauthorized access, or attempts to manipulate the system,
          is strictly prohibited. If you encounter any abusive or inappropriate behavior from other users, please report
          it to our customer support team for investigation.
        </Text>

        <Title id="9" margins={[50, 0, 0, 0]}>
          9.&nbsp;&nbsp;&nbsp;SUBSCRIPTION UPGRADE/DOWNGRADE
        </Title>

        <Text margins={[12, 0, 0, 0]}>
          We understand that your needs may change over time. If you wish to upgrade or downgrade your LG+ subscription
          plan, you can do so from your account settings. Please note that any changes to your subscription may result
          in adjusted pricing and modified benefits.
        </Text>

        <Title id="10" margins={[50, 0, 0, 0]}>
          10.&nbsp;&nbsp;&nbsp;THIRD-PARTY INVOLVEMENT
        </Title>

        <Text margins={[12, 0, 0, 0]}>
          LG+ may involve third parties or affiliates, such as payment processors or educational course providers. While
          we strive to ensure the quality and reliability of these third-party services, we are not responsible for any
          issues or disputes that may arise from their use. Your interactions with third parties are subject to their
          respective terms and conditions.
        </Text>

        <Title id="11" margins={[50, 0, 0, 0]}>
          11.&nbsp;&nbsp;&nbsp;LEGAL JURISDICTION
        </Title>

        <Text margins={[12, 0, 0, 0]}>
          These terms and conditions are governed by the laws of Estonia, without regard to its conflict of laws
          principles. Any disputes arising from or relating to your LG+ subscription or the use of our services shall be
          subject to the jurisdiction of the courts in Estonia. In addition, any disputes that are not resolved through
          negotiation or mediation may be submitted to international arbitration in accordance with the rules of the
          International Chamber of Commerce (ICC).
        </Text>

        <Title id="12" margins={[50, 0, 0, 0]}>
          12.&nbsp;&nbsp;&nbsp;AMENDMENTS AND UPDATES
        </Title>

        <Text margins={[12, 0, 0, 0]}>
          We reserve the right to modify, amend, or update these terms and conditions at any time. We will notify you of
          any changes via email or through the LG+ platform. It is your responsibility to review the updated terms and
          conditions. Continued use of LG+ after the effective date of the changes constitutes your acceptance of the
          modified terms.
        </Text>

        <Text margins={[50, 0, 0, 0]}>
          If you have any questions or concerns regarding these terms and conditions or your LG+ subscription, please
          contact our customer support team for assistance.
        </Text>

        <Title margins={[50, 0, 0, 0]} size={TITLE_SIZE.h6}>
          Thank you for choosing LegitGrails Plus!
        </Title>
      </TextAgreementBlock>
    </ConditionsWrapper>
  );
};
