import { AccountTypeEnum, FormDataType } from '../types';

type FormingObjectEndpointType = {
  accountType: AccountTypeEnum | string;
  formData: FormDataType;
};

const { personal, business } = AccountTypeEnum;

/** формирует объект для передачи его в эндпоинт 'auth/me' в зависимости от типа аккаунта
 * которые выбрал пользователь */

// .trim() - удаляем пробелы в начале и в конце введенных данных пользователя

export const formingObjectEndpoint = ({ accountType, formData }: FormingObjectEndpointType) => {
  const isCountryStateId = formData.countryStateId !== '';
  switch (accountType) {
    case personal:
      return {
        isBusiness: false,
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
        country: {
          id: formData.country?.value || '',
        },
        ...(isCountryStateId && { countryStateId: formData.countryStateId }),
      };

    case business:
      return {
        isBusiness: true,
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
        companyName: formData.companyName,
        businessType: formData.typeBusiness,
        mainAddress: formData.addressOne,
        // валидация на стороне бэка - если данные есть отправляем, если нет или пустая строка
        // то поле не отправлять
        additionalAddress: formData.addressTwo ? formData.addressTwo : undefined,
        city: formData.city,
        postalCode: formData.postalCode,
        state: formData.stateOrProvince,
        country: {
          id: formData.country?.value || '',
        },
        ...(isCountryStateId && { countryStateId: formData.countryStateId }),
        vatNumber: formData.vatNumber,
      };

    default:
      return {
        isBusiness: false,
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
        country: {
          id: formData.country?.value || '',
          ...(isCountryStateId && { countryStateId: formData.countryStateId }),
        },
      };
  }
};
