import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../../common/icons/SvgInline/SvgIcons_2';
import { starsDesktopImg, starsMobileImg } from '../../../common/images';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { TAG_NAME, Z_INDEX_LAYERS } from '../../../componentUI/constants';
import { DarkButton } from '../../../componentUI/simple/Button';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../../../componentUI/simple/Title';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
import { stylesForDarkButton, WelcomeBannerNewUserStyled, WrapperImgStyled } from './styled';

const WELCOME_BANNER_NEW_USER_KEY = 'WelcomeBannerNewUserHidden';

export const WelcomeBannerNewUser = () => {
  const { t } = useTranslation();
  const { isDesktopS, isMobile } = useMatchMedia();

  const isHiddenWelcomeBannerNewUser = Boolean(localStorage.getItem(WELCOME_BANNER_NEW_USER_KEY));

  const [isHiddenBanner, setIsHiddenBanner] = useState(isHiddenWelcomeBannerNewUser || false);

  const closeIconSize = isMobile ? '28px' : '32px';

  if (isHiddenBanner) {
    return null;
  }

  return (
    <WelcomeBannerNewUserStyled isDesktopS={isDesktopS}>
      <DarkButton
        style={stylesForDarkButton}
        onClick={() => {
          setIsHiddenBanner(true);
          localStorage.setItem(WELCOME_BANNER_NEW_USER_KEY, 'true');
        }}
      >
        <CloseIcon size={closeIconSize} />
      </DarkButton>

      <Title
        style={{ zIndex: `${Z_INDEX_LAYERS.modalOnboarding}` }}
        size={TITLE_SIZE.h5}
        tag={TAG_NAME.h5}
        colorText={COLOR_MAP.text.darkGrey}
      >
        {t('welcomeBanner.title')}
      </Title>

      <Title
        style={{ zIndex: `${Z_INDEX_LAYERS.other}` }}
        size={TITLE_SIZE.h6}
        tag={TAG_NAME.h6}
        weight={TITLE_WEIGHT.regular}
        colorText={COLOR_MAP.text.darkGrey}
      >
        {t('welcomeBanner.subtitle')}
      </Title>

      <WrapperImgStyled>
        <img width="100%" height="100%" src={isDesktopS ? starsMobileImg : starsDesktopImg} alt="stars" />
      </WrapperImgStyled>
    </WelcomeBannerNewUserStyled>
  );
};
