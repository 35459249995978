import React from 'react';

import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { SkeletonBasic } from '../../../../../componentUI/skeleton';
import { OrderStatisticsSkeletonStyled } from './styled';

export const OrderStatisticsSkeleton = () => {
  return (
    <OrderStatisticsSkeletonStyled>
      <SkeletonBasic width="100%" height="22px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
      <SkeletonBasic width="100%" height="22px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
      <SkeletonBasic width="100%" height="22px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
      <SkeletonBasic width="100%" height="22px" bgColor={COLOR_MAP.controls.skeleton.sidebar2} />
    </OrderStatisticsSkeletonStyled>
  );
};
