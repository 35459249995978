import Cookies from 'js-cookie';
import { instance } from '../axios-instance';
import { SubscriptionsResponseType, buySubscriptionRequestType, userSubscriptionsResponseType } from './types';

export const buySubscription = (params: buySubscriptionRequestType) => {
  return instance.post('/subscriptions/buy', params, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getSubscriptions = () => {
  return instance.get<SubscriptionsResponseType>('/subscriptions', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getUserSubscriptions = () => {
  return instance.get<userSubscriptionsResponseType>('/user-subscriptions', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};
