/* eslint-disable react/no-array-index-key */
import React from 'react';

import { verificationConditionsPassword } from '../../../../../helpers/validate';

import { IndicatorStyled, WrapperIndicatorStyled } from './styled';

type Props = {
  password: string;
};

export const PasswordStrength = ({ password }: Props) => {
  const verificationResult = verificationConditionsPassword(password);

  const arrVerificationResult = Object.values(verificationResult);

  const sortedArray = arrVerificationResult.sort().reverse();

  return (
    <WrapperIndicatorStyled>
      {sortedArray.map((element, index) => {
        return <IndicatorStyled indicator={element} key={index} />;
      })}
    </WrapperIndicatorStyled>
  );
};
