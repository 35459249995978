import Cookies from 'js-cookie';
import { instance } from '../axios-instance';

import { userUTMRequest, userUTMResponse } from './types';

export const userUTM = (data: userUTMRequest) => {
  return instance.post<userUTMResponse>('/user-utm', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};
