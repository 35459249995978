import styled from 'styled-components';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../componentUI/screen';

import { BackgroundImage, BackgroundImage600 } from '../../assets';

const RightColumnStyled = styled.div`
  padding: 40px 20px 16px 20px;

  min-height: 100vh;
  width: 100%;

  display: grid;
  grid-template-rows: auto;
  justify-items: center;
  gap: 28px;

  background-color: ${COLOR_MAP.background.darkGrey};

  ${truthfulAdaptive.maxWidth.mobile} {
    padding: 24px 20px 16px 20px;
  }
`;

const WrapperSignUpStyled = styled.div`
  align-self: center;

  width: 100%;
  max-width: 484px;

  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 24px;

  ${truthfulAdaptive.maxWidth.mobile} {
    align-self: start;
  }
`;

const ContainerSignUpStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const WrapperConditionsStyled = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const WrapperBtnSingUpStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const WrapperSignInStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const WrapperTextStyled = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const LeftColumnStyled = styled.div`
  padding: 40px 20px 16px 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url(${BackgroundImage});
  background-size: cover;

  ${truthfulAdaptive.maxWidth.desktop1440} {
    background-image: url(${BackgroundImage600});
  }
`;

const WrapperSeparatorStyled = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 4px;
`;

const SeparatorStyled = styled.div`
  height: 1px;
  width: 100%;

  background-color: ${COLOR_MAP.accent.white};
`;

export {
  ContainerSignUpStyled,
  LeftColumnStyled,
  RightColumnStyled,
  SeparatorStyled,
  WrapperBtnSingUpStyled,
  WrapperConditionsStyled,
  WrapperSeparatorStyled,
  WrapperSignInStyled,
  WrapperSignUpStyled,
  WrapperTextStyled,
};
