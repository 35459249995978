import Cookies from 'js-cookie';
import { instance } from '../axios-instance';
import {
  getBalanceConversationInfoResponseType,
  getBalanceInfoResponseType,
  getUserTransactionsCreditsInfoResponseType,
  getUserTransactionsCreditsParamsRequest,
} from './types';

export const getBalanceConversationInfo = () => {
  return instance.get<getBalanceConversationInfoResponseType>('user-credits/get-balance-conversion-info', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getUserTransactionsCreditsInfo = (data: getUserTransactionsCreditsParamsRequest) => {
  return instance.get<getUserTransactionsCreditsInfoResponseType>('user-credits', {
    params: {
      ...data,
    },
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getBalanceInfo = () => {
  return instance.get<getBalanceInfoResponseType>('user-credits/get-balance', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};
