import { Dispatch, SetStateAction } from 'react';
import { SelectedSetFiltersType } from '../../../../containers/AuthenticationsTablet/types';

export enum NAME_OBJECT_GET_PRODUCT {
  publicId = 'publicId',
  date = 'date',
  brand = 'brand',
  modelName = 'modelName',
  sku = 'sku',
  checkStatus = 'checkStatus',
}

type DropDownListType = {
  key: string;
  value: boolean | string;
  label: string;
};

type serviceInfoType = {
  id: string;
  nameObject: NAME_OBJECT_GET_PRODUCT;
  dropDownList: Array<DropDownListType>;
};

type Props = {
  filterID: string;
  removeFilter: (id: string) => void;
  selectedSetFilters: SelectedSetFiltersType;
  setSelectedSetFilters: Dispatch<SetStateAction<SelectedSetFiltersType>>;
};

type SelectDropDownListType = serviceInfoType;

type OptionsMasterDropdownListType = {
  key: string;
  value: NAME_OBJECT_GET_PRODUCT;
  label: string;
};

export type { DropDownListType, OptionsMasterDropdownListType, Props, SelectDropDownListType };
