import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { sendEvent } from '../../../../analytics';
import { PAYMENT_KEY_FROM_STORAGE } from '../../../../analytics/constants';

import { useMatchMedia } from '../../../../hooks';

import { TAG_NAME } from '../../../../componentUI/constants';
import { Button, BUTTON_SIZE, SecondButton } from '../../../../componentUI/simple/Button';
import { Text } from '../../../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../../../componentUI/simple/Title';

import { RefreshIcon } from '../../../../common/icons/SvgInline/SvgIcons_3';

import { FailurePicture } from './assets';
import {
  FailureTopUpStyled,
  stylesForBtn,
  WrapperBtnStyled,
  WrapperFailureTopUpStyled,
  WrapperTextStyled,
} from './styled';

export const FailureTopUp = () => {
  const { isMobile } = useMatchMedia();
  const navigate = useNavigate();

  const goToPage = (page: string) => {
    navigate(`../${page}`);
  };

  useEffect(() => {
    const sum = window.localStorage.getItem(PAYMENT_KEY_FROM_STORAGE);

    if (sum) {
      TagManager.initialize({
        gtmId: 'GTM-KKTZZ99',
        dataLayer: {
          event: 'purchaseUnSuccess',
          purchaseValue: `${Number(sum) / 100}`,
        },
      });

      sendEvent('purchase', 'purchaseUnSuccess', { value: Number(sum) / 100 });

      window.localStorage.removeItem(PAYMENT_KEY_FROM_STORAGE);
    }
  }, []);

  return (
    <FailureTopUpStyled>
      <WrapperFailureTopUpStyled>
        <img alt="Top up error" src={FailurePicture} width={isMobile ? 180 : 225} height={isMobile ? 160 : 200} />

        <WrapperTextStyled>
          <Title tag={isMobile ? TAG_NAME.h4 : TAG_NAME.h3} size={isMobile ? TITLE_SIZE.h4 : TITLE_SIZE.h3}>
            Something&nbsp;went&nbsp;wrong
          </Title>

          <Text>Please go back and try again!</Text>
        </WrapperTextStyled>

        <WrapperBtnStyled>
          <Button style={stylesForBtn} isStretch size={BUTTON_SIZE.small} onClick={() => goToPage('top-up')}>
            <RefreshIcon />
            TRY AGAIN
          </Button>

          <SecondButton isStretch size={BUTTON_SIZE.small} onClick={() => goToPage('dashboard')}>
            GO TO DASHBOARD
          </SecondButton>
        </WrapperBtnStyled>
      </WrapperFailureTopUpStyled>
    </FailureTopUpStyled>
  );
};
