import styled, { CSSProperties } from 'styled-components';
import { Z_INDEX_LAYERS } from '../../../componentUI';
import { truthfulAdaptive } from '../../../componentUI/screen';

const SurveyBannerStyled = styled.div<{ isMobile: boolean }>`
  position: relative;

  padding: ${({ isMobile }) => (isMobile ? '16px' : '16px 40px;')};

  display: flex;
  justify-content: space-between;

  background: linear-gradient(90deg, #051937 0%, #004d7a 25%, #008793 50%, #00bf72 75%, #a8eb12 100%);
  overflow: hidden;
`;

const WrapperTitleStyled = styled.div`
  display: flex;
  gap: 16px;

  ${truthfulAdaptive.maxWidth.desktopS} {
    flex-direction: column;
    gap: 8px;
  }
`;

const WrapperImgStyled = styled.div<{ isMobile: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  height: 268px;

  transform: ${({ isMobile }) => (isMobile ? 'translate(60%, -50%)' : 'translate(0, -50%)')};
`;

const stylesForDarkButton: CSSProperties = {
  zIndex: `${Z_INDEX_LAYERS.other}`,
};

const stylesForLink: CSSProperties = {
  textDecoration: 'underline dotted',
  whiteSpace: 'nowrap',
  zIndex: `${Z_INDEX_LAYERS.other}`,
  textUnderlineOffset: '6px',
  WebkitTextDecorationLine: 'underline',
  WebkitTextDecorationStyle: 'dotted',
};

export { stylesForDarkButton, stylesForLink, SurveyBannerStyled, WrapperImgStyled, WrapperTitleStyled };
