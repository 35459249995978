/* eslint-disable max-len */
import React from 'react';

export const BannerShieldMobileIcon = () => (
  <svg width="auto" height="196" viewBox="0 0 343 196" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M-51 186C-11.8633 109.919 57.296 160.78 107.155 149.431C157.014 138.081 136.105 56.5358 195.615 58.2172C255.124 59.8986 250.299 77.1323 289.435 70.4068C320.745 65.0265 347 35.0378 347 6"
      stroke="#EA9338"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M-7 181.716C114.835 225.596 36.4716 55.7256 121.158 83.39C218.938 115.331 227.117 5.01742 231 -19"
      stroke="#D86F22"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <g opacity="0.5" filter="url(#filter0_d_13887_168505)">
      <path
        d="M98.5155 40.4121L100.571 48.0475L108.207 50.1033L100.571 52.1592L98.5155 59.7946L96.4596 52.1592L88.8242 50.1033L96.4596 48.0475L98.5155 40.4121Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter1_d_13887_168505)">
      <path
        d="M129.274 30.2998L130.168 33.6196L133.488 34.5134L130.168 35.4072L129.274 38.727L128.38 35.4072L125.061 34.5134L128.38 33.6196L129.274 30.2998Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter2_d_13887_168505)">
      <path
        d="M198.376 26.085L198.912 28.0768L200.904 28.6131L198.912 29.1494L198.376 31.1413L197.84 29.1494L195.848 28.6131L197.84 28.0768L198.376 26.085Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter3_d_13887_168505)">
      <path
        d="M241.352 103.615L241.889 105.607L243.881 106.143L241.889 106.68L241.352 108.672L240.816 106.68L238.824 106.143L240.816 105.607L241.352 103.615Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter4_d_13887_168505)">
      <path
        d="M86.2938 85.918L86.8301 87.9098L88.8219 88.4461L86.8301 88.9824L86.2938 90.9743L85.7575 88.9824L83.7656 88.4461L85.7575 87.9098L86.2938 85.918Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter5_d_13887_168505)">
      <path
        d="M213.546 143.225L214.439 146.544L217.759 147.438L214.439 148.332L213.546 151.652L212.652 148.332L209.332 147.438L212.652 146.544L213.546 143.225Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter6_d_13887_168505)">
      <path
        d="M227.028 61.4795L227.922 64.7992L231.242 65.6931L227.922 66.5869L227.028 69.9067L226.134 66.5869L222.814 65.6931L226.134 64.7992L227.028 61.4795Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter7_d_13887_168505)">
      <path
        d="M103.993 112.886L104.887 116.205L108.206 117.099L104.887 117.993L103.993 121.313L103.099 117.993L99.7793 117.099L103.099 116.205L103.993 112.886Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter8_d_13887_168505)">
      <path
        d="M155.399 155.021L156.293 158.34L159.613 159.234L156.293 160.128L155.399 163.448L154.505 160.128L151.186 159.234L154.505 158.34L155.399 155.021Z"
        fill="white"
      />
    </g>
    <g opacity="0.5" filter="url(#filter9_d_13887_168505)">
      <path
        d="M229.137 123.84L231.192 131.475L238.828 133.531L231.192 135.587L229.137 143.222L227.081 135.587L219.445 133.531L227.081 131.475L229.137 123.84Z"
        fill="white"
      />
    </g>
    <g opacity="0.7" filter="url(#filter10_d_13887_168505)">
      <path
        d="M116.631 123.84L117.309 128.104L120.803 125.568L118.266 129.062L122.53 129.739L118.266 130.416L120.803 133.91L117.309 131.374L116.631 135.638L115.954 131.374L112.46 133.91L114.996 130.416L110.732 129.739L114.996 129.062L112.46 125.568L115.954 128.104L116.631 123.84Z"
        fill="white"
      />
    </g>
    <g opacity="0.7" filter="url(#filter11_d_13887_168505)">
      <path
        d="M232.928 26.9287L233.606 31.1927L237.1 28.6565L234.563 32.1505L238.827 32.8277L234.563 33.505L237.1 36.999L233.606 34.4627L232.928 38.7267L232.251 34.4627L228.757 36.999L231.293 33.505L227.029 32.8277L231.293 32.1505L228.757 28.6565L232.251 31.1927L232.928 26.9287Z"
        fill="white"
      />
    </g>
    <g opacity="0.7" filter="url(#filter12_d_13887_168505)">
      <path
        d="M112.419 58.1084L113.096 62.3724L116.59 59.8362L114.054 63.3302L118.318 64.0074L114.054 64.6846L116.59 68.1786L113.096 65.6424L112.419 69.9064L111.741 65.6424L108.247 68.1786L110.784 64.6846L106.52 64.0074L110.784 63.3302L108.247 59.8362L111.741 62.3724L112.419 58.1084Z"
        fill="white"
      />
    </g>
    <path
      d="M318.737 0C349.695 80.711 274.366 131.586 230.401 124.01C178.323 115.037 167.193 108.916 128.57 165.761C106.457 198.306 18.228 178.432 -3 137.122"
      stroke="#C05B11"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M125.059 49.0646C125.059 47.9363 125.819 46.9497 126.91 46.6626L142.384 42.5907C161.962 37.4385 182.54 37.4385 202.118 42.5907L217.591 46.6626C218.682 46.9497 219.443 47.9363 219.443 49.0646V92.3452C219.443 112.196 209.459 130.716 192.875 141.627L172.251 155.195L151.627 141.627C135.043 130.716 125.059 112.196 125.059 92.3452V49.0646Z"
      fill="#F1EAE6"
    />
    <g filter="url(#filter13_d_13887_168505)">
      <path
        d="M131.27 53.6501C131.27 52.6696 131.93 51.8122 132.878 51.5627L146.315 48.024C163.317 43.5467 181.187 43.5467 198.189 48.024L211.627 51.5627C212.574 51.8122 213.235 52.6696 213.235 53.6501V93.1252C213.235 110.376 204.564 126.471 190.162 135.952L172.252 147.744L154.342 135.952C139.94 126.471 131.27 110.376 131.27 93.1252V53.6501Z"
        fill="#F7F1EE"
      />
    </g>
    <g opacity="0.4" filter="url(#filter14_d_13887_168505)">
      <path
        d="M172.25 59.5693L175.845 67.9245L181.845 61.0889L182.681 70.1458L190.5 65.4989L188.496 74.3709L197.368 72.3675L192.721 80.1863L201.778 81.0226L194.943 87.0227L203.298 90.6168L194.943 94.2109L201.778 100.211L192.721 101.047L197.368 108.866L188.496 106.863L190.5 115.735L182.681 111.088L181.845 120.145L175.845 113.309L172.25 121.664L168.656 113.309L162.656 120.145L161.82 111.088L154.001 115.735L156.005 106.863L147.133 108.866L151.78 101.047L142.723 100.211L149.558 94.2109L141.203 90.6168L149.558 87.0227L142.723 81.0226L151.78 80.1863L147.133 72.3675L156.005 74.3709L154.001 65.4989L161.82 70.1458L162.656 61.0889L168.656 67.9245L172.25 59.5693Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter15_d_13887_168505)">
      <path
        d="M172.25 59.5693L179.446 68.4709L190.5 65.4989L191.089 76.9299L201.778 81.0226L195.536 90.6168L201.778 100.211L191.089 104.304L190.5 115.735L179.446 112.763L172.25 121.664L165.055 112.763L154.001 115.735L153.412 104.304L142.723 100.211L148.965 90.6168L142.723 81.0226L153.412 76.9299L154.001 65.4989L165.055 68.4709L172.25 59.5693Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter16_d_13887_168505)">
      <path
        d="M159.832 92.4798L168.598 99.9312L184.67 81.3027"
        stroke="#F46E1F"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_13887_168505"
        x="76.8242"
        y="33.4121"
        width="43.3828"
        height="43.3828"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter1_d_13887_168505"
        x="113.061"
        y="23.2998"
        width="32.4277"
        height="32.4268"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter2_d_13887_168505"
        x="183.848"
        y="19.085"
        width="29.0566"
        height="29.0566"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter3_d_13887_168505"
        x="226.824"
        y="96.6152"
        width="29.0566"
        height="29.0566"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter4_d_13887_168505"
        x="71.7656"
        y="78.918"
        width="29.0566"
        height="29.0566"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter5_d_13887_168505"
        x="197.332"
        y="136.225"
        width="32.4277"
        height="32.4268"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter6_d_13887_168505"
        x="210.814"
        y="54.4795"
        width="32.4277"
        height="32.4268"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter7_d_13887_168505"
        x="87.7793"
        y="105.886"
        width="32.4277"
        height="32.4268"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter8_d_13887_168505"
        x="139.186"
        y="148.021"
        width="32.4277"
        height="32.4268"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter9_d_13887_168505"
        x="207.445"
        y="116.84"
        width="43.3828"
        height="43.3828"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter10_d_13887_168505"
        x="98.7324"
        y="116.84"
        width="35.7988"
        height="35.7979"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter11_d_13887_168505"
        x="215.029"
        y="19.9287"
        width="35.7988"
        height="35.7979"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter12_d_13887_168505"
        x="94.5195"
        y="51.1084"
        width="35.7988"
        height="35.7979"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter13_d_13887_168505"
        x="119.27"
        y="37.666"
        width="105.965"
        height="127.077"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter14_d_13887_168505"
        x="129.203"
        y="52.5693"
        width="86.0938"
        height="86.0947"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter15_d_13887_168505"
        x="130.723"
        y="52.5693"
        width="83.0547"
        height="86.0947"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
      <filter
        id="filter16_d_13887_168505"
        x="156.832"
        y="79.3027"
        width="30.8379"
        height="25.4385"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.475 0 0 0 0 0.36432 0 0 0 0 0.158333 0 0 0 0.16 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168505" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168505" result="shape" />
      </filter>
    </defs>
  </svg>
);
