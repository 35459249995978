import React from 'react';

import { useTranslation } from 'react-i18next';
import { RadioChipsPictureInPicture } from '../../../../componentUI/simple/Radio/RadioChipsPictureInPicture';
import { CertificateInfoSkeletonTemplates } from '../../skeleton';

import { TAG_NAME } from '../../../../componentUI/constants';
import { Text } from '../../../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../../../componentUI/simple/Title';

import { WrapperTemplateElementStyled } from './styled';
import { Props } from './types';

export const CertificateTemplate = ({
  setUserCertificateTemplateId,
  userCertificateTemplateId,
  templateData,
  isLoadingTemplate,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Title tag={TAG_NAME.h6} size={TITLE_SIZE.h6}>
        {t('certificateInfoWs.selectCertificateTemplate.title')}
      </Title>

      <Text>{t('certificateInfoWs.selectCertificateTemplate.description')}</Text>

      <WrapperTemplateElementStyled>
        {isLoadingTemplate && <CertificateInfoSkeletonTemplates />}

        {templateData &&
          templateData.map((templateElement) => {
            return (
              <RadioChipsPictureInPicture
                key={templateElement.id}
                checkedValue={userCertificateTemplateId || ''}
                onChange={(value) => {
                  setUserCertificateTemplateId(value);
                }}
                url={templateElement.certificateTemplate.path}
                thumbnail={templateElement?.logo?.path}
                name={templateElement.id}
                templateOrientation={templateElement.certificateTemplate.certificateOrientation}
                nameTemplate={templateElement.name}
                value={templateElement.id}
                isBgColor
                isDefault={templateElement?.isDefault}
              />
            );
          })}
      </WrapperTemplateElementStyled>
    </>
  );
};
