import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';
import { postUpdateProductFileUpload } from '../file-api';

import { addToast } from '../../../redux/reducers/toast-reducer';

import { UpdateProductUploadRequestType } from '../types';

type ErrorType = {
  response: {
    data: {
      path: string;
      statusCode: number;
      timestamp: string;
      message: string | Array<string>;
    };
    status: number;
    statusText: string;
  };
};

export const useUpdatePhotoProductMutate = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationKey: [QUERY_KEY.postUpdatePhoto],
    mutationFn: async ({
      productFileInfo,
      idempotencyKey,
    }: {
      productFileInfo: UpdateProductUploadRequestType;
      idempotencyKey: string;
    }) => {
      const { angleId, productId, photos } = productFileInfo;

      const formData = new FormData();

      photos.forEach((file) => {
        formData.append('photos', file);
      });

      formData.append('angleId', angleId);
      formData.append('productId', productId);
      formData.append('idempotencyKey', idempotencyKey);

      const { data } = await postUpdateProductFileUpload(formData);

      return data;
    },
    retry: (failureCount, error) => {
      if (error.response.status >= 500) {
        return failureCount < 2;
      }

      return false;
    },
    retryDelay: 2000,
    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};
