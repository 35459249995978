import styled from 'styled-components';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { LinkRouter } from '../../../../componentUI/simple/Link';

const UserAreaStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const WrapperUserSettingStyled = styled(LinkRouter)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WrapperOtherStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperLogOutStyled = styled.div`
  display: flex;
  gap: 8px;

  cursor: pointer;
`;

const HorizonLineStyled = styled.div`
  width: 100%;
  height: 1px;

  background-color: ${COLOR_MAP.background.grey};
`;

export { HorizonLineStyled, UserAreaStyled, WrapperLogOutStyled, WrapperOtherStyled, WrapperUserSettingStyled };
