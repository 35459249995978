import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../../common/icons/SvgInline/SvgIcons_2';
import { starsPlusImg } from '../../../common/images';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { TAG_NAME, Z_INDEX_LAYERS } from '../../../componentUI/constants';
import { DarkButton } from '../../../componentUI/simple/Button';
import { LinkRouter } from '../../../componentUI/simple/Link';
import { TEXT_SIZE, TEXT_WEIGHT } from '../../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../../componentUI/simple/Title';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
import { stylesForDarkButton, stylesForLink, SurveyBannerStyled, WrapperImgStyled, WrapperTitleStyled } from './styled';

const SURVEY_BANNER_KEY = 'SurveyBannerHidden';
const SURVEY_LINK = 'https://1m3lrnrrjqi.typeform.com/to/o4I9sLF3';

const COLOR_MAP_LINK = {
  clickable: COLOR_MAP.controls.fillNormal,
  clickableHover: COLOR_MAP.controls.fillNormal,
  clickablePressed: COLOR_MAP.controls.fillNormal,
  clickableDisabled: COLOR_MAP.controls.fillNormal,
};

export const SurveyBanner = () => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();

  const isHiddenSurveyBanner = Boolean(localStorage.getItem(SURVEY_BANNER_KEY));
  const closeIconSize = isMobile ? '32px' : '28px';

  const [isHiddenBanner, setIsHiddenBanner] = useState(isHiddenSurveyBanner || false);

  if (isHiddenBanner) {
    return null;
  }

  return (
    <SurveyBannerStyled isMobile={isMobile}>
      <WrapperTitleStyled>
        <Title
          style={{ zIndex: `${Z_INDEX_LAYERS.modalOnboarding}` }}
          size={TITLE_SIZE.h6}
          tag={TAG_NAME.h6}
          colorText={COLOR_MAP.text.white}
        >
          {t('surveyBanner.title')}
        </Title>

        <LinkRouter
          style={stylesForLink}
          to={SURVEY_LINK}
          size={TEXT_SIZE.titleV2}
          colorText={COLOR_MAP.text.white}
          weight={TEXT_WEIGHT.semibold}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('surveyBanner.link')}
        </LinkRouter>
      </WrapperTitleStyled>

      <DarkButton
        colorsMap={COLOR_MAP_LINK}
        style={isMobile ? { ...stylesForDarkButton, alignItems: 'flex-start' } : stylesForDarkButton}
        onClick={() => {
          setIsHiddenBanner(true);
          localStorage.setItem(SURVEY_BANNER_KEY, 'true');
        }}
      >
        <CloseIcon color={COLOR_MAP.accent.white} size={closeIconSize} />
      </DarkButton>

      <WrapperImgStyled isMobile={isMobile}>
        <img width="100%" height="100%" src={starsPlusImg} alt="stars" />
      </WrapperImgStyled>
    </SurveyBannerStyled>
  );
};
