import { GetBrandsListResponseType } from '../../../../../../../api/brands/types';
import { GetCheckStatusesResponse } from '../../../../../../../api/product/type';
import { NAME_OBJECT_GET_PRODUCT } from '../types';

type getOptionsFilterSelectTypeTwoType = {
  dataBrandsList: GetBrandsListResponseType | undefined;
  dataCheckStatuses: GetCheckStatusesResponse | undefined;
  nameObject: NAME_OBJECT_GET_PRODUCT;
};

const { brand, checkStatus } = NAME_OBJECT_GET_PRODUCT;

/** формирует объект который используется для отрисовки значений для выпадающих
 * списков brand и Outcome  */

export const getOptionsFilterSelectTypeTwo = ({
  dataBrandsList,
  dataCheckStatuses,
  nameObject,
}: getOptionsFilterSelectTypeTwoType) => {
  if (dataBrandsList && nameObject === brand) {
    const optionsSelectBrand = dataBrandsList.map((element) => {
      return {
        key: element.id,
        value: element.id,
        label: element.displayName,
      };
    });

    return optionsSelectBrand;
  }

  if (dataCheckStatuses && nameObject === checkStatus) {
    const optionsSelectCheckStatus = Object.entries(dataCheckStatuses).map((element) => {
      return {
        value: element[0],
        key: element[0],
        label: element[1],
      };
    });
    return optionsSelectCheckStatus;
  }

  return [];
};
