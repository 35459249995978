import styled from 'styled-components';
import { Z_INDEX_LAYERS } from '../../componentUI';

const ContainerStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 16px;
  box-sizing: border-box;

  z-index: ${Z_INDEX_LAYERS.modalsLayer.overlayLayer};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const OverlayStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.5);

  cursor: pointer;
`;

export { ContainerStyled, OverlayStyled };
