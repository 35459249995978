import React from 'react';

import { COLOR_MAP } from '../../colorsMap';
import { TEXT_SIZE, Text } from '../Text';

import { TriangleError } from '../../../common/icons/SvgInline/SvgIcons_7';

import { ErrorInputReactSelectBlockStyled, WrapperTextErrorStyled } from './styled';

type Props = {
  children: JSX.Element;
  errorMessage: string;
  isError: boolean;
};

export const ErrorInputReactSelectBlock = ({ errorMessage, isError, children }: Props) => {
  return (
    <ErrorInputReactSelectBlockStyled>
      {children}
      {isError && (
        <WrapperTextErrorStyled>
          <TriangleError />
          &nbsp;
          <Text colorText={COLOR_MAP.accent.red} size={TEXT_SIZE.annotation}>
            {errorMessage}
          </Text>
        </WrapperTextErrorStyled>
      )}
    </ErrorInputReactSelectBlockStyled>
  );
};
