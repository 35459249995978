import React, { useCallback } from 'react';
import styled from 'styled-components';

import { COLOR_MAP } from '../../colorsMap';
import { Text, TEXT_SIZE } from '../Text';

import { noop, VisuallyHiddenInput } from '../../utils';

import { Props } from './Radio';

type PropsRadioChips = Props & {
  descriptions: string;
};

const RadioWrapper = styled.label<{ checked: boolean; disabled?: boolean }>`
  width: 100%;
  padding: 12px 20px;

  display: flex;
  gap: 8px;

  ${({ checked }) => (checked ? `border: 2px solid ${COLOR_MAP.text.brandColor};` : '')};
  ${({ disabled }) => (disabled ? `background-color: ${COLOR_MAP.background.disabled}; pointer-events: none;` : '')};

  border: ${({ disabled }) => (disabled ? `2px solid ${COLOR_MAP.controls.disabled};` : '')};

  border-radius: 6px;

  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.08), 0px 4px 9px 0px rgba(150, 112, 38, 0.05);
  user-select: none;
  cursor: pointer;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Circle = styled.div<{ disabled?: boolean }>`
  position: relative;

  flex-shrink: 0;
  width: 20px;
  height: 20px;

  border-radius: 50%;
  border: ${({ disabled }) =>
    disabled ? `1.2px solid ${COLOR_MAP.controls.disabled};` : `1.2px solid ${COLOR_MAP.controls.clickableNormal};`};

  background-color: ${({ disabled }) => (disabled ? `${COLOR_MAP.background.disabled};` : '')};
`;

const CheckedCircle = styled(Circle)`
  &::before {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;

    width: 10px;
    height: 10px;

    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ disabled }) =>
      disabled ? `${COLOR_MAP.controls.disabled};` : `${COLOR_MAP.controls.clickableNormal};`};
  }
`;

export const RadioChips = ({
  checkedValue,
  disabled,
  onChange = noop,
  name,
  label = '',
  value,
  descriptions,
}: PropsRadioChips) => {
  const changeHandler = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  const checked = checkedValue === value;

  return (
    <RadioWrapper disabled={disabled} checked={checked}>
      {checked ? <CheckedCircle disabled={disabled} /> : <Circle disabled={disabled} />}
      <TextWrapper>
        <Text size={TEXT_SIZE.regular}>{label}</Text>
        <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey}>
          {descriptions}
        </Text>
      </TextWrapper>

      <VisuallyHiddenInput type="radio" onChange={changeHandler} checked={checked} name={name} value={value} />
    </RadioWrapper>
  );
};
