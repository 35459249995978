import styled from 'styled-components';

import { truthfulAdaptive } from '../../../../../../componentUI/screen';

const CompanyInformationBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const WrapperCityPostalStated = styled.div`
  display: flex;
  gap: 16px;

  ${truthfulAdaptive.maxWidth.mobile} {
    flex-direction: column;
    gap: 20px;
  }
`;
export { CompanyInformationBlockStyled, WrapperCityPostalStated };
