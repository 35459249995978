import React from 'react';
import styled from 'styled-components';

import { COLOR_MAP } from '../../colorsMap';
import { SIZE_STYLE, TEXT_SIZE, TEXT_WEIGHT } from '../Text/constant';
import { PropButton } from './types';

const LinkButton = styled.button<PropButton>`
  display: flex;
  align-items: center;
  gap: 4px;

  font-family: 'Open Sans', sans-serif;
  color: ${({ colorText }) => colorText};
  ${({ size }) => SIZE_STYLE[size]};
  ${({ weight }) => `font-weight: ${weight}`};

  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;

  text-decoration: ${({ isUnderlined }) => (isUnderlined ? 'underline' : 'none')};

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonLinkClassic = ({
  size = TEXT_SIZE.regular,
  colorText = COLOR_MAP.text.brandColor,
  weight = TEXT_WEIGHT.normal,
  isUnderlined = false,
  children,
  ...otherProps
}: Partial<PropButton>) => {
  return (
    <LinkButton
      colorText={colorText}
      size={size}
      weight={weight}
      isUnderlined={isUnderlined}
      type="button"
      {...otherProps}
    >
      {children}
    </LinkButton>
  );
};
