/* eslint-disable react/no-array-index-key */
import React from 'react';

import { useMatchMedia } from '../../../hooks';

import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { SkeletonBasic } from '../../../componentUI/skeleton';

import { SkeletonMobileStyled, SkeletonStyled, WrapperBlockOne } from './styled';

export const TransactionsSkeleton = () => {
  const { isMobile } = useMatchMedia();

  return (
    <>
      {new Array(10).fill('1').map((_, index) => {
        return (
          <React.Fragment key={index}>
            {!isMobile && (
              <SkeletonStyled>
                <SkeletonBasic width="100%" height="20px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              </SkeletonStyled>
            )}

            {isMobile && (
              <SkeletonMobileStyled key={index}>
                <SkeletonBasic width="100%" height="20px" bgColor={COLOR_MAP.controls.skeleton.content2} />

                <WrapperBlockOne>
                  <SkeletonBasic width="120px" height="36px" bgColor={COLOR_MAP.controls.skeleton.content2} />
                  <SkeletonBasic width="150px" height="36px" bgColor={COLOR_MAP.controls.skeleton.content1} />
                </WrapperBlockOne>
              </SkeletonMobileStyled>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
