import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from '../../../common';
import { getNotification } from '../../index';

export const useNotificationQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEY.getNotification],
    refetchInterval: 30000,
    refetchIntervalInBackground: false,
    queryFn: async () => {
      const { data } = await getNotification({ limit: 20, 'is-read': false });

      return data;
    },
  });
};
