import React from 'react';

import { useMatchMedia } from '../../../../hooks';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TAG_NAME } from '../../../../componentUI/constants';
import { TITLE_SIZE, TITLE_WEIGHT, Title } from '../../../../componentUI/simple/Title';

import { ContainerTitleStyled, TitleBlockStyled } from './styled';
import { Props } from './types';

export const TitleBlock = ({ titleText, subtitleText, emailText, subtitleColor = COLOR_MAP.text.white }: Props) => {
  const { isMobile } = useMatchMedia();

  return (
    <TitleBlockStyled>
      <ContainerTitleStyled>
        <Title
          tag={isMobile ? TAG_NAME.h1 : TAG_NAME.h2}
          colorText={COLOR_MAP.text.brandColor}
          size={isMobile ? TITLE_SIZE.h1 : TITLE_SIZE.h2}
        >
          {titleText}
        </Title>

        {subtitleText && (
          <Title weight={TITLE_WEIGHT.normal} colorText={subtitleColor} size={isMobile ? TITLE_SIZE.h6 : TITLE_SIZE.h5}>
            {subtitleText}
          </Title>
        )}
      </ContainerTitleStyled>

      {emailText && (
        <Title
          weight={TITLE_WEIGHT.normal}
          colorText={COLOR_MAP.text.brandColor}
          size={isMobile ? TITLE_SIZE.h6 : TITLE_SIZE.h5}
        >
          {emailText}
        </Title>
      )}
    </TitleBlockStyled>
  );
};
