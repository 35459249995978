/* eslint-disable max-len */
import React from 'react';

export const BannerShieldIcon = () => (
  <svg width="335" height="174" viewBox="0 0 335 174" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 195C38.5 104.5 103 165 149.5 151.5C196 138 176.5 41.0002 232 43.0002C287.5 45.0002 283 65.5 319.5 57.5C348.7 51.1 363.333 19.5 367 4.5"
      stroke="#EA9338"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M171.5 187.5C200.5 101.5 223.348 112.076 264 121C305 130 342 86 345.5 57.5"
      stroke="#C05B11"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M79 187C83 106.5 125.458 75.5486 167 73C248.5 68 262.5 -20 266 -48.5"
      stroke="#D86F22"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M186 32.2675C186 30.9286 186.902 29.7579 188.197 29.4172L206.558 24.5853C229.791 18.4716 254.209 18.4716 277.442 24.5853L295.803 29.4172C297.098 29.7579 298 30.9286 298 32.2675V83.626C298 107.182 286.152 129.158 266.473 142.105L242 158.206L217.527 142.105C197.848 129.158 186 107.182 186 83.626V32.2675Z"
      fill="#F1EAE6"
    />
    <g filter="url(#filter0_d_13887_168481)">
      <path
        d="M193.369 37.7087C193.369 36.5452 194.153 35.5278 195.277 35.2317L211.223 31.0326C231.398 25.7196 252.604 25.7196 272.779 31.0326L288.724 35.2317C289.849 35.5278 290.632 36.5452 290.632 37.7087V84.5513C290.632 105.022 280.343 124.12 263.254 135.372L242.001 149.364L220.748 135.372C203.658 124.12 193.369 105.022 193.369 84.5513V37.7087Z"
        fill="#F7F1EE"
      />
    </g>
    <g opacity="0.4" filter="url(#filter1_d_13887_168481)">
      <path
        d="M242 44.7324L246.265 54.647L253.385 46.5356L254.377 57.2829L263.655 51.7686L261.278 62.2966L271.806 59.9193L266.292 69.1973L277.039 70.1897L268.928 77.3096L278.842 81.5745L268.928 85.8394L277.039 92.9594L266.292 93.9517L271.806 103.23L261.278 100.852L263.655 111.38L254.377 105.866L253.385 116.613L246.265 108.502L242 118.417L237.735 108.502L230.615 116.613L229.623 105.866L220.345 111.38L222.722 100.852L212.194 103.23L217.709 93.9517L206.961 92.9594L215.073 85.8394L205.158 81.5745L215.073 77.3096L206.961 70.1897L217.709 69.1973L212.194 59.9193L222.722 62.2966L220.345 51.7686L229.623 57.2829L230.615 46.5356L237.735 54.647L242 44.7324Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter2_d_13887_168481)">
      <path
        d="M242 44.7324L250.539 55.2953L263.655 51.7686L264.355 65.3331L277.039 70.1897L269.632 81.5745L277.039 92.9594L264.355 97.816L263.655 111.38L250.539 107.854L242 118.417L233.462 107.854L220.345 111.38L219.646 97.816L206.961 92.9594L214.369 81.5745L206.961 70.1897L219.646 65.3331L220.345 51.7686L233.462 55.2953L242 44.7324Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter3_d_13887_168481)">
      <path
        d="M227.264 83.7846L237.666 92.6267L256.737 70.5215"
        stroke="#F46E1F"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </g>
    <g opacity="0.5" filter="url(#filter4_d_13887_168481)">
      <path
        d="M154.5 22L156.94 31.0605L166 33.5L156.94 35.9395L154.5 45L152.06 35.9395L143 33.5L152.06 31.0605L154.5 22Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter5_d_13887_168481)">
      <path
        d="M191 10L192.061 13.9393L196 15L192.061 16.0607L191 20L189.939 16.0607L186 15L189.939 13.9393L191 10Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter6_d_13887_168481)">
      <path
        d="M273 5L273.636 7.3636L276 8L273.636 8.6364L273 11L272.364 8.6364L270 8L272.364 7.3636L273 5Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter7_d_13887_168481)">
      <path
        d="M324 97L324.636 99.3636L327 100L324.636 100.636L324 103L323.364 100.636L321 100L323.364 99.3636L324 97Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter8_d_13887_168481)">
      <path
        d="M140 76L140.636 78.3636L143 79L140.636 79.6364L140 82L139.364 79.6364L137 79L139.364 78.3636L140 76Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter9_d_13887_168481)">
      <path
        d="M291 144L292.061 147.939L296 149L292.061 150.061L291 154L289.939 150.061L286 149L289.939 147.939L291 144Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter10_d_13887_168481)">
      <path
        d="M307 47L308.061 50.9393L312 52L308.061 53.0607L307 57L305.939 53.0607L302 52L305.939 50.9393L307 47Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter11_d_13887_168481)">
      <path
        d="M161 108L162.061 111.939L166 113L162.061 114.061L161 118L159.939 114.061L156 113L159.939 111.939L161 108Z"
        fill="white"
      />
    </g>
    <g opacity="0.9" filter="url(#filter12_d_13887_168481)">
      <path
        d="M222 158L223.061 161.939L227 163L223.061 164.061L222 168L220.939 164.061L217 163L220.939 161.939L222 158Z"
        fill="white"
      />
    </g>
    <g opacity="0.5" filter="url(#filter13_d_13887_168481)">
      <path
        d="M309.5 121L311.94 130.06L321 132.5L311.94 134.94L309.5 144L307.06 134.94L298 132.5L307.06 130.06L309.5 121Z"
        fill="white"
      />
    </g>
    <g opacity="0.7" filter="url(#filter14_d_13887_168481)">
      <path
        d="M176 121L176.804 126.06L180.95 123.05L177.94 127.196L183 128L177.94 128.804L180.95 132.95L176.804 129.94L176 135L175.196 129.94L171.05 132.95L174.06 128.804L169 128L174.06 127.196L171.05 123.05L175.196 126.06L176 121Z"
        fill="white"
      />
    </g>
    <g opacity="0.7" filter="url(#filter15_d_13887_168481)">
      <path
        d="M314 6L314.804 11.0599L318.95 8.05025L315.94 12.1964L321 13L315.94 13.8036L318.95 17.9497L314.804 14.9401L314 20L313.196 14.9401L309.05 17.9497L312.06 13.8036L307 13L312.06 12.1964L309.05 8.05025L313.196 11.0599L314 6Z"
        fill="white"
      />
    </g>
    <g opacity="0.7" filter="url(#filter16_d_13887_168481)">
      <path
        d="M171 43L171.804 48.0599L175.95 45.0503L172.94 49.1964L178 50L172.94 50.8036L175.95 54.9497L171.804 51.9401L171 57L170.196 51.9401L166.05 54.9497L169.06 50.8036L164 50L169.06 49.1964L166.05 45.0503L170.196 48.0599L171 43Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_13887_168481"
        x="181.369"
        y="20.0479"
        width="121.264"
        height="146.315"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter1_d_13887_168481"
        x="193.158"
        y="37.7324"
        width="97.6836"
        height="97.6846"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter2_d_13887_168481"
        x="194.961"
        y="37.7324"
        width="94.0781"
        height="97.6846"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter3_d_13887_168481"
        x="224.264"
        y="68.5215"
        width="35.4727"
        height="28.915"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.475 0 0 0 0 0.36432 0 0 0 0 0.158333 0 0 0 0.16 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter4_d_13887_168481"
        x="131"
        y="15"
        width="47"
        height="47"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter5_d_13887_168481"
        x="174"
        y="3"
        width="34"
        height="34"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter6_d_13887_168481"
        x="258"
        y="-2"
        width="30"
        height="30"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter7_d_13887_168481"
        x="309"
        y="90"
        width="30"
        height="30"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter8_d_13887_168481"
        x="125"
        y="69"
        width="30"
        height="30"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter9_d_13887_168481"
        x="274"
        y="137"
        width="34"
        height="34"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter10_d_13887_168481"
        x="290"
        y="40"
        width="34"
        height="34"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter11_d_13887_168481"
        x="144"
        y="101"
        width="34"
        height="34"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter12_d_13887_168481"
        x="205"
        y="151"
        width="34"
        height="34"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter13_d_13887_168481"
        x="286"
        y="114"
        width="47"
        height="47"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter14_d_13887_168481"
        x="157"
        y="114"
        width="38"
        height="38"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter15_d_13887_168481"
        x="295"
        y="-1"
        width="38"
        height="38"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
      <filter
        id="filter16_d_13887_168481"
        x="152"
        y="36"
        width="38"
        height="38"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13887_168481" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13887_168481" result="shape" />
      </filter>
    </defs>
  </svg>
);
