import axios from 'axios';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_ROOT_ENDPOINT, // "http://3.8.69.175:90",
});

// https://account.legitgrails.com/api - ИСПОЛЬЗОВАТЬ ЭТО!!!!!
// https://b2b-portal-dev.herokuapp.com/ api для тестовых данных
// http://3.8.69.175:90 настоящий бэкенд
// dev.legitgrails.com пока не понятно - дал бэк урл
