import React from 'react';

import styled from 'styled-components';
import { PropsLinkButton } from './types';
import { linkCommonStyles } from './utils';
import { BUTTON_SIZE, SIZE_STYLE } from './constants';
import { supportHover } from '../../screen';
import { COLOR_MAP } from '../../colorsMap';

const LinkButtonComponent = styled.a<PropsLinkButton>`
  ${linkCommonStyles};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;

  border: 2px solid ${COLOR_MAP.controls.clickableNormal};
  border-radius: 2px;
  background-color: transparent;

  ${({ size }) => SIZE_STYLE[size]};
  font-family: Montserrat, sans-serif;
  color: ${COLOR_MAP.controls.clickableNormal};
  text-decoration: none;

  user-select: none;

  & path {
    stroke: ${COLOR_MAP.controls.clickableNormal};
  }

  ${supportHover} {
    &:hover {
      color: ${COLOR_MAP.controls.clickableHover};
      border: 2px solid ${COLOR_MAP.controls.clickableHover};
      & path {
        stroke: ${COLOR_MAP.controls.clickableHover};
      }
    }
  }

  &:active {
    color: ${COLOR_MAP.controls.clickablePressed};
    border: 2px solid ${COLOR_MAP.controls.clickablePressed};
    & path {
      stroke: ${COLOR_MAP.controls.clickablePressed};
    }
  }
`;

export const SecondaryLinkButton = ({
  size = BUTTON_SIZE.small,
  isStretch = false,
  children,
  ...props
}: Partial<PropsLinkButton>) => {
  return (
    <LinkButtonComponent size={size} isStretch={isStretch} {...props}>
      {typeof children === 'string' ? children : children}
    </LinkButtonComponent>
  );
};
