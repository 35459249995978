import Cookies from 'js-cookie';
import { instance } from '../axios-instance';

import { ResponseCountriesType } from './type';

export const getCountries = () => {
  return instance.get<ResponseCountriesType>('/countries', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
    params: { page: 1, limit: 300, sort: [['common', 'ASC']] },
  });
};
