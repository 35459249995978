import { RefObject } from 'react';

/** Сброс состояния инпута, если не сделать, то пользователь не выберет
 * повторно эти же данные */

export const inputPhotoClear = (inputRef: RefObject<HTMLInputElement>) => {
  if (inputRef.current) {
    inputRef.current.type = '';
    inputRef.current.type = 'file';
  }
};
