import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShieldIcon } from '../../../../common/icons/SvgInline/SvgIcons_11';
import { ImgTitleHovered, ShieldIconWrapper, Tooltip } from './styles';
import { Text, TEXT_SIZE } from '../../../../componentUI/simple/Text';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';

type ImgTitleFinGStyledProps = {
  children: React.ReactNode;
};

export const ImgTitleFinGStyled = ({ children }: ImgTitleFinGStyledProps) => {
  const { t } = useTranslation();

  return (
    <ImgTitleHovered>
      {children}
      <ShieldIconWrapper>
        <ShieldIcon />
        <Tooltip className="tooltip">
          <Text size={TEXT_SIZE.annotation} colorText={COLOR_MAP.text.white}>
            {t('authenticationsTablet.elementTable.financialGuaranteeTooltip')}
          </Text>
        </Tooltip>
      </ShieldIconWrapper>
    </ImgTitleHovered>
  );
};
