import React from 'react';

import { Text, TEXT_SIZE, TEXT_WEIGHT } from '../../componentUI/simple/Text';

import { LogoPlusStyled } from './styled';

export const LogoPlus = () => {
  return (
    <LogoPlusStyled to="/">
      <Text weight={TEXT_WEIGHT.bold} size={TEXT_SIZE.sub}>
        LegitGrails+
      </Text>
    </LogoPlusStyled>
  );
};
