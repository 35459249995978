import Cookies from 'js-cookie';

import { instance } from '../axios-instance';

import { GetCountryStatesRequestType, GetCountryStatesResponseType } from './types';

export const getCountryStates = (value: GetCountryStatesRequestType) => {
  return instance.get<Array<GetCountryStatesResponseType>>('/country-states', {
    params: { countryId: value },
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};
