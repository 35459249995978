import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { CSSProperties } from 'styled-components';
import { COLOR_MAP } from '../componentUI/colorsMap';
import { Text, TEXT_SIZE } from '../componentUI/simple/Text';
import { CanceledIcon, FakeIcon, LegitIcon, SkipOutcomeIcon } from '../common/icons/SvgInline/SvgIcons_1';
import { CheckStatus } from '../api/product/type';

export const OutcomeInfoStyled = styled.div<{ isCard: boolean }>`
  display: flex;
  gap: ${({ isCard }) => (isCard ? '8px' : '4px')};
  flex-direction: ${({ isCard }) => (isCard ? 'row-reverse' : 'row')};
  align-items: center;
  width: 100%;
  justify-content: ${({ isCard }) => (isCard ? 'flex-end' : 'flex-start')};
`;

const stylesForText: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const OUTCOME_ICON_MAP: Record<CheckStatus, JSX.Element> = {
  LEGIT: <LegitIcon color={COLOR_MAP.accent.green} />,
  FAKE: <FakeIcon />,
  CANCELED: <CanceledIcon />,
  DRAFT: <SkipOutcomeIcon />,
};

export const useGetOutcomeInfo = (
  checkStatus: CheckStatus | null,
  isCard = false,
  isBigSize = false,
  isDraft = false,
) => {
  const { t } = useTranslation();

  if (isDraft) {
    return null;
  }

  if (checkStatus) {
    const outcomeTextKey = `outcome.${checkStatus.toLowerCase()}`;

    return (
      <OutcomeInfoStyled isCard={isCard}>
        {OUTCOME_ICON_MAP[checkStatus]}

        <Text style={stylesForText} size={isCard || isBigSize ? TEXT_SIZE.regular : TEXT_SIZE.sub}>
          {t(outcomeTextKey)}
        </Text>
      </OutcomeInfoStyled>
    );
  }

  return (
    <OutcomeInfoStyled isCard={isCard}>
      <IconWrapper>
        <SkipOutcomeIcon />
        <Text colorText={COLOR_MAP.text.white}>-</Text>
      </IconWrapper>
    </OutcomeInfoStyled>
  );
};
