import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DarkButton } from '../../componentUI/simple/Button';
import { Text, TEXT_SIZE, TEXT_WEIGHT } from '../../componentUI/simple/Text';
import { ChevronDownIcon, ChevronUpIcon } from '../../common/icons/SvgInline/SvgIcons_4';
import { InstructionsContainer, InstructionsWrapper, TitleWrapper, UnorderedList } from './styled';
import { Props } from './types';

export const PhotoInstructions = ({
  setShowInstructions,
  textTitle,
  firstColumnText,
  secondColumnText,
  isSelect,
}: Props) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(false);

  const firstColText = firstColumnText || (t('photoInstructions.firstColumn', { returnObjects: true }) as string[]);
  const secondColText = secondColumnText || (t('photoInstructions.secondColumn', { returnObjects: true }) as string[]);

  const toggleShow = () => {
    setIsShow((prev) => !prev);
  };

  const toggleShowInstructions = () => {
    if (setShowInstructions) {
      setShowInstructions(false);
    }
  };

  return isSelect ? (
    <InstructionsContainer>
      <TitleWrapper isSelect={isSelect} onClick={toggleShow}>
        <Text size={TEXT_SIZE.regular} weight={TEXT_WEIGHT.bold}>
          {textTitle || t('photoInstructions.title')}
        </Text>
        <DarkButton>{isShow ? <ChevronUpIcon /> : <ChevronDownIcon />}</DarkButton>
      </TitleWrapper>

      {isShow && (
        <InstructionsWrapper>
          <UnorderedList>
            {firstColText?.map((value: string) => (
              <li key={value}>
                <Text size={TEXT_SIZE.sub}>{value}</Text>
              </li>
            ))}
          </UnorderedList>
          <UnorderedList>
            {secondColText?.map((value: string) => (
              <li key={value}>
                <Text size={TEXT_SIZE.sub}>{value}</Text>
              </li>
            ))}
          </UnorderedList>
        </InstructionsWrapper>
      )}
    </InstructionsContainer>
  ) : (
    <InstructionsContainer>
      <TitleWrapper isSelect={isSelect}>
        <Text size={TEXT_SIZE.regular} weight={TEXT_WEIGHT.bold}>
          {textTitle || t('photoInstructions.title')}
        </Text>
        <DarkButton onClick={toggleShowInstructions}>
          <ChevronUpIcon />
        </DarkButton>
      </TitleWrapper>
      <InstructionsWrapper>
        <UnorderedList>
          {firstColText?.map((value: string) => (
            <li key={value}>
              <Text size={TEXT_SIZE.sub}>{value}</Text>
            </li>
          ))}
        </UnorderedList>
        <UnorderedList>
          {secondColText?.map((value: string) => (
            <li key={value}>
              <Text size={TEXT_SIZE.sub}>{value}</Text>
            </li>
          ))}
        </UnorderedList>
      </InstructionsWrapper>
    </InstructionsContainer>
  );
};
