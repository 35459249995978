import styled from 'styled-components';

import { truthfulAdaptive } from '../../../componentUI/screen';

const ConvertedBalanceSkeletonStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
`;

const ConvertedBalanceBlockOneStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
`;

const ConvertedBalanceBlockTwoStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
`;

const ConvertedBalanceBlockTreeStyled = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 8px;
  align-items: center;

  ${truthfulAdaptive.maxWidth.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr;
  }
`;

export {
  ConvertedBalanceBlockOneStyled,
  ConvertedBalanceBlockTreeStyled,
  ConvertedBalanceBlockTwoStyled,
  ConvertedBalanceSkeletonStyled,
};
