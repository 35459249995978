import styled from 'styled-components';

import { Z_INDEX_LAYERS } from '../../../../componentUI';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../componentUI/screen';

const ExportCSVStyled = styled.div`
  position: relative;
`;

const ControlStyled = styled.div<{ showChosenBlock: boolean }>`
  position: absolute;

  min-width: 320px;
  padding: 16px;
  margin-top: 12px;

  right: 0;

  display: none;
  flex-direction: column;
  gap: 12px;

  background-color: ${COLOR_MAP.background.white};

  box-shadow: 0 4px 9px rgba(150, 112, 38, 0.05), 0 5px 14px rgba(0, 0, 0, 0.08);
  border-radius: 6px;

  z-index: ${Z_INDEX_LAYERS.other};

  ${({ showChosenBlock }) => showChosenBlock && 'display: flex;'}

  ${truthfulAdaptive.maxWidth.mobile} {
    top: 0;

    min-width: auto;
    width: 100%;
    margin-top: 0;
  }
`;
const WrapperTitleStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export { ControlStyled, ExportCSVStyled, WrapperTitleStyled };
