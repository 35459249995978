import React, { useEffect } from 'react';

import { Portal } from '../Portal';
import { ContainerStyled, OverlayStyled } from './styled';
import { Props } from './types';

export const OverlayingPopup = ({ onClose, isOpened, children }: Props) => {
  useEffect(() => {
    const handleEscapePress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose(false);
      }
    };

    window.addEventListener('keydown', handleEscapePress);

    return () => {
      window.removeEventListener('keydown', handleEscapePress);
    };
  }, [onClose]);

  useEffect(() => {
    document.body.style.overflow = isOpened ? 'hidden' : '';
  }, [isOpened]);

  if (!isOpened) {
    return null;
  }

  return (
    <Portal>
      <ContainerStyled aria-modal="true" role="dialog">
        <OverlayStyled role="button" tabIndex={0} onClick={() => onClose(false)} />
        {children}
      </ContainerStyled>
    </Portal>
  );
};
