import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';
import { SIZE_STYLE, TEXT_SIZE, TEXT_WEIGHT } from '../Text/constant';

type CustomStyleProps = {
  size?: keyof typeof TEXT_SIZE;
  colorText?: string;
  weight?: typeof TEXT_WEIGHT[keyof typeof TEXT_WEIGHT];
  isUnderlined?: boolean;
  ref?: React.MutableRefObject<null>;
};

type PropsRouterLink = LinkProps & CustomStyleProps;

const StyledLink = styled(Link)<PropsRouterLink>`
  font-family: 'Open Sans', sans-serif;
  color: ${({ colorText }) => colorText || COLOR_MAP.text.brandColor};
  ${({ size }) => size && SIZE_STYLE[size]};
  ${({ weight }) => weight && `font-weight: ${weight}`};

  text-decoration: ${({ isUnderlined }) => (isUnderlined ? 'underline' : 'none')};
  -webkit-text-decoration: ${({ isUnderlined }) => (isUnderlined ? 'underline' : 'none')};

  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 0.8;
    }
  }

  @media (hover: none) and (pointer: coarse) {
    &:active {
      opacity: 0.8;
    }
  }
`;

export const LinkRouter = ({
  to,
  size = TEXT_SIZE.regular,
  colorText = COLOR_MAP.text.brandColor,
  weight = TEXT_WEIGHT.normal,
  isUnderlined = false,
  children,
  ref,
  ...otherProps
}: PropsRouterLink) => {
  return (
    <StyledLink
      to={to}
      size={size}
      colorText={colorText}
      weight={weight}
      isUnderlined={isUnderlined}
      ref={ref}
      {...otherProps}
    >
      {children}
    </StyledLink>
  );
};
