import styled from 'styled-components';
import { truthfulAdaptive } from '../../../../componentUI/screen';

const WrapperTemplateElementStyled = styled.div`
  display: flex;
  align-items: center;

  flex-wrap: wrap;
  gap: 12px;

  ${truthfulAdaptive.maxWidth.mobile} {
    width: 100%;

    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

export { WrapperTemplateElementStyled };
