export const verificationConditionsPassword = (password: string) => {
  const validationCheckPass = {
    isLength: false,
    isNumber: false,
    isSpecialSymbol: false,
    isLowerCase: false,
    isUpperCase: false,
  };

  if (password.length >= 8) {
    validationCheckPass.isLength = true;
  } else {
    validationCheckPass.isLength = false;
  }

  if (password.match(/\d/g)) {
    validationCheckPass.isNumber = true;
  } else {
    validationCheckPass.isNumber = false;
  }

  if (/[!@#$&*%]/.test(password)) {
    validationCheckPass.isSpecialSymbol = true;
  } else {
    validationCheckPass.isSpecialSymbol = false;
  }

  if (password !== password.toLowerCase()) {
    validationCheckPass.isUpperCase = true;
  } else {
    validationCheckPass.isUpperCase = false;
  }

  if (password !== password.toUpperCase()) {
    validationCheckPass.isLowerCase = true;
  } else {
    validationCheckPass.isLowerCase = false;
  }

  return validationCheckPass;
};
