import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';

import { getUserCertificateTemplates } from '../index';

import { addToast } from '../../../redux/reducers/toast-reducer';

type ErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

export const useUserCertificateTemplatesQuery = () => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [QUERY_KEY.getUserCertificateTemplates],
    queryFn: async () => {
      const { data } = await getUserCertificateTemplates();

      return data;
    },

    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};
