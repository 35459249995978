import styled from 'styled-components';

import { LogoMain } from '../../common/images';
import { LinkRouter } from '../../componentUI/simple/Link';

const LogoStyled = styled(LinkRouter)`
  width: 40px;
  height: 40px;

  background-image: url(${LogoMain});
  background-size: cover;

  cursor: pointer;
`;

export { LogoStyled };
