import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import Cookies from 'js-cookie';
import { CertificateCreatedEventType } from './types';

type SocketContextProps = {
  socket: Socket | null;
  certificateData: CertificateCreatedEventType | null;
  certificateUpdatedData: CertificateCreatedEventType | null;
};

type SocketProviderProps = {
  children: ReactNode;
};

export const SocketContext = createContext<SocketContextProps>({
  socket: null,
  certificateData: null,
  certificateUpdatedData: null,
});

export const SocketProvider = ({ children }: SocketProviderProps) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [certificateData, setCertificateData] = useState<CertificateCreatedEventType | null>(null);
  const [certificateUpdatedData, setCertificateUpdatedData] = useState<CertificateCreatedEventType | null>(null);

  const token = Cookies.get('jwt') || '';
  const socketURL = process.env.REACT_APP_SOCKET_ENDPOINT || ''; // Fallback URL

  useEffect(() => {
    if (!token) {
      console.error('No token found, socket connection skipped');
      return;
    }

    const socketIo = io(socketURL, {
      auth: { token },
      transports: ['polling', 'websocket'],
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
    });

    setSocket(socketIo);

    // start part about events handling
    socketIo.on('product:certificate_created', (data: CertificateCreatedEventType) => {
      setCertificateData(data);
    });

    socketIo.on('product:certificate_updated', (data: CertificateCreatedEventType) => {
      setCertificateUpdatedData(data);
    });
    // finish part about events handling

    // Handle visibility change and reconnect if necessary
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Tab is active - reconnect the socket if it's not connected
        if (socketIo && !socketIo.connected) {
          socketIo.connect();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Clean up on unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      socketIo.disconnect();
    };
  }, [socketURL, token]);

  const value = useMemo(
    () => ({ socket, certificateData, certificateUpdatedData }),
    [certificateData, certificateUpdatedData, socket],
  );

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};
