import React from 'react';

import styled from 'styled-components';
import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { Text, TEXT_SIZE } from '../../../../../componentUI/simple/Text';
import { ThemeEnum } from '../../../CheckPassword';

const ValidContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const checkmarkIcon = (
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.3332 1L4.99984 8.33333L1.6665 5"
      stroke="#6BCBA9"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const dashIconWhite = (
  <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.33325 1H10.6666" stroke="#ffffff" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const dashIconBlack = (
  <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.33325 1H10.6666" stroke="#DCD0B9" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

type Props = {
  designTheme: ThemeEnum;
  messageText: string;
  verificationStatus: boolean;
};

const themeSettingsText = {
  black: COLOR_MAP.text.black,
  white: COLOR_MAP.text.white,
};

const themeSettingsIcons = {
  black: dashIconBlack,
  white: dashIconWhite,
};

export const ValidPassText = ({ verificationStatus, messageText, designTheme }: Props) => {
  return (
    <ValidContainer>
      {verificationStatus ? checkmarkIcon : themeSettingsIcons[designTheme]}
      <Text size={TEXT_SIZE.sub} colorText={themeSettingsText[designTheme]}>
        {messageText}
      </Text>
    </ValidContainer>
  );
};
