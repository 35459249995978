type BreadcrumbItem = {
  name: string;
  path: string;
};

type RouteHandler = {
  pattern: RegExp | ((pathname: string) => boolean);
  handler: (pathname: string, pathnames: string[], previousPath?: string) => BreadcrumbItem[];
};

export const routeHandlers: RouteHandler[] = [
  {
    pattern: /^\/dashboard(\/|$)/,
    handler: () => [
      {
        name: 'breadCrumbs.dashboard',
        path: '/dashboard',
      },
    ],
  },
  {
    pattern: /^\/authenticate/,
    handler: () => [
      {
        name: 'breadCrumbs.dashboard',
        path: '/dashboard',
      },
      {
        name: 'breadCrumbs.authenticate',
        path: '',
      },
    ],
  },
  {
    pattern: /^\/card\//,
    handler: (pathname, pathnames, previousPath) => {
      const breadcrumbItems: BreadcrumbItem[] = [
        {
          name: 'breadCrumbs.dashboard',
          path: '/dashboard',
        },
      ];

      if (previousPath) {
        const prevPathnames = previousPath.split('/').filter((x) => x);

        if (prevPathnames[0] === 'authentications') {
          breadcrumbItems.push({
            name: 'breadCrumbs.authentications',
            path: '/authentications/all',
          });

          if (prevPathnames[1]) {
            breadcrumbItems.push({
              name: `breadCrumbs.${prevPathnames[1]}`,
              path: `/authentications/${prevPathnames[1]}`,
            });
          }
        }
      }

      breadcrumbItems.push({
        name: 'breadCrumbs.orderDetails',
        path: '',
      });

      return breadcrumbItems;
    },
  },
  {
    pattern: /^\/authentications/,
    handler: (pathname, pathnames) => {
      const breadcrumbItems: BreadcrumbItem[] = [
        {
          name: 'breadCrumbs.dashboard',
          path: '/dashboard',
        },
        {
          name: 'breadCrumbs.authentications',
          path: '/authentications/all',
        },
      ];

      if (pathnames[1]) {
        const isLast = pathnames.length === 2;
        breadcrumbItems.push({
          name: `breadCrumbs.${pathnames[1]}`,
          path: isLast ? '' : `/authentications/${pathnames[1]}`,
        });
      }

      // Handle additional sub-paths if any
      for (let i = 2; i < pathnames.length; i++) {
        const isLast = i === pathnames.length - 1;
        breadcrumbItems.push({
          name: `breadCrumbs.${pathnames[i]}` || pathnames[i],
          path: isLast ? '' : `/${pathnames.slice(0, i + 1).join('/')}`,
        });
      }

      return breadcrumbItems;
    },
  },
  {
    pattern: /^\/account-settings/,
    handler: (pathname, pathnames) => {
      const breadcrumbItems: BreadcrumbItem[] = [
        {
          name: 'breadCrumbs.dashboard',
          path: '/dashboard',
        },
        {
          name: 'breadCrumbs.settings',
          path: '/account-settings/general-info',
        },
      ];

      if (pathnames[1]) {
        const isLast = pathnames.length === 2;
        breadcrumbItems.push({
          name: `breadCrumbs.${pathnames[1]}`,
          path: isLast ? '' : `/account-settings/${pathnames[1]}`,
        });
      }

      // Handle additional sub-paths if any
      for (let i = 2; i < pathnames.length; i++) {
        const isLast = i === pathnames.length - 1;
        breadcrumbItems.push({
          name: `breadCrumbs.${pathnames[i]}` || pathnames[i],
          path: isLast ? '' : `/${pathnames.slice(0, i + 1).join('/')}`,
        });
      }

      return breadcrumbItems;
    },
  },
];
