import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useCheckAuthQuery } from '../../api/auth/hooks/useCheckAuthQuery';

import { useUserUTMMutate } from '../../api/UserUTM/hooks';
import { userUTMRequest } from '../../api/UserUTM/types';

const IS_UTM_URL = 'utm_source';
const UTM_KEY = 'utm_package';
const UTM_ROOT_URL = 'utm_root_url';

export const useCheckUTM = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [params] = useSearchParams();

  const tokenFromGoogle = params.get('token');
  const isJwtKey = Boolean(tokenFromGoogle) || Boolean(Cookies.get('jwt'));

  const { data: isValidToken, isError: isErrorValidToken } = useCheckAuthQuery(
    IS_UTM_URL in Object.fromEntries(searchParams) || window.localStorage.getItem(UTM_KEY) ? tokenFromGoogle : null,
  );

  const {
    mutate: getSendUserUTMData,
    data: sendUserUTMData,
    isSuccess: isSuccessSendUserUTM,
    isError: isErrorSendUserUTM,
  } = useUserUTMMutate();

  const hashUTM = window.localStorage.getItem(UTM_KEY);

  if (hashUTM && hashUTM !== 'undefined' && !sendUserUTMData && isValidToken) {
    window.localStorage.removeItem(UTM_KEY);
    getSendUserUTMData(JSON.parse(hashUTM));
  }

  useEffect(() => {
    if ((sendUserUTMData && isSuccessSendUserUTM) || isErrorSendUserUTM) {
      const rootURL = window.localStorage.getItem(UTM_ROOT_URL);

      if (rootURL) {
        window.localStorage.removeItem(UTM_ROOT_URL);
        navigate(rootURL);
      }
    }
  }, [isSuccessSendUserUTM, isErrorSendUserUTM]);

  useEffect(() => {
    if (IS_UTM_URL in Object.fromEntries(searchParams)) {
      window.localStorage.setItem(UTM_ROOT_URL, pathname);

      const userParamsUTM = Object.fromEntries(searchParams);

      if (isValidToken && !sendUserUTMData) {
        getSendUserUTMData(userParamsUTM as userUTMRequest);
      }

      if (isErrorValidToken || !isJwtKey) {
        window.localStorage.setItem(UTM_KEY, JSON.stringify(userParamsUTM));
      }
    }
  }, [searchParams, isValidToken, isErrorValidToken, isJwtKey]);
};
