import styled from 'styled-components';
import { Z_INDEX_LAYERS } from '../../componentUI';
import { COLOR_MAP } from '../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../componentUI/screen';

const ContainerStyled = styled.div`
  width: min(554px, 100%);

  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;

  border-radius: 12px;

  background-color: ${COLOR_MAP.background.white};

  z-index: ${Z_INDEX_LAYERS.modalsLayer.overlayLayer};

  ${truthfulAdaptive.maxWidth.tablet} {
    padding: 0px 16px 16px 16px;
    gap: 0px;
  }
`;

export { ContainerStyled };
