/* eslint-disable max-len */
import React from 'react';

const InfoWarningIcon = ({ size = '24' }: { size?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#F46C20"
      />
      <path d="M12 16V12" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 8H12.01" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const CheckmarkIcon = ({ size = '16' }: { size?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M7.99998 0L8.92607 2.15288L10.4721 0.391548L10.6876 2.72524L12.7022 1.52786L12.186 3.81393L14.4721 3.29772L13.2747 5.31238L15.6084 5.52786L13.8471 7.07391L16 8L13.8471 8.92609L15.6084 10.4721L13.2747 10.6876L14.4721 12.7023L12.186 12.1861L12.7022 14.4721L10.6876 13.2748L10.4721 15.6085L8.92607 13.8471L7.99998 16L7.07389 13.8471L5.52785 15.6085L5.31236 13.2748L3.29771 14.4721L3.81392 12.1861L1.52786 12.7023L2.72523 10.6876L0.391547 10.4721L2.15288 8.92609L0 8L2.15288 7.07391L0.391547 5.52786L2.72523 5.31238L1.52786 3.29772L3.81392 3.81393L3.29771 1.52786L5.31236 2.72524L5.52785 0.391548L7.07389 2.15288L7.99998 0Z"
        fill="white"
      />
      <path
        d="M7.99998 0L9.85407 2.29366L12.7022 1.52786L12.8541 4.47329L15.6084 5.52786L14 8L15.6084 10.4721L12.8541 11.5267L12.7022 14.4721L9.85407 13.7063L7.99998 16L6.14588 13.7063L3.29771 14.4721L3.14589 11.5267L0.391547 10.4721L1.99999 8L0.391547 5.52786L3.14589 4.47329L3.29771 1.52786L6.14588 2.29366L7.99998 0Z"
        fill="white"
      />
      <g filter="url(#filter0_d_14399_17335)">
        <path
          d="M4.79999 8.47961L7.0588 10.3996L11.2 5.59961"
          stroke="url(#paint0_linear_14399_17335)"
          strokeWidth="1.2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_14399_17335"
          x="3.19995"
          y="5"
          width="9.60004"
          height="8.24219"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.475 0 0 0 0 0.36432 0 0 0 0 0.158333 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14399_17335" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14399_17335" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_14399_17335"
          x1="4.79999"
          y1="10.3996"
          x2="11.3261"
          y2="10.2215"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51977A" />
          <stop offset="1" stopColor="#2BBC99" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Shield = ({ color = '#CBAD73', size = '20' }: { color?: string; size?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_14609_5656)">
      <path
        d="M16.6667 10.0001C16.6667 15.0001 10 18.3335 10 18.3335C10 18.3335 3.33337 15.0001 3.33337 10.0001V4.16681L10 1.66681L16.6667 4.16681V10.0001Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14609_5656">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Flag = ({ color = 'black' }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M8 14C5 14 4 15 4 15V3C4 3 5 2 8 2C11 2 13 4 16 4C19 4 20 3 20 3V15C20 15 19 16 16 16C13 16 11 14 8 14Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4 22V15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const ShieldOff = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <g clipPath="url(#clip0_14744_203)">
        <path
          d="M22.9718 16.3334C23.2064 15.5776 23.3283 14.7914 23.3335 14V5.83337L14.0001 2.33337L10.3135 3.71004"
          stroke="#666666"
          strokeWidth="2.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.51817 5.51831L4.6665 5.83331V14C4.6665 21 13.9998 25.6666 13.9998 25.6666C16.4697 24.3634 18.6895 22.6334 20.5565 20.5566"
          stroke="#666666"
          strokeWidth="2.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.1665 1.16663L26.8332 26.8333"
          stroke="#666666"
          strokeWidth="2.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_14744_203">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const InfoIconFillIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="white"
      />
      <path d="M12 16V12" stroke="#F09C1E" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 8H12.01" stroke="#F09C1E" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const Hammer = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M1.6665 16.6667C1.6665 15.7462 2.4127 15 3.33317 15H10.8332C11.7536 15 12.4998 15.7462 12.4998 16.6667V18.3333H1.6665V16.6667Z"
        stroke="#CBAD73"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2975 8.29774L17.567 14.4639M8.39577 5.61016L11.2421 9.72092M12.5065 2.76381L15.3529 6.87457M9.87187 10.6697L16.7231 5.92578C17.1015 5.66379 17.1959 5.14465 16.9339 4.76627L15.0363 2.02577C14.7743 1.64738 14.2552 1.55303 13.8768 1.81503L7.02552 6.55895C6.64714 6.82095 6.55279 7.34008 6.81479 7.71847L8.71235 10.459C8.97435 10.8374 9.49349 10.9317 9.87187 10.6697Z"
        stroke="#CBAD73"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const LifeBuoy = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="#2976EA"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
        stroke="#2976EA"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.93018 4.92999L9.17018 9.16999"
        stroke="#2976EA"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8301 14.83L19.0701 19.07"
        stroke="#2976EA"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8301 9.16999L19.0701 4.92999"
        stroke="#2976EA"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8301 9.17001L18.3601 5.64001"
        stroke="#2976EA"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.93018 19.07L9.17018 14.83"
        stroke="#2976EA"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ExternalLinkIcon = ({ size = '16', color = '#CBAD73' }: { size?: string; color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path
        d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 2H14V6" stroke={color} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.66666 9.33333L14 2" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const TimerIcon = ({ size = '14', color = '#666666' }: { size?: string; color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 14 14" fill="none">
      <path
        d="M7 12.833C9.89949 12.833 12.25 10.4825 12.25 7.58301C12.25 4.68351 9.89949 2.33301 7 2.33301C4.1005 2.33301 1.75 4.68351 1.75 7.58301C1.75 10.4825 4.1005 12.833 7 12.833Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 4.66699V7.72949L9.33333 8.75033"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 1.16699L7 2.33366" stroke={color} strokeWidth="1.2" />
      <path d="M8.16659 0.717773H5.83325" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
      <path d="M2.50635 2.95996L3.18621 3.63982" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
      <path d="M11.4789 2.95996L10.8198 3.61905" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
    </svg>
  );
};

export {
  CheckmarkIcon,
  ExternalLinkIcon,
  Flag,
  Hammer,
  InfoIconFillIcon,
  InfoWarningIcon,
  LifeBuoy,
  Shield,
  ShieldOff,
  TimerIcon,
};
