import { SelectedSetFiltersType } from '../containers/AuthenticationsTablet/types';

/** преобразует объект с набором выбранных фильтров в тот
 * что примет бэк */

export const convertingObjectQuery = (filters: SelectedSetFiltersType) => {
  const keys = Object.keys(filters);

  const nameObject = keys.reduce<SelectedSetFiltersType>((acc, key) => {
    const name = filters[key].nameObject;

    if (typeof name === 'string') {
      const correctObject = {
        [name]: {
          ...filters[key],
        },
      };

      return { ...acc, ...correctObject };
    }

    return acc;
  }, {});

  return nameObject;
};
