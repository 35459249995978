import styled from 'styled-components';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';

const SummaryInformationStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const WrapperCreditAndDiscountStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const WrapperCashbackStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const WrapperTotalLGStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MainSeparatorStyled = styled.div`
  border-bottom: 2px solid ${COLOR_MAP.text.grey};
`;

const SecondarySeparatorStyled = styled.div`
  border-bottom: 1px solid ${COLOR_MAP.text.lightGrey};
`;

export {
  MainSeparatorStyled,
  SecondarySeparatorStyled,
  SummaryInformationStyled,
  WrapperCashbackStyled,
  WrapperCreditAndDiscountStyled,
  WrapperTotalLGStyled,
};
