import React, { useState } from 'react';
import { EyeIcon, EyeOffIcon } from '../../../common/icons/SvgInline/SvgIcons_3';

export const usePasswordIconManage = (password: string) => {
  const [passTypeInput, setPassTypeInput] = useState<'password' | 'text'>('password');

  const eyeClickHandler = () => {
    setPassTypeInput((prev) => (prev === 'password' ? 'text' : 'password'));
  };

  const rightIconPassword =
    Boolean(password) && passTypeInput === 'password' ? (
      <EyeIcon clickHandler={eyeClickHandler} />
    ) : (
      <EyeOffIcon clickHandler={eyeClickHandler} />
    );

  return { rightIconPassword, passTypeInput };
};
