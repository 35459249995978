/* eslint-disable max-len */
import React from 'react';

const LockIcon = ({ fill = '#888888' }: { fill?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8333 9.1665H4.16667C3.24619 9.1665 2.5 9.9127 2.5 10.8332V16.6665C2.5 17.587 3.24619 18.3332 4.16667 18.3332H15.8333C16.7538 18.3332 17.5 17.587 17.5 16.6665V10.8332C17.5 9.9127 16.7538 9.1665 15.8333 9.1665Z"
        stroke={fill}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83325 9.1665V5.83317C5.83325 4.7281 6.27224 3.66829 7.05364 2.88689C7.83504 2.10549 8.89485 1.6665 9.99992 1.6665C11.105 1.6665 12.1648 2.10549 12.9462 2.88689C13.7276 3.66829 14.1666 4.7281 14.1666 5.83317V9.1665"
        stroke={fill}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const MatchIcon = ({ size = '20', color = '#6BCBA9' }: { size?: string; color?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9693_7733)">
        <path
          d="M18.3332 9.23336V10C18.3321 11.797 17.7503 13.5456 16.6743 14.9849C15.5983 16.4242 14.0859 17.4771 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8122C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0234 1.56633 11.2401 1.68457 9.44696C1.80281 7.65383 2.49751 5.94697 3.66507 4.58092C4.83263 3.21488 6.41049 2.26285 8.16333 1.86682C9.91617 1.47079 11.7501 1.65198 13.3915 2.38336"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3333 3.33331L10 11.675L7.5 9.17498"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9693_7733">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const MismatchIcon = ({ size = '20', color = '#DE4141' }: { size?: string; color?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9693_10362)">
        <path
          d="M13.4503 1.66669H6.55033L1.66699 6.55002V13.45L6.55033 18.3334H13.4503L18.3337 13.45V6.55002L13.4503 1.66669Z"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12.5 7.5L7.5 12.5" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5 7.5L12.5 12.5" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_9693_10362">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const PasswordMatchVerificationIcon = ({
  isValid,
  password,
  size = '20',
}: {
  isValid?: string;
  password?: string;
  size?: string;
}) => {
  if (password === '') {
    return <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" />;
  }

  return !isValid ? <MatchIcon /> : <MismatchIcon />;
};

export { LockIcon, PasswordMatchVerificationIcon };
