import styled, { CSSProperties } from 'styled-components';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../componentUI/screen';
import { TEXT_WEIGHT } from '../../../../componentUI/simple/Text';

const ConvertedBalanceModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const WrapperTitleStyled = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 0 8px;

  ${truthfulAdaptive.maxWidth.mobile} {
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    gap: 8px;
  }
`;

const SeparatorFormStyled = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(203, 173, 115, 0.2);
`;

const WrapperConvertCreditStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${truthfulAdaptive.maxWidth.mobile} {
    gap: 8px;
  }
`;

const DescriptionTextStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const WrapperConvertBalanceElementStyled = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;

  ${truthfulAdaptive.maxWidth.mobile} {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 4px 0;
  }
`;

const WrapperConvertBalanceBonusElementStyled = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
`;

const WrapperConvertTotalBalanceElementStyled = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
`;

const WrapperOldBalanceStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
`;

const WrapperNewBalanceStyled = styled.div`
  display: flex;
  gap: 0 8px;
`;

const SeparatorBalanceStyled = styled.div<{ color?: string }>`
  ${({ color }) =>
    color ? `border-bottom: 1px dotted ${color};` : `border-bottom: 1px dotted ${COLOR_MAP.text.grey};`}
`;

const CircleMarkerOldBalanceStyled = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgba(203, 173, 115, 0.4);
`;

const WrapperBonusStyled = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InformationalTextStyled = styled.div`
  padding: 8px 10px;
  border: solid 1px ${COLOR_MAP.accent.lightBlue};
`;

const stylesForButtons: CSSProperties = {
  gap: '10px',

  textTransform: 'uppercase',
  fontWeight: TEXT_WEIGHT.bold,
};

export {
  CircleMarkerOldBalanceStyled,
  ConvertedBalanceModalStyled,
  InformationalTextStyled,
  SeparatorBalanceStyled,
  SeparatorFormStyled,
  DescriptionTextStyled,
  WrapperBonusStyled,
  WrapperConvertBalanceBonusElementStyled,
  WrapperConvertBalanceElementStyled,
  WrapperConvertCreditStyled,
  WrapperConvertTotalBalanceElementStyled,
  WrapperNewBalanceStyled,
  WrapperOldBalanceStyled,
  WrapperTitleStyled,
  stylesForButtons,
};
