import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './image-gallery.scss';

import { ButtonV2, SecondButton } from '../../componentUI/simple/Button';

import { useMatchMedia } from '../../hooks';

import { Gallery } from './components';
import { FooterStyled, stylesForButtons } from './styled';
import { Props } from './types';

export const OnboardingSlider = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const { isTablet } = useMatchMedia();

  const [isLastSlide, setIsLastSlide] = useState(false);

  const rightNavRef = useRef<HTMLButtonElement>(null);

  const handleButtonClick = () => {
    if (rightNavRef.current) {
      rightNavRef.current.click();
    }
  };

  return (
    <>
      {isTablet && (
        <SecondButton onClick={onClose} style={stylesForButtons}>
          {t('onboardingSlider.closeBtn')}
        </SecondButton>
      )}

      <Gallery rightNavRef={rightNavRef} setIsLastSlide={setIsLastSlide} onClose={onClose} />

      <FooterStyled>
        {!isTablet && (
          <SecondButton onClick={onClose} style={stylesForButtons}>
            {t('onboardingSlider.closeBtn')}
          </SecondButton>
        )}

        <ButtonV2
          style={{ textTransform: 'uppercase' }}
          isStretch={isTablet}
          onClick={handleButtonClick}
          aria-label="Next Slide"
        >
          {isLastSlide ? t('onboardingSlider.lastSlideBtn') : t('onboardingSlider.nextSlideBtn')}
        </ButtonV2>
      </FooterStyled>
    </>
  );
};
