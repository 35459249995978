import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { LeftNav } from './LeftNav';
import { RightNav } from './RightNav';
import './image-gallery.scss';
import { useMatchMedia } from '../../../hooks';

/**
 * модалка для отображения фотографий в индексе когда их больше одной
 */

type Props = {
  FilesImages: string[];
};

export const ViewingExamplePhotos = ({ FilesImages }: Props) => {
  const { isDesktopSNew } = useMatchMedia();
  const [maxImageHeight, setMaxImageHeight] = useState(0);

  useEffect(() => {
    // Calculate the maximum height of all images
    const loadImageHeights = async () => {
      const heights = await Promise.all(
        FilesImages.map((src) => {
          return new Promise<number>((resolve) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve(img.height);
          });
        }),
      );
      setMaxImageHeight(Math.max(...heights));
    };

    loadImageHeights();
  }, [FilesImages]);

  const images = FilesImages.map((element) => {
    return {
      original: `${element || ''}`,
      thumbnail: `${element || ''}`,
      renderItem: () => (
        <div className="image-wrapper">
          <img src={element || ''} alt="" className="image-gallery-image" style={{ height: maxImageHeight }} />
        </div>
      ),
    };
  });

  return (
    // <div className="example-image-wrapper">
    <ImageGallery
      additionalClass="example-gallery"
      showNav={!isDesktopSNew}
      showPlayButton={false}
      showBullets={false}
      showFullscreenButton={false}
      items={images}
      showThumbnails={FilesImages.length > 1}
      renderLeftNav={(onClick, disabled) => <LeftNav onClick={onClick} disabled={disabled} />}
      renderRightNav={(onClick, disabled) => <RightNav onClick={onClick} disabled={disabled} />}
    />
    // </div>
  );
};
