import React from 'react';

import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { SkeletonBasic } from '../../../componentUI/skeleton';

import { NotificationsSkeletonStyled } from './styled';

export const NotificationsSkeleton = () => {
  return (
    <NotificationsSkeletonStyled>
      <SkeletonBasic width="455px" height="95px" bgColor={COLOR_MAP.controls.skeleton.content1} />
      <SkeletonBasic width="455px" height="95px" bgColor={COLOR_MAP.controls.skeleton.content1} />
      <SkeletonBasic width="455px" height="95px" bgColor={COLOR_MAP.controls.skeleton.content1} />
      <SkeletonBasic width="455px" height="95px" bgColor={COLOR_MAP.controls.skeleton.content1} />
    </NotificationsSkeletonStyled>
  );
};
