// Helper function to determine if user is from USA
export const isUSALocale = (language: string): boolean => {
  return language === 'en-US' || (language === 'en' && navigator.language === 'en-US');
};

/**
 * Formats the date based on the provided language and user's locale
 * @param date The date string to be formatted
 * @param language The current language (from i18next or manually provided)
 * @param options Intl.DateTimeFormat options for customization
 */
export const formatDateByLang = (date: string, language: string, options?: Intl.DateTimeFormatOptions): string => {
  const dateObj = new Date(date);

  // For English, we need to check if it's US English or other English-speaking regions
  if (language === 'en') {
    const locale = isUSALocale(language) ? 'en-US' : 'en-GB';
    return dateObj.toLocaleDateString(locale, options);
  }

  // For other languages, use their locale conventions
  return dateObj.toLocaleDateString(language, options);
};
