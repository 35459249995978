import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { uploadRequestPhoto } from '../product-api';
import { UploadRequestBody } from '../type';

type ErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

export const useUploadRequestPhotoMutate = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationKey: [QUERY_KEY.UploadRequestPhotoMutate],
    mutationFn: async (value: UploadRequestBody) => {
      const { data } = await uploadRequestPhoto(value);

      return data;
    },
    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Error while uploading photo. Please try again.',
        }),
      );
    },
  });
};
