import styled from 'styled-components';

import { truthfulAdaptive } from '../../../componentUI/screen';

const MainContainerCertificateInfoStyled = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr;
  gap: 16px;
`;

const MainContainerCertificateInfoBlockOne = styled.div`
  display: flex;
  gap: 20px;

  ${truthfulAdaptive.maxWidth.mobile} {
    flex-direction: column;
  }
`;

const MainContainerCertificateInfoBlockTwo = styled.div`
  justify-self: end;

  max-width: 600px;
  width: 100%;

  display: flex;
  gap: 20px;

  ${truthfulAdaptive.maxWidth.mobile} {
    flex-direction: column;
  }
`;

export {
  MainContainerCertificateInfoBlockOne,
  MainContainerCertificateInfoBlockTwo,
  MainContainerCertificateInfoStyled,
};
