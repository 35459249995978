import Cookies from 'js-cookie';
import { instance } from '../axios-instance';
import { CreateProductUploadResponseType, LogoForCertificateType, UpdateAvatarResponseType } from './types';

export const postCreateProductFileUpload = (data: FormData) => {
  return instance.post<Array<CreateProductUploadResponseType>>('/file/create-product-upload', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const postUpdateProductFileUpload = (data: FormData) => {
  return instance.post<Array<CreateProductUploadResponseType>>('/file/upload-requested-photo', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const uploadLogoForCertificates = (data: FormData) => {
  return instance.post<LogoForCertificateType>('file/logo', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getLogoForCertificates = () => {
  return instance.get('file/get-user-logos', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};
export const deleteLogoForCertificates = (id: string) => {
  return instance.delete(`file/${id}/logo`, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const uploadAvatarUser = (newAvatar: FormData) => {
  return instance.post<UpdateAvatarResponseType>('file/avatar', newAvatar, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const removeAvatarUser = () => {
  return instance.delete('file/avatar', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};
