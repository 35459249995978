import React from 'react';
import styled from 'styled-components';
import { InfoIcon } from '../../common/icons/SvgInline/SvgIcons_5';
import { COLOR_MAP } from '../../componentUI/colorsMap';
import { LinkClassic } from '../../componentUI/simple/Link';
import { Text } from '../../componentUI/simple/Text';

type Props = {
  text: string;
  linkData?: {
    link: string;
    linkDescription: string;
  };
};

const Container = styled.div`
  padding: 10px;

  display: grid;
  grid-template-columns: auto 1fr;
  gap: 6px;

  border-radius: 4px;

  background-color: ${COLOR_MAP.accent.blue};
`;

export const InfoBlock = ({ text, linkData }: Props) => {
  return (
    <Container>
      <InfoIcon color={COLOR_MAP.text.white} size="20px" />
      {linkData ? (
        <Text colorText={COLOR_MAP.text.white}>
          {text}
          <LinkClassic colorText={COLOR_MAP.text.white} href={linkData.link}>
            {linkData.linkDescription}
          </LinkClassic>
        </Text>
      ) : (
        <Text colorText={COLOR_MAP.text.white}>{text} </Text>
      )}
    </Container>
  );
};
