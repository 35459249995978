/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { Button, SecondButton } from '../../../../componentUI/simple/Button';
import { Hr } from '../../../../componentUI/simple/Hr';

import { useCreateCertificateMutation } from '../../../../api/product/hooks/useCreateCertificateMutation';
import { WrapperCancelButtonStyle, WrapperFooterButtonStyled, WrapperGenerateButtonStyle } from './styled';
import { Props } from './types';

export const AddCertificateFromCard = ({
  setIsOpenCertificateInfoBtn,
  onRefetch,
  userCertificateTemplateId,
  idCard,
  isIssueCertificate,
}: Props) => {
  const { mutate: addTemplateCertificate, isSuccess: isSuccessAddCertificate } = useCreateCertificateMutation();

  const handleTemplateCertificateChange = () => {
    if (idCard) {
      addTemplateCertificate({
        productId: idCard,
        userCertificateTemplateId,
      });
    }
  };

  // при успешном выполнении запроса, закрываем функционал создания сертификата
  // и перезапрашиваем данные карточки
  useEffect(() => {
    if (isSuccessAddCertificate && setIsOpenCertificateInfoBtn && onRefetch) {
      setIsOpenCertificateInfoBtn(false);
      onRefetch();
    }
  }, [isSuccessAddCertificate]);

  return (
    <>
      <Hr style={{ width: '100%' }} />

      <WrapperFooterButtonStyled>
        <WrapperCancelButtonStyle>
          <SecondButton isStretch onClick={() => setIsOpenCertificateInfoBtn && setIsOpenCertificateInfoBtn(false)}>
            CANCEL
          </SecondButton>
        </WrapperCancelButtonStyle>

        <WrapperGenerateButtonStyle>
          <Button isStretch disabled={isIssueCertificate} onClick={handleTemplateCertificateChange}>
            GENERATE CERTIFICATE
          </Button>
        </WrapperGenerateButtonStyle>
      </WrapperFooterButtonStyled>
    </>
  );
};
