import { formatDateByLang, isUSALocale } from './formatDateByLang';

/**
 * Returns the date in the format - MM.DD.YYYY HH:MM (or DD.MM.YYYY HH:MM for non-USA)
 */
export const dataFormatterViewFive = (date: string, language: string): string => {
  const isUSA = isUSALocale(language);

  return formatDateByLang(date, language, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: isUSA ? '2-digit' : 'numeric',
    minute: 'numeric',
    hour12: isUSA,
  })
    .replace(',', '')
    .replaceAll('/', '.');
};

/**
 * Returns the date in the format - MM.DD.YYYY at HH:MM (or DD.MM.YYYY at HH:MM for non-USA)
 */
export const dataFormatterFromTable = (date: string, language: string): string => {
  const isUSA = isUSALocale(language);

  return formatDateByLang(date, language, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: isUSA ? '2-digit' : 'numeric',
    minute: 'numeric',
    hour12: isUSA,
  })
    .replace(',', ' at ')
    .replaceAll('/', '.');
};

/**
 * Returns the date in the format - MM/DD/YYYY (or DD/MM/YYYY for non-USA)
 */
export const dataFormatter = (date: string, language: string): string => {
  return formatDateByLang(date, language, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  }).replace(/ /g, '/');
};

/**
 * Returns the date in the format - MM.DD.YYYY (or DD.MM.YYYY for non-USA)
 */
export const dataDotFormatter = (date: string, language: string): string => {
  return formatDateByLang(date, language, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  }).replaceAll('/', '.');
};

/**
 * Returns the date in the format - 9:18 AM, April 26, 2023 (or 09:18, 26 April 2023 for non-USA)
 */
export const dataDotFormatterNotification = (date: string, language: string): string => {
  const isUSA = isUSALocale(language);
  const dateObj = new Date(date);

  const time = dateObj.toLocaleTimeString(language, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: isUSA,
  });

  const formattedDate = formatDateByLang(date, language, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return `${time}, ${formattedDate}`;
};
