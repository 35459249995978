import styled from 'styled-components';

import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../../componentUI/screen';

const TableHeaderStyled = styled.div<{ isDraftPage: boolean }>`
  padding: 10px 8px;
  display: grid;
  grid-template-columns: ${({ isDraftPage }) =>
    isDraftPage
      ? 'minmax(0px, 32px) minmax(0px, 32px) minmax(118px, 138px) repeat(auto-fit, minmax(0px, auto));'
      : 'minmax(0px, 32px) minmax(118px, 138px) repeat(auto-fit, minmax(0px, auto));'};

  align-items: center;
  gap: 10px;

  border-bottom: 1px solid ${COLOR_MAP.accent.grey};
`;

const CheckboxTitleStyled = styled.div`
  height: 100%;
  width: 32px;

  display: flex;
  align-items: center;
`;

const ImgTitleStyled = styled.div`
  height: 100%;
  width: 32px;
`;

const OrderNumberTitleStyled = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;

  white-space: nowrap;
`;

const StatusTitleStyled = styled.div`
  width: 120px;

  display: flex;
  align-items: center;
  gap: 6px;

  ${truthfulAdaptive.maxWidth.desktop} {
    width: clamp(62px, 5.2vw, 120px);
  }
`;

const DateTitleStyled = styled.div`
  width: 75px;

  display: flex;
  align-items: center;
  gap: 6px;
`;

const BrandTitleStyled = styled.div`
  width: clamp(165px, calc(10.2vw + 12px), 214px);

  display: flex;
  align-items: center;
  gap: 6px;

  ${truthfulAdaptive.maxWidth.desktop1600} {
    width: clamp(138px, 8.6vw, 165px);
  }
  ${truthfulAdaptive.maxWidth.desktop1440} {
    width: clamp(108px, 7.5vw, 138px);
  }
  ${truthfulAdaptive.maxWidth.desktop} {
    width: clamp(62px, 5.2vw, 108px);
  }
`;

const ModelTitleStyled = styled.div`
  width: clamp(165px, calc(10.2vw + 12px), 214px);

  display: flex;
  align-items: center;
  gap: 6px;

  ${truthfulAdaptive.maxWidth.desktop1600} {
    width: clamp(138px, 8.6vw, 165px);
  }
  ${truthfulAdaptive.maxWidth.desktop1440} {
    width: clamp(108px, 7.5vw, 138px);
  }
  ${truthfulAdaptive.maxWidth.desktop} {
    width: clamp(62px, 5.2vw, 108px);
  }
`;

const SKUTitleStyled = styled.div`
  width: clamp(165px, calc(8.6vw + 10px), 182px);

  display: flex;
  align-items: center;
  gap: 6px;

  ${truthfulAdaptive.maxWidth.desktop1600} {
    width: clamp(138px, 8.6vw, 165px);
  }
  ${truthfulAdaptive.maxWidth.desktop1440} {
    width: clamp(108px, 7.5vw, 138px);
  }
  ${truthfulAdaptive.maxWidth.desktop} {
    width: clamp(62px, 5.2vw, 108px);
  }
`;

const AnswerTitleStyled = styled.div`
  width: 100px;

  display: flex;
  align-items: center;
  gap: 6px;
`;

const OutcomeTitleStyled = styled.div`
  width: 100px;

  display: flex;
  align-items: center;
  gap: 6px;
`;

const ActionTitleStyled = styled.div`
  width: 128px;

  display: flex;
  align-items: center;
  gap: 6px;
`;

const WrapperChevronStyled = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  align-content: space-between;

  cursor: pointer;
`;

const ButtonIconStyled = styled.button`
  padding: 0px;
  margin: 0px;

  width: 10px;
  height: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: none;

  cursor: pointer;
`;

export {
  ActionTitleStyled,
  AnswerTitleStyled,
  BrandTitleStyled,
  ButtonIconStyled,
  CheckboxTitleStyled,
  DateTitleStyled,
  ImgTitleStyled,
  ModelTitleStyled,
  OrderNumberTitleStyled,
  OutcomeTitleStyled,
  SKUTitleStyled,
  StatusTitleStyled,
  TableHeaderStyled,
  WrapperChevronStyled,
};
