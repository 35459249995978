import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { confirmEmail } from '../auth-api';

import { ConfirmEmailRequestType } from '../authType';

type ErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

export const useConfirmEmailMutate = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationKey: [QUERY_KEY.useConfirmEmailMutate],
    mutationFn: async (dataConfirmEmail: ConfirmEmailRequestType) => {
      const { data } = await confirmEmail(dataConfirmEmail);

      return data;
    },
    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};
