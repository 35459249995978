import Cookies from 'js-cookie';
import { instance } from '../axios-instance';
import { referralCodesActivateResponseTypes } from './types';

export const referralCodesActivate = (code: string) => {
  return instance.post<referralCodesActivateResponseTypes>(
    'referral-codes/apply',
    { code },
    {
      headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
    },
  );
};
