import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { truthfulAdaptive } from '../../componentUI/screen';

const Container = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;

  ${truthfulAdaptive.maxWidth.mobile} {
    grid-template-columns: 1fr;
  }
`;

type Props = {
  children: ReactNode;
};

export const AuthContainer = ({ children }: Props) => {
  return <Container>{children}</Container>;
};
