import styled from 'styled-components';

export const DescriptionListStyled = styled.div`
  max-width: 484px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const WrapperElementDescriptionStyled = styled.div`
  display: flex;
  gap: 16px;
`;

export const WrapperTextStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
