import React, { ChangeEvent, InputHTMLAttributes, isValidElement, ReactNode, useCallback } from 'react';
import styled from 'styled-components';

import { Text } from '../Text';

import { COLOR_MAP } from '../../colorsMap';
import { noop, VisuallyHiddenInput } from '../../utils';

import { CHECKBOX_BODY_STYLE, checkboxTypes, TYPE_CHECKBOX } from './constants';
import { IconCheckbox } from './IconCheckbox';

type BasePropsCheckbox = {
  checked?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  typeCheckbox: checkboxTypes;
};

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> &
  Omit<BasePropsCheckbox, 'typeCheckbox'> & {
    onChange?: (isChecked: boolean) => void;
    isRight?: boolean;
    label?: string | ReactNode;
    typeCheckbox?: checkboxTypes;
  };

const CheckboxStyled = styled.label<{ isRight?: boolean; disabled?: boolean }>`
  box-sizing: border-box;
  display: flex;

  gap: 8px;
  ${({ isRight }) => (isRight ? 'flex-direction: row-reverse; justify-content: flex-end;' : '')};
  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')};

  user-select: none;
  cursor: pointer;
`;

const CheckboxWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CheckboxRenderStyled = styled.span<BasePropsCheckbox>`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ typeCheckbox }) => CHECKBOX_BODY_STYLE[typeCheckbox]}

  border-color: ${({ hasError, disabled, typeCheckbox }) =>
    (typeCheckbox === 'type2' && COLOR_MAP.controls.disabled) || // костыль
    (hasError && COLOR_MAP.accent.red) ||
    (disabled && COLOR_MAP.controls.disabled)};

  background-color: ${({ disabled }) => (disabled ? ` ${COLOR_MAP.controls.fillDisabled}` : '')};

  border-radius: 3px;
`;

export const Checkbox = ({
  checked = false,
  disabled,
  label = '',
  hasError,
  onChange = noop,
  typeCheckbox = TYPE_CHECKBOX.type1,
  isRight,
}: Props) => {
  const changeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.checked);
    },
    [onChange],
  );

  // без данной проверки, если проп label пустой, рисуется
  // пустой элемент <p> к которому применяется отступ обертки
  const isEmptyValue = typeof label === 'string' && label === '';

  const labelRender = isValidElement(label) ? label : <Text>{label}</Text>;

  return (
    <CheckboxStyled isRight={isRight} disabled={disabled}>
      <CheckboxWrapperStyled>
        <VisuallyHiddenInput type="checkbox" onChange={changeHandler} checked={checked} />

        <CheckboxRenderStyled disabled={disabled} hasError={hasError} typeCheckbox={typeCheckbox}>
          {checked && <IconCheckbox typeCheckbox={typeCheckbox} />}
        </CheckboxRenderStyled>
      </CheckboxWrapperStyled>
      {!isEmptyValue && labelRender}
    </CheckboxStyled>
  );
};
