import Cookies from 'js-cookie';
import { refreshTokenRequest } from '../api/auth/auth-api';

export const refreshAuthToken = async (refreshToken: string | undefined) => {
  if (refreshToken) {
    try {
      const { data } = await refreshTokenRequest({ refreshToken });

      Cookies.set('jwt', data.token);
      Cookies.set('jwt-refresh', data.refreshToken);
    } catch {
      const { protocol, host } = window.location;

      Cookies.remove('jwt');
      Cookies.remove('jwt-refresh');

      window.location.href = `${protocol}//${host}/signin`;
    }
    return;
  }

  if (!refreshToken) {
    const { protocol, host } = window.location;

    Cookies.remove('jwt');
    Cookies.remove('jwt-refresh');

    window.location.href = `${protocol}//${host}/signin`;
  }
};
