import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { sendUserEvent, useGoogleAnalytics } from '../../../../analytics';
import { useRegisterMutate } from '../../../../api/auth/hooks';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { InputWithError } from '../../../../componentUI/complex/InputWithError';
import { TAG_NAME } from '../../../../componentUI/constants';
import { Button, SecondButton } from '../../../../componentUI/simple/Button';
import { Checkbox } from '../../../../componentUI/simple/Checkbox';
import { LinkClassic, LinkRouter } from '../../../../componentUI/simple/Link';
import { Text } from '../../../../componentUI/simple/Text';
import { NotificationType } from '../../../../componentUI/types';
import { HASH_KEY, TermsBlock, ThemeEnum } from '../../../../components';
import { VisualizationPassSecurity } from '../../../../components/CheckPassword/components';
import { usePasswordIconManage } from '../../../../components/CheckPassword/helpers/usePasswordIconManage';
import { validateEmail, validatePassword, verificationConditionsPassword } from '../../../../helpers/validate';
import { useFormField, useLanguageSwitcherButton, useMatchMedia } from '../../../../hooks';
import { addToast } from '../../../../redux/reducers/toast-reducer';
import { AuthContainer } from '../../Auth';
import { GoogleLogo, LockIcon } from '../../assets';
import { DescriptionList, LogoBlock, TitleBlock } from '../../components';
import { generateGoogleAuthUrl } from '../../helpers/generateGoogleAuthUrl';
import { parseSearchParamToString } from '../../helpers/parseSearchParamToString';
import { OTPCode } from '../OTPCode';
import {
  ContainerSignUpStyled,
  LeftColumnStyled,
  RightColumnStyled,
  SeparatorStyled,
  WrapperBtnSingUpStyled,
  WrapperConditionsStyled,
  WrapperSeparatorStyled,
  WrapperSignInStyled,
  WrapperSignUpStyled,
  WrapperTextStyled,
} from './styled';

export const SignUp = () => {
  useGoogleAnalytics();
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const googleError = searchParams.get('error');
  const googleErrorText = googleError ? parseSearchParamToString(googleError) : t('auth.signUpPage.googleErrorDefault');
  const { LanguageSwitcher } = useLanguageSwitcherButton();

  const {
    mutate: registerUser,
    isLoading: isLoadingRegisterUser,
    isSuccess: isSuccessRegisterUser,
    data: dataRegisterUser,
  } = useRegisterMutate();

  const [email, setEmail, emailError, setEmailError] = useFormField({
    initValue: '',
    withError: true,
  });

  const [newPassword, setNewPassword, newPasswordError, setNewPasswordError] = useFormField({
    initValue: '',
    withError: true,
  });

  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const { rightIconPassword, passTypeInput } = usePasswordIconManage(newPassword);
  const [infoValidPass, setInfoValidPass] = useState({
    isLength: false,
    isNumber: false,
    isSpecialSymbol: false,
    isLowerCase: false,
    isUpperCase: false,
  });

  const accountNotVerified = isSuccessRegisterUser && dataRegisterUser;

  const isDisabledSubmitAndSend = Boolean(
    !termsAndConditions || isLoadingRegisterUser || !newPassword || newPasswordError || !email || emailError,
  );

  const changeEmail = (dataEmail: string) => {
    if (!validateEmail(dataEmail) || dataEmail.length === 0) {
      setEmailError(t('auth.signUpPage.invalidEmail'));
      setEmail(dataEmail);
    } else {
      setEmailError('');
      setEmail(dataEmail);
    }
  };

  const changePassword = (newPassword: string) => {
    setInfoValidPass((prev) => {
      const resultCheck = verificationConditionsPassword(newPassword);
      return { ...prev, ...resultCheck };
    });

    setNewPasswordError(validatePassword(newPassword) ? '' : t('auth.signUpPage.invalidPassword'));
    setNewPassword(newPassword);
  };

  const handleSubmit = () => {
    const hash = window.localStorage.getItem(HASH_KEY);

    registerUser({
      email,
      password: newPassword,
      registrationSourceHash: hash && hash !== 'undefined' ? hash : null,
    });

    sendUserEvent('Sign up button clicked');
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      changeEmail(email);
      changePassword(newPassword);

      if (!isDisabledSubmitAndSend) {
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    if (googleError) {
      dispatch(
        addToast({
          text: googleErrorText,
          type: NotificationType.Default,
        }),
      );
    }
  });

  return (
    <AuthContainer>
      <RightColumnStyled>
        <LanguageSwitcher />

        <LogoBlock />

        {accountNotVerified && <OTPCode email={email} />}

        {!accountNotVerified && (
          <WrapperSignUpStyled>
            <TitleBlock titleText={t('auth.signUpPage.title')} subtitleText={t('auth.signUpPage.subtitle')} />

            <ContainerSignUpStyled>
              <InputWithError
                value={email}
                onChange={(event) => setEmail(event)}
                onBlur={(event) => changeEmail(event.currentTarget.value)}
                placeholder={t('auth.signUpPage.emailPlaceholder')}
                errorMessage={emailError}
                type="email"
              />

              <InputWithError
                value={newPassword}
                onChange={(event) => setNewPassword(event)}
                onBlur={(event) => changePassword(event.currentTarget.value)}
                placeholder={t('auth.signUpPage.passwordPlaceholder')}
                errorMessage={newPasswordError}
                type={passTypeInput}
                leftIcon={<LockIcon fill={newPassword ? COLOR_MAP.controls.normal : COLOR_MAP.controls.placeholder} />}
                rightIcon={rightIconPassword}
                onKeyDown={handleKeyDown}
              />

              <VisualizationPassSecurity
                infoValidPass={infoValidPass}
                password={newPassword}
                designTheme={ThemeEnum.white}
              />

              <Checkbox
                onChange={() => setTermsAndConditions(!termsAndConditions)}
                checked={termsAndConditions}
                id="conditions"
                label={
                  <WrapperConditionsStyled>
                    <Text colorText={COLOR_MAP.text.white}>{t('auth.signUpPage.termsAndConditions')}&nbsp;</Text>

                    <LinkClassic href="/conditions" target="_blank" rel="noopener noreferrer">
                      {t('auth.signUpPage.termsLinkText')}
                    </LinkClassic>
                  </WrapperConditionsStyled>
                }
              />

              <Button isStretch disabled={isDisabledSubmitAndSend} onClick={handleSubmit}>
                {t('auth.signUpPage.createAccountButton')}
              </Button>

              <WrapperSeparatorStyled>
                <SeparatorStyled />
                <Text style={{ flex: '1 0 auto' }} colorText={COLOR_MAP.text.white}>
                  {t('auth.signUpPage.orText')}
                </Text>
                <SeparatorStyled />
              </WrapperSeparatorStyled>

              <WrapperBtnSingUpStyled>
                <SecondButton style={{ gap: '10px' }} isStretch linkButton={generateGoogleAuthUrl('signup')}>
                  <img src={GoogleLogo} width={24} height={24} alt="google logo" />
                  {t('auth.signUpPage.signInWithGoogle')}
                </SecondButton>

                <WrapperSignInStyled>
                  <Text tag={TAG_NAME.span} colorText={COLOR_MAP.text.white}>
                    {t('auth.signUpPage.alreadyHaveAccount')}
                  </Text>

                  <WrapperTextStyled>
                    <Text tag={TAG_NAME.span} colorText={COLOR_MAP.text.white}>
                      {t('auth.signUpPage.clickHereTo')}
                    </Text>
                    &nbsp;
                    <LinkRouter to="/signin">{t('auth.signUpPage.signIn')}</LinkRouter>
                  </WrapperTextStyled>
                </WrapperSignInStyled>
              </WrapperBtnSingUpStyled>
            </ContainerSignUpStyled>
          </WrapperSignUpStyled>
        )}

        <TermsBlock />
      </RightColumnStyled>

      {!isMobile && (
        <LeftColumnStyled>
          <DescriptionList />
        </LeftColumnStyled>
      )}
    </AuthContainer>
  );
};
