import styled from 'styled-components';
import { truthfulAdaptive } from '../../../../componentUI/screen';

const RadioChipsCertificateStyled = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  ${truthfulAdaptive.maxWidth.mobile} {
    flex-direction: column;
    gap: 16px;
  }
`;

export { RadioChipsCertificateStyled };
