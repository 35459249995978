import React, { useCallback } from 'react';
import styled from 'styled-components';

import { COLOR_MAP } from '../../colorsMap';
import { Text, TEXT_SIZE } from '../Text';

import { noop, VisuallyHiddenInput } from '../../utils';

import { Props } from './Radio';

const RadioWrapper = styled.label<{ checked: boolean; disabled?: boolean }>`
  display: flex;
  width: 26px;
  height: 26px;

  align-items: center;
  justify-content: center;

  ${({ checked }) =>
    checked
      ? `background-color: ${COLOR_MAP.controls.clickableNormal};`
      : `background-color: ${COLOR_MAP.controls.clickableNormal}20`};
  ${({ disabled }) => (disabled ? `background-color: ${COLOR_MAP.background.disabled}; pointer-events: none;` : '')};

  border-radius: 4px;
  box-sizing: border-box;

  user-select: none;
  cursor: pointer;
`;

export const SquareRadioChipsV2 = ({ checkedValue, disabled, onChange = noop, name, label = '', value }: Props) => {
  const changeHandler = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  const checked = checkedValue === value;

  return (
    <RadioWrapper disabled={disabled} checked={checked}>
      <Text size={TEXT_SIZE.sub}>{label}</Text>

      <VisuallyHiddenInput type="radio" onChange={changeHandler} checked={checked} name={name} value={value} />
    </RadioWrapper>
  );
};
