import { useMutation } from '@tanstack/react-query';

import { QUERY_KEY } from '../../../common';
import { updateLocale } from '../auth-api';

import { UpdateLocaleRequestType } from '../authType';

type ErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

export const useUpdateLocaleMutate = () => {
  return useMutation({
    mutationKey: [QUERY_KEY.useUpdateLocaleMutate],
    mutationFn: async (updateLocaleData: UpdateLocaleRequestType) => {
      const { data } = await updateLocale(updateLocaleData);

      return data;
    },
    // запоминаем язык только для авторизированных пользователей
    // кнопка находится не только в авторизированной зоне
    // поэтому принял решение не показывать ошибки для данного эндпоинта
    // отсутствие ошибок в данном случае не вредит функционалу
    onError: (error: ErrorType) => {
      return error;
    },
  });
};
