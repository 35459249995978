import { verificationConditionsPassword } from './verificationConditionsPassword';

export const validatePassword = (password: string) => {
  const verificationResult = verificationConditionsPassword(password);

  const arrVerificationResult = Object.values(verificationResult);

  const testResult = arrVerificationResult.includes(false);

  return !testResult;
};
