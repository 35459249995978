import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useGetUserSubscriptions } from '../../../api/subscriptions/hooks/useGetUserSubscriptions';
import { Text, TEXT_WEIGHT } from '../../../componentUI/simple/Text';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { Hr } from '../../../componentUI/simple/Hr';
import { LightButton } from '../../../componentUI/simple/Button';

const SubscriptionBannerContainer = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  background: linear-gradient(90deg, #eeb41e 4.22%, #f46c20 100%);
`;

export const SubscriptionBanner = () => {
  const { data } = useGetUserSubscriptions();
  const navigate = useNavigate();

  if (data?.result) {
    return null;
  }

  return (
    <SubscriptionBannerContainer>
      <Text colorText={COLOR_MAP.text.white} weight={TEXT_WEIGHT.bold} margins={[0, 0, 10, 0]}>
        Try LegitGrails+ now and get unlimited custom certificates.
      </Text>
      <Hr width="100%" />
      <LightButton
        colorText={COLOR_MAP.text.white}
        style={{ fontWeight: '700', marginTop: '10px' }}
        onClick={() => navigate('/account-settings/subscriptions')}
      >
        LEARN MORE {'>'}
      </LightButton>
    </SubscriptionBannerContainer>
  );
};
