type SearchingValueType = {
  object: { [key: string]: boolean } | null;
};

/** Проверка, все ли фотографии загружены в запрошенные индексы */

export const checkUploadedPhotosIndex = ({ object }: SearchingValueType) => {
  if (!object) {
    return false;
  }

  const keys = Object.values(object);

  return !keys.includes(false);
};
