/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { publicRoutes } from '../../router';

export const StartPublicSession = () => {
  return (
    <Routes>
      {publicRoutes.map((el) => (
        <Route key={el.path} element={el.component} path={el.path} />
      ))}
      <Route path="*" element={<Navigate to="signin" />} />
    </Routes>
  );
};
