/** Проверка размера загружаемой фотографии
 * Checking the size of the uploaded photo
 *
 * 1048576 - конвертация размера в мегабайты */

export const checkingFileSize = (selectedFile: File, sizeLimit: number) => {
  if (Number(selectedFile.size) / 1048576 < sizeLimit) {
    return true;
  }

  return false;
};
