import styled from 'styled-components';
import { LinkRouter } from '../../componentUI/simple/Link';

const LogoPlusStyled = styled(LinkRouter)`
  display: flex;
  padding: 5px 10px;
  justify-content: center;

  border-radius: 69px;
  background: linear-gradient(90deg, #b1945f 7.29%, #e1c694 50.52%, #ba9b63 93.24%);

  cursor: pointer;
`;

export { LogoPlusStyled };
