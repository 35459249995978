/* eslint-disable no-param-reassign */
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { TYPE_INPUT } from './constants';
import { InputComponents } from './styled';
import { PropsInput } from './types';

const WrapperLeftIcon = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;

  left: 10px;
  bottom: 11px;
`;

const WrapperRightIcon = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;

  right: 10px;
  bottom: 11px;
`;

const WrapperStyled = styled.div<{ width: string }>`
  position: relative;

  input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  ${({ width }) => `width: ${width};`}
`;

const WrapperComponent = ({
  children,
  rightIcon,
  leftIcon,
  isStretch,
}: {
  children: JSX.Element;
  rightIcon?: JSX.Element | boolean;
  leftIcon?: JSX.Element | boolean;
  isStretch?: boolean;
}) => (
  <WrapperStyled width={isStretch ? '100%' : ''}>
    {children}
    {rightIcon && <WrapperRightIcon>{rightIcon}</WrapperRightIcon>}
    {leftIcon && <WrapperLeftIcon>{leftIcon}</WrapperLeftIcon>}
  </WrapperStyled>
);

export const Input = forwardRef<HTMLInputElement, PropsInput>(
  ({ onChange, type = TYPE_INPUT.text, isStretch, ...props }, ref) => {
    const style = props.style || {};
    delete props.style;

    if (props.rightIcon) {
      Object.assign(style, { paddingRight: '38px' });
    }

    if (props.leftIcon) {
      Object.assign(style, { paddingLeft: '38px' });
    }

    return (
      <WrapperComponent rightIcon={props.rightIcon} leftIcon={props.leftIcon} isStretch={isStretch}>
        <InputComponents ref={ref} onChange={(e) => onChange(e.target.value)} type={type} {...props} style={style} />
      </WrapperComponent>
    );
  },
);
