import styled, { CSSProperties } from 'styled-components';
import { COLOR_MAP } from '../../../componentUI/colorsMap';

const SelectWithTitleWrapper = styled.label`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`;

const SelectWrapper = styled.div`
  width: 100%;
`;

const StyledOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 8px;
`;

const stylesForOption: CSSProperties = {
  padding: '10px',
  borderBottom: `1px solid ${COLOR_MAP.background.brandColorSoftV3}`,
};

const stylesForMenu: CSSProperties = {
  padding: '4px 6px 0 0',
  boxShadow: '0px 2px 4px 0px rgba(203, 173, 115, 0.20), 0px 3px 16px 0px rgba(0, 0, 0, 0.12)',
};

export { SelectWithTitleWrapper, SelectWrapper, StyledOptionWrapper, stylesForOption, stylesForMenu };
