import { useTranslation } from 'react-i18next';
import { NAME_OBJECT_GET_PRODUCT, OptionsMasterDropdownListType } from './types';

export const useOptionsMasterDropdownList = (): Array<OptionsMasterDropdownListType> => {
  const { t } = useTranslation();

  const { publicId, date, brand, modelName, sku, checkStatus } = NAME_OBJECT_GET_PRODUCT;

  return [
    { key: 'Unique code', value: publicId, label: t('authenticationsTablet.optionsMasterDropdownList.uniqueCode') },
    { key: 'date', value: date, label: t('authenticationsTablet.optionsMasterDropdownList.date') },
    { key: 'Brand', value: brand, label: t('authenticationsTablet.optionsMasterDropdownList.brand') },
    { key: 'Model name', value: modelName, label: t('authenticationsTablet.optionsMasterDropdownList.modelName') },
    { key: 'SKU', value: sku, label: t('authenticationsTablet.optionsMasterDropdownList.sku') },
    { key: 'Outcome', value: checkStatus, label: t('authenticationsTablet.optionsMasterDropdownList.outcome') },
  ];
};
