import { GetProductsRequestType } from '../../../../api/product/type';

enum CVS_TYPE {
  CurrentTable = 'CurrentTable',
  CurrentPage = 'CurrentPage',
  SearchAndFilter = 'SearchAndFilter',
}

type Props = {
  paramsGetProductQuery: GetProductsRequestType;
  page: string | undefined;
};

export { CVS_TYPE };
export type { Props };
