import { Dispatch, SetStateAction } from 'react';
import { SelectedSetFiltersType } from '../../../../../../containers/AuthenticationsTablet/types';
import { SelectDropDownListType } from '../../types';

type Props = {
  selectDropDownList: SelectDropDownListType;
  setSelectedSetFilters: Dispatch<SetStateAction<SelectedSetFiltersType>>;
};

type DropDownListStringType = {
  key: string;
  value: string;
  label: string;
};

enum RANGE {
  today = 'today',
  last7 = 'last7',
  last30 = 'last30',
  last90 = 'last90',
  custom = 'custom',
}

export { RANGE };
export type { DropDownListStringType, Props };
