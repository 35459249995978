import styled, { css, CSSProperties } from 'styled-components';
import { Z_INDEX_LAYERS } from '../../componentUI';
import { adaptive, truthfulAdaptive } from '../../componentUI/screen';

export const stylesForCloseButton: CSSProperties = {
  position: 'absolute',
  top: '15px',
  right: '15px',
};

export const stylesForCloseButtonMobile: CSSProperties = {
  position: 'absolute',
  top: '16px',
  right: '16px',
};

export const stylesForCloseButtonNps: CSSProperties = {
  position: 'absolute',
  top: '20px',
  right: '20px',

  width: '20px',
  height: '20px',
};

const isBigHeightStyle = css`
  top: 100px;
  right: 0;
  left: 0;
  transform: translate(0, 0);

  margin: 0 auto;
`;

export const CustomModalContent = styled.div<{ isBigHeight: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${Z_INDEX_LAYERS.modalsLayer.modalLayer};

  ${({ isBigHeight }) => isBigHeight && isBigHeightStyle}

  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 30px 40px;
  height: auto;
  width: calc(100% - 400px);
  max-width: 1200px;

  border: none;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(150, 112, 38, 0.05), 0 16px 28px rgba(0, 0, 0, 0.1);

  background: rgb(255, 255, 255);
  outline: none;

  ${adaptive.maxWidth.desktop} {
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);

    width: calc(100% - 200px);
    max-width: calc(100% - 200px);
  }

  ${adaptive.maxWidth.desktopS} {
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0);

    width: calc(100% - 100px);
    max-width: calc(100% - 100px);
  }

  ${adaptive.maxWidth.mobile} {
    top: 20px;
    left: 16px;
    transform: translate(0, 0);

    width: calc(100% - 32px);
    max-width: calc(100% - 32px);
    padding: 24px 16px;
  }
`;

export const CustomModalNpsContent = styled.div<{ isBigHeight: boolean }>`
  position: absolute;
  top: 20px;
  right: 20px;
  left: auto;
  z-index: ${Z_INDEX_LAYERS.modalsLayer.modalLayer};

  ${({ isBigHeight }) => isBigHeight && isBigHeightStyle}

  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  height: auto;
  width: 100%;
  max-width: 378px;

  border: none;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(150, 112, 38, 0.05), 0 16px 28px rgba(0, 0, 0, 0.1);

  background: rgb(255, 255, 255);
  outline: none;

  ${adaptive.maxWidth.desktop} {
    top: 84px;
    right: 16px;
  }

  ${adaptive.maxWidth.mobile} {
    width: calc(100% - 32px);
  }
`;

export const CustomModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${Z_INDEX_LAYERS.modalsLayer.overlayLayer};

  background-color: rgba(255, 255, 255, 0.85);

  overflow-y: auto;
`;

export const CustomModalPhotoContent = styled.div<{ isBigHeight: boolean; hasTitle: boolean }>`
  position: relative;
  z-index: ${Z_INDEX_LAYERS.modalsLayer.modalLayer};

  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: ${({ hasTitle }) => (hasTitle ? '20px 0 20px 0' : '76px 0')};
  height: auto;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  border: none;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(150, 112, 38, 0.05), 0 16px 28px rgba(0, 0, 0, 0.1);

  background: rgb(255, 255, 255);
  outline: none;

  ${truthfulAdaptive.maxWidth.desktop1440} {
    padding: ${({ hasTitle }) => (hasTitle ? '20px 0' : '64px 0 38px 0')};
  }

  ${truthfulAdaptive.maxWidth.desktop} {
    max-width: initial;
  }

  // dont make modal placed in center of screen, its broken for 380 screen size

  ${truthfulAdaptive.maxWidth.tabletNew} {
    max-height: 1246px;
    justify-content: center;
    padding: ${({ hasTitle }) => (hasTitle ? '20px 0 24px 0' : '64px 0 24px 0')};
  }

  ${truthfulAdaptive.maxWidth.mobile} {
    height: auto;
  }
`;

export const CustomModalPhotoOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${Z_INDEX_LAYERS.modalsLayer.overlayLayer};

  padding: 54px 0;
  background-color: rgba(255, 255, 255, 0.85);

  overflow-y: auto;

  ${truthfulAdaptive.maxWidth.desktop} {
    padding: 36px 100px;
  }

  // dont make modal placed in center of screen, its broken for 380 screen size

  ${truthfulAdaptive.maxWidth.tablet} {
    padding: 16px 16px;
  }
`;

export const CustomModalTextContent = styled.div<{ isBigHeight: boolean; hasTitle: boolean }>`
  position: relative;
  z-index: ${Z_INDEX_LAYERS.modalsLayer.modalLayer};

  display: flex;
  flex-direction: column;
  padding: ${({ hasTitle }) => (hasTitle ? '20px 32px' : '49px 32px')};
  height: auto;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;

  border: none;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(150, 112, 38, 0.05), 0 16px 28px rgba(0, 0, 0, 0.1);

  background: rgb(255, 255, 255);
  outline: none;

  ${truthfulAdaptive.maxWidth.desktop1440} {
    padding: ${({ hasTitle }) => (hasTitle ? '20px 32px' : '49px 32px 20px 32px')};
  }

  ${truthfulAdaptive.maxWidth.desktop} {
    max-width: initial;
  }

  // dont make modal placed in center of screen, its broken for 380 screen size

  ${truthfulAdaptive.maxWidth.tabletNew} {
    justify-content: center;
    padding: ${({ hasTitle }) => (hasTitle ? '20px' : '49px 20px 20px 20px')};
  }

  ${truthfulAdaptive.maxWidth.mobile} {
    height: auto;
  }
`;

export const CustomModalTextOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${Z_INDEX_LAYERS.modalsLayer.overlayLayer};

  padding: 54px 0;
  background-color: rgba(255, 255, 255, 0.85);

  overflow-y: auto;

  ${truthfulAdaptive.maxWidth.desktop} {
    padding: 36px 100px;
  }

  ${truthfulAdaptive.maxWidth.tablet} {
    padding: 32px;
  }

  // dont make modal placed in center of screen, its broken for 380 screen size

  ${truthfulAdaptive.maxWidth.mobile} {
    padding: 32px 0;
  }
`;

export const TitleTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 32px;

  ${truthfulAdaptive.maxWidth.desktop1440} {
    padding: 0 20px;
  }

  ${truthfulAdaptive.maxWidth.tablet} {
    padding: 0 20px;
  }
`;

export const CustomModalErrorContent = styled.div<{ isBigHeight: boolean }>`
  position: relative;
  z-index: ${Z_INDEX_LAYERS.modalsLayer.modalLayer};

  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px;
  height: auto;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  border: none;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(150, 112, 38, 0.05), 0 16px 28px rgba(0, 0, 0, 0.1);

  background: rgb(255, 255, 255);
  outline: none;

  ${truthfulAdaptive.maxWidth.desktop1440} {
    padding: 40px;
  }
`;

export const CustomModalErrorOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${Z_INDEX_LAYERS.modalsLayer.overlayLayer};

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 54px 0;
  background-color: rgba(255, 255, 255, 0.85);

  overflow-y: auto;

  ${truthfulAdaptive.maxWidth.desktop} {
    padding: 36px 100px;
  }

  // dont make modal placed in center of screen, its broken for 380 screen size

  ${truthfulAdaptive.maxWidth.tablet} {
    padding: 16px 16px;
  }
`;
