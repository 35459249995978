import React, { InputHTMLAttributes, useCallback } from 'react';
import styled from 'styled-components';

import { PaymentSystemEnum } from '../../../modules/TopUpV3/types';

import { COLOR_MAP } from '../../colorsMap';
import { VisuallyHiddenInput, noop } from '../../utils';

export type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  checkedValue: string | null;
  name: string;
  value: PaymentSystemEnum;
  onChange: (value: PaymentSystemEnum) => void;
  disabled?: boolean;
};

const RadioWrapper = styled.label<{ isChecked: boolean; disabled?: boolean }>`
  padding: 10px 0px;

  display: flex;

  align-items: center;
  justify-content: center;

  border-radius: 4px;
  box-sizing: content-box;
  overflow: hidden;

  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')};
  ${({ isChecked }) =>
    isChecked
      ? `border: 1px solid ${COLOR_MAP.accent.gold}; background-color: ${COLOR_MAP.background.brandColor10};`
      : 'box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.08), 0px 4px 9px 0px rgba(150, 112, 38, 0.05);'};

  user-select: none;
  cursor: pointer;
`;

export const RadioChipsBtn = ({ children, checkedValue, value, name, disabled, onChange = noop }: Props) => {
  const isChecked = checkedValue === value;

  const changeHandler = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <RadioWrapper disabled={disabled} isChecked={isChecked}>
      {typeof children === 'string' ? children : children}
      <VisuallyHiddenInput type="radio" onChange={changeHandler} checked={isChecked} name={name} value={value} />
    </RadioWrapper>
  );
};
