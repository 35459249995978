import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../../../hooks';
import { LogoMain } from '../../../../common/images';
import { Picture } from '../../../../componentUI/complex/Picture';

export const LogoBlock = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return <Picture url={LogoMain} width={isMobile ? 48 : 72} height={isMobile ? 48 : 72} alt={t('logoBlock.altText')} />;
};
