import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useUpdateLocaleMutate } from '../../api/auth/hooks';
import { GlobeIcon } from '../../common/icons/SvgInline/SvgIcons_12';
import { BUTTON_SIZE, TransparentButton } from '../../componentUI/simple/Button';

const ButtonWrapperStyled = styled.div`
  justify-self: flex-start;
`;

export const useLanguageSwitcherButton = () => {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);

  const { mutate: updateLocale } = useUpdateLocaleMutate();

  useEffect(() => {
    const initialLang = ['en', 'ja'].includes(i18n.language) ? i18n.language : 'en';
    i18n.changeLanguage(initialLang);
    setCurrentLang(initialLang);
  }, []);

  const toggleLanguage = () => {
    const newLang = currentLang === 'en' ? 'ja' : 'en';
    i18n.changeLanguage(newLang);
    setCurrentLang(newLang);

    // шлем на бэк выбранный пользователем язык
    updateLocale({ localeName: newLang.toUpperCase() });
  };

  const getOtherLanguage = () => {
    return currentLang === 'en' ? '日本語' : 'English';
  };

  const LanguageSwitcher = () => (
    <ButtonWrapperStyled>
      <TransparentButton onClick={toggleLanguage}>
        <GlobeIcon size="20px" />
        {getOtherLanguage()}
      </TransparentButton>
    </ButtonWrapperStyled>
  );

  const MenuLanguageSwitcher = () => (
    <ButtonWrapperStyled>
      <TransparentButton onClick={toggleLanguage} size={BUTTON_SIZE.extraSmall}>
        <GlobeIcon size="20px" />
        {getOtherLanguage()}
      </TransparentButton>
    </ButtonWrapperStyled>
  );

  return { LanguageSwitcher, MenuLanguageSwitcher };
};
