import React, { MouseEventHandler } from 'react';
import { LeftNavIcon } from '../../../common/icons/SvgInline/SvgIcons_10';

type Props = {
  onClick: MouseEventHandler<HTMLElement>;
  disabled: boolean;
};

const LeftNav = React.memo(({ disabled, onClick }: Props) => {
  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-left-nav"
      disabled={disabled}
      onClick={onClick}
      aria-label="Previous Slide"
    >
      <LeftNavIcon />
    </button>
  );
});

LeftNav.displayName = 'LeftNav';

export { LeftNav };
