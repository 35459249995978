import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';
import { getCountries } from '../index';

import { addToast } from '../../../redux/reducers/toast-reducer';

type ErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

export const useCountriesQuery = () => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [QUERY_KEY.getCountries],
    queryFn: async () => {
      const { data } = await getCountries();

      return data;
    },

    // преобразуем полученные данные в список для react-select
    select: (data) => {
      return data.entities.map(({ common, id, isEU, postalRegex }) => {
        return {
          value: id,
          label: common,
          isEU,
          postalRegex,
        };
      });
    },
    // список стран статичен, перезапрос не требуется
    cacheTime: Infinity,
    staleTime: Infinity,

    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};
