import React from 'react';

export const ChevronTooltipIcon = ({
  color = '#E5E5E5',
  width = '14',
  height = '12',
}: {
  color?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 12L0.0717975 -1.30507e-06L13.9282 -9.36995e-08L7 12Z" fill={color} />
    </svg>
  );
};
