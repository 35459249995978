import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLOR_MAP } from '../../componentUI/colorsMap';
import { Text, TEXT_SIZE } from '../../componentUI/simple/Text';
import { TermsBlockStyled } from './styled';
import { LinkRouter } from '../../componentUI/simple/Link';

export const TermsBlock = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <TermsBlockStyled>
      <Text size={TEXT_SIZE.annotation} colorText={COLOR_MAP.text.darkGrey}>
        {t('auth.termsBlock.copyright', { year: currentYear })}
      </Text>

      <LinkRouter size={TEXT_SIZE.annotation} to="/conditions">
        {t('auth.termsBlock.termsLinkText')}
      </LinkRouter>
    </TermsBlockStyled>
  );
};
