import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { CSSProperties } from 'styled-components';
import { COLOR_MAP } from '../componentUI/colorsMap';
import { LinkClassic } from '../componentUI/simple/Link';
import { Text, TEXT_SIZE } from '../componentUI/simple/Text';
import { AddCertFromCompletedAuth } from '../components/AddCertFromCompletedAuth';
import {
  AddCertificateIcon,
  LoaderCertificate,
  UploadPhotoIcon,
  ViewCertificateIcon,
} from '../common/icons/SvgInline/SvgIcons_1';
import { EditIcon, TrashIcon } from '../common/icons/SvgInline/SvgIcons_2';
import { CheckStatus } from '../api/product/type';
import { CertificateFinGIcon } from '../common/icons/SvgInline/SvgIcons_11';
import { systemStatus } from '../redux/types';

const stylesForLinkClassic: CSSProperties = {
  width: '100%',
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
};

const ActionInfoStyled = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ActionDraftInfoStyled = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const WrapperDraftIcon = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
`;

const CERTIFICATE_LINK_BASE = 'https://certificates.legitgrails.com/';

type Props = {
  setIdProduct: Dispatch<SetStateAction<string>>;
  OpenDeletePhotoModal: () => void;
  certificateAvailable: boolean | null;
  systemStatus: string;
  checkStatus: CheckStatus | null;
  publicId: string | null;
  isBigSize: boolean;
  id: string;
  isFinGuardActive: boolean;
};

const { DONE, AWAIT_CERTIFICATE, DRAFT, AWAIT_PHOTOS } = systemStatus;
const { FAKE, CANCELED } = CheckStatus;
/**
 * получение информации для поля Action заказа пользователя,
 * формируется пара значёк/текст и действие, исходя из полученного CheckStatus заказа
 *
 * isBigSize - флаг, выставляет размер шрифта равным TEXT_SIZE.regular и размер миниатюр
 */

// кнопка View certificate показывается если к данному заказу с бэка пришло поле certificateAvailable в значении true
// информационная плашка "Generating..." показывается если к данному заказу с бэка пришло поле systemStatus со
// значением AWAIT_CERTIFICATE
// кнопка Upload photos показывается если к данному заказу с бэка пришло поле systemStatus со значением AWAIT_PHOTOS
// и поле checkStatus со значением отличным от FACE
// кнопка Add certificate показывается если к данному заказу с бэка пришло поле systemStatus со значением DONE и
// поле certificateAvailable в значении false
// блок кнопок Edit/Delete показывается если к данному заказу с бэка пришло поле systemStatus со значением DRAFT

export const useGetActionInfo = ({
  setIdProduct,
  OpenDeletePhotoModal,
  certificateAvailable,
  systemStatus,
  checkStatus,
  publicId,
  isBigSize,
  id,
  isFinGuardActive,
}: Props) => {
  const { t } = useTranslation();
  let colorText: string;

  const isCanceled = checkStatus === CANCELED;

  if (isFinGuardActive) {
    colorText = isBigSize ? COLOR_MAP.accent.gold : COLOR_MAP.text.green;
  } else {
    colorText = COLOR_MAP.accent.gold;
  }

  if (certificateAvailable && publicId) {
    return (
      <LinkClassic
        onClick={(event) => event.stopPropagation()}
        size={isBigSize ? TEXT_SIZE.regular : TEXT_SIZE.sub}
        href={CERTIFICATE_LINK_BASE + publicId}
        target="_blank"
        rel="noopener noreferrer"
        colorText={colorText}
        style={stylesForLinkClassic}
      >
        {isFinGuardActive ? (
          <CertificateFinGIcon
            size={isBigSize ? '24px' : '20px'}
            color={isBigSize ? COLOR_MAP.accent.gold : COLOR_MAP.text.green}
          />
        ) : (
          <ViewCertificateIcon size={isBigSize ? '24px' : '20px'} />
        )}
        {t('authenticationsTablet.elementTable.viewCertificate')}
      </LinkClassic>
    );
  }

  if (systemStatus === AWAIT_CERTIFICATE) {
    return (
      <ActionInfoStyled onClick={(event) => event.stopPropagation()}>
        <LoaderCertificate size={isBigSize ? '24px' : '20px'} />
        <Text colorText={COLOR_MAP.text.grey} size={isBigSize ? TEXT_SIZE.regular : TEXT_SIZE.sub}>
          {t('authenticationsTablet.elementTable.generating')}
        </Text>
      </ActionInfoStyled>
    );
  }

  if (systemStatus === AWAIT_PHOTOS && checkStatus !== FAKE) {
    return (
      <ActionInfoStyled>
        <UploadPhotoIcon size={isBigSize ? '24px' : '20px'} color={COLOR_MAP.accent.gold} />
        <Text colorText={COLOR_MAP.text.brandColor} size={isBigSize ? TEXT_SIZE.regular : TEXT_SIZE.sub}>
          {t('authenticationsTablet.elementTable.uploadPhotos')}
        </Text>
      </ActionInfoStyled>
    );
  }

  if (systemStatus === DRAFT) {
    return (
      <ActionDraftInfoStyled>
        <WrapperDraftIcon>
          <EditIcon size={isBigSize ? '24px' : '20px'} />
          <Text colorText={COLOR_MAP.text.brandColor} size={isBigSize ? TEXT_SIZE.regular : TEXT_SIZE.sub}>
            {t('authenticationsTablet.elementTable.edit')}
          </Text>
        </WrapperDraftIcon>

        <WrapperDraftIcon
          onClick={(event) => {
            event.stopPropagation();
            setIdProduct(id);
            OpenDeletePhotoModal();
          }}
        >
          <TrashIcon color={COLOR_MAP.controls.clickableNormal} size={isBigSize ? '24px' : '20px'} />
          <Text colorText={COLOR_MAP.text.brandColor} size={isBigSize ? TEXT_SIZE.regular : TEXT_SIZE.sub}>
            {t('authenticationsTablet.elementTable.delete')}
          </Text>
        </WrapperDraftIcon>
      </ActionDraftInfoStyled>
    );
  }

  if (systemStatus === DONE && !certificateAvailable && !isCanceled) {
    return (
      <AddCertFromCompletedAuth
        id={id}
        RowElement={
          <ActionInfoStyled>
            <AddCertificateIcon size={isBigSize ? '24px' : '20px'} />
            <Text colorText={COLOR_MAP.text.brandColor} size={isBigSize ? TEXT_SIZE.regular : TEXT_SIZE.sub}>
              {t('authenticationsTablet.elementTable.addCertificate')}
            </Text>
          </ActionInfoStyled>
        }
      />
    );
  }

  return null;
};
