const locale = {
  accountType: [
    { value: 'Buyer', label: 'Buyer' },
    { value: 'Seller, Reseller', label: 'Seller, Reseller' },
    { value: 'Consignment Store or Boutique', label: 'Consignment Store or Boutique' },
    { value: 'Retail Business', label: 'Retail Business' },
    { value: 'Online Marketplace', label: 'Online Marketplace' },
    { value: 'Pawn Store', label: 'Pawn Store' },
    { value: 'Other', label: 'Other' },
  ],
  USA_ID: '2688aa4e-076c-4f53-bfc8-01f5a8e33a02',
};

export { locale };
