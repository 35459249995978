/* eslint-disable no-redeclare */
import { Dispatch, SetStateAction, useState } from 'react';

type FormField<T> = [value: T, setValue: Dispatch<SetStateAction<T>>];
type FormFieldWithErr<T> = [
  value: T,
  setValue: Dispatch<SetStateAction<T>>,
  isError: string,
  setError: Dispatch<SetStateAction<string>>,
];

type Params<T> = {
  initValue: (() => T) | T;
  withError?: true;
};
/**
 * useFormField - Хук инкапсулирующий состояние поля формы
 *  @typedef {Object} Config
 *  @property {any} initValue примитивное значение
 *  @property {true=} withError
 *  @param {Config} config объект настройки поля формы со следующими параметрами
 *    ** initValue - начальное значение поля **
 *    ** withError - (необязательный параметр) признак необходимости состояния ошибки в поле **
 *  @return {Array} массив со значениями состояния формы
 *  ** [0] - значение поля **
 *  ** [1] - функция для изменения значения поля **
 *  ** [2] - значение состояния ошибки поля **
 *  ** [3] - функция для изменения состояния ошибки **
 *  @example const[value, setValue] = useFormField({ initValue: 0 });
 *  @example const[value, setValue, valueErr, setValueErr] = useFormField({ initValue: 0, withValue: true });
 */
export function useFormField<T>(config: { initValue: T }): FormField<T>;
export function useFormField<T>(config: { initValue: () => T }): FormField<T>;
export function useFormField<T>(config: { initValue: T; withError?: true }): FormFieldWithErr<T>;
export function useFormField<T>(config: { initValue: () => T; withError?: true }): FormFieldWithErr<T>;

export function useFormField<T>({ initValue, withError }: Params<T>): FormField<T> | FormFieldWithErr<T> {
  const [value, setValue] = useState<T>(initValue);
  const [isError, setError] = useState<string>('');

  if (!withError) {
    return [value, setValue];
  }

  return [value, setValue, isError, setError];
}
