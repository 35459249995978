import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '../../../../../../common/icons/SvgInline/SvgIcons_7';
import { COLOR_MAP } from '../../../../../../componentUI/colorsMap';
import { InputWithError } from '../../../../../../componentUI/complex/InputWithError';
import { SecondButton } from '../../../../../../componentUI/simple/Button';
import { Text, TEXT_SIZE } from '../../../../../../componentUI/simple/Text';
import { ReferralCodeStyled, stylesForButtons, WrapperReferralCodeStyled } from './styled';
import { Props } from './types';

export const ReferralCode = ({
  referralCode,
  referralCodeError,
  changeReferralCode,
  isSuccessReferralCodesActivate,
  referralCodesActivate,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ReferralCodeStyled>
      <WrapperReferralCodeStyled>
        <InputWithError
          name="referralCode"
          value={referralCode}
          errorMessage={referralCodeError}
          onChange={(event) => changeReferralCode(event)}
          onBlur={(event) => changeReferralCode(event.currentTarget.value)}
          placeholder={t('auth.completionRegistration.additionalBasicInfo.placeholders.referralCode')}
          style={{ minWidth: '264px' }}
          rightIcon={isSuccessReferralCodesActivate && <CheckIcon />}
        />

        <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.lightBlue}>
          {t('auth.completionRegistration.additionalBasicInfo.descriptionReferralCode')}
        </Text>
      </WrapperReferralCodeStyled>

      <SecondButton
        style={stylesForButtons}
        onClick={() => {
          referralCodesActivate(referralCode);
        }}
        isStretch
        disabled={isSuccessReferralCodesActivate}
      >
        {t('auth.completionRegistration.additionalBasicInfo.referralBtn')}
      </SecondButton>
    </ReferralCodeStyled>
  );
};
