import styled, { CSSProperties } from 'styled-components';
import { truthfulAdaptive } from '../../../../../../../../componentUI/screen';

const FilterSelectTypeTwoStyled = styled.div`
  display: grid;
  grid-template-columns: 168px 1fr;
  gap: 12px;

  ${truthfulAdaptive.maxWidth.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

// стилизация placeholder для скрытия его текста в три точки
const stylesForPlaceholder: CSSProperties = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: '100%',
  overflow: 'hidden',
};

const stylesForControl: CSSProperties = {
  minHeight: '44px',
};

export { FilterSelectTypeTwoStyled, stylesForControl, stylesForPlaceholder };
