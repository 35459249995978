import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationPageType } from '../../../../common/constants';

import { ContainerTitle, TypeTitle } from './styled';

const { viewed, notViewed } = NotificationPageType;

type Props = {
  tap: NotificationPageType;
  setCurrentTab: Dispatch<SetStateAction<NotificationPageType>>;
};

export const ChosenTabBlock = ({ tap, setCurrentTab }: Props) => {
  const { t } = useTranslation();

  return (
    <ContainerTitle>
      <TypeTitle
        onClick={() => {
          setCurrentTab(notViewed);
        }}
        isChosen={tap === notViewed}
      >
        {t('notificationPage.new')}
      </TypeTitle>

      <TypeTitle
        onClick={() => {
          setCurrentTab(viewed);
        }}
        isChosen={tap === viewed}
      >
        {t('notificationPage.seen')}
      </TypeTitle>
    </ContainerTitle>
  );
};
