import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../../../../hooks';
import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { Picture } from '../../../../../componentUI/complex/Picture';
import { TAG_NAME } from '../../../../../componentUI/constants';
import { Text } from '../../../../../componentUI/simple/Text';
import { TitleBlock } from '../../TitleBlock';
import { SuccessfullyPasswordLogo } from '../../../assets';
import { LinkTextStyled, ScreenResetPassRequestSuccessStyled, WrapperSignInStyled, WrapperTextStyled } from './styled';

export const ScreenResetPassSetNewSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <ScreenResetPassRequestSuccessStyled>
      <Picture url={SuccessfullyPasswordLogo} width={isMobile ? 180 : 225} height={isMobile ? 160 : 200} />

      <TitleBlock
        titleText={t('auth.resetPassword.resetPasswordSuccess.title')}
        subtitleText={t('auth.resetPassword.resetPasswordSuccess.subtitle')}
      />

      <WrapperSignInStyled>
        <WrapperTextStyled>
          <Text tag={TAG_NAME.span} colorText={COLOR_MAP.text.white}>
            {t('auth.resetPassword.resetPasswordSuccess.goToText')}&nbsp;
          </Text>

          <LinkTextStyled
            tag={TAG_NAME.span}
            colorText={COLOR_MAP.text.brandColor}
            onClick={() => {
              navigate('/signin');
            }}
          >
            {t('auth.resetPassword.resetPasswordSuccess.signIn')}
          </LinkTextStyled>
        </WrapperTextStyled>
      </WrapperSignInStyled>
    </ScreenResetPassRequestSuccessStyled>
  );
};
