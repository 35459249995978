import React from 'react';
import { GroupBase, MenuListProps, components } from 'react-select';
import styled from 'styled-components';

import { truthfulAdaptive } from '../../../componentUI/screen';

import { SelectedModelsType } from '../types';

type CustomMenuListProps = MenuListProps<SelectedModelsType, false, GroupBase<SelectedModelsType>>;

const MenuListStyled = styled(components.MenuList)`
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background-color: #d9d9d9;

    border-left: 3px solid white;
    border-right: 3px solid white;

    margin: 4px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #7c7059;
    border-radius: 3px;
  }
`;

const MenuWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${truthfulAdaptive.maxWidth.mobile} {
    grid-template-columns: auto;
  }
`;

export const CustomMenuList = (props: CustomMenuListProps) => {
  const { children } = props;

  return (
    // @ts-ignore
    <MenuListStyled {...props}>
      <MenuWrapper>{children}</MenuWrapper>
    </MenuListStyled>
  );
};
