import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';
import { TAG_NAME } from '../../constants';
import { truthfulAdaptive } from '../../screen';
import { SIZE_STYLE, SIZE_STYLE_MOBILE, TITLE_SIZE, TITLE_WEIGHT } from './constants';

type PropsTitle = HTMLAttributes<HTMLElement> & {
  colorText: string;
  weight: string;
  size: keyof typeof TITLE_SIZE;
  tag?: keyof JSX.IntrinsicElements;
  margins: [number, number, number, number] | [number, number, number] | [number, number] | [number];
};

const TitleComponent = styled.h2<PropsTitle>`
  font-family: Montserrat, sans-serif;
  color: ${({ colorText }) => colorText};
  ${({ size }) => SIZE_STYLE[size]};
  ${({ weight }) => `font-weight: ${weight}`};

  margin: ${({ margins }) => margins.map((margin) => `${margin}px `).join('')};

  ${truthfulAdaptive.maxWidth.mobile} {
    ${({ size }) => SIZE_STYLE_MOBILE[size]};
  }
`;

export const Title = ({
  size = TITLE_SIZE.h5,
  colorText = COLOR_MAP.text.black,
  weight = TITLE_WEIGHT.bold,
  tag = TAG_NAME.h5,
  margins = [0],
  children,
  ...otherProps
}: Partial<PropsTitle>) => {
  return (
    <TitleComponent as={tag} colorText={colorText} size={size} weight={weight} margins={margins} {...otherProps}>
      {children}
    </TitleComponent>
  );
};
