export type userCreditElement = {
  balanceAfter: number;
  comment: null;
  createdAt: string;
  description: string;
  discountPrice: null;
  id: string;
  invoiceId: null;
  price: null;
  productId: string;
  promoPackageId: null;
  promocodeDiscount: null;
  subscriptionDiscount: null;
  subscriptionId: null;
  total: null;
  totalDiscount: null;
  totalDiscountedPrice: null;
  totalPromocodeDiscount: null;
  totalSubscriptionDiscount: null;
  type: {
    id: number;
    name: number;
    displayName: number;
  };
  typeId: string;
  userId: string;
  volume: number;
};

export type ProductAngleTypes = {
  id: string;
  name: string;
  publicName: string | null;
  clickupId: null | string;
  clickupName: string;
  legitCheckId: string;
  iconUrl: null | string;
  orderIndex: number | null;
  comment: string | null;
  __entity: string;
};

export type ProductReasonsType = {
  angle: ProductAngleTypes;
  angleId: string;
  id: string;
  problems: Array<string>;
  productId: string;
  comment: string | null;
  __entity: string;
};

export type AnswerTimeTypes = {
  createdAt: string;
  default: boolean;
  id: string;
  name: string;
  shortName: string;
  updatedAt: null;
  value: string;
  __entity: string;
};

export type BalanceProductTypeTypes = {
  id?: string;
  name?: string;
  publicName: string;
  __entity?: string;
};

export type BundleTypes = {
  clickupId: string;
  id: string;
  name: string;
  publicName: string;
  __entity: string;
};

export enum ResultStatusName {
  OPEN = 'OPEN',
  UPDATED = 'UPDATED',
  ERROR = 'ERROR',
  UPDATE_NEEDED = 'UPDATE_NEEDED',
  READY = 'READY',
  COMPLETED = 'COMPLETED',
  AWAIT_CERTIFICATE = 'AWAIT_CERTIFICATE',
  DRAFT = 'DRAFT',
  AWAIT_QR_LABEL = 'AWAIT_QR_LABEL',
  DOUBLE_CHECK = 'DOUBLE_CHECK',
}

export enum CheckStatus {
  FAKE = 'FAKE',
  LEGIT = 'LEGIT',
  CANCELED = 'CANCELED',
  DRAFT = 'DRAFT',
}

export enum systemStatus {
  AWAIT_CERTIFICATE = 'AWAIT_CERTIFICATE',
  AWAIT_PHOTOS = 'AWAIT_PHOTOS',
  COMPLETED = 'COMPLETED',
  DONE = 'DONE',
  DOUBLE_CHECK = 'DOUBLE_CHECK',
  DRAFT = 'DRAFT',
  ERROR = 'ERROR',
  INIT = 'INIT',
  INITA4000 = 'INITA4000',
  OPEN = 'OPEN',
  PHOTOS_UPLOADED = 'PHOTOS_UPLOADED',
  QUEUE_AWAIT_PHOTOS = 'QUEUE_AWAIT_PHOTOS',
  QUEUE_AWAIT_PHOTOS_REMINDER = 'QUEUE_AWAIT_PHOTOS_REMINDER',
  AWAIT_QR_LABEL = 'AWAIT_QR_LABEL',
  QUEUE_GET_INFO_FROM_A4000 = 'QUEUE_GET_INFO_FROM_A4000',
  QUEUE_CANCEL_AFTER_REMINDER = 'QUEUE_CANCEL_AFTER_REMINDER',
  QUEUE_CREATE_TASK = 'QUEUE_CREATE_TASK',
  QUEUE_COMPLETED = 'QUEUE_COMPLETED',
  QUEUE_SEND_REQUESTED_PHOTOS = 'QUEUE_SEND_REQUESTED_PHOTOS',
  QUEUE_SEND_REQUESTED_PHOTOS_UNABLE_PROVIDE = 'QUEUE_SEND_REQUESTED_PHOTOS_UNABLE_PROVIDE',
  READY = 'READY',
  SEND_REQUESTED_PHOTOS_UNABLE_PROVIDE = 'SEND_REQUESTED_PHOTOS_UNABLE_PROVIDE',
  UPDATED = 'UPDATED',
  UPDATE_NEEDED = 'UPDATE_NEEDED',
}

export type ResultStatusType = {
  id: string;
  name: ResultStatusName;
  publicName: string;
  clickupName: string;
};

export type BrandType = {
  id: string;
  name: string;
  publicName: string;
  clickupId: string | null;
  __entity: string;
};

export type AngelsTypes = {
  clickupId: string | null;
  id: string;
  name: string;
  publicName: string;
  __entity: string;
};

export type ReasonsTypes = {
  angle: AngelsTypes;
  angleId: string;
  id: string;
  productId: string;
  __entity: string;
};

export type FileType = {
  id: string;
  productId: string | null;
  userId: string | null;
  awsKey: string;
  path: string;
  name: string;
  extension: string;
  feature: string;
  angleId: string | null;
  isSelected: boolean | null;
  jpegUrl: string | null;
  isAdditional: boolean | null;
  createdAt: string;
  angle: AngelsTypes | null;
  problems?: Array<string>;
  comment?: string | null;
  resizedFilePath?: string | null;
};

export type AngleTypes = {
  id: string;
  name: string;
  publicName: string | null;
  clickupId: null | string;
  __entity: string;
  iconUrl: null | string;
  orderIndex: number | null;
};

export type AnglesType = {
  id: string;
  necessity: number;
  publicName: string | null;
  forCertificate: boolean | null;
  __entity: string;
  angle: AngleTypes;
  angleId: string;
  productTypeId: string;
  iconUrl: string | null;
  orderIndex: number;
};

// это дубль при переходе на квери удалить
// Тип неполный - бэк проапдейтит типы потом взять из сваггера
export type ResultProductsType = {
  id: string;
  answerTimeId: string;
  autoincrement: number;
  certificateAvailable: boolean | null;
  certificateNeeded: boolean;
  checkStatus: CheckStatus | null;
  clickupId: string;
  clientProductNumber: number;
  comment: null;
  isPhotosUploaded: null;
  itemLabelAvailable: boolean | null;
  itemLabelNeeded: boolean | null;
  publicId: string;
  hashPhoto: string;
  name: null;
  orderId: string;
  productSubType: null;
  reasons: Array<ProductReasonsType> | null;
  modelName: string;
  supplier: string;
  systemStatus: string;
  zendeskTicketId: null;
  zendeskTicketUrl: null;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  dueDate: string;
  photosRequestedAt: null;
  photosRequestedReminderAt: null;
  firstPhotosRequestedAt: null;
  completedAt: null;
  answerTimeOld: null;
  answerTime: AnswerTimeTypes;
  brand: BrandType;
  resultStatus: ResultStatusType;
  files: Array<FileType>;
  brandType: BundleTypes;
  orderNumber: string;
  sku?: string | null;
  productType: {
    id: string;
    name: string;
    publicName: string;
    clickupName: string;
    isSendA4000: boolean;
    legitCheckName: string;
    productTypeAngles: Array<AnglesType>;
  };
  additionalNotes: string | null;
  isDelayed: boolean | null;
  refundedAt: string | null;
  userCredits: Array<userCreditElement>;
  unableToVerify: boolean;
  isCertificateUpdated: boolean | null;
};

export type CategoryTypes = {
  id: string;
  productType: {
    id: string;
    name: string;
    publicName: string;
    __entity: string;
  };
};

export type BrandsTypes = {
  id: string;
  name: string;
  publicName: string;
  clickupId: string | null;
  brandTypeId: string;
  brandType: BrandType;
  productTypeBrands: Array<CategoryTypes>;
};

export type NotificationType = {
  id: string;
  productId: string;
  product: ResultProductsType;
  resultStatus: ResultStatusType;
  checkStatus: string;
  nullable: true;
  title: string;
  text: string;
  isRead: boolean;
  createdAt: string;
};

export type ModelType = {
  brandId: string;
  displayName: string;
  id: string;
  image: {
    angleId: string | null;
    awsKey: string;
    createdAt: string;
    extension: string;
    feature: string;
    id: string;
    isAdditional: boolean;
    isSelected: boolean;
    jpegUrl: string | null;
    modelId: string;
    name: string;
    path: string;
    productId: string | null;
    userId: string | null;
  } | null;
  productTypeId: string;
};
