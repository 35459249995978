/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NotificationType } from '../../../../componentUI/types';
import { addToast } from '../../../../redux/reducers/toast-reducer';
import { useGetCSVMutate } from '../../../../api/product/hooks/useGetCSVMutate';
import { useGetUserSubscriptions } from '../../../../api/subscriptions/hooks/useGetUserSubscriptions';
import { DataBaseIcon } from '../../../../common/icons/SvgInline/SvgIcons_7';
import { TAG_NAME } from '../../../../componentUI/constants';
import { BUTTON_SIZE, CrossButton, SecondButton } from '../../../../componentUI/simple/Button';
import { Radio } from '../../../../componentUI/simple/Radio';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../../../../componentUI/simple/Title';
import { exportCSVSettings } from './helpers';
import { ControlStyled, ExportCSVStyled, WrapperTitleStyled } from './styled';
import { CVS_TYPE, Props } from './types';

const { CurrentTable, CurrentPage, SearchAndFilter } = CVS_TYPE;

export const ExportCSV = ({ paramsGetProductQuery, page }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedTablePage = paramsGetProductQuery.page;
  const [showChosenBlock, setShowChosenBlock] = useState(false);
  const [checkedImport, setCheckedImport] = useState('');

  const paramsGetCSVQuery = exportCSVSettings(paramsGetProductQuery, checkedImport);

  const { data: userSubscriptionsData } = useGetUserSubscriptions();
  const isSubscriptions = userSubscriptionsData?.result;

  const {
    mutate: exportCSV,
    data: dataExportCSV,
    isSuccess: isSuccessExportCSV,
    isLoading: isLoadingExportCSV,
  } = useGetCSVMutate();

  useEffect(() => {
    if (isSuccessExportCSV) {
      const blob = new Blob([dataExportCSV as unknown as BlobPart], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', 'legitGrails.csv');
      a.click();
      setShowChosenBlock(false);
    }
  }, [isSuccessExportCSV]);

  useEffect(() => {
    if (isSuccessExportCSV) {
      dispatch(
        addToast({
          text: t('authenticationsTablet.exportCSV.toastMessage'),
          type: NotificationType.Success,
        }),
      );
    }
  }, [isSuccessExportCSV]);

  if (!isSubscriptions) {
    return null;
  }

  return (
    isSubscriptions && (
      <ExportCSVStyled>
        <SecondButton
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => setShowChosenBlock((prev) => !prev)}
          size={BUTTON_SIZE.extraSmall}
          isStretch
        >
          {t('authenticationsTablet.exportCSV.buttonText')}
        </SecondButton>

        <ControlStyled showChosenBlock={showChosenBlock}>
          <WrapperTitleStyled>
            <Title tag={TAG_NAME.h5} size={TITLE_SIZE.h5} weight={TITLE_WEIGHT.normal}>
              {t('authenticationsTablet.exportCSV.title')}
            </Title>

            <CrossButton onClick={() => setShowChosenBlock(false)} />
          </WrapperTitleStyled>

          <Radio
            value={CurrentTable}
            checkedValue={checkedImport}
            onChange={setCheckedImport}
            name="CVS"
            label={t('authenticationsTablet.exportCSV.radio.currentTable', {
              pageName: t(`authenticationsTablet.exportCSV.pages.${page}`),
            })}
            style={{ gap: '8px' }}
          />

          <Radio
            value={CurrentPage}
            checkedValue={checkedImport}
            onChange={setCheckedImport}
            name="CVS"
            label={t('authenticationsTablet.exportCSV.radio.currentPage', { pageNumber: selectedTablePage })}
            style={{ gap: '8px' }}
          />

          <Radio
            value={SearchAndFilter}
            checkedValue={checkedImport}
            onChange={setCheckedImport}
            name="CVS"
            label={t('authenticationsTablet.exportCSV.radio.searchAndFilter')}
            style={{ gap: '8px' }}
          />

          <SecondButton
            onClick={() => exportCSV(paramsGetCSVQuery)}
            size={BUTTON_SIZE.extraSmall}
            disabled={!checkedImport || isLoadingExportCSV}
          >
            <DataBaseIcon />
            {t('authenticationsTablet.exportCSV.exportButton')}
          </SecondButton>
        </ControlStyled>
      </ExportCSVStyled>
    )
  );
};
