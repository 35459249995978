import styled from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';
import { truthfulAdaptive } from '../../screen';

export const HrVertical = styled.div<{ width?: string; height?: string; color?: string }>`
  ${({ height }) => (height ? `height: ${height};` : 'height: 20px;')}
  ${({ width }) => (width ? `width: ${width};` : 'width: 2px;')}
  ${({ color }) => (color ? `background-color: ${color};` : `background-color: ${COLOR_MAP.text.lightGrey};`)}

  ${truthfulAdaptive.maxWidth.mobile} {
    display: none;
  }
`;
