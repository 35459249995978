/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import Select from 'react-select';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../../../../../componentUI/simple/Input';
import { noop } from '../../../../../../../../componentUI/utils';
import { DropdownIndicator } from '../../../../../../../../common';
import { ArrowRight, Calendar } from '../../../../../../../../common/icons/SvgInline/SvgIcons_2';
import { dataDotFormatter } from '../../../../../../../../helpers';
import { useMatchMedia } from '../../../../../../../../hooks';
import { objectFormation } from '../../helpers';
import './dataFilter.css';
import { getDateRange } from './helpers';
import {
  ArrowWrapper,
  DateFilterStyled,
  DatePickerWrapper,
  stylesForControl,
  WrapperMultiSelectStyled,
} from './styled';
import { DropDownListStringType, Props, RANGE } from './types';

const { today, custom } = RANGE;

export const DateFilter = ({ selectDropDownList, setSelectedSetFilters }: Props) => {
  const { t, i18n } = useTranslation();

  const { dropDownList, id, nameObject } = selectDropDownList;

  const { isMobile } = useMatchMedia();

  // содержит текущий элемент выбранный из списка dropDownList
  const [range, setRange] = useState<DropDownListStringType | null>(null);

  // хранит дату из первого календаря и дату из второго календаря
  const [fromDatePicker, setFromDatePicker] = useState<Date>();
  const [untilDatePicker, setUntilDatePicker] = useState<Date>();

  const [fromDate, setFromDate] = useState('');
  const [untilDate, setUntilDate] = useState('');

  // данные два стейта открывают/закрывают меню календаря
  // isOpenFromDate - первый интпут, isOpenUntilDate - второй инпут
  const [isOpenFromDate, setIsOpenFromDate] = useState(false);
  const [isOpenUntilDate, setIsOpenUntilDate] = useState(false);

  // используется для предотвращения ввода значения если выбран предустановленный вариант из списка
  const isStaticRange = range?.value !== custom;
  // если выбран текущий день (1- Today) то рисуется только одно поле ввода
  const isTodayInput = range?.value === today;
  // если выбран параметр с произвольным выбором
  const isCustomRange = range?.value === custom;

  useEffect(() => {
    const { fromDate: DateOne, untilDate: dateTwo } = getDateRange({
      selectRange: range?.value,
      fromDatePicker,
      untilDatePicker,
    });

    setFromDate(DateOne);
    setUntilDate(dateTwo);
  }, [range, fromDatePicker, untilDatePicker]);

  useEffect(() => {
    if (!range?.value || !fromDate || !untilDate) {
      return;
    }

    setSelectedSetFilters((prev) => {
      const settingSelect = objectFormation({
        nameObject,
        idFilter: id,
        parameterOne: fromDate,
        parameterTwo: untilDate,
      });

      return {
        ...prev,
        ...settingSelect,
      };
    });
  }, [fromDate, untilDate]);

  return (
    <DateFilterStyled>
      <Select
        styles={{
          control: (base) => ({ ...base, ...stylesForControl }),
        }}
        components={{ DropdownIndicator }}
        options={dropDownList as Array<DropDownListStringType>}
        value={range}
        onChange={(option) => {
          if (option) {
            setRange(option);
          }
        }}
        isSearchable={false}
        placeholder={t('select.placeholder')}
      />

      {range && isTodayInput && (
        <Input
          onChange={noop}
          value={dataDotFormatter(fromDate, i18n.language)}
          placeholder="DD.MM.YYYY"
          disabled
          rightIcon={<Calendar isDisabled={isStaticRange} />}
        />
      )}

      {range && !isTodayInput && (
        <WrapperMultiSelectStyled>
          <DatePickerWrapper>
            <DatePicker
              isOpen={isOpenFromDate}
              onChange={(value) => {
                // либа отдает кривой тип
                if (value instanceof Date) {
                  setFromDatePicker(value);
                }
              }}
              value={fromDate || null}
              maxDate={new Date()}
            />

            <Input
              onChange={noop}
              value={fromDate && dataDotFormatter(fromDate, i18n.language)}
              placeholder="DD.MM.YYYY"
              disabled
              isStretch
              rightIcon={
                <Calendar
                  isDisabled={isStaticRange}
                  props={{
                    onClick: () => {
                      if (isCustomRange) setIsOpenFromDate((prev) => !prev);
                    },
                    style: { cursor: 'pointer' },
                  }}
                />
              }
            />
          </DatePickerWrapper>

          {!isMobile && (
            <ArrowWrapper>
              <ArrowRight />
            </ArrowWrapper>
          )}

          <DatePickerWrapper>
            <DatePicker
              isOpen={isOpenUntilDate}
              onChange={(value) => {
                // либа отдает кривой тип
                if (value instanceof Date) {
                  setUntilDatePicker(value);
                }
              }}
              value={untilDate || null}
              maxDate={new Date()}
            />

            <Input
              onChange={noop}
              value={untilDate && dataDotFormatter(untilDate, i18n.language)}
              placeholder="DD.MM.YYYY"
              disabled
              isStretch
              rightIcon={
                <Calendar
                  isDisabled={isStaticRange}
                  props={{
                    onClick: () => {
                      if (isCustomRange) setIsOpenUntilDate((prev) => !prev);
                    },
                    style: { cursor: 'pointer' },
                  }}
                />
              }
            />
          </DatePickerWrapper>
        </WrapperMultiSelectStyled>
      )}
    </DateFilterStyled>
  );
};
