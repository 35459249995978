/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';

import { usePromoPackagesActivateMutate } from '../../../../api/PromoPackages/hooks/usePromoPackagesActivateMutate';

import { QUERY_KEY } from '../../../../common';

import { useStyledModal } from '../../../../hooks';

import { ConvertedBalanceModalNewUser } from '../../../ConvertedBalance/containers/ConvertedBalanceModalNewUser';

import { HASH_KEY } from '../../../../components/Promo';

import { stylesForModal } from './styled';

const MINIMUM_NUMBER_ELEMENTS = 0;

export const IsNewUser = () => {
  const client = useQueryClient();

  const {
    mutate: activatePromoPackages,
    data: PromoPackagesActivateData,
    isError: PromoPackagesActivateError,
    isSuccess: PromoPackagesActivateSuccess,
  } = usePromoPackagesActivateMutate();

  const [ModalConvertedBalanceModalNewUser, toggleModal] = useStyledModal();

  useEffect(() => {
    const hash = window.localStorage.getItem(HASH_KEY);

    if (hash && hash !== 'undefined') {
      window.localStorage.removeItem(HASH_KEY);
      activatePromoPackages(hash);
    }
  }, []);

  useEffect(() => {
    if (PromoPackagesActivateSuccess && PromoPackagesActivateData.result) {
      client.invalidateQueries({ queryKey: [QUERY_KEY.getBalanceUserInfo] });
    }

    if (PromoPackagesActivateData && PromoPackagesActivateData.packages.length > MINIMUM_NUMBER_ELEMENTS) {
      toggleModal();
    }
  }, [PromoPackagesActivateError, PromoPackagesActivateSuccess, PromoPackagesActivateData]);

  return (
    <>
      <ModalConvertedBalanceModalNewUser stylesForModal={stylesForModal} onClose={toggleModal}>
        {PromoPackagesActivateData && (
          <ConvertedBalanceModalNewUser PromoPackagesActivateData={PromoPackagesActivateData} />
        )}
      </ModalConvertedBalanceModalNewUser>
    </>
  );
};
