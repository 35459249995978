import Cookies from 'js-cookie';

import { instance } from '../axios-instance';

import {
  entitiesNotificationsSettingType,
  entitiesNotificationsType,
  notificationsSettingsType,
  responseEditNotificationSetting,
} from './types';

export const getNotification = (paramsQuery: { limit: number; 'is-read': boolean }) => {
  return instance.get<entitiesNotificationsType>('/user-notifications', {
    params: {
      sort: 'DESC',
      page: 1,
      ...paramsQuery,
    },
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const markedReadNotification = (id: string) => {
  return instance.patch<{}>(
    `/user-notifications/read${id}`,
    {},
    {
      headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
    },
  );
};

export const getNotificationSetting = () => {
  return instance.get<Array<entitiesNotificationsSettingType>>('/user-notification-settings', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const editNotificationSetting = (settingData: Array<notificationsSettingsType> | undefined) => {
  return instance.put<responseEditNotificationSetting>(
    '/user-notification-settings',
    { data: settingData },
    {
      headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
    },
  );
};
