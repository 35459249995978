import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';
import { getCountryStates } from '../index';

import { addToast } from '../../../redux/reducers/toast-reducer';
import { GetCountryStatesRequestType } from '../types';

type ErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

// export const useProductQuery = (idProduct: string, enabled = false) => {
//   const dispatch = useDispatch();

//   return useQuery({
//     queryKey: [QUERY_KEY.getProductId, idProduct],
//     queryFn: async () => {
//       const { data } = await getProduct(idProduct);

//       return data;
//     },
//     enabled,
//     onError: (error: ErrorType) => {
//       dispatch(
//         addToast({
//           text: error?.response?.data?.message || 'Something is wrong, please try again.',
//         }),
//       );
//     },
//   });
// };

export const useCountryStatesQuery = (value: GetCountryStatesRequestType, enabled = false) => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [QUERY_KEY.getCountryStates],
    queryFn: async () => {
      const { data } = await getCountryStates(value);

      return data;
    },
    // преобразуем полученные данные в список для react-select
    select: (data) => {
      return data.map(({ displayName, id }) => {
        return {
          value: id,
          label: displayName,
        };
      });
    },
    enabled,
    // список стран статичен, перезапрос не требуется
    cacheTime: Infinity,
    staleTime: Infinity,
    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};
