import Cookies from 'js-cookie';
import { instance } from '../axios-instance';

import { GetModelsRequestType, GetModelsResponseType } from './types';

export const getModels = (data: GetModelsRequestType) => {
  return instance.get<GetModelsResponseType>('models', {
    params: data,
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};
