import styled from 'styled-components';

const TermsBlockStyled = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  align-self: flex-start;
`;

export { TermsBlockStyled };
