import styled, { CSSProperties } from 'styled-components';
import { Z_INDEX_LAYERS } from '../../../componentUI';
import { COLOR_MAP } from '../../../componentUI/colorsMap';

const WelcomeBannerNewUserStyled = styled.div<{ isDesktopS: boolean }>`
  position: relative;

  margin: ${({ isDesktopS }) => (isDesktopS ? '20px 20px 0 20px' : '24px 40px 0 40px;')};
  padding: ${({ isDesktopS }) => (isDesktopS ? '12px' : '12px 0px 12px 16px;')};

  display: flex;
  flex-direction: column;
  gap: 4px;

  border-radius: 10px;
  background-color: ${COLOR_MAP.background.greyShade1};
  overflow: hidden;
`;

const WrapperImgStyled = styled.div`
  position: absolute;

  top: 0;
  right: 0;

  width: 223px;
  height: 74px;
`;

const stylesForDarkButton: CSSProperties = {
  position: 'absolute',
  top: '6px',
  right: '6px',
  zIndex: `${Z_INDEX_LAYERS.other}`,
};

export { stylesForDarkButton, WelcomeBannerNewUserStyled, WrapperImgStyled };
